import React, { useContext } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import View from '../../common/View';
import { mq } from '../../../config/theme';
import ManagementTournamentEditor from './ManagementTournamentEditor';
import TournamentVenues from './TournamentVenues';

const TournamentView = ({ className }) => {
  const { state, updateManagementTournament } = useContext(LiveStreamContext);
  const { pkMgmtTournament } = useParams();
  const location = useLocation();
  const tournament = state.managementTournaments.value.find(
    (t) => t.pkMgmtTournament.toString() === pkMgmtTournament
  );

  return (
    <Segment
      aria-label="tournamentView"
      className={className}
      css={mq({
        width: '100%',
        marginLeft: [null, null, '20px !important'],
        marginTop: [null, null, '34px !important'],
        minHeight: '50px'
      })}
      loading={state.managementTournaments.loading}
      role="region"
    >
      {tournament && !state.managementTournaments.loading && (
        <View
          css={mq({
            flexDirection: ['column', 'row'],
            alignItems: ['center', 'flex-start']
          })}
        >
          <View css={{ flexDirection: 'column', flex: 1, width: '100%' }}>
            <Menu
              aria-label="tournamentNav"
              pointing
              role="tablist"
              secondary
              stackable
            >
              <Menu.Item
                active={location.pathname.startsWith(
                  `/tournaments/${pkMgmtTournament}/details`
                )}
                as={Link}
                css={{ justifyContent: 'center' }}
                name="Details"
                to={`/tournaments/${pkMgmtTournament}/details`}
              />
              <Menu.Item
                active={location.pathname.startsWith(
                  `/tournaments/${pkMgmtTournament}/venues`
                )}
                as={Link}
                css={{ justifyContent: 'center' }}
                name="Venues"
                to={`/tournaments/${pkMgmtTournament}/venues`}
              />
            </Menu>
            <Switch>
              <Route path={`/tournaments/${pkMgmtTournament}/details`}>
                <ManagementTournamentEditor tournament={tournament} />
              </Route>
              <Route path={`/tournaments/${pkMgmtTournament}/venues`}>
                <TournamentVenues
                  canRedirect
                  onAdd={(venue) =>
                    updateManagementTournament(tournament.pkMgmtTournament, {
                      ...tournament,
                      pkVenues: [
                        ...tournament.venues.map((v) => v.id),
                        venue.id
                      ]
                    })
                  }
                  onRemove={(venue) =>
                    updateManagementTournament(tournament.pkMgmtTournament, {
                      ...tournament,
                      pkVenues: [
                        ...tournament.venues
                          .filter((v) => v.id !== venue.id)
                          .map((v) => v.id)
                      ]
                    })
                  }
                  venues={tournament.venues}
                />
              </Route>
              <Redirect to={`/tournaments/${pkMgmtTournament}/details`} />
            </Switch>
          </View>
        </View>
      )}
    </Segment>
  );
};

TournamentView.propTypes = { className: PropTypes.string };

export default TournamentView;
