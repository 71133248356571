import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Group, Select, Stack, Tooltip } from '@mantine/core';
import { format } from 'date-fns';
import PaginationList from '../../common/PaginationList';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { mq } from '../../../config/theme';
import ActionListItem from '../../common/ActionListItem';
import {
  currencyFormat,
  singularPluralFormat,
  stripUtcDateFormatting
} from '../../../helpers/formatHelper';
import { PAYMENT_PROVIDER_ENUM } from '../../../config/constants';
import FixRegCartCheckoutSessionsModal from './FixRegCartCheckoutSessionsModal';
import ViewRegCartOrderDetailsModal from '../MerchView/ViewRegCartOrderDetailsModal';

const VIEW_MODAL_ACTIONS = {
  VIEW: 'view',
  FIX: 'fix'
};

const MissingTransfersView = () => {
  const hasFetched = useRef(false);
  const {
    state,
    fetchRegCartsWithMissingTransfersSummary,
    fetchRegCartsWithMissingTransfers
  } = useContext(RegistrationContext);
  const [modalState, setModalState] = useState({
    isOpen: false,
    item: null,
    action: ''
  });
  const [filterState, setFilterState] = useState({
    pkRegAssociation: null,
    pageIndex: 1
  });
  const isLoading =
    !hasFetched.current || state.regCartsMissingTransfers.loading;

  const associationOptions = state.regCartsMissingTransfers.value.reduce(
    (r, c) => {
      if (
        !r.find((f) => f.value === c.regAssociation.pkRegAssociation.toString())
      ) {
        r.push({
          value: c.regAssociation.pkRegAssociation.toString(),
          label: c.regAssociation.association.name,
          regAssociationPaymentProvider:
            c.regAssociation.regAssociationPaymentProvider
        });
      }
      return r;
    },
    []
  );
  const selectedRegAssociation = associationOptions.find(
    (a) => a.value === filterState.pkRegAssociation
  );
  const isTransferFixSupported =
    selectedRegAssociation?.regAssociationPaymentProvider?.fkPaymentProvider ===
    PAYMENT_PROVIDER_ENUM.SPORTSPAY;

  const filteredRegCartsMissingTransfers = state.regCartsMissingTransfers.value
    .filter(
      (f) =>
        !filterState.pkRegAssociation ||
        f.regAssociation.pkRegAssociation.toString() ===
          filterState.pkRegAssociation.toString()
    )
    .sort(
      (a, b) =>
        new Date(a.regCartHistory.cart.lineItems[0].purchaseDate) -
        new Date(b.regCartHistory.cart.lineItems[0].purchaseDate)
    );

  useEffect(() => {
    fetchRegCartsWithMissingTransfersSummary();
    fetchRegCartsWithMissingTransfers();
    setFilterState({
      pkRegAssociation: null,
      pageIndex: 1
    });
    hasFetched.current = true;
  }, []);

  return (
    <>
      <Card
        shadow="lg"
        sx={{
          flex: 1,
          border: 'solid 1px lightgrey',
          padding: 15,
          gap: 5,
          alignSelf: 'stretch',
          minHeight: 400
        }}
      >
        <Stack sx={{ flex: 1, gap: 10 }}>
          <Group
            sx={mq({
              flex: 1,
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              alignItems: 'end',
              flexDirection: ['column', 'row']
            })}
          >
            <Select
              clearable
              data={associationOptions
                .map((a) => ({
                  value: a.value,
                  label: a.label
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              disabled={isLoading}
              label="Association"
              onChange={(value) =>
                setFilterState({
                  ...filterState,
                  pkRegAssociation: value
                })
              }
              placeholder="Search by association..."
              searchable
              sx={mq({ flex: [1, 'unset'], alignSelf: ['stretch', 'start'] })}
              value={filterState.pkRegAssociation ?? ''}
            />
            <Tooltip
              disabled={
                !!filterState.pkRegAssociation && isTransferFixSupported
              }
              inline
              label={
                !filterState.pkRegAssociation
                  ? 'Select an association before continuing.'
                  : 'Association is using Stripe. Transfers must manually be fixed.'
              }
              withArrow
              withinPortal
            >
              <Stack style={{ position: 'relative' }}>
                <Button
                  disabled={
                    !filterState.pkRegAssociation || !isTransferFixSupported
                  }
                  onClick={() =>
                    setModalState({
                      ...modalState,
                      isOpen: true,
                      item: null,
                      action: VIEW_MODAL_ACTIONS.FIX
                    })
                  }
                  sx={mq({ width: ['100%', 'unset'] })}
                  variant="outline"
                >
                  Fix Transfers{' '}
                  {filteredRegCartsMissingTransfers.length > 0
                    ? `(${filteredRegCartsMissingTransfers.length})`
                    : ''}
                </Button>
              </Stack>
            </Tooltip>
          </Group>

          <PaginationList
            emptyMessage="No transfers exists"
            isLoading={isLoading}
            items={filteredRegCartsMissingTransfers.map((c) => (
              <ActionListItem
                key={c.regCartHistory.pkRegCart}
                actions={[
                  {
                    label: 'View',
                    value: VIEW_MODAL_ACTIONS.VIEW
                  }
                ]}
                avatar={c.regAssociation.logoImageUrl}
                description={c.regAssociation.association.name}
                extraDescriptions={[
                  format(
                    new Date(
                      `${stripUtcDateFormatting(
                        c.regCartHistory.cart.lineItems[0].purchaseDate
                      )}Z`
                    ),
                    'M/dd/yyyy'
                  ),
                  singularPluralFormat(
                    c.regCartHistory.cart.lineItems.length,
                    'cart item',
                    'cart items'
                  ),
                  c.regCartHistory.user.name,
                  c.regCartHistory.user.email
                ]}
                header={currencyFormat(
                  c.regCartHistory.cart.lineItems.reduce(
                    (r1, c1) => r1 + c1.amountPaidInCents,
                    0
                  ) / 100
                )}
                onAction={(action) => {
                  setModalState({
                    isOpen: true,
                    item: c,
                    action
                  });
                }}
              />
            ))}
            itemsPerPage={10}
            LoadingComponent={ActionListItem}
            onPageChange={(pageIndex) =>
              setFilterState({
                ...filterState,
                pageIndex
              })
            }
            pageIndex={filterState.pageIndex}
            showItemBorder
          />
        </Stack>
      </Card>

      <ViewRegCartOrderDetailsModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_MODAL_ACTIONS.VIEW
        }
        onClose={() => setModalState({ ...modalState, isOpen: false })}
        pkRegCart={modalState.item?.regCartHistory.pkRegCart}
      />

      <FixRegCartCheckoutSessionsModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_MODAL_ACTIONS.FIX
        }
        onClose={() => setModalState({ ...modalState, isOpen: false })}
        pkRegAssociation={filterState.pkRegAssociation}
      />
    </>
  );
};

MissingTransfersView.propTypes = {};

export default MissingTransfersView;
