import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Group,
  Image,
  Skeleton,
  Stack,
  Text,
  useMantineTheme
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { mq } from '../../../config/theme';
import MerchPackageOrderDetailsModal from '../MerchView/MerchPackageOrderDetailsModal';

const CheckoutMerchItem = ({
  lineItem,
  regCartMerchPackage,
  isDisabled,
  isLoading,
  withBorder
}) => {
  const theme = useMantineTheme();
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: null
  });
  const firstImage = regCartMerchPackage?.merchPackage.merchPackageImages.sort(
    (a, b) => a.sort - b.sort
  )[0];
  const requiredInputs =
    regCartMerchPackage?.merchPackage.merchPackageInputs.filter(
      (i) => i.isRequired
    ) || [];
  const allRequiredInputsFilled =
    requiredInputs.length === 0 ||
    requiredInputs.every((i) =>
      regCartMerchPackage.regCartMerchPackageInputAnswers.some(
        (a) => a.fkMerchPackageInput === i.pkMerchPackageInput
      )
    );

  return regCartMerchPackage ? (
    <Card
      key={regCartMerchPackage.pkRegCartMerchPackage}
      sx={mq({
        padding: 0,
        borderRadius: [0, 0, 0, 4],
        border: withBorder ? 'solid 1px lightgrey' : 'none'
      })}
    >
      <MerchPackageOrderDetailsModal
        isOpen={modalState.isOpen}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
        regCartMerchPackage={regCartMerchPackage}
      />
      <Divider sx={mq({ display: ['flex', 'flex', 'none'] })} />
      <Stack style={{ gap: 0, flexWrap: 'nowrap' }}>
        <Group
          sx={mq({
            flex: 1,
            margin: 30,
            flexWrap: 'nowrap',
            flexDirection: ['column', 'column', 'row']
          })}
        >
          <Group style={{ flexWrap: 'nowrap', alignSelf: 'stretch', flex: 2 }}>
            <Group style={{ alignSelf: 'start', flex: 1 }}>
              <Group
                component={Link}
                to={`/association/${regCartMerchPackage.fkRegAssociation}`}
              >
                <Image
                  fit="contain"
                  height={100}
                  src={firstImage?.image || ''}
                  width={100}
                />
              </Group>

              <Stack
                style={{
                  flex: 1,
                  gap: 0,
                  textDecoration: 'none',
                  justifyContent: 'center',
                  color: '#000'
                }}
              >
                <Text size="lg">{regCartMerchPackage.merchPackage.name}</Text>
                <Text
                  style={{
                    fontSize: 14,
                    color: 'grey',
                    textOverflow: 'ellipsis',
                    lineHeight: '16px',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    lineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {regCartMerchPackage.merchPackage.description}
                </Text>

                {lineItem.addOns.length > 0 && (
                  <>
                    <Divider
                      label={
                        <Text color="dark" weight={700}>
                          Add-ons
                        </Text>
                      }
                      labelPosition="center"
                      style={{ marginTop: 10 }}
                    />

                    <Stack style={{ marginTop: 10, gap: 15 }}>
                      {lineItem.addOns.map((addOn) => (
                        <Group
                          key={addOn.productName}
                          style={{ gap: 5, justifyContent: 'space-between' }}
                        >
                          <Text>{addOn.productName}</Text>
                          <Group style={{ gap: 5 }}>
                            <Text weight={700}>
                              {addOn.unitAmountInCents < 0 ? (
                                <>
                                  (
                                  <NumericFormat
                                    decimalScale={2}
                                    displayType="text"
                                    fixedDecimalScale
                                    prefix="$"
                                    thousandSeparator
                                    value={addOn.unitAmountInCents / 100}
                                  />
                                  )
                                </>
                              ) : (
                                <NumericFormat
                                  decimalScale={2}
                                  displayType="text"
                                  fixedDecimalScale
                                  prefix="$"
                                  thousandSeparator
                                  value={addOn.unitAmountInCents / 100}
                                />
                              )}
                            </Text>
                            <Text weight={700}>CAD</Text>
                          </Group>
                        </Group>
                      ))}
                    </Stack>
                  </>
                )}
              </Stack>
            </Group>
          </Group>

          <Stack
            sx={mq({
              flex: 1,
              alignSelf: 'start',
              placeItems: 'end',
              marginLeft: 'auto',
              gap: [5, 10],
              flexDirection: ['row-reverse', 'row-reverse', 'column'],
              justifyContent: ['space-between', 'space-between', 'inital'],
              width: ['100%', '100%', 'unset']
            })}
          >
            <Stack style={{ gap: 0, alignItems: 'end' }}>
              <Group style={{ gap: 10, flexWrap: 'nowrap' }}>
                <Text
                  size="xl"
                  style={{
                    textDecoration: lineItem.financialAssistance
                      ? 'line-through'
                      : 'normal'
                  }}
                  weight={700}
                >
                  <NumericFormat
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={lineItem.subtotalInCents / 100}
                  />{' '}
                  CAD
                </Text>
              </Group>
              {lineItem.taxInCents > 0 && (
                <Group style={{ gap: 5, flexWrap: 'nowrap' }}>
                  <Text
                    size="md"
                    style={{
                      textDecoration: lineItem.financialAssistance
                        ? 'line-through'
                        : 'normal'
                    }}
                    weight={500}
                  >
                    +{' '}
                    <NumericFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={lineItem.taxInCents / 100}
                    />{' '}
                    @{' '}
                    <NumericFormat
                      displayType="text"
                      fixedDecimalScale
                      suffix="%"
                      thousandSeparator
                      value={lineItem.taxRate * 100}
                    />{' '}
                    TAX
                  </Text>
                </Group>
              )}
              {lineItem.customerPaysServiceFees &&
                lineItem.serviceFeeInCents > 0 && (
                  <Group style={{ gap: 5, flexWrap: 'nowrap' }}>
                    <Text
                      size="md"
                      style={{
                        textDecoration: lineItem.financialAssistance
                          ? 'line-through'
                          : 'normal'
                      }}
                      weight={500}
                    >
                      +{' '}
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={lineItem.serviceFeeInCents / 100}
                      />{' '}
                      Service fee
                    </Text>
                  </Group>
                )}
              {lineItem.financialAssistance && (
                <Group style={{ gap: 5, flexWrap: 'nowrap' }}>
                  <Text size="md" weight={500}>
                    Jumpstart{' '}
                    <NumericFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={0.0}
                    />{' '}
                    CAD
                  </Text>
                </Group>
              )}
            </Stack>
          </Stack>
        </Group>
        <Group
          style={{
            backgroundColor: theme.black,
            color: '#FFF',
            opacity: 0.8,
            padding: 30,
            justifyContent: 'space-between'
          }}
        >
          <Stack style={{ gap: 0 }}>
            <Text weight={700}>Merch Package</Text>
            <Text>{regCartMerchPackage.merchPackage.name}</Text>
          </Stack>

          {isDisabled ? (
            <Button
              color="gray"
              disabled={isLoading}
              onClick={() => setModalState({ isOpen: true, action: 'view' })}
              radius="xs"
              size="lg"
              style={{
                color: theme.white,
                borderColor: theme.white,
                width: 200
              }}
              variant="outline"
            >
              View Info
            </Button>
          ) : allRequiredInputsFilled ? (
            <Button
              color="blue"
              disabled={isLoading}
              onClick={() => setModalState({ isOpen: true, action: 'view' })}
              radius="xs"
              size="lg"
              style={{ width: 200 }}
              variant="filled"
            >
              {regCartMerchPackage.merchPackage.merchPackageInputs.length === 0
                ? 'View Info'
                : 'Update Info'}
            </Button>
          ) : (
            <Button
              color="gray"
              disabled={isLoading}
              onClick={() => setModalState({ isOpen: true, action: 'view' })}
              radius="xs"
              size="lg"
              style={{
                color: theme.white,
                borderColor: theme.white,
                animationName: 'pulse',
                animationDuration: '2s',
                animationIterationCount: 'infinite',
                width: 200,
                '@keyframes pulse': { '50%': { borderColor: 'red' } }
              }}
              variant="outline"
            >
              Fill out info
            </Button>
          )}
        </Group>
      </Stack>
    </Card>
  ) : (
    <Card
      sx={mq({
        padding: 0,
        borderRadius: [0, 0, 0, 4],
        border: withBorder ? 'solid 1px lightgrey' : 'none'
      })}
    >
      <Divider sx={mq({ display: ['flex', 'flex', 'none'] })} />
      <Stack style={{ gap: 0, flexWrap: 'nowrap' }}>
        <Group
          sx={mq({
            margin: 30,
            flexWrap: 'nowrap',
            flexDirection: ['column', 'column', 'column', 'row']
          })}
        >
          <Group style={{ flexWrap: 'nowrap', flex: 2, alignSelf: 'stretch' }}>
            <Skeleton height={100} width={100} />
            <Stack
              style={{
                flex: 1,
                gap: 10,
                textDecoration: 'none',
                justifyContent: 'center',
                color: '#000'
              }}
            >
              <Skeleton height={16} width="50%" />
              <Skeleton height={14} width="40%" />
            </Stack>
          </Group>

          <Stack
            sx={mq({
              flex: 1,
              alignSelf: 'start',
              placeItems: 'end',
              marginLeft: 'auto',
              gap: [0, 30],
              flexDirection: [
                'row-reverse',
                'row-reverse',
                'row-reverse',
                'column'
              ],
              justifyContent: [
                'space-between',
                'space-between',
                'space-between',
                'inital'
              ],
              width: ['100%', '100%', '100%', 'unset']
            })}
          >
            <Stack style={{ gap: 0, alignItems: 'end' }}>
              <Skeleton height={20} width={100} />
            </Stack>

            {!isDisabled && (
              <Group style={{ cursor: 'pointer', gap: 10 }}>
                <Skeleton height={15} width={80} />
              </Group>
            )}
          </Stack>
        </Group>
        <Group
          style={{
            backgroundColor: theme.black,
            color: '#FFF',
            opacity: 0.8,
            padding: 30,
            justifyContent: 'space-between'
          }}
        >
          <Stack style={{ gap: 10 }}>
            <Skeleton height={10} width={100} />
            <Skeleton height={10} width={100} />
          </Stack>

          <Skeleton height={48} width={200} />
        </Group>
      </Stack>
    </Card>
  );
};

CheckoutMerchItem.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  lineItem: PropTypes.object,
  onUpdateMerchPackageVersion: PropTypes.func,
  regCartMerchPackage: PropTypes.object,
  withBorder: PropTypes.bool
};

export default CheckoutMerchItem;
