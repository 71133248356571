import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';

const SearchBar = ({
  results,
  loading,
  onResultSelect,
  onSearch,
  placeholder,
  onClear
}) => {
  const timeoutRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const handleSearchChange = useCallback((e, data) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setSearchTerm(data.value);
    }, 300);
  });

  useEffect(() => {
    if (searchTerm.length > 3) {
      onSearch(searchTerm);
    }
    else if (onClear) {
      onClear();
    }
  }, [searchTerm]);

  return (
    <Search
      css={{ width: '100%' }}
      fluid
      input={{ fluid: true, placeholder }}
      loading={isLoading}
      minCharacters={4}
      onResultSelect={(e, { result }) => {
        onResultSelect(result.data);
      }}
      onSearchChange={handleSearchChange}
      results={results}
      selection="true"
    />
  );
};

SearchBar.propTypes = {
  loading: PropTypes.bool,
  onClear: PropTypes.func,
  onResultSelect: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  results: PropTypes.array
};

export default SearchBar;
