import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, List, Segment } from 'semantic-ui-react';
import { format } from 'date-fns';
import ActionableListItem from '../../common/ActionableListItem';
import View from '../../common/View';
import Text from '../../common/Text';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';

const TeamEventList = ({
  title,
  events,
  isLoading,
  onClick,
  onAddEvent,
  onRemoveEvent
}) => (
  <View>
    <View
      css={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Header as="h4" css={{ margin: '0px !important' }}>
        {title}
      </Header>
      {onAddEvent && (
        <Button
          color="green"
          compact
          content="Add"
          icon={{ name: 'plus' }}
          labelPosition="right"
          onClick={onAddEvent}
        />
      )}
    </View>

    <Segment
      aria-label="eventList"
      css={{ minHeight: '50px' }}
      loading={isLoading}
      role="region"
    >
      <View
        css={{
          maxHeight: '325px',
          overflowY: 'auto'
        }}
      >
        <List selection>
          {!isLoading &&
            (events && events.length > 0 ? (
              events.map((event, index, eventsArray) => {
                const eventDate = format(
                  new Date(`${stripUtcDateFormatting(event.start)}Z`),
                  'PP'
                );
                if (
                  index === 0 ||
                  (eventsArray[index - 1] &&
                    format(
                      new Date(
                        `${stripUtcDateFormatting(
                          eventsArray[index - 1].start
                        )}Z`
                      ),
                      'PP'
                    ) !== eventDate)
                ) {
                  return [
                    <Header
                      key={event.start}
                      as="h4"
                      css={{ margin: '0px !important' }}
                    >
                      {eventDate}
                    </Header>,
                    <ActionableListItem
                      key={event.pkCalendar ?? event.pkPrivateCalendar}
                      actionColor="red"
                      actionTitle="Remove"
                      description={event.title}
                      header={`${format(
                        new Date(`${stripUtcDateFormatting(event.start)}Z`),
                        'p'
                      )} - ${format(
                        new Date(`${stripUtcDateFormatting(event.end)}Z`),
                        'p'
                      )} (${format(
                        new Date(`${stripUtcDateFormatting(event.start)}`),
                        'p'
                      )} - ${format(
                        new Date(`${stripUtcDateFormatting(event.end)}`),
                        'p'
                      )} UTC)`}
                      onAction={
                        onRemoveEvent ? () => onRemoveEvent(event) : null
                      }
                      onClick={() => {
                        onClick ? onClick(event) : null;
                      }}
                    />
                  ];
                }
                return (
                  <ActionableListItem
                    key={event.pkCalendar ?? event.pkPrivateCalendar}
                    actionColor="red"
                    actionTitle="Remove"
                    description={`${event.title} . ${event.locationName}`}
                    header={`${format(
                      new Date(`${stripUtcDateFormatting(event.start)}Z`),
                      'p'
                    )} - ${format(
                      new Date(`${stripUtcDateFormatting(event.end)}Z`),
                      'p'
                    )} (${format(
                      new Date(`${stripUtcDateFormatting(event.start)}`),
                      'p'
                    )} - ${format(
                      new Date(`${stripUtcDateFormatting(event.end)}`),
                      'p'
                    )} UTC)`}
                    onAction={onRemoveEvent ? () => onRemoveEvent(event) : null}
                    onClick={() => {
                      onClick ? onClick(event) : null;
                    }}
                  />
                );
              })
            ) : (
              <View css={{ marginLeft: '5px' }}>
                <Text>No scheduled events</Text>
              </View>
            ))}
        </List>
      </View>
    </Segment>
  </View>
);

TeamEventList.propTypes = {
  events: PropTypes.array,
  isLoading: PropTypes.bool,
  onAddEvent: PropTypes.func,
  onClick: PropTypes.func,
  onRemoveEvent: PropTypes.func,
  title: PropTypes.string
};

export default TeamEventList;
