import React, { useContext, useState } from 'react';
import { Icon, Image, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Indicator } from '@mantine/core';
import View from '../components/common/View';
import { mq } from '../config/theme';
import UsersView from './UsersView';
import logo from '../images/sh_logo_circle.png';
import { Context as AuthContext } from '../providers/AuthProvider';
import { useWindowSize } from '../helpers/useWindowSize';
import CamerasView from './CamerasView';
import TeamsView from './TeamsView';
import AssociationsView from './AssociationsView';
import TournamentsView from './TournamentsView';
import DebugStreamsView from '../components/content/CamerasView/DebugStreamsView';
import RegistrationsView from './RegistrationsView';
import PaymentView from './PaymentView';
import BillingView from './BillingView';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';

const LandingView = () => {
  const { logout } = useContext(AuthContext);
  const { isMobile } = useWindowSize();
  const location = useLocation();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { state: regState } = useContext(RegistrationContext);
  const billingWarningCount = regState.regAssociationPaymentWindowsSummary.value.filter(
    (w) => !w.invoiced && !w.paid
  ).length;
  const missingTransferCount =
    regState.regCartsMissingTransfersSummary.value
      ?.regCartMissingTransferCount ?? 0;

  return (
    <View
      css={mq({
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        marginTop: [0, 25],
        marginBottom: [0, 25]
      })}
    >
      <View
        css={mq({
          backgroundColor: 'white',
          width: ['100%', 'unset', '60%'],
          maxWidth: 1152,
          minWidth: [360, 360, 875],
          padding: 25,
          borderRadius: [0, 16]
        })}
      >
        <View css={{ flex: 1 }}>
          {isMobile ? (
            <View
              css={{
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Image
                bordered
                circular
                size="tiny"
                src={logo}
                verticalAlign="middle"
              />
              <Icon
                corner
                css={css`
                  font-size: 36px !important;
                  align-self: center;
                `}
                name="bars"
                onClick={() => setSidebarVisible(!sidebarVisible)}
              />
            </View>
          ) : (
            <Menu secondary>
              <Menu.Item>
                <Image
                  bordered
                  centered
                  circular
                  size="tiny"
                  src={logo}
                  verticalAlign="middle"
                />
              </Menu.Item>
              <Menu.Item
                active={location.pathname.startsWith('/users')}
                as={Link}
                name="Users"
                to="/users"
              />
              <Menu.Item
                active={location.pathname.startsWith('/team-management')}
                as={Link}
                name="Teams"
                to="/team-management"
              />
              <Menu.Item
                active={location.pathname.startsWith('/cameras')}
                as={Link}
                name="Cameras"
                to="/cameras"
              />
              <Menu.Item
                active={location.pathname.startsWith('/tournaments')}
                as={Link}
                name="Tournaments"
                to="/tournaments"
              />
              <Menu.Item
                active={location.pathname.startsWith('/registration')}
                as={Link}
                name="Registration"
                to="/registration/associations"
              />
              <Menu.Item
                active={location.pathname.startsWith('/payment')}
                as={Link}
                name="Payment"
                to="/payment"
              />
              <Indicator
                color={billingWarningCount > 0 ? 'red' : 'yellow'}
                disabled={
                  billingWarningCount === 0 && missingTransferCount === 0
                }
                inline
                label={
                  billingWarningCount > 0
                    ? billingWarningCount
                    : missingTransferCount
                }
                size={20}
                sx={{ alignSelf: 'center' }}
              >
                <Menu.Item
                  active={location.pathname.startsWith('/billing')}
                  as={Link}
                  name="Billing"
                  to="/billing"
                />
              </Indicator>
              <Menu.Menu position="right">
                <Menu.Item name="Logout" onClick={() => logout()} />
              </Menu.Menu>
            </Menu>
          )}
          <Switch>
            <Route path="/users">
              <UsersView />
            </Route>
            <Route path="/cameras/debug">
              <DebugStreamsView />
            </Route>
            <Route path="/cameras">
              <CamerasView />
            </Route>
            <Route path="/tournaments">
              <TournamentsView />
            </Route>
            <Route path="/team-management">
              <Menu attached="top" tabular>
                <Menu.Item
                  active={location.pathname.startsWith(
                    '/team-management/teams'
                  )}
                  as={Link}
                  name="Teams"
                  to="/team-management/teams"
                />
                <Menu.Item
                  active={location.pathname.startsWith(
                    '/team-management/associations'
                  )}
                  as={Link}
                  name="Associations"
                  to="/team-management/associations"
                />
              </Menu>
              <Segment attached="bottom">
                <Switch>
                  <Route path="/team-management/associations">
                    <AssociationsView />
                  </Route>
                  <Route path="/team-management/teams">
                    <TeamsView />
                  </Route>
                  <Route path="/team-management">
                    <Redirect to="/team-management/teams" />
                  </Route>
                </Switch>
              </Segment>
            </Route>
            <Route path="/registration">
              <RegistrationsView />
            </Route>
            <Route path="/payment">
              <PaymentView />
            </Route>
            <Route path="/billing">
              <BillingView />
            </Route>
            <Route path="/">
              <Redirect to="/users" />
            </Route>
          </Switch>
        </View>
      </View>
      <Sidebar
        animation="overlay"
        as={Menu}
        direction="right"
        icon="labeled"
        onHide={() => setSidebarVisible(false)}
        vertical
        visible={sidebarVisible}
        width="thin"
      >
        <Menu.Item
          active={location.pathname.startsWith('/users')}
          as={Link}
          icon={{ name: 'user' }}
          name="Users"
          to="/users"
        />
        <Menu.Item
          active={location.pathname.startsWith('/team-management')}
          as={Link}
          icon={{ name: 'users' }}
          name="Teams"
          to="/team-management"
        />
        <Menu.Item
          active={location.pathname.startsWith('/cameras')}
          as={Link}
          icon={{ name: 'video camera' }}
          name="Cameras"
          to="/cameras"
        />
        <Menu.Item
          active={location.pathname.startsWith('/tournaments')}
          as={Link}
          icon={{ name: 'trophy' }}
          name="Tournaments"
          to="/tournaments"
        />
        <Menu.Item
          active={location.pathname.startsWith('/registration')}
          as={Link}
          icon={{ name: 'file alternate' }}
          name="Registration"
          to="/registration/associations"
        />
        <Menu.Item
          active={location.pathname.startsWith('/payment')}
          as={Link}
          icon={{ name: 'credit card' }}
          name="Payment"
          to="/payment"
        />
        <Menu.Item
          active={location.pathname.startsWith('/billing')}
          as={Link}
          icon={{ name: 'file alternate' }}
          name="Billing"
          to="/billing"
        />
        <Menu.Item
          icon={{ name: 'sign out', color: 'red' }}
          name="Logout"
          onClick={() => logout()}
        />
      </Sidebar>
    </View>
  );
};

LandingView.propTypes = {};

export default LandingView;
