import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Form,
  Icon,
  Menu,
  Message,
  Modal,
  Select
} from 'semantic-ui-react';
import { DateTimePicker } from '@material-ui/pickers';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ResponsiveInput from '../../common/ResponsiveInput';
import View from '../../common/View';
import InputLabel from '../../common/InputLabel';
import { mq } from '../../../config/theme';

const AddScheduledEventModal = ({ pkStream, isOpen, onClose }) => {
  const { state, addScheduledEvent, fetchUpcoming } = useContext(
    LiveStreamContext
  );
  const { state: teamManagementState, fetchAssociations } = useContext(
    TeamManagementContext
  );
  const [streamType, setStreamType] = useState('standard');
  const [formState, setFormState] = useState({
    data: {
      start: new Date(),
      end: new Date(),
      recurring: null,
      title: '',
      type: '',
      association: null,
      tournament: null
    },
    error: ''
  });
  const recurringOptions = [
    { key: 'daily', value: 'daily', text: 'Daily' },
    { key: 'weekly', value: 'weekly', text: 'Weekly' },
    { key: 'monthly', value: 'monthly', text: 'Monthly' }
  ];
  const sortedAssociationsList = teamManagementState.associations.value.sort(
    (a, b) => a.name?.localeCompare(b.name)
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        data: {
          start: new Date(),
          end: new Date(),
          recurring: null,
          title: '',
          type: '',
          association: null,
          tournament: null
        },
        error: ''
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      !teamManagementState.associations.loading &&
      teamManagementState.associations.value.length === 0
    ) {
      fetchAssociations();
    }
  }, [teamManagementState.associations]);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      data: {
        ...formState.data,
        [e.target.name]: e.target.value
      }
    });
  };

  const setFormError = (error) => {
    setFormState({
      ...formState,
      error
    });
  };

  const createScheduledEvent = async () => {
    if (formState.data.end <= formState.data.start) {
      setFormError('Invalid date range');
    }
    else if (streamType === 'recurring' && !formState.data.recurring) {
      setFormError('Frequency is required');
    }
    else if (streamType === 'tournament' && !formState.data.tournament) {
      setFormError('Tournament is required');
    }
    else {
      onClose();
      await addScheduledEvent(pkStream, {
        ...formState.data,
        recurring: streamType === 'recurring' ? formState.data.recurring : null,
        tournament:
          streamType === 'tournament' ? formState.data.tournament : null
      });
      fetchUpcoming();
    }
  };

  return (
    <Modal
      closeIcon
      css={mq({ minWidth: ['100%', 'unset'] })}
      onClose={onClose}
      open={isOpen}
      size="tiny"
      style={{ margin: 0 }}
    >
      <Modal.Header style={{ border: 'none' }}>Create Event</Modal.Header>
      <Modal.Content style={{ padding: 0 }}>
        <Menu
          aria-label="createEventTypeNav"
          attached="top"
          role="menu"
          tabular
        >
          <Menu.Item
            active={streamType === 'standard'}
            as={Button}
            name="Standard"
            onClick={() => setStreamType('standard')}
          />
          <Menu.Item
            active={streamType === 'recurring'}
            as={Button}
            name="Recurring"
            onClick={() => setStreamType('recurring')}
          />
          <Menu.Item
            active={streamType === 'tournament'}
            as={Button}
            data-testid="tournamentTab"
            name="Tournament"
            onClick={() => setStreamType('tournament')}
          />
        </Menu>
        <Form
          aria-label="createEvent"
          css={mq({ padding: [null, '1.5rem', '1.5rem'], paddingTop: '1rem' })}
          onSubmit={createScheduledEvent}
          role="form"
        >
          <Form.Group widths="equal">
            <Form.Field>
              <DateTimePicker
                label="Start Time"
                onChange={(start) =>
                  setFormState({
                    ...formState,
                    data: {
                      ...formState.data,
                      start
                    }
                  })
                }
                style={{ display: 'flex' }}
                value={formState.data.start}
              />
            </Form.Field>
            <Form.Field>
              <DateTimePicker
                data-testid="endTime"
                label="End Time"
                onChange={(end) =>
                  setFormState({
                    ...formState,
                    data: {
                      ...formState.data,
                      end
                    }
                  })
                }
                style={{ display: 'flex' }}
                value={formState.data.end}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <ResponsiveInput
              label="Title"
              name="title"
              onChange={handleInputChange}
              required
              style={{ fontSize: 15 }}
              value={formState.data.title}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              label="Event Type"
              name="type"
              onChange={handleInputChange}
              required
              style={{ fontSize: 15 }}
              value={formState.data.type}
            />
          </Form.Field>
          <Form.Field>
            <InputLabel>Association</InputLabel>
            <View css={{ flexDirection: 'row', flex: 1 }}>
              <Select
                css={{ flex: 1 }}
                loading={teamManagementState.associations.loading}
                name="association"
                onChange={(e, option) =>
                  setFormState({
                    ...formState,
                    data: {
                      ...formState.data,
                      association: option.value
                    }
                  })
                }
                options={[
                  {
                    key: '',
                    value: null,
                    text: 'Select an association'
                  },
                  ...sortedAssociationsList.map((a) => ({
                    key: a.pkAssociation,
                    value: a.pkAssociation,
                    text: a.name
                  }))
                ]}
                placeholder="Select an association"
                search
                value={formState.data.association}
              />
              <Icon
                css={{ alignSelf: 'center', cursor: 'pointer' }}
                disabled={teamManagementState.associations.loading}
                loading={teamManagementState.associations.loading}
                name="refresh"
                onClick={() => {
                  fetchAssociations();
                }}
                style={{ margin: 10 }}
              />
            </View>
          </Form.Field>
          {streamType === 'recurring' && (
            <Form.Field>
              <InputLabel>Frequency</InputLabel>
              <Select
                onChange={(e, option) =>
                  setFormState({
                    ...formState,
                    data: {
                      ...formState.data,
                      recurring: option.value
                    }
                  })
                }
                options={recurringOptions}
                placeholder="Frequency"
                value={formState.data.recurring}
              />
            </Form.Field>
          )}
          {streamType === 'tournament' && (
            <Form.Field>
              <InputLabel>Tournament</InputLabel>
              <Select
                data-testid="selectTournament"
                loading={state.tournaments.loading}
                onChange={(e, option) =>
                  setFormState({
                    ...formState,
                    data: {
                      ...formState.data,
                      tournament: option.value
                    }
                  })
                }
                options={state.tournaments.value.map((t) => ({
                  key: t.pkTournament,
                  value: t.pkTournament,
                  text: t.name
                }))}
                placeholder="Tournament Name"
                value={formState.data.tournament}
              />
            </Form.Field>
          )}
          <Container
            style={{ textAlign: 'right', marginTop: 20, marginRight: 0 }}
          >
            <Button
              color="black"
              onClick={onClose}
              style={{ marginLeft: '0.75em' }}
              type="button"
            >
              Cancel
            </Button>
            <Button
              content="Create"
              positive
              style={{ marginLeft: '0.75em' }}
              type="submit"
            />
          </Container>
        </Form>
      </Modal.Content>
      {formState.error && (
        <Modal.Actions>
          <Message color="red" data-testid="modalError" size="small">
            {formState.error}
          </Message>
        </Modal.Actions>
      )}
    </Modal>
  );
};

AddScheduledEventModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkStream: PropTypes.number
};

export default AddScheduledEventModal;
