import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Group, Stack, Text } from '@mantine/core';
import { DatePicker } from '@material-ui/pickers';
import { NumericFormat } from 'react-number-format';
import { compareAsc, compareDesc, format } from 'date-fns';
import Papa from 'papaparse';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import PaginationList from '../../common/PaginationList';
import ActionListItem from '../../common/ActionListItem';
import {
  getInitialsFromName,
  stripUtcDateFormatting
} from '../../../helpers/formatHelper';
import { mq } from '../../../config/theme';

const RegAssociationOfflinePayments = ({ regAssociation }) => {
  const { state, fetchRegAssociationPaymentSubmissions } = useContext(
    RegistrationContext
  );
  const hasFetched = useRef(false);
  const [formState, setFormState] = useState({
    minDate: null,
    maxDate: null
  });
  const loading = state.regFormSubmissions.loading || !hasFetched.current;

  useEffect(() => {
    if (regAssociation) {
      fetchRegAssociationPaymentSubmissions(regAssociation.pkRegAssociation);
      hasFetched.current = true;
    }
  }, [regAssociation]);

  const regFormSubmissionsFilter = (s) => {
    const submittedAt = new Date(`${stripUtcDateFormatting(s.submittedAt)}Z`);
    const compareDate = new Date(
      submittedAt.getUTCFullYear(),
      submittedAt.getUTCMonth(),
      submittedAt.getUTCDate(),
      0,
      0,
      0
    );
    return (
      (!formState.minDate ||
        compareAsc(compareDate, new Date(formState.minDate)) !== -1) &&
      (!formState.maxDate ||
        compareDesc(compareDate, new Date(formState.maxDate)) !== -1)
    );
  };

  const downloadCsv = () => {
    const sortedFormSubmissions = state.regFormSubmissions.value
      .filter(regFormSubmissionsFilter)
      .sort((a, b) => new Date(a.submittedAt) - new Date(b.submittedAt));

    const columns = [
      'Submitted At',
      'Registrant',
      'Division',
      'Email',
      'Amount'
    ];

    let rows = [];
    sortedFormSubmissions.forEach((s) => {
      rows = [
        ...rows,
        [
          format(new Date(`${stripUtcDateFormatting(s.submittedAt)}Z`), 'PP p'),
          `${s.regPerson.firstName} ${s.regPerson.lastName}`,
          s.regAssociationDivisionForm.regAssociationDivision.name,
          s.user.email,
          s.registrationCost
        ]
      ];
    });

    const fileDateString =
      formState.minDate && formState.maxDate
        ? `${format(new Date(formState.minDate), 'P')}-${format(
            new Date(formState.maxDate),
            'P'
          )}`
        : format(new Date(), 'P');

    const csv = Papa.unparse({
      fields: columns,
      data: rows
    });
    const blob = new Blob([csv]);
    const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
    const link = document.createElement('a');
    link.setAttribute('href', csvURL);
    link.setAttribute(
      'download',
      `${regAssociation.association.name}-payments-${fileDateString}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Stack sx={{ gap: 20 }}>
      <Stack sx={{ gap: 5 }}>
        <Group
          sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'end' }}
        >
          <Group>
            <DatePicker
              css={mq({ width: ['100%', 'unset'] })}
              disabled={loading}
              label="Minimum  Date"
              onChange={(value) => {
                const dateValue = new Date(
                  value.getUTCFullYear(),
                  value.getUTCMonth(),
                  value.getUTCDate(),
                  0,
                  0,
                  0
                );
                setFormState({
                  ...formState,
                  minDate: dateValue,
                  maxDate:
                    formState.maxDate && dateValue > new Date(formState.maxDate)
                      ? dateValue
                      : formState.maxDate
                });
              }}
              value={formState.minDate}
            />
            <DatePicker
              css={mq({ width: ['100%', 'unset'] })}
              disabled={loading}
              label="Maximum Date"
              onChange={(value) => {
                const dateValue = new Date(
                  value.getUTCFullYear(),
                  value.getUTCMonth(),
                  value.getUTCDate(),
                  0,
                  0,
                  0
                );
                setFormState({
                  ...formState,
                  maxDate: dateValue,
                  minDate:
                    formState.minDate &&
                    new Date(dateValue) < new Date(formState.minDate)
                      ? dateValue
                      : formState.minDate
                });
              }}
              value={formState.maxDate}
            />
          </Group>
          <Button
            color="dark"
            compact
            disabled={loading}
            onClick={downloadCsv}
            sx={mq({ flex: [1, 'unset'], marginBottom: [10, 0] })}
          >
            Download
          </Button>
        </Group>
        <PaginationList
          emptyMessage="- No Payments Received -"
          isLoading={loading}
          items={state.regFormSubmissions.value
            .filter(regFormSubmissionsFilter)
            .sort((a, b) => new Date(a.submittedAt) - new Date(b.submittedAt))
            .map((s) => (
              <Group
                key={s.pkRegFormSubmission}
                sx={{
                  gap: 10,
                  flex: 1,
                  padding: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Group css={{ flex: 1, gap: 10 }}>
                  <Avatar
                    radius="xl"
                    sx={(theme) => ({backgroundColor: theme.colors.yellow[9]})}
                  >
                    <Text sx={{ color: '#000' }}>
                      {getInitialsFromName(
                        `${s.regPerson.firstName} ${s.regPerson.lastName}`
                      )}
                    </Text>
                  </Avatar>
                  <Stack sx={{ gap: 0, flex: 1 }}>
                    <Text sx={{ lineHeight: '16px' }} weight={500}>
                      {`${s.regPerson.firstName} ${s.regPerson.lastName}`}{' '}
                    </Text>
                    <Text sx={{ lineHeight: '16px' }}>
                      {s.regAssociationDivisionForm.regAssociationDivision.name}
                    </Text>
                  </Stack>
                  <Stack sx={{ gap: 0, flex: 2 }}>
                    <Text sx={{ lineHeight: '16px' }} weight={500}>
                      {s.user.email}
                    </Text>
                    <Text sx={{ lineHeight: '16px' }}>
                      {format(
                        new Date(`${stripUtcDateFormatting(s.submittedAt)}Z`),
                        'PP'
                      )}
                    </Text>
                  </Stack>
                </Group>
                <Stack sx={{ gap: 0, alignItems: 'end' }}>
                  <Text weight={500}>
                    <NumericFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={s.registrationCost}
                    />
                  </Text>
                  <Text weight={500}>{s.status}</Text>
                </Stack>
              </Group>
            ))}
          itemsPerPage={10}
          LoadingComponent={ActionListItem}
          showItemBorder
        />
      </Stack>
    </Stack>
  );
};

RegAssociationOfflinePayments.propTypes = { regAssociation: PropTypes.object };

export default RegAssociationOfflinePayments;
