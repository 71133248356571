import React from 'react';
import { AlertCircle, CircleCheck } from 'tabler-icons-react';
import { showNotification } from '@mantine/notifications';

const triggerNotification = (message, title, notificationColor) => {
  const color = notificationColor ?? 'red';
  const icon =
    notificationColor === 'green' ? <CircleCheck /> : <AlertCircle />;

  showNotification({
    id: 'app-notification',
    title: title ?? 'Error',
    message: message ?? 'Oops something went wrong!',
    color,
    icon,
    className: 'my-notification-class',
    styles: {
      root: {
        backgroundColor: color,
        borderColor: color
      },
      title: { color: '#FFF' },
      description: { color: '#FFF' },
      closeButton: { color: '#FFF' }
    }
  });
};

export { triggerNotification };
