import { Auth } from '@aws-amplify/auth';
import createDataProvider from './createDataProvider';
import sportsheadzApi from '../apis/sportsheadzApi';
import { debug, getCircularReplacer } from '../helpers/Logger';

const initialState = {
  venues: { value: [], loading: false },
  tournaments: { value: [], loading: false },
  managementTournaments: { value: [], loading: false },
  upcoming: { value: [], loading: false },
  recorded: { value: [], loading: false },
  streamViews: { value: null, loading: false }
};

const streamReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_VENUES_START':
    case 'UPDATE_STREAM_START':
    case 'CREATE_VENUE_START':
    case 'UPDATE_VENUE_START':
    case 'CREATE_STREAM_START':
      return {
        ...state,
        venues: { ...state.venues, loading: true }
      };
    case 'FETCH_VENUES_FINISH':
    case 'UPDATE_STREAM_FINISH':
    case 'CREATE_STREAM_FINISH':
      return {
        ...state,
        venues: {
          value: action.payload ? action.payload : state.venues.value,
          loading: false
        }
      };
    case 'FETCH_UPCOMING_START':
    case 'DELETE_SCHEDULED_EVENT_START':
    case 'ADD_SCHEDULED_EVENT_START':
      return {
        ...state,
        upcoming: { ...state.upcoming, loading: true }
      };
    case 'FETCH_UPCOMING_FINISH':
      return {
        ...state,
        upcoming: {
          value: action.payload,
          loading: false
        }
      };
    case 'FETCH_RECORDED_START':
      return {
        ...state,
        recorded: { ...state.recorded, loading: true }
      };
    case 'FETCH_RECORDED_FINISH':
      return {
        ...state,
        recorded: {
          value: action.payload,
          loading: false
        }
      };
    case 'ADD_SCHEDULED_EVENT_FINISH':
      return {
        ...state,
        upcoming: { ...state.upcoming, loading: false }
      };
    case 'DELETE_SCHEDULED_EVENT_FINISH': {
      const upcomingCopy = state.upcoming.value.filter(
        (s) =>
          s.title.pkScheduledEventTitle !==
          action.payload?.pkScheduledEventTitle
      );
      return {
        ...state,
        upcoming: {
          value: [...upcomingCopy],
          loading: false
        }
      };
    }
    case 'FETCH_TOURNAMENTS_START':
      return {
        ...state,
        tournaments: { ...state.tournaments, loading: true }
      };
    case 'FETCH_TOURNAMENTS_FINISH':
      return {
        ...state,
        tournaments: {
          value: action.payload ?? state.tournaments.value,
          loading: false
        }
      };
    case 'CREATE_VENUE_FINISH':
      return {
        ...state,
        venues: {
          ...state.venues,
          value: action.payload
            ? [...state.venues.value, action.payload]
            : state.venues.value,
          loading: false
        }
      };
    case 'UPDATE_VENUE_FINISH':
      return {
        ...state,
        venues: {
          value: action.payload
            ? [
                ...state.venues.value.filter((v) => v.id !== action.payload.id),
                action.payload
              ]
            : state.venues.value,
          loading: false
        }
      };
    case 'FETCH_MANAGEMENT_TOURNAMENTS_START':
    case 'CREATE_MANAGEMENT_TOURNAMENT_START':
    case 'UPDATE_MANAGEMENT_TOURNAMENT_START':
    case 'DELETE_MANAGEMENT_TOURNAMENT_START':
      return {
        ...state,
        managementTournaments: { ...state.managementTournaments, loading: true }
      };
    case 'FETCH_MANAGEMENT_TOURNAMENTS_FINISH':
    case 'DELETE_MANAGEMENT_TOURNAMENT_FINISH':
      return {
        ...state,
        managementTournaments: {
          value: action.payload ?? state.managementTournaments.value,
          loading: false
        }
      };
    case '':
      return {
        ...state,
        managementTournaments: { ...state.managementTournaments, loading: true }
      };
    case 'CREATE_MANAGEMENT_TOURNAMENT_FINISH':
      return {
        ...state,
        managementTournaments: {
          value: action.payload
            ? [...state.managementTournaments.value, action.payload]
            : state.managementTournaments.value,
          loading: false
        }
      };
    case 'UPDATE_MANAGEMENT_TOURNAMENT_FINISH':
      return {
        ...state,
        managementTournaments: {
          value: action.payload
            ? [
                ...state.managementTournaments.value.filter(
                  (t) => t.pkMgmtTournament !== action.payload.pkMgmtTournament
                ),
                action.payload
              ]
            : state.managementTournaments.value,
          loading: false
        }
      };
    case 'FETCH_STREAM_VIEWS_START':
      return {
        ...state,
        streamViews: { ...state.streamViews, loading: true }
      };
    case 'FETCH_STREAM_VIEWS_FINISH':
      return {
        ...state,
        streamViews: {
          value: action.payload,
          loading: false
        }
      };
    default:
      return state;
  }
};

// #region Venues
const fetchVenues = (dispatch) => async () => {
  try {
    dispatch({ type: 'FETCH_VENUES_START' });
    const response = await sportsheadzApi.get('/api/hockeysight/venues', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'FETCH_VENUES_FINISH', payload: response.data });
  } catch (e) {
    debug(e);
    dispatch({ type: 'FETCH_VENUES_FINISH', payload: null });
  }
};

const fetchUpcoming = (dispatch) => async () => {
  dispatch({ type: 'FETCH_UPCOMING_START' });
  try {
    const response = await sportsheadzApi.get(`/api/schedule/upcoming/`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({
      type: 'FETCH_UPCOMING_FINISH',
      payload: response.data?.data
    });
  } catch (e) {
    debug(e);
    dispatch({ type: 'FETCH_UPCOMING_FINISH', payload: null });
  }
};

const fetchRecorded = (dispatch) => async () => {
  dispatch({ type: 'FETCH_RECORDED_START' });
  try {
    const response = await sportsheadzApi.get(`/api/schedule/recorded`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      params: { 'include-empty': true }
    });
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'FETCH_RECORDED_FINISH', payload: response.data?.data });
  } catch (e) {
    debug(e);
    dispatch({ type: 'FETCH_RECORDED_FINISH', payload: null });
  }
};

const addScheduledEvent = (dispatch) => async (
  pkStream,
  { start, end, recurring, title, type, association, tournament }
) => {
  if (pkStream) {
    dispatch({ type: 'ADD_SCHEDULED_EVENT_START' });
    try {
      const response = await sportsheadzApi.post(
        `api/schedule/events/${pkStream}`,
        null,
        {
          params: {
            start: Math.floor(new Date(start) / 1000),
            end: Math.floor(new Date(end) / 1000),
            recurring,
            title,
            type,
            association,
            tournament
          },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'ADD_SCHEDULED_EVENT_FINISH' });
    } catch (e) {
      debug(e);
      dispatch({ type: 'ADD_SCHEDULED_EVENT_FINISH' });
    }
  }
};

const removeScheduledEvent = (dispatch) => async (pkScheduledEventTitle) => {
  if (pkScheduledEventTitle) {
    try {
      dispatch({ type: 'DELETE_SCHEDULED_EVENT_START' });
      const response = await sportsheadzApi.delete(
        `api/schedule/events/${pkScheduledEventTitle}`,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'DELETE_SCHEDULED_EVENT_FINISH',
        payload: { pkScheduledEventTitle }
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'DELETE_SCHEDULED_EVENT_FINISH', payload: null });
    }
  }
};

const fetchTournaments = (dispatch) => async () => {
  try {
    dispatch({ type: 'FETCH_TOURNAMENTS_START' });
    const response = await sportsheadzApi.get(`/api/tournament`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'FETCH_TOURNAMENTS_FINISH', payload: response.data });
  } catch (e) {
    debug(e);
    dispatch({ type: 'FETCH_TOURNAMENTS_FINISH', payload: null });
  }
};

const createVenue = (dispatch) => async ({
  name,
  street,
  city,
  province,
  provinceCode,
  postalCode,
  country,
  countryCode,
  phone,
  imageUrl,
  isEnabled,
  isComingSoon
}) => {
  try {
    dispatch({ type: 'CREATE_VENUE_START' });
    const response = await sportsheadzApi.post(
      `/api/staff/venues/`,
      {
        name,
        street,
        city,
        province,
        provinceCode,
        postalCode,
        country,
        countryCode,
        phone,
        imageUrl,
        isEnabled,
        isComingSoon
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'CREATE_VENUE_FINISH', payload: response.data });
  } catch (e) {
    debug(e);
    dispatch({ type: 'CREATE_VENUE_FINISH', payload: null });
  }
};

const updateVenue = (dispatch) => async (
  pkVenue,
  {
    name,
    street,
    city,
    province,
    provinceCode,
    postalCode,
    country,
    countryCode,
    phone,
    imageUrl,
    isEnabled,
    isComingSoon
  }
) => {
  try {
    dispatch({ type: 'UPDATE_VENUE_START' });
    const response = await sportsheadzApi.put(
      `/api/staff/venues/${pkVenue}`,
      {
        name,
        street,
        city,
        province,
        provinceCode,
        postalCode,
        country,
        countryCode,
        phone,
        imageUrl,
        isEnabled,
        isComingSoon
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'UPDATE_VENUE_FINISH', payload: response.data });
  } catch (e) {
    debug(e);
    dispatch({ type: 'UPDATE_VENUE_FINISH', payload: null });
  }
};

const createStream = (dispatch) => async (
  pkVenue,
  {
    hostName,
    name,
    title,
    description,
    hls,
    stream,
    application,
    isActive,
    isBlackout
  }
) => {
  if (pkVenue) {
    dispatch({ type: 'CREATE_STREAM_START' });
    try {
      const response = await sportsheadzApi.post(
        `api/staff/streams/${pkVenue}`,
        {
          title,
          description,
          hls,
          stream,
          application,
          isActive,
          isBlackout,
          camera: {
            name,
            hostName
          }
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'CREATE_STREAM_FINISH', payload: null });
    } catch (e) {
      debug(e);
      dispatch({ type: 'CREATE_STREAM_FINISH', payload: null });
    }
  }
};
// #endregion

// #region Management Tournaments
const fetchManagementTournaments = (dispatch) => async () => {
  try {
    dispatch({ type: 'FETCH_MANAGEMENT_TOURNAMENTS_START' });
    const response = await sportsheadzApi.get(`/api/managementTournament`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({
      type: 'FETCH_MANAGEMENT_TOURNAMENTS_FINISH',
      payload: response.data
    });
  } catch (e) {
    debug(e);
    dispatch({ type: 'FETCH_MANAGEMENT_TOURNAMENTS_FINISH', payload: null });
  }
};

const createManagementTournament = (dispatch) => async ({
  fkAssociation,
  mbswId,
  title,
  description,
  startDate,
  endDate,
  isActive,
  pkVenues
}) => {
  dispatch({ type: 'CREATE_MANAGEMENT_TOURNAMENT_START' });
  try {
    const response = await sportsheadzApi.post(
      `api/managementTournament`,
      {
        fkAssociation,
        mbswId,
        title,
        description,
        startDate,
        endDate,
        isActive,
        pkVenues
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({
      type: 'CREATE_MANAGEMENT_TOURNAMENT_FINISH',
      payload: response.data
    });
  } catch (e) {
    debug(e);
    dispatch({ type: 'CREATE_MANAGEMENT_TOURNAMENT_FINISH', payload: null });
  }
};

const updateManagementTournament = (dispatch) => async (
  pkManagementTournament,
  {
    fkAssociation,
    mbswId,
    title,
    description,
    startDate,
    endDate,
    isActive,
    pkVenues
  }
) => {
  if (pkManagementTournament) {
    dispatch({ type: 'UPDATE_MANAGEMENT_TOURNAMENT_START' });
    try {
      const response = await sportsheadzApi.put(
        `api/managementTournament/${pkManagementTournament}`,
        {
          fkAssociation,
          mbswId,
          title,
          description,
          startDate,
          endDate,
          isActive,
          pkVenues
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'UPDATE_MANAGEMENT_TOURNAMENT_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'UPDATE_MANAGEMENT_TOURNAMENT_FINISH', payload: null });
    }
  }
};

const deleteManagementTournament = (dispatch) => async (
  pkManagementTournament
) => {
  try {
    dispatch({ type: 'DELETE_MANAGEMENT_TOURNAMENT_START' });
    const response = await sportsheadzApi.delete(
      `/api/managementTournament/${pkManagementTournament}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({
      type: 'DELETE_MANAGEMENT_TOURNAMENT_FINISH',
      payload: null
    });
  } catch (e) {
    debug(e);
    dispatch({ type: 'DELETE_MANAGEMENT_TOURNAMENT_FINISH', payload: null });
  }
};
// #endregion

const updateStream = (dispatch) => async (
  pkStream,
  {
    hostName,
    name,
    title,
    description,
    hls,
    stream,
    application,
    isActive,
    isBlackout
  }
) => {
  try {
    dispatch({ type: 'UPDATE_STREAM_START' });
    const response = await sportsheadzApi.put(
      `/api/Staff/streams/${pkStream}`,
      {
        title,
        description,
        hls,
        stream,
        application,
        isActive,
        isBlackout,
        camera: {
          name,
          hostName
        }
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'UPDATE_STREAM_FINISH', payload: null });
  } catch (e) {
    debug(e);
    dispatch({ type: 'UPDATE_STREAM_FINISH', payload: null });
  }
};

const fetchStreamViews = (dispatch) => async (
  pkStream,
  { minDate, maxDate }
) => {
  dispatch({ type: 'FETCH_STREAM_VIEWS_START' });
  try {
    const response = await sportsheadzApi.get(
      `/api/staff/streams/views/${pkStream}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        params: {
          minDate,
          maxDate
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'FETCH_STREAM_VIEWS_FINISH', payload: response.data });
  } catch (e) {
    debug(e);
    dispatch({ type: 'FETCH_STREAM_VIEWS_FINISH', payload: null });
  }
};

const generateTournamentsCrmReportFileBlob = (dispatch) => async (
  { minDate, maxDate },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.get(
      `/api/staff/tournament-crm-report`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        params: {
          minDate,
          maxDate
        },
        responseType: 'blob' // important
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));

    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    debug(e);

    if (onErrorCallback) {
      onErrorCallback('Oops something went wrong');
    }
  }
};

export const { Provider, Context } = createDataProvider(
  streamReducer,
  {
    fetchVenues,
    fetchUpcoming,
    fetchRecorded,
    fetchTournaments,
    fetchManagementTournaments,
    fetchStreamViews,
    addScheduledEvent,
    removeScheduledEvent,
    createVenue,
    createStream,
    createManagementTournament,
    updateVenue,
    updateStream,
    updateManagementTournament,
    deleteManagementTournament,
    generateTournamentsCrmReportFileBlob
  },
  initialState
);
