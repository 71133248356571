import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Header,
  List,
  Placeholder,
  Segment,
  Confirm
} from 'semantic-ui-react';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import View from '../../common/View';
import ActionableListItem from '../../common/ActionableListItem';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import Text from '../../common/Text';
import ResponsiveInput from '../../common/ResponsiveInput';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';

const UserDetails = ({ className }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [showConfirmUserDetails, setShowConfirmUserDetails] = useState(false);
  const { state, updateUser, fetchUser, deleteRemoteUsers } = useContext(
    TeamManagementContext
  );
  const history = useHistory();

  const { user } = state.user.value;

  useEffect(() => {
    if (!state.user.value) {
      return;
    }
    setName(user.name);
    setPhone(user.phone);
  }, [state.user.value]);

  useEffect(() => {
    if (
      state.updatedUser.value &&
      !state.updatedUser.loading &&
      !state.updatedUser.error
    ) {
      fetchUser(user.pkUser);
    }
  }, [state.updatedUser]);

  return (
    <View className={className}>
      <Confirm
        aria-label="confirm"
        content="Are you sure you want to save changes?"
        onCancel={() => setShowConfirmUserDetails(false)}
        onConfirm={async () => {
          setShowConfirmUserDetails(false);
          await updateUser(user.pkUser, { name, phone });
        }}
        open={showConfirmUserDetails}
        role="dialog"
        size="small"
      />
      <Form aria-label="userDetails" role="form">
        <Form.Group widths="equal">
          <Form.Field>
            <ResponsiveInput
              focus
              icon={{
                name: 'pencil',
                color: name !== user.name && name !== '' ? 'yellow' : undefined
              }}
              label={{
                content: 'Name',
                color: name !== user.name && name !== '' ? 'yellow' : undefined
              }}
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              focus
              label={{ content: 'E-mail' }}
              value={user.email ?? 'Basic User'}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <ResponsiveInput
              focus
              icon={{
                name: 'pencil',
                color:
                  phone !== user.phone && phone !== '' ? 'yellow' : undefined
              }}
              label={{
                content: 'Phone',
                color:
                  phone !== user.phone && phone !== '' ? 'yellow' : undefined
              }}
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              value={phone ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              focus
              label={{ content: 'Joined' }}
              value={
                user.created && user.created.length > 0
                  ? format(new Date(`${user.created}Z`), 'P')
                  : user.created
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <ResponsiveInput
              disabled
              focus
              label={{ content: 'Last Login' }}
              name="last_login"
              onChange={(e) => {}}
              style={{ opacity: 1 }}
              value={
                user
                  ? user.lastLogin
                    ? format(
                        new Date(`${stripUtcDateFormatting(user.lastLogin)}Z`),
                        'PPp'
                      )
                    : 'Never'
                  : ''
              }
            />
          </Form.Field>
          <Form.Field />
        </Form.Group>
        {((name !== user.name && name !== '') ||
          (phone !== user.phone && phone !== '')) && (
          <View>
            <View
              css={{
                flex: 1,
                justifyContent: 'flex-end',
                flexDirection: 'row'
              }}
            >
              <Button
                aria-label="saveDetails"
                content="Save Changes"
                loading={state.updatedUser.loading}
                onClick={() => setShowConfirmUserDetails(true)}
                primary
                role="button"
              />
              <Button
                aria-label="cancelSaveDetails"
                content="Cancel"
                css={css`
                  margin-left: 10px !important;
                `}
                onClick={() => {
                  setName(user.name);
                  setPhone(user.phone);
                }}
                role="button"
                secondary
              />
            </View>
            <View
              css={{ flex: 1, textAlign: 'right', color: 'red', padding: 10 }}
            >
              <Text>{state.updatedUser.error}</Text>
            </View>
          </View>
        )}
      </Form>
      <Header
        as="h4"
        css={css`
          margin-bottom: 8px !important;
          margin-top: 10px !important;
        `}
      >
        Household
      </Header>
      <Segment
        css={css`
          margin-top: 0px !important;
          min-height: '50px';
        `}
        loading={state.associatedAccounts.loading}
      >
        {!state.associatedAccounts.loading ? (
          <List aria-label="associatedAccounts" role="list" selection>
            {state.associatedAccounts.value.length > 0 ? (
              state.associatedAccounts.value.map((u) => (
                <ActionableListItem
                  key={u.pkUser}
                  actionColor="red"
                  actionTitle="Remove"
                  avatar={
                    u.teams && u.teams.length > 0 ? u.teams[0].team.image : null
                  }
                  description={
                    u.teams && u.teams.length > 0
                      ? u.teams[0].team.name
                      : 'No Team'
                  }
                  header={u.name}
                  onAction={() =>
                    deleteRemoteUsers([
                      { parent: user.pkUser, child: u.pkUser }
                    ])
                  }
                  onClick={() => history.push(`/users/${u.pkUser}`)}
                  showImage
                />
              ))
            ) : (
              <Text>No associated accounts.</Text>
            )}
          </List>
        ) : (
          <Placeholder fluid>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        )}
      </Segment>
    </View>
  );
};

UserDetails.propTypes = { className: PropTypes.string };

export default UserDetails;
