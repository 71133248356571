import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Confirm,
  Divider,
  Header,
  Icon,
  List,
  Segment
} from 'semantic-ui-react';
import { css } from '@emotion/react';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import View from '../components/common/View';
import { Context as LiveStreamContext } from '../providers/LiveStreamProvider';
import TournamentView from '../components/content/TournamentView/TournamentView';
import { mq } from '../config/theme';
import ContentModal from '../components/common/ContentModal';
import ManagementTournamentEditor from '../components/content/TournamentView/ManagementTournamentEditor';
import { stripUtcDateFormatting } from '../helpers/formatHelper';
import ActionableListItem from '../components/common/ActionableListItem';
import GenerateCrmReportModal from '../components/content/TournamentView/GenerateCrmReportModal';

const TournamentsView = () => {
  const {
    state,
    fetchManagementTournaments,
    deleteManagementTournament
  } = useContext(LiveStreamContext);
  const [showCreateTournament, setShowCreateTournament] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(null);
  const [showGenerateCrmReport, setShowGenerateCrmReport] = useState(false);

  useEffect(() => {
    fetchManagementTournaments();
  }, []);

  return (
    <>
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want to remove ${showConfirmDelete?.title}`}
        onCancel={() => setShowConfirmDelete(null)}
        onConfirm={async () => {
          setShowConfirmDelete(null);
          await deleteManagementTournament(showConfirmDelete.pkMgmtTournament);
          fetchManagementTournaments();
        }}
        open={showConfirmDelete != null}
        role="dialog"
        size="tiny"
      />
      <ContentModal
        actionContent="Create"
        hideActions
        isOpen={showCreateTournament}
        onClose={() => setShowCreateTournament(false)}
        size="tiny"
        title="Create Management Tournament"
      >
        <ManagementTournamentEditor
          onClose={() => setShowCreateTournament(false)}
        />
      </ContentModal>
      <Divider
        css={css`
          margin-top: 0px !important;
        `}
      />
      <View css={mq({ flexDirection: ['column', 'column', 'row'] })}>
        <View
          css={mq({
            maxWidth: ['100%', '100%', '33%'],
            minWidth: ['100%', '100%', '33%']
          })}
        >
          <View
            css={{
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Header
              as="h4"
              css={css`
                margin-bottom: 0px !important;
                margin-top: 0px !important;
              `}
            >
              Tournaments
            </Header>
            <View css={{ flexDirection: 'row' }}>
              <Icon
                css={{ alignSelf: 'center', cursor: 'pointer' }}
                name="refresh"
                onClick={() => {
                  if (!state.venues.loading) {
                    fetchManagementTournaments();
                  }
                }}
              />
              <Icon
                aria-label="createTournament"
                css={{
                  alignSelf: 'center',
                  cursor: 'pointer',
                  marginLeft: '5px !important'
                }}
                name="plus"
                onClick={() => {
                  setShowCreateTournament(true);
                }}
                role="button"
              />
            </View>
          </View>
          <Segment
            aria-label="tournamentsList"
            loading={state.managementTournaments.loading}
            role="region"
            style={{ flex: 1, minHeight: '50px' }}
          >
            <View
              css={mq({
                minHeight: [null, null, '100%'],
                maxHeight: ['40vh', '40vh', 450],
                overflowY: 'auto',
                flex: 1
              })}
            >
              {!state.managementTournaments.loading && (
                <List selection>
                  {state.managementTournaments.value.map((t) => (
                    <ActionableListItem
                      key={t.pkMgmtTournament}
                      actionColor="red"
                      actionTitle={
                        <List.Icon name="trash" style={{ margin: 0 }} />
                      }
                      as={Link}
                      data-key={t.pkMgmtTournament}
                      description={`${format(
                        new Date(`${stripUtcDateFormatting(t.startDate)}Z`),
                        'PP'
                      )}${
                        t.endDate
                          ? ` - ${format(
                              new Date(`${stripUtcDateFormatting(t.endDate)}Z`),
                              'PP'
                            )}`
                          : ''
                      }`}
                      header={t.title}
                      icon={(
                        <List.Icon
                          color={t.isActive ? 'green' : 'red'}
                          name="trophy"
                          style={{ paddingTop: 7 }}
                        />
                      )}
                      onAction={
                        t.isActive
                          ? () => t.isActive && setShowConfirmDelete(t)
                          : null
                      }
                      to={`/tournaments/${t.pkMgmtTournament}/details`}
                    />
                  ))}
                </List>
              )}
            </View>
          </Segment>
          <Button
            color="blue"
            onClick={() => setShowGenerateCrmReport(true)}
            style={{ marginLeft: '0.75em' }}
            type="button"
          >
            <Icon name="download" />
            CRM Report
          </Button>
        </View>
        <Switch>
          <Route path="/tournaments/:pkMgmtTournament">
            <TournamentView />
          </Route>
        </Switch>
      </View>
      <GenerateCrmReportModal
        isOpen={showGenerateCrmReport}
        onClose={() => setShowGenerateCrmReport(false)}
      />
    </>
  );
};

TournamentsView.propTypes = {};

export default TournamentsView;
