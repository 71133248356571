import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm, Header, List, Segment } from 'semantic-ui-react';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import View from '../../common/View';
import ActionableListItem from '../../common/ActionableListItem';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import Text from '../../common/Text';
import AddSubscriptionModal from './AddSubscriptionModal';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';

const UserSubscriptions = ({ className }) => {
  const {
    state,
    fetchPromotions,
    fetchUserPromotions,
    deleteUserPromotion
  } = useContext(TeamManagementContext);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [showAddSubscription, setShowAddSubscription] = useState(false);
  const activePromotions = state.userPromotions.value.filter((p) => p.isActive);
  const inactivePromotions = state.userPromotions.value.filter(
    (p) => !p.isActive
  );

  useEffect(() => {
    if (state.user.value) {
      fetchPromotions();
      fetchUserPromotions(state.user.value.user.pkUser);
    }
  }, [state.user.value]);

  return (
    <View className={className} css={{ flexDirection: 'column' }}>
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want remove ${selectedPromotion?.type} promotion`}
        onCancel={() => setSelectedPromotion(null)}
        onConfirm={() => {
          setSelectedPromotion(null);
          deleteUserPromotion(selectedPromotion.pkPromotion);
        }}
        open={selectedPromotion !== null}
        role="dialog"
        size="tiny"
      />
      <AddSubscriptionModal
        isOpen={showAddSubscription}
        onClose={() => setShowAddSubscription(false)}
      />
      <View
        css={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 8,
          alignItems: 'center'
        }}
      >
        <Header
          as="h4"
          css={css`
            margin-bottom: 0px !important;
          `}
        >
          Active Promotions
        </Header>
        <Button
          color="green"
          compact
          content="Add"
          icon={{ name: 'plus' }}
          labelPosition="right"
          onClick={() => setShowAddSubscription(true)}
        />
      </View>
      <Segment
        aria-label="userPromotionsActive"
        loading={state.userPromotions.loading}
        role="region"
        style={{ minHeight: '50px', marginTop: '0px' }}
      >
        {!state.userPromotions.loading && (
          <List aria-label="userPromotionsActive" role="list" selection>
            {activePromotions.length > 0 ? (
              activePromotions.map((t) => (
                <ActionableListItem
                  key={t.pkPromotion}
                  actionColor="red"
                  actionTitle="Remove"
                  description={`${format(
                    new Date(`${stripUtcDateFormatting(t.start)}Z`),
                    'P p'
                  )} - ${format(
                    new Date(`${stripUtcDateFormatting(t.end)}Z`),
                    'P p'
                  )}`}
                  header={`${t.type} - ${t.description}`}
                  onAction={() => setSelectedPromotion(t)}
                  onClick={() => {}}
                />
              ))
            ) : (
              <Text>No active promotions</Text>
            )}
          </List>
        )}
      </Segment>
      {inactivePromotions.length > 0 && (
        <View>
          <View
            css={{
              marginTop: 5,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 8,
              alignItems: 'center'
            }}
          >
            <Header
              as="h4"
              css={css`
                margin-bottom: 0px !important;
              `}
            >
              Inactive Promotions
            </Header>
          </View>
          <Segment
            aria-label="userPromotionsInactive"
            loading={state.userPromotions.loading}
            role="region"
            style={{ minHeight: '50px', marginTop: '0px' }}
          >
            {!state.userPromotions.loading && (
              <List aria-label="userPromotionsInactive" role="list" selection>
                {inactivePromotions.map((t) => (
                  <ActionableListItem
                    key={t.pkPromotion}
                    description={`${format(
                      new Date(`${stripUtcDateFormatting(t.start)}Z`),
                      'P p'
                    )} - ${format(
                      new Date(`${stripUtcDateFormatting(t.end)}Z`),
                      'P p'
                    )}`}
                    header={`${t.type} - ${t.description}`}
                    onClick={() => {}}
                  />
                ))}
              </List>
            )}
          </Segment>
        </View>
      )}
    </View>
  );
};

UserSubscriptions.propTypes = { className: PropTypes.string };

export default UserSubscriptions;
