import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Header, Loader, Segment } from 'semantic-ui-react';
import View from '../../common/View';
import Text from '../../common/Text';
import VideoPlayer from '../../common/VideoPlayer';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import { mq } from '../../../config/theme';

const DebugStreamsView = () => {
  const { state, fetchVenues } = useContext(LiveStreamContext);
  const [filterInactive, setFilterInactive] = useState(false);
  const sortedVenues = state.venues.value.sort((a, b) =>
    a.name?.localeCompare(b.name)
  );
  const venueCameras = sortedVenues
    ?.filter(
      (v) =>
        v.enabled &&
        (filterInactive || v.cameras.some((c) => !c.stream.disabled))
    )
    .map((v) => ({
      id: v.id,
      name: v.name,
      cameras: v.cameras
        .filter((c) => filterInactive || !c.stream.disabled)
        .map((c) => ({
          pkStream: c.pkStream,
          name: c.name,
          stream: c.stream,
          file: c.stream.hls,
          playerId: c.name + v.id
        }))
    }));

  useEffect(() => {
    fetchVenues();
  }, []);

  const handleActionForAllCameras = (action) => {
    venueCameras.forEach((v) => {
      v.cameras.forEach((c) => {
        window.jwplayer(c.playerId)?.[action]();
      });
    });
  };

  return (
    <View style={{ flex: 1 }}>
      {!state.venues.loading && !state.upcoming.loading ? (
        <>
          <View
            css={mq({
              flexDirection: ['column', 'row'],
              justifyContent: 'space-between',
              marginBottom: 15,
              alignItems: 'center'
            })}
          >
            <View
              css={mq({
                marginTop: [10, 'unset'],
                marginRight: [null, 20],
                flexDirection: 'row'
              })}
            >
              <Header as="h4" style={{ margin: 'auto 0', marginRight: 30 }}>
                Cameras
              </Header>
              <Checkbox
                checked={filterInactive}
                label="Show Inactive"
                onChange={(e, data) => setFilterInactive(data.checked)}
                style={{ margin: 'auto 0' }}
              />
            </View>
            <View
              css={mq({
                marginTop: [20, 'unset'],
                marginBottom: [20, 'unset'],
                flexDirection: 'row'
              })}
            >
              <View>
                <Button
                  color="red"
                  compact
                  content="Stop All"
                  icon={{ name: 'stop' }}
                  labelPosition="right"
                  onClick={() => {
                    handleActionForAllCameras('stop');
                  }}
                />
              </View>
              <View>
                <Button
                  color="green"
                  compact
                  content="Play All"
                  icon={{ name: 'play' }}
                  labelPosition="right"
                  onClick={() => {
                    handleActionForAllCameras('play');
                  }}
                />
              </View>
            </View>
          </View>
          {venueCameras.length > 0 ? (
            <Segment
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 5,
                marginBottom: 30
              }}
            >
              {venueCameras.map((v) =>
                v.cameras.map((c) => (
                  <View
                    key={c.name}
                    css={mq({
                      width: ['100%', '50%'],
                      textAlign: 'center',
                      flexDirection: 'column',
                      marginBottom: 15,
                      padding: 5
                    })}
                  >
                    <VideoPlayer
                      playerId={c.playerId}
                      playerProps={{
                        aspectratio: '16:9',
                        autostart: false,
                        file: c.file,
                        mute: true
                      }}
                    />
                    <View css={{ marginTop: 5 }}>
                      {!filterInactive && (
                        <Header as="h4" style={{ margin: 'auto 0' }}>
                          {c.stream.currentEventTitle.title}
                        </Header>
                      )}
                      <Header as="h4" style={{ margin: 'auto 0' }}>
                        {v.name}
                      </Header>
                    </View>
                  </View>
                ))
              )}
            </Segment>
          ) : (
            <Text>No Streams available.</Text>
          )}
        </>
      ) : (
        <View
          css={{
            alignItem: 'center',
            justifyContent: 'center',
            flex: 1,
            minHeight: 60
          }}
        >
          <Loader active size="big" />
        </View>
      )}
    </View>
  );
};

export default DebugStreamsView;
