import React from 'react';
import PropTypes from 'prop-types';

const View = ({ children, className, ...rest }) => (
  <div
    className={className}
    css={{ display: 'flex', flexDirection: 'column' }}
    {...rest}
  >
    {children}
  </div>
);

View.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default View;
