import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Button, Modal, Message } from 'semantic-ui-react';
import View from '../../common/View';
import CreateTeamInvite from './CreateTeamInvite';
import CreateBasicUser from './CreateBasicUser';
import { mq } from '../../../config/theme';

const AddTeamUserModal = ({ team, user, isOpen, onClose, isJoinRequest }) => {
  const [isBasicUser, setIsBasicUser] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    setFormError('');
  }, [isBasicUser, isOpen]);

  return (
    <Modal
      aria-label="addTeamUserModal"
      closeIcon
      css={mq({ minWidth: ['100%', 'unset'] })}
      onClose={onClose}
      open={isOpen}
      role="dialog"
      size="tiny"
      style={{ margin: 0 }}
    >
      <Modal.Header as="h4">
        {user
          ? isJoinRequest
            ? 'Send Team Join Request'
            : 'Add Team'
          : 'Add User'}
      </Modal.Header>
      <Modal.Content style={{ padding: 0 }}>
        {!user && (
          <Menu attached="top" tabular>
            <Menu.Item
              active={!isBasicUser}
              as={Button}
              name="Standard"
              onClick={() => setIsBasicUser(false)}
            />
            <Menu.Item
              active={isBasicUser}
              as={Button}
              data-testid="basicUserTab"
              name="Basic"
              onClick={() => setIsBasicUser(true)}
            />
          </Menu>
        )}
        <View attached="bottom" css={{ padding: '1.5rem' }}>
          {!isBasicUser ? (
            <CreateTeamInvite
              isJoinRequest={isJoinRequest}
              onClose={onClose}
              onError={setFormError}
              team={team}
              user={user}
            />
          ) : (
            <CreateBasicUser onClose={onClose} onError={setFormError} />
          )}
          {formError && (
            <Message color="red" size="small">
              {formError}
            </Message>
          )}
        </View>
      </Modal.Content>
    </Modal>
  );
};

AddTeamUserModal.propTypes = {
  isJoinRequest: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  team: PropTypes.object,
  user: PropTypes.object
};

export default AddTeamUserModal;
