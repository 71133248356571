import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Confirm,
  Container,
  Form,
  Icon,
  Select
} from 'semantic-ui-react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import ContentModal from '../../common/ContentModal';
import ResponsiveInput from '../../common/ResponsiveInput';
import View from '../../common/View';
import InputLabel from '../../common/InputLabel';

const initialFormState = {
  data: {
    isGroup: false,
    isCoupon: false,
    isTournament: false,
    promo: {
      name: '',
      description: ''
    },
    group: {
      period: '',
      pkAssociation: null,
      maxRedemptions: null
    },
    coupon: {
      title: '',
      oldPrice: null,
      newPrice: null,
      package: ''
    },
    tournament: { pkTournament: null }
  },
  error: null
};

const CreateSubscriptionModal = ({ isOpen, onClose }) => {
  const { state, fetchAssociations, createPromotion } = useContext(
    TeamManagementContext
  );
  const { state: liveStreamState, fetchTournaments } = useContext(
    LiveStreamContext
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [formState, setFormState] = useState(initialFormState);
  const sortedAssociationsList = state.associations.value.sort((a, b) =>
    a.name?.localeCompare(b.name)
  );

  useEffect(() => {
    if (isOpen) {
      setFormState(initialFormState);
    }
  }, [isOpen]);

  useEffect(() => {
    if (state.associations.value.length === 0) {
      fetchAssociations();
    }
  }, [state.associations.value]);

  useEffect(() => {
    if (liveStreamState.tournaments.value.length === 0) {
      fetchTournaments();
    }
  }, [liveStreamState.tournaments.value]);

  const handleDataChange = (dataGroup, e) => {
    setFormState({
      ...formState,
      data: {
        ...formState.data,
        [dataGroup]: {
          ...formState.data[dataGroup],
          [e.target.name]: e.target.value
        }
      },
      error: null
    });
  };

  return (
    <ContentModal
      error={formState.error}
      hideActions
      isOpen={isOpen}
      onClose={onClose}
      size="tiny"
      title="Create Promotion"
    >
      <Confirm
        aria-label="confirm"
        content="Are you sure you want create a new promotion?"
        onCancel={() => setShowConfirm(false)}
        onConfirm={async () => {
          setShowConfirm(false);
          onClose();
          createPromotion({
            ...formState.data.promo,
            group: formState.data.isTournament
              ? { maxRedemptions: formState.data.group.maxRedemptions }
              : formState.data.isGroup
              ? formState.data.group
              : null,
            coupon: formState.data.isCoupon ? formState.data.coupon : null,
            pkTournament: formState.data.isTournament
              ? formState.data.tournament.pkTournament
              : null
          });
        }}
        open={showConfirm}
        role="dialog"
        size="tiny"
      />
      <Form
        aria-label="createPromotion"
        onSubmit={(e) => {
          e.preventDefault();
          if (
            formState.data.isGroup &&
            !formState.data.group.period &&
            !formState.data.isTournament
          ) {
            setFormState({
              ...formState,
              error: 'Group period required.'
            });
          }
          else if (
            formState.data.isTournament &&
            !formState.data.tournament.pkTournament
          ) {
            setFormState({
              ...formState,
              error: 'Tournament is required.'
            });
          }
          else if (formState.data.isCoupon && !formState.data.coupon.package) {
            setFormState({
              ...formState,
              error: 'Coupon package required.'
            });
          }
          else {
            setShowConfirm(true);
          }
        }}
        role="form"
      >
        <Form.Field>
          <ResponsiveInput
            label="Name"
            name="name"
            onChange={(e) =>
              handleDataChange('promo', {
                target: {
                  name: e.target.name,
                  value: e.target.value.replace(/\s/g, '-')
                }
              })
            }
            placeholder="Demo-Promotion"
            required
            style={{ fontSize: 15 }}
            value={formState.data.promo.name}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Description"
            name="description"
            onChange={(e) => handleDataChange('promo', e)}
            placeholder="All-Access Yearly Subscription"
            required
            style={{ fontSize: 15 }}
            value={formState.data.promo.description}
          />
        </Form.Field>
        <Form.Group inline style={{ marginTop: 15, marginBottom: 15 }}>
          <Form.Field>
            <Checkbox
              aria-label="isGroup"
              checked={formState.data.isGroup || formState.data.isTournament}
              disabled={formState.data.isTournament}
              label="Group"
              name="isGroup"
              onClick={(e) =>
                !formState.data.isTournament
                  ? setFormState({
                      ...formState,
                      data: {
                        ...formState.data,
                        isGroup: !formState.data.isGroup
                      },
                      error: null
                    })
                  : null
              }
              role="checkbox"
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              aria-label="isCoupon"
              checked={formState.data.isCoupon}
              label="Coupon"
              name="isCoupon"
              onClick={(e) =>
                setFormState({
                  ...formState,
                  data: {
                    ...formState.data,
                    isCoupon: !formState.data.isCoupon
                  },
                  error: null
                })
              }
              role="checkbox"
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              aria-label="isTournament"
              checked={formState.data.isTournament}
              label="Tournament"
              name="isTournament"
              onClick={(e) =>
                setFormState({
                  ...formState,
                  data: {
                    ...formState.data,
                    isTournament: !formState.data.isTournament
                  },
                  error: null
                })
              }
              role="checkbox"
            />
          </Form.Field>
        </Form.Group>
        {(formState.data.isGroup || formState.data.isTournament) && (
          <View style={{ marginBottom: 15 }}>
            {!formState.data.isTournament && (
              <Form.Field>
                <InputLabel>Period</InputLabel>
                <Select
                  data-testid="groupPeriod"
                  name="period"
                  onChange={(e, option) =>
                    handleDataChange('group', {target: { name: 'period', value: option.value }})
                  }
                  options={[
                    { key: '', value: '', text: 'Select Period' },
                    { key: 'year', value: 'year', text: 'Year' },
                    { key: 'month', value: 'month', text: 'Month' }
                  ]}
                  placeholder="Select Period"
                  value={formState.data.group.period ?? ''}
                />
              </Form.Field>
            )}
            {!formState.data.isTournament && (
              <Form.Field>
                <InputLabel>Association</InputLabel>
                <View css={{ flexDirection: 'row', flex: 1 }}>
                  <Select
                    css={{ flex: 1 }}
                    loading={state.associations.loading}
                    name="association"
                    onChange={(e, option) =>
                      handleDataChange('group', {target: { name: 'pkAssociation', value: option.value }})
                    }
                    options={[
                      {
                        key: '',
                        value: null,
                        text: 'Select Association'
                      },
                      ...sortedAssociationsList.map((a) => ({
                        key: a.pkAssociation,
                        value: a.pkAssociation,
                        text: a.name
                      }))
                    ]}
                    placeholder="Select Association"
                    search
                    value={formState.data.group.pkAssociation ?? ''}
                  />
                  <Icon
                    css={{ alignSelf: 'center', cursor: 'pointer' }}
                    disabled={state.associations.loading}
                    loading={state.associations.loading}
                    name="refresh"
                    onClick={() => {
                      fetchAssociations();
                    }}
                    style={{ margin: 10 }}
                  />
                </View>
              </Form.Field>
            )}
            <Form.Field>
              <ResponsiveInput
                label="Max Redemptions"
                min="0"
                name="maxRedemptions"
                onChange={(e) => handleDataChange('group', e)}
                placeholder="0"
                style={{ fontSize: 15 }}
                type="number"
                value={formState.data.group.maxRedemptions ?? 0}
              />
            </Form.Field>
          </View>
        )}
        {formState.data.isCoupon && (
          <View style={{ marginBottom: 15 }}>
            <Form.Field>
              <ResponsiveInput
                label="Card Title"
                name="title"
                onChange={(e) => handleDataChange('coupon', e)}
                placeholder="PROMOTION"
                required
                style={{ fontSize: 15 }}
                value={formState.data.coupon.title}
              />
            </Form.Field>
            <Form.Field>
              <ResponsiveInput
                label="Old Price"
                name="oldPrice"
                onChange={(e) => handleDataChange('coupon', e)}
                placeholder="$59.99"
                required
                style={{ fontSize: 15 }}
                value={formState.data.coupon.oldPrice ?? ''}
              />
            </Form.Field>
            <Form.Field>
              <ResponsiveInput
                label="New Price"
                name="newPrice"
                onChange={(e) => handleDataChange('coupon', e)}
                placeholder="$44.99"
                required
                style={{ fontSize: 15 }}
                value={formState.data.coupon.newPrice ?? ''}
              />
            </Form.Field>
            <Form.Field>
              <InputLabel>Package</InputLabel>
              <Select
                name="package"
                onChange={(e, option) =>
                  handleDataChange('coupon', {target: { name: 'package', value: option.value }})
                }
                options={[
                  {
                    key: '',
                    value: '',
                    text: 'Select Package'
                  },
                  {
                    key: 'MonthlyAccess',
                    value: 'MonthlyAccess',
                    text: 'MonthlyAccess'
                  },
                  {
                    key: 'YearlyAccess',
                    value: 'YearlyAccess',
                    text: 'YearlyAccess'
                  },
                  {
                    key: 'BulkYearlyAccess',
                    value: 'BulkYearlyAccess',
                    text: 'BulkYearlyAccess'
                  }
                ]}
                placeholder="Select Package"
                value={formState.data.coupon.package ?? ''}
              />
            </Form.Field>
          </View>
        )}
        {formState.data.isTournament && (
          <View style={{ marginBottom: 15 }}>
            <Form.Field>
              <InputLabel>Tournament</InputLabel>
              <View css={{ flexDirection: 'row', flex: 1 }}>
                <Select
                  css={{ flex: 1 }}
                  loading={liveStreamState.tournaments.loading}
                  name="tournament"
                  onChange={(e, option) =>
                    handleDataChange('tournament', {target: { name: 'pkTournament', value: option.value }})
                  }
                  options={[
                    {
                      key: '',
                      value: null,
                      text: 'Select Tournament'
                    },
                    ...liveStreamState.tournaments.value.map((t) => ({
                      key: t.pkTournament,
                      value: t.pkTournament,
                      text: t.name
                    }))
                  ]}
                  placeholder="Select Tournament"
                  search
                  value={formState.data.tournament.pkTournament ?? ''}
                />
                <Icon
                  css={{ alignSelf: 'center', cursor: 'pointer' }}
                  disabled={liveStreamState.tournaments.loading}
                  loading={liveStreamState.tournaments.loading}
                  name="refresh"
                  onClick={() => {
                    fetchTournaments();
                  }}
                  style={{ margin: 10 }}
                />
              </View>
            </Form.Field>
          </View>
        )}
        <Container style={{ textAlign: 'right', margin: 0 }}>
          <Button
            aria-label="cancelCreatePromotion"
            color="black"
            onClick={onClose}
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="button"
          >
            Cancel
          </Button>
          <Button
            aria-label="createPromotion"
            content="Create"
            positive
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="submit"
          />
        </Container>
      </Form>
    </ContentModal>
  );
};

CreateSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default CreateSubscriptionModal;
