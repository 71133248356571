import { Button, Group, Stack } from '@mantine/core';
import React from 'react';
import { Route, Switch, useLocation, Link, Redirect } from 'react-router-dom';
import { mq } from '../../../config/theme';
import RegMerchPackageView from './RegMerchPackageView';
import MerchPackageOrderView from './MerchPackageOrderView';

const VIEW_TABS = [
  {
    label: 'Packages',
    value: 'packages',
    to: '/registration/merchandise/packages',
    renderView: () => <RegMerchPackageView />,
    isSelected: (pathname) =>
      pathname.startsWith('/registration/merchandise/packages')
  },
  {
    label: 'Orders',
    value: 'orders',
    to: '/registration/merchandise/orders',
    renderView: () => <MerchPackageOrderView />,
    isSelected: (pathname) =>
      pathname.startsWith('/registration/merchandise/orders')
  }
];

const RegMerchView = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Group
        sx={mq({
          flex: 1,
          alignItems: 'start',
          flexDirection: ['column', 'column', 'row']
        })}
      >
        <Stack
          sx={mq({
            flex: 1,
            gap: 5,
            alignSelf: 'stretch',
            maxWidth: ['unset', 250]
          })}
        >
          {VIEW_TABS.map((tab) => {
            const isSelected = tab.isSelected(pathname);

            return (
              <Button
                key={tab.value}
                color="gray"
                component={Link}
                justify="start"
                size="md"
                style={{
                  background: 'unset',
                  ...(isSelected
                    ? {
                        color: '#000',
                        background: '#ededed',
                        outline: '1px solid #FFF',
                        boxShadow:
                          'inset 0px -2px 6px 0px rgba(100, 100, 100, 0.2), inset 0px 2px 6px 0px rgba(100, 100, 100, 0.2), inset 0px 2px 4px 0px rgba(100, 100, 100, 0.2)'
                      }
                    : { color: 'grey' })
                }}
                to={tab.to}
                variant={isSelected ? 'light' : 'subtle'}
              >
                {tab.label}
              </Button>
            );
          })}
        </Stack>
        <Stack
          sx={{
            flex: 1,
            alignSelf: 'stretch'
          }}
        >
          <Switch>
            {VIEW_TABS.map((t) => (
              <Route key={t.value} path={t.to}>
                {t.renderView()}
              </Route>
            ))}
            <Route path="*">
              <Redirect to={VIEW_TABS[0].to} />
            </Route>
          </Switch>
        </Stack>
      </Group>
    </>
  );
};

export default RegMerchView;
