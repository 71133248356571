import {
  ActionIcon,
  Checkbox,
  Group,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import React, { useContext, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import { Route, Switch } from 'react-router-dom';
import { mq } from '../../../config/theme';
import RegAssociationList from './RegAssociationList';
import RegAssociationDetails from './RegAssociationDetails';
import AddRegAssociationModal from './AddRegAssociationModal';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';

const RegAssociationsView = () => {
  const { state } = useContext(RegistrationContext);
  const [showAddModal, setShowAddModal] = useState(false);
  const [filterState, setFilterState] = useState({
    name: '',
    showDeleted: false
  });

  return (
    <>
      <Group sx={{ flex: 1, alignItems: 'start' }}>
        <Stack css={{ flex: 1, gap: 10, maxWidth: 350 }}>
          <Group sx={{ flex: 1, justifyContent: 'space-between' }}>
            <Text sx={{ fontSize: 15 }} weight={700}>
              Associations
            </Text>
            <ActionIcon
              color="dark"
              height={20}
              onClick={() => setShowAddModal(true)}
              variant="transparent"
            >
              <Plus />
            </ActionIcon>
          </Group>
        </Stack>
      </Group>
      <Group
        sx={mq({
          flex: 1,
          alignItems: 'start',
          flexDirection: ['column', 'column', 'row']
        })}
      >
        <Stack css={{ flex: 1, gap: 5, alignSelf: 'stretch', maxWidth: 350 }}>
          <Checkbox
            checked={filterState.showDeleted}
            label="Show deleted associations"
            onChange={(e) =>
              setFilterState({
                ...filterState,
                showDeleted: e.currentTarget.checked
              })
            }
            style={{ fontWeight: 500 }}
          />
          <TextInput
            onChange={(e) =>
              setFilterState({
                ...filterState,
                name: e.currentTarget.value
              })
            }
            placeholder="Filter by name or domain"
            value={filterState.name}
          />

          <RegAssociationList
            isLoading={state.regAssociations.loading}
            regAssociations={state.regAssociations.value
              .filter(
                (a) =>
                  (filterState.showDeleted || !a.deleted) &&
                  (!filterState.name ||
                    a.association.name
                      .toLowerCase()
                      .replace(' ', '')
                      .includes(
                        filterState.name?.toLowerCase().replace(' ', '')
                      ) ||
                    a.association.domain
                      .toLowerCase()
                      .replace(' ', '')
                      .includes(
                        filterState.name?.toLowerCase().replace(' ', '')
                      ))
              )
              .sort((a, b) =>
                a.association.name.localeCompare(b.association.name)
              )}
          />
        </Stack>
        <Stack
          sx={{
            flex: 1,
            alignSelf: 'stretch'
          }}
        >
          <Switch>
            <Route path="/registration/associations/:pkRegAssociation">
              <RegAssociationDetails />
            </Route>
          </Switch>
        </Stack>
      </Group>
      <AddRegAssociationModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
      />
    </>
  );
};

export default RegAssociationsView;
