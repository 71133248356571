import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Image, Menu, Placeholder, Segment } from 'semantic-ui-react';
import { Route, Switch, useParams, useLocation, Redirect } from 'react-router';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import View from '../../common/View';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { mq } from '../../../config/theme';
import AssociationDetails from './AssociationDetails';
import { uploadFileToStorage } from '../../../helpers/awsHelper';
import AssociationTeamsView from './AssociationTeamsView';

const AssociationView = ({ className }) => {
  const { pkAssociation } = useParams();
  const { pathname } = useLocation();
  const { state, updateAssociation } = useContext(TeamManagementContext);
  const association = state.associations.value.find(
    (a) => a.pkAssociation.toString() === pkAssociation
  );
  const imageInputRef = useRef();

  return (
    <Segment
      aria-label="associationView"
      css={css`
        margin-top: 10px !important;,
        min-height: '50px';
      `}
      loading={state.associations.loading}
      role="region"
    >
      {!state.associations.loading && association ? (
        <View
          className={className}
          css={mq({
            flexDirection: ['column', 'row'],
            alignItems: ['center', 'flex-start']
          })}
        >
          <View css={{ position: 'relative' }}>
            <Image
              avatar
              bordered
              circular
              css={{ fontSize: 80, minWidth: '2em' }}
              rounded
              src={association.image}
            />
            <Icon
              circular
              color="yellow"
              css={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                cursor: 'pointer'
              }}
              inverted
              name="pencil"
              onClick={() => imageInputRef.current.click()}
              size="big"
            />
            <input
              ref={imageInputRef}
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) =>
                uploadFileToStorage(
                  `association${association.pkAssociation}-`,
                  e.target.files[0],
                  (url) =>
                    updateAssociation(association.pkAssociation, {
                      ...association,
                      image: url
                    })
                )
              }
              type="file"
            />
          </View>
          <View
            css={mq({ flexDirection: 'column', flex: 1, marginLeft: [0, 10] })}
          >
            <Menu pointing secondary stackable>
              <Menu.Item
                active={pathname.startsWith(
                  `/team-management/associations/${association.pkAssociation}/details`
                )}
                as={Link}
                name="Details"
                to={`/team-management/associations/${association.pkAssociation}/details`}
              />
              <Menu.Item
                active={pathname.startsWith(
                  `/team-management/associations/${association.pkAssociation}/teams`
                )}
                as={Link}
                name="Teams"
                to={`/team-management/associations/${association.pkAssociation}/teams`}
              />
            </Menu>
            <Switch>
              <Route
                path={`/team-management/associations/${association.pkAssociation}/details`}
              >
                <AssociationDetails association={association} />
              </Route>
              <Route
                path={`/team-management/associations/${association.pkAssociation}/teams`}
              >
                <AssociationTeamsView association={association} />
              </Route>
              <Redirect
                to={`/team-management/associations/${association.pkAssociation}/details`}
              />
            </Switch>
          </View>
        </View>
      ) : (
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
    </Segment>
  );
};

AssociationView.propTypes = { className: PropTypes.string };

export default AssociationView;
