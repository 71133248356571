import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Select, Stack } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import Papa from 'papaparse';
import { format } from 'date-fns';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_LIST } from '../../../config/constants';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';

const MerchReportModal = ({
  isOpen,
  onClose,
  filterState,
  merchPackageFilterOptions
}) => {
  const { generateRegistrationReport } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    fkRegAssociation: '',
    merchPackageUuid: '',
    purchasedAfter: null,
    purchasedBefore: null,
    includeInputAnswers: false,
    analyticsCta: '',
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        fkRegAssociation: filterState?.fkRegAssociation ?? '',
        merchPackageUuid: filterState?.merchPackageUuid ?? '',
        purchasedAfter: filterState?.purchasedAfter ?? null,
        purchasedBefore: filterState?.purchasedBefore ?? null,
        analyticsCta: filterState?.analyticsCta ?? '',
        includeInputAnswers: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  const downloadCsv = (reportResult) => {
    const reportHeaders =
      reportResult?.report.headers.map((h, i) => ({
        ...h,
        selectOptions:
          h.inputType === 'select'
            ? [
                ...new Set(
                  reportResult.report.rows.map((r) => r.columns[i]?.value ?? '')
                )
              ]
            : [],
        key: i
      })) || [];
    const reportRows =
      reportResult?.report.rows.map((r, i) => ({ ...r, key: i })) || [];

    const csv = Papa.unparse({
      fields: reportHeaders.map((h) => h.value),
      data: (reportResult.report.multiReport
        ? reportRows
        : reportRows.sort(filterState.sortCondition)
      ).map((r) => [...r.columns.map((v) => v.value)])
    });
    const blob = new Blob([csv]);
    const csvURL = URL.createObjectURL(blob, { type: 'text/plain' });
    const link = document.createElement('a');
    link.setAttribute('href', csvURL);
    link.setAttribute(
      'download',
      `${reportResult.report.name}-${format(new Date(), 'PP p')}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title="Generate Merch Report"
    >
      <FormSection
        isLoading={formState.isLoading}
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            isLoading: true
          });
          generateRegistrationReport(
            {
              pkRegAssociation: formState.fkRegAssociation || null,
              reportGuid: 'MERCH_TRANSACTIONS',
              inputAnswers: [
                {
                  valueName: 'merchPackageUuid',
                  value: formState.merchPackageUuid ?? null
                },
                {
                  valueName: 'callToAction',
                  value: formState.analyticsCta ?? null
                },
                {
                  valueName: 'includeInputAnswers',
                  value: formState.includeInputAnswers ? 'yes' : null
                }
              ],
              startDate: formState.purchasedAfter
                ? formState.purchasedAfter
                : null,
              endDate: formState.purchasedBefore
                ? formState.purchasedBefore.toISOString()
                : null
            },
            (data) => {
              downloadCsv(data);
              onClose();
            },
            () => {
              triggerNotification();
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }}
        submitTitle="Generate"
      >
        <Stack style={{ gap: 10 }}>
          <Select
            clearable
            data={merchPackageFilterOptions.regAssociations}
            disabled={formState.isLoading}
            label="Association"
            onChange={(value) =>
              setFormState({
                ...formState,
                fkRegAssociation: value
              })
            }
            searchable
            value={formState.fkRegAssociation ?? ''}
          />
          <Select
            clearable
            data={merchPackageFilterOptions.merchPackages}
            disabled={formState.isLoading}
            label="Merch Package"
            onChange={(value) =>
              setFormState({
                ...formState,
                merchPackageUuid: value
              })
            }
            searchable
            value={formState.merchPackageUuid ?? ''}
          />
          <DatePicker
            disabled={formState.isLoading}
            label="Purchased After"
            onChange={(value) => {
              const dateValue = value
                ? new Date(
                    value.getUTCFullYear(),
                    value.getUTCMonth(),
                    value.getUTCDate(),
                    0,
                    0,
                    0
                  )
                : null;
              setFormState({
                ...formState,
                purchasedAfter: dateValue
              });
            }}
            value={formState.purchasedAfter}
          />
          <DatePicker
            disabled={formState.isLoading}
            label="Purchased Before"
            onChange={(value) => {
              const dateValue = value
                ? new Date(
                    value.getUTCFullYear(),
                    value.getUTCMonth(),
                    value.getUTCDate(),
                    0,
                    0,
                    0
                  )
                : null;
              setFormState({
                ...formState,
                purchasedBefore: dateValue
              });
            }}
            value={formState.purchasedBefore}
          />
          <Select
            clearable
            data={ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_LIST}
            disabled={formState.isLoading}
            label="Call to Action"
            onChange={(value) =>
              setFormState({
                ...formState,
                analyticsCta: value
              })
            }
            searchable
            value={formState.analyticsCta ?? ''}
          />

          <Checkbox
            checked={formState.includeInputAnswers}
            disabled={formState.isLoading}
            label="Include input answers"
            onChange={(e) =>
              setFormState({
                ...formState,
                includeInputAnswers: e.target.checked
              })
            }
            style={{ fontWeight: 500, marginTop: 10 }}
          />
        </Stack>
      </FormSection>
    </ResponsiveModal>
  );
};

MerchReportModal.propTypes = {
  filterState: PropTypes.object,
  isOpen: PropTypes.bool,
  merchPackageFilterOptions: PropTypes.object,
  onClose: PropTypes.func
};

export default MerchReportModal;
