import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm, Header, List, Segment } from 'semantic-ui-react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router';
import View from '../../common/View';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import AddTeamUserModal from './AddTeamUserModal';
import ActionableListItem from '../../common/ActionableListItem';
import Text from '../../common/Text';

const UserTeams = ({ className }) => {
  const {
    state,
    updateTeamMembersStatus,
    fetchTeamJoinRequests,
    cancelTeamJoinRequest
  } = useContext(TeamManagementContext);
  const history = useHistory();
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showCreateJoinRequest, setShowCreateJoinRequest] = useState(false);
  const [cancelJoinRequest, setCancelJoinRequest] = useState(null);
  const { teams } = state.user.value.user;
  const activeJoinRequests = state.teamJoinRequests.value.filter(
    (t) => t.isActive
  );

  useEffect(() => {
    if (state.user.value) {
      fetchTeamJoinRequests(state.user.value.user.pkUser, true);
    }
  }, [state.user.value]);

  return (
    <View className={className} css={{ flexDirection: 'column' }}>
      <AddTeamUserModal
        isJoinRequest={showCreateJoinRequest}
        isOpen={showAddTeam || showCreateJoinRequest}
        onClose={() =>
          showCreateJoinRequest
            ? setShowCreateJoinRequest(false)
            : setShowAddTeam(false)
        }
        user={state.user.value.user}
      />
      <Confirm
        aria-label="confirm"
        content={
          selectedTeam?.isActive
            ? `Are you sure you want to remove ${state.user.value.user.name} from ${selectedTeam?.team.name}`
            : `Are you sure you want to restore ${state.user.value.user.name} to ${selectedTeam?.team.name}`
        }
        onCancel={() => setSelectedTeam(null)}
        onConfirm={() => {
          updateTeamMembersStatus(
            selectedTeam.team.pkTeam,
            [
              {
                pkUser: state.user.value.user.pkUser,
                isActive: !selectedTeam.isActive
              }
            ],
            true
          );
          setSelectedTeam(null);
        }}
        open={selectedTeam != null}
        role="dialog"
        size="small"
      />
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want to cancel the join request to ${cancelJoinRequest?.team.name} for ${cancelJoinRequest?.user.name}`}
        onCancel={() => setCancelJoinRequest(null)}
        onConfirm={() => {
          setCancelJoinRequest(null);
          cancelTeamJoinRequest(cancelJoinRequest.pkTeamJoinRequest);
        }}
        open={cancelJoinRequest != null}
        role="dialog"
        size="small"
      />
      <View
        css={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 8,
          alignItems: 'center'
        }}
      >
        <Header
          as="h4"
          css={css`
            margin-bottom: 0px !important;
          `}
        >
          Current Teams
        </Header>
        <Button
          color="green"
          compact
          content="Add Team"
          icon={{ name: 'plus' }}
          labelPosition="right"
          onClick={() => setShowAddTeam(true)}
        />
      </View>
      <Segment
        css={css`
          margin-top: 0px !important;
        `}
      >
        <List aria-label="activeTeams" role="list" selection>
          {teams.some((t) => t.isActive) ? (
            teams
              .filter((t) => t.isActive)
              .map((t) => (
                <ActionableListItem
                  key={t.team.pkTeam}
                  actionColor="red"
                  actionTitle="Remove"
                  avatar={t.team.image}
                  description={t.team.domain}
                  header={t.team.name}
                  onAction={() => setSelectedTeam(t)}
                  onClick={() =>
                    history.push(`/team-management/teams/${t.team.pkTeam}`)
                  }
                  showImage
                />
              ))
          ) : (
            <Text>No active teams</Text>
          )}
        </List>
      </Segment>
      <View aria-label="inactiveTeams" role="region">
        {teams.some((t) => !t.isActive) && (
          <>
            <View
              css={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 8,
                alignItems: 'center'
              }}
            >
              <Header
                as="h4"
                css={css`
                  margin-bottom: 0px !important;
                  margin-top: 10px !important;
                `}
              >
                Previous Teams
              </Header>
            </View>
            <Segment style={{ marginTop: 0, marginBottom: '1rem' }}>
              <List aria-label="inactiveTeams" role="list" selection>
                {teams
                  .filter((t) => !t.isActive)
                  .map((t) => (
                    <ActionableListItem
                      key={t.team.pkTeam}
                      actionColor="green"
                      actionTitle="Restore"
                      avatar={t.team.image}
                      description={t.team.domain}
                      header={t.team.name}
                      onAction={() => setSelectedTeam(t)}
                      onClick={() =>
                        history.push(`/team-management/teams/${t.team.pkTeam}`)
                      }
                      showImage
                    />
                  ))}
              </List>
            </Segment>
          </>
        )}
      </View>
      <View>
        <View
          css={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 8,
            alignItems: 'center'
          }}
        >
          <Header
            as="h4"
            css={css`
              margin-bottom: 0px !important;
              margin-top: 10px !important;
            `}
          >
            Join Requests
          </Header>
          <Button
            color="green"
            compact
            content="Join Team"
            icon={{ name: 'plus' }}
            labelPosition="right"
            onClick={() => setShowCreateJoinRequest(true)}
          />
        </View>
        <Segment
          aria-label="joinRequests"
          css={css`
            margin-top: 0px !important;
          `}
          loading={state.teamJoinRequests.loading}
          role="region"
          style={{ marginTop: '0px', minHeight: '50px' }}
        >
          {!state.teamJoinRequests.loading && (
            <List aria-label="joinRequests" role="list" selection>
              {activeJoinRequests.length > 0 ? (
                activeJoinRequests.map((t) => (
                  <ActionableListItem
                    key={t.pkTeamJoinRequest}
                    actionColor="red"
                    actionTitle="Cancel"
                    avatar={t.team.image}
                    description={t.roles.join(', ')}
                    header={`${t.team.name} - ${t.status}`}
                    onAction={() => setCancelJoinRequest(t)}
                    onClick={() =>
                      history.push(`/team-management/teams/${t.team.pkTeam}`)
                    }
                    showImage
                  />
                ))
              ) : (
                <Text>No active join requests</Text>
              )}
            </List>
          )}
        </Segment>
      </View>
    </View>
  );
};

UserTeams.propTypes = { className: PropTypes.string };

export default UserTeams;
