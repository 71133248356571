import React from 'react';
import { Button, Modal, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { mq } from '../../config/theme';

const ContentModal = ({
  actionContent,
  ariaLabel,
  children,
  error,
  hideActions,
  isOpen,
  onAction,
  onClose,
  onOpen,
  size,
  title
}) => (
  <Modal
    aria-label={ariaLabel ?? 'contentModal'}
    closeIcon
    css={mq({ minWidth: ['100%', 'unset'] })}
    onClose={onClose}
    onOpen={onOpen}
    open={isOpen}
    role="dialog"
    size={size}
    style={{ margin: 0 }}
  >
    {title && <Modal.Header>{title}</Modal.Header>}
    <Modal.Content>{children}</Modal.Content>
    {(((onClose || onAction) && !hideActions) || error) && (
      <Modal.Actions>
        {onClose && !hideActions && (
          <Button color="black" onClick={onClose}>
            Cancel
          </Button>
        )}
        {onAction && !hideActions && (
          <Button
            content={actionContent ?? 'Submit'}
            onClick={onAction}
            positive
          />
        )}
        {error && (
          <Message color="red" data-testid="modalError" size="small">
            {error}
          </Message>
        )}
      </Modal.Actions>
    )}
  </Modal>
);

ContentModal.propTypes = {
  actionContent: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.string,
  hideActions: PropTypes.bool,
  isOpen: PropTypes.bool,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.string
};

export default ContentModal;
