import { Auth } from '@aws-amplify/auth';
import createDataProvider from './createDataProvider';
import sportsheadzApi from '../apis/sportsheadzApi';

const initialState = {
  merchPackages: { value: [], loading: false, error: null },
  regCartMerchPackageBalance: { value: null, loading: false, error: null },
  regCartMerchPackageFilters: { value: null, loading: false, error: null },
  regCartMerchPackages: { value: [], loading: false, error: null },
  regCartOrderHistory: { value: null, loading: false, error: null },
  regAssociationAdmins: { value: [], loading: false, error: null },
  regAssociations: { value: [], loading: false, error: null },
  regAssociation: { value: null, loading: false, error: null },
  regAssociationPaymentWindows: { value: [], loading: false, error: null },
  regAssociationPaymentWindowsSummary: {
    value: [],
    loading: false,
    error: null
  },
  regFormSubmissions: { value: [], loading: false, error: null },
  userAssociationAdmins: { value: [], loading: false, error: null },
  regCartsMissingTransfers: { value: [], loading: false, error: null },
  regCartsMissingTransfersSummary: { value: null, loading: false, error: null }
};

const registrationReducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: null,
          loading: true
        }
      };
    case 'success':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          ...action.payload,
          loading: false
        }
      };
    case 'append':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value,
            ...(Array.isArray(action.payload)
              ? action.payload
              : [action.payload])
          ],
          loading: false
        }
      };
    case 'replace':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value.filter(action.payload.filter),
            ...(Array.isArray(action.payload.value)
              ? action.payload.value
              : [action.payload.value])
          ],
          loading: false
        }
      };
    case 'remove':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: state[action.stateName].value.filter(action.payload),
          loading: false
        }
      };
    case 'error':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: action.payload,
          loading: false
        }
      };
    default:
      return state;
  }
};

const getErrorMessage = (error) => {
  let errorMessage = 'Oops something went wrong!';
  if (error?.response && error.response.status === 409) {
    errorMessage = error.response.data?.error ?? errorMessage;
  }

  return errorMessage;
};

const fetchRegAssociations = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociations'
    });

    const response = await sportsheadzApi.get(
      '/api/staff/registration/associations',
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociations',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociations',
      payload: 'Oops something went wrong'
    });
  }
};

const createRegAssociation = (dispatch) => async (
  {
    fkAssociation,
    primaryColor,
    secondaryColor,
    heroImageUrl,
    logoImageUrl,
    lightModeTheme,
    socials,
    sidebars
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociations'
    });

    const response = await sportsheadzApi.post(
      `/api/registration`,
      {
        fkAssociation,
        primaryColor,
        secondaryColor,
        heroImageUrl,
        logoImageUrl,
        lightModeTheme,
        socials,
        sidebars
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'regAssociations',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociations',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

const deleteRegAssociation = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(`/api/registration/${pkRegAssociation}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociations',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback('Oops something went wrong');
    }
  }
};

const rebaseRegAssociation = (dispatch) => async (
  pkRegAssociation,
  { fkAssociation, merchEnabled, deleted },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/staff/registration/associations/${pkRegAssociation}/rebase`,
      {
        fkAssociation,
        merchEnabled,
        deleted
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociations',
      payload: {
        filter: (a) =>
          a.pkRegAssociation.toString() !== pkRegAssociation.toString(),
        value: response.data
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociations',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback('Oops something went wrong');
    }
  }
};

const fetchRegAssociationAdmins = (dispatch) => async (pkRegAssociation) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationAdmins'
    });

    const response = await sportsheadzApi.get(
      `/api/staff/registration/admins`,
      {
        params: { pkRegAssociation },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationAdmins',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociationAdmins',
      payload: 'Oops something went wrong'
    });
  }
};

const addRegAssociationAdmin = (dispatch) => async (
  { fkUser, fkRegAssociation, permissions },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/staff/registration/admins`,
      {
        fkUser,
        fkRegAssociation,
        permissions
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    dispatch({
      type: 'error',
      stateName: 'regAssociationAdmins',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback(getErrorMessage(e));
    }
  }
};

const deleteRegAssociationAdmin = (dispatch) => async (
  pkRegAssociationAdmin,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/registration/admin/administrators/invite/${pkRegAssociationAdmin}/respond?isAccepted=${false}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociationAdmins',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

const fetchRegAssociationPaymentSubmissions = (dispatch) => async (
  pkRegAssociation
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regFormSubmissions'
    });

    const response = await sportsheadzApi.get(
      `/api/staff/registration/offline-payments?pkRegAssociation=${pkRegAssociation}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissions',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regFormSubmissions',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchRegAssociationPaymentWindowsSummary = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationPaymentWindowsSummary'
    });
    const response = await sportsheadzApi.get(
      `/api/staff/registration/billing/offline-payments/summary`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationPaymentWindowsSummary',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociationPaymentWindowsSummary',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchRegAssociationPaymentWindows = (dispatch) => async ({
  pkRegAssociation,
  status
}) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationPaymentWindows'
    });
    const response = await sportsheadzApi.get(
      `/api/staff/registration/billing/offline-payments`,
      {
        params: { pkRegAssociation, status },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationPaymentWindows',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociationPaymentWindows',
      payload: 'Oops something went wrong'
    });
  }
};

const changeRegAssociationPaymentWindows = (dispatch) => async (
  { pkRegAssociationPaymentWindows, invoiced, paid },
  onSuccess,
  onError
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/staff/registration/billing/offline-payments`,
      {
        pkRegAssociationPaymentWindows,
        invoiced,
        paid
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationPaymentWindows',
      payload: {
        filter: (p) =>
          !pkRegAssociationPaymentWindows.includes(
            p.pkRegAssociationPaymentWindow
          ),
        value: response.data
      }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociationPaymentWindows',
      payload: 'Oops something went wrong'
    });
    if (onError) {
      onError();
    }
  }
};

const fetchMerchPackages = (dispatch) => async (onSuccess, onError) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'merchPackages'
    });
    const response = await sportsheadzApi.get(`/api/staff/merchandise`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    dispatch({
      type: 'success',
      stateName: 'merchPackages',
      payload: { value: response.data }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'merchPackages',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const createMerchPackages = (dispatch) => async (
  { merchPackages },
  onSuccess,
  onError
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/staff/merchandise/packages`,
      merchPackages,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'merchPackages',
      payload: response.data
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'merchPackages',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const updateMerchPackages = (dispatch) => async (
  { merchPackages },
  onSuccess,
  onError
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/staff/merchandise/packages`,
      merchPackages,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'merchPackages',
      payload: {
        filter: (a) =>
          !merchPackages.some((p) => p.pkMerchPackage === a.pkMerchPackage),
        value: response.data
      }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'merchPackages',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const deleteMerchPackages = (dispatch) => async (
  pkMerchPackages,
  onSuccess,
  onError
) => {
  try {
    await sportsheadzApi.delete(
      `/api/staff/merchandise/packages?pkMerchPackages=${pkMerchPackages.join(
        ','
      )}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'merchPackages',
      payload: (a) => !pkMerchPackages.some((p) => p === a.pkMerchPackage)
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'merchPackages',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const fetchMerchPackageOrders = (dispatch) => async (
  {
    fkRegAssociation,
    merchPackageUuid,
    purchasedAfter,
    purchasedBefore,
    analyticsCta,
    search
  } = {},
  onSuccess,
  onError
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartMerchPackages'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/merchandise/orders`,
      {
        params: {
          fkRegAssociation,
          merchPackageUuid,
          purchasedAfter,
          purchasedBefore,
          analyticsCta,
          search
        },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartMerchPackages',
      payload: { value: response.data }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'regCartMerchPackages',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const fetchRegCartMerchPackageFilters = (dispatch) => async (
  { fkRegAssociation } = {},
  onSuccess,
  onError
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartMerchPackageFilters'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/merchandise/orders/filters`,
      {
        params: { fkRegAssociation },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartMerchPackageFilters',
      payload: { value: response.data }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'regCartMerchPackageFilters',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const updateMerchPackageDetails = (dispatch) => async (
  pkRegCartMerchPackage,
  { inputAnswers } = {},
  onSuccess,
  onError
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/staff/merchandise/orders/${pkRegCartMerchPackage}`,
      { inputAnswers },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regCartMerchPackages',
      payload: {
        filter: (f) => f.pkRegCartMerchPackage !== pkRegCartMerchPackage,
        value: response.data
      }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'regCartMerchPackages',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const fetchRegCartMerchPackageBalance = (dispatch) => async (
  pkRegCartMerchPackage,
  onSuccess,
  onError
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartMerchPackageBalance'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/merchandise/orders/${pkRegCartMerchPackage}/balance`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartMerchPackageBalance',
      payload: { value: response.data }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'regCartMerchPackageBalance',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const refundRegCartMerchPackage = (dispatch) => async (
  pkRegCartMerchPackage,
  { partialRefundAmount },
  onSuccess,
  onError
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/merchandise/refund/${pkRegCartMerchPackage}`,
      { partialRefundAmount },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartMerchPackageBalance',
      payload: { value: response.data }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'regCartMerchPackageBalance',
      payload: error
    });
    if (onError) {
      onError(error);
    }
  }
};

const fetchRegCartOrderHistory = (dispatch) => async (
  pkRegCart,
  onSuccess,
  onError
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartOrderHistory'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/cart/history/${pkRegCart}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartOrderHistory',
      payload: { value: response.data }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    const error = getErrorMessage(e);

    dispatch({
      type: 'error',
      stateName: 'regCartOrderHistory',
      payload: 'Oops something went wrong'
    });
    if (onError) {
      onError(error);
    }
  }
};

const sendAdminRegFormSubmissionOrderReceipt = (dispatch) => async (
  orderReceiptRequests,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.post(
      `/api/registration/admin/registrations/submissions/order-receipts`,
      orderReceiptRequests,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    if (onErrorCallback) {
      onErrorCallback(getErrorMessage(e));
    }
  }
};

const fetchUserAdminAssociations = (dispatch) => async (pkUser) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'userAssociationAdmins'
    });

    const response = await sportsheadzApi.get(
      `/api/staff/registration/admins`,
      {
        params: { pkUser },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'userAssociationAdmins',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'userAssociationAdmins',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchRegCartsWithMissingTransfersSummary = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartsMissingTransfersSummary'
    });

    const response = await sportsheadzApi.get(
      `/api/staff/registration/billing/transfers/summary`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartsMissingTransfersSummary',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regCartsMissingTransfersSummary',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchRegCartsWithMissingTransfers = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartsMissingTransfers'
    });

    const response = await sportsheadzApi.get(
      `/api/staff/registration/billing/transfers`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartsMissingTransfers',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regCartsMissingTransfers',
      payload: 'Oops something went wrong'
    });
  }
};

const completeRegCartCheckoutSessions = (dispatch) => async (
  pkRegCarts,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'missingTransfersCreateSummary'
    });

    const response = await sportsheadzApi.post(
      `/api/staff/registration/billing/complete-checkout-session`,
      pkRegCarts,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'missingTransfersCreateSummary',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch {
    dispatch({
      type: 'error',
      stateName: 'missingTransfersCreateSummary',
      payload: 'Oops something went wrong'
    });
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

const generateRegistrationReport = (dispatch) => async (
  { reportGuid, pkRegAssociation, inputAnswers, startDate, endDate },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/staff/registration/reports/generate`,
      { reportGuid, pkRegAssociation, inputAnswers, startDate, endDate },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );

    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};

export const { Provider, Context } = createDataProvider(
  registrationReducer,
  {
    addRegAssociationAdmin,
    changeRegAssociationPaymentWindows,
    createMerchPackages,
    completeRegCartCheckoutSessions,
    createRegAssociation,
    deleteMerchPackages,
    deleteRegAssociation,
    deleteRegAssociationAdmin,
    fetchMerchPackageOrders,
    fetchMerchPackages,
    fetchRegCartsWithMissingTransfers,
    fetchRegCartsWithMissingTransfersSummary,
    fetchRegCartMerchPackageBalance,
    fetchRegCartMerchPackageFilters,
    fetchRegCartOrderHistory,
    fetchRegAssociations,
    fetchRegAssociationAdmins,
    fetchRegAssociationPaymentSubmissions,
    fetchRegAssociationPaymentWindows,
    fetchRegAssociationPaymentWindowsSummary,
    fetchUserAdminAssociations,
    generateRegistrationReport,
    rebaseRegAssociation,
    refundRegCartMerchPackage,
    sendAdminRegFormSubmissionOrderReceipt,
    updateMerchPackageDetails,
    updateMerchPackages
  },
  initialState
);
