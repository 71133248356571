import React, { useContext, useEffect, useRef, useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { Group, Skeleton, Stack, Text } from '@mantine/core';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import View from '../../common/View';
import { mq } from '../../../config/theme';

const StreamViewStats = ({ pkStream }) => {
  const hasFetched = useRef(false);
  const { state, fetchStreamViews } = useContext(LiveStreamContext);
  const dateNow = new Date();
  const [formState, setFormState] = useState({
    loading: false,
    minDate: new Date(dateNow.setDate(dateNow.getDate() - 30)),
    maxDate: new Date()
  });

  useEffect(() => {
    fetchStreamViews(pkStream, formState);
    hasFetched.current = true;
  }, []);

  const onStateChange = (newState) => {
    fetchStreamViews(pkStream, newState);
    setFormState(newState);
  };

  return (
    <View css={{ marginBottom: 15 }}>
      <View
        css={mq({
          flexDirection: ['column', 'column', 'column', 'row'],
          justifyContent: 'space-between',
          alignItems: ['start', 'start', 'start', 'end']
        })}
      >
        <Header
          as="h4"
          css={{
            margin: '0px !important',
            marginBottom: [5, 5, 5, 5, 0]
          }}
        >
          Stream Stats
        </Header>
        <Group
          sx={{
            alignSelf: 'stretch',
            flexDirection: ['column', 'row', 'column', 'row']
          }}
        >
          <DatePicker
            css={mq({
              width: ['100%', '100%', '100%', 'unset'],
              flex: ['unset', 1, 'unset', 1]
            })}
            disabled={formState.loading}
            label="Minimum Date"
            onChange={(value) => {
              const dateValue = new Date(
                value.getUTCFullYear(),
                value.getUTCMonth(),
                value.getUTCDate(),
                0,
                0,
                0
              );
              onStateChange({
                ...formState,
                minDate: dateValue,
                maxDate:
                  formState.maxDate && dateValue > new Date(formState.maxDate)
                    ? dateValue
                    : formState.maxDate
              });
            }}
            value={formState.minDate}
          />
          <DatePicker
            css={mq({
              width: ['100%', '100%', '100%', 'unset'],
              flex: ['unset', 1, 'unset', 1]
            })}
            disabled={formState.loading}
            label="Maximum Date"
            onChange={(value) => {
              const dateValue = new Date(
                value.getUTCFullYear(),
                value.getUTCMonth(),
                value.getUTCDate(),
                0,
                0,
                0
              );
              onStateChange({
                ...formState,
                maxDate: dateValue,
                minDate:
                  formState.minDate &&
                  new Date(dateValue) < new Date(formState.minDate)
                    ? dateValue
                    : formState.minDate
              });
            }}
            value={formState.maxDate}
          />
        </Group>
      </View>
      <Segment aria-label="eventList" css={{ minHeight: '50px' }} role="region">
        {!hasFetched.current || state.streamViews.loading ? (
          <Stack sx={{ gap: 15 }}>
            <Skeleton height={12} width={175} />
            <Skeleton height={12} width={175} />
            <Skeleton height={12} width={175} />
          </Stack>
        ) : (
          <Stack sx={{ gap: 10 }}>
            <Text>Total Views: {state.streamViews.value?.totalViewsCount}</Text>
            <Text>
              Unique Views: {state.streamViews.value?.uniqueViewsCount}
            </Text>
            <Text>
              Engaged Accounts: {state.streamViews.value?.engagedAccountsCount}
            </Text>
          </Stack>
        )}
      </Segment>
    </View>
  );
};

StreamViewStats.propTypes = { pkStream: PropTypes.string };

export default StreamViewStats;
