import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm, Header, List, Segment } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import View from '../../common/View';
import Text from '../../common/Text';
import ActionableListItem from '../../common/ActionableListItem';
import AddVenueModal from './AddVenueModal';

const TournamentVenues = ({ venues, onAdd, onRemove, canRedirect }) => {
  const history = useHistory();
  const [showAddVenue, setShowAddVenue] = useState(false);
  const [confirmRemoveVenue, setConfirmRemoveVenue] = useState(null);

  return (
    <View css={{ flexDirection: 'column' }}>
      <View
        css={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 5,
          alignItems: 'center',
          placeItems: 'end'
        }}
      >
        <Header as="h4" style={{ marginBottom: '0px' }}>
          Venues
        </Header>
        <Button
          aria-label="addVenue"
          color="green"
          compact
          content="Add Venue"
          icon={{ name: 'plus' }}
          labelPosition="right"
          onClick={() => setShowAddVenue(true)}
          role="button"
          type="button"
        />
      </View>
      <Segment stlye={{ marginTop: '0px' }}>
        <List aria-label="tournamentVenues" role="list" selection>
          {venues.length > 0 ? (
            venues.map((v) => (
              <ActionableListItem
                key={v.id}
                actionColor="red"
                actionTitle="Remove"
                avatar={v.image_url}
                description={`${v.city}, ${v.province_code}`}
                header={v.name}
                onAction={() => setConfirmRemoveVenue(v)}
                onClick={() =>
                  canRedirect && history.push(`/cameras/${v.id}/venue`)
                }
                showImage
              />
            ))
          ) : (
            <Text>No Venues</Text>
          )}
        </List>
      </Segment>
      <AddVenueModal
        isOpen={showAddVenue}
        onAdd={onAdd}
        onClose={() => setShowAddVenue(false)}
        tournamentPkVenues={venues.map((v) => v.id)}
      />
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want to remove ${confirmRemoveVenue?.name}`}
        onCancel={() => setConfirmRemoveVenue(null)}
        onConfirm={() => {
          onRemove(confirmRemoveVenue);
          setConfirmRemoveVenue(null);
        }}
        open={confirmRemoveVenue != null}
        role="dialog"
        size="tiny"
      />
    </View>
  );
};

TournamentVenues.propTypes = {
  canRedirect: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  venues: PropTypes.array
};

export default TournamentVenues;
