import React from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Button, Divider, Group, Stack } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { mq } from '../../config/theme';

const FormSection = ({
  onSubmit,
  onCancel,
  cancelTitle,
  cancelColor,
  submitTitle,
  submitColor,
  children,
  isLoading,
  isSubmitDisabled,
  isSubmitHidden,
  isCancelHidden,
  isDisabled,
  error,
  ...rest
}) => (
  <Stack
    component="form"
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit(e);
    }}
    {...rest}
    sx={{ gap: 40, ...rest.sx }}
  >
    {children}
    {(!isSubmitHidden || !isCancelHidden) && (
      <>
        {error && (
          <Alert
            aria-label="formError"
            color="red"
            icon={<AlertCircle size={16} />}
            radius="xs"
            role="dialog"
            variant="filled"
          >
            {error}
          </Alert>
        )}
        <Divider />
        <Group sx={{ justifyContent: 'center' }}>
          {!isCancelHidden && (
            <Button
              color={cancelColor ?? 'dark'}
              disabled={isLoading || isDisabled}
              onClick={onCancel}
              sx={mq({
                flex: 1,
                maxWidth: 250,
                display: ['none', 'none', 'inline-block', 'inline-block']
              })}
              type="button"
            >
              {cancelTitle ?? 'Cancel'}
            </Button>
          )}
          {!isSubmitHidden && (
            <Button
              color={submitColor ?? 'blue'}
              disabled={isSubmitDisabled || isDisabled}
              loading={isLoading}
              sx={{ flex: 1, maxWidth: 250 }}
              type="submit"
            >
              {submitTitle ?? 'Submit'}
            </Button>
          )}
        </Group>
        <Stack
          sx={mq({
            gap: 40,
            flex: 1,
            justifyContent: 'flex-end',
            display: ['flex', 'flex', 'none', 'none']
          })}
        >
          <Divider />
          <Group sx={{ justifyContent: 'center' }}>
            {!isCancelHidden && (
              <Button
                color={cancelColor ?? 'dark'}
                disabled={isLoading || isDisabled}
                onClick={onCancel}
                sx={{ flex: 1, maxWidth: 250 }}
                type="button"
              >
                Cancel
              </Button>
            )}
          </Group>
        </Stack>
      </>
    )}
  </Stack>
);

FormSection.propTypes = {
  cancelColor: PropTypes.string,
  cancelTitle: PropTypes.string,
  children: PropTypes.any,
  error: PropTypes.string,
  isCancelHidden: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  isSubmitHidden: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitColor: PropTypes.string,
  submitTitle: PropTypes.string
};

export default FormSection;
