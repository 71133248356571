import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Badge,
  Button,
  Divider,
  Group,
  Menu,
  Stack
} from '@mantine/core';
import { Plus, X } from 'tabler-icons-react';

const ListFilter = ({
  onClear,
  onApply,
  appliedFilters,
  onRemoveFilter,
  children
}) => (
  <Group>
    <Menu shadow="md" width={350} withinPortal>
      <Menu.Target>
        <Button compact radius="xl" rightIcon={<Plus size={18} />} size="xs">
          Filter
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Stack style={{ gap: 10 }}>
          <Stack style={{ gap: 10, padding: 5 }}>{children}</Stack>
          <Divider />
          <Group style={{ gap: 5, justifyContent: 'flex-end' }}>
            <Button
              disabled={appliedFilters.length === 0}
              onClick={onClear}
              radius="xl"
              size="xs"
              variant="outline"
            >
              Clear
            </Button>
            <Button onClick={onApply} radius="xl" size="xs">
              Apply
            </Button>
          </Group>
        </Stack>
      </Menu.Dropdown>
    </Menu>
    {appliedFilters.length > 0 &&
      appliedFilters.map((a) => (
        <Badge
          key={a.label}
          rightSection={(
            <ActionIcon color="red" onClick={() => onRemoveFilter(a)} size={20}>
              <X />
            </ActionIcon>
          )}
        >
          {a.label}
        </Badge>
      ))}
  </Group>
);

ListFilter.propTypes = {
  appliedFilters: PropTypes.array,
  children: PropTypes.node,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  onRemoveFilter: PropTypes.func
};

export default ListFilter;
