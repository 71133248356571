import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm, Container, Form, Select } from 'semantic-ui-react';
import { DateTimePicker } from '@material-ui/pickers';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ContentModal from '../../common/ContentModal';
import CreateSubscriptionModal from './CreateSubscriptionModal';

const AddSubscriptionModal = ({ isOpen, onClose }) => {
  const { state, addUserPromotion } = useContext(TeamManagementContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showCreateSubscription, setShowCreateSubscription] = useState(false);
  const [formState, setFormState] = useState({
    data: { promotion: null, end: new Date() },
    error: null
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        data: { promotion: null, end: new Date() },
        error: null
      });
    }
  }, [isOpen]);

  const sortedPromotionsList = state.promotions.value
    ?.filter(
      (p) =>
        !state.userPromotions.value.some(
          (u) => u.isActive && u.fkPromotionType === p.pkPromotionType
        )
    )
    .sort((a, b) => a.name?.localeCompare(b.name));

  return (
    <ContentModal
      error={formState.error}
      hideActions
      isOpen={isOpen}
      onClose={onClose}
      size="tiny"
      title="Add Promotion"
    >
      <CreateSubscriptionModal
        isOpen={showCreateSubscription}
        onClose={() => setShowCreateSubscription(false)}
      />
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want add ${formState.data.promotion?.name} promotion to ${state.user.value.user.name}`}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          addUserPromotion(state.user.value.user.pkUser, {
            ...formState.data,
            pkPromotionType: formState.data.promotion.pkPromotionType
          });
          setShowConfirm(false);
          onClose();
        }}
        open={showConfirm}
        role="dialog"
        size="tiny"
      />
      <Form
        aria-label="addPromotion"
        onSubmit={(e) => {
          e.preventDefault();

          if (!formState.data.promotion) {
            setFormState({
              ...formState,
              error: 'Promotion required'
            });
          }
          else if (formState.data.end <= new Date()) {
            setFormState({
              ...formState,
              error: 'Invalid end time'
            });
          }
          else {
            setShowConfirm(true);
          }
        }}
        role="form"
      >
        <Form.Field>
          <Select
            css={{ flex: 1 }}
            data-testid="selectPromotion"
            loading={state.promotions.loading}
            name="promotions"
            onChange={(e, option) => {
              if (option.value === 'create') {
                setShowCreateSubscription(true);
              }
              else {
                setFormState({
                  ...formState,
                  data: {
                    ...formState.data,
                    promotion: sortedPromotionsList.find(
                      (a) => a.pkPromotionType === option.value
                    )
                  }
                });
              }
            }}
            options={[
              ...sortedPromotionsList.map((a) => ({
                key: a.pkPromotionType,
                value: a.pkPromotionType,
                text: a.name,
                'data-key': a.pkPromotionType,
                'data-group': a.isGroup,
                'data-tournament': a.isTournament,
                'data-coupon': a.hasCoupon
              })),
              { key: 'create', value: 'create', text: 'Create New Promotion' }
            ]}
            placeholder="Select promotion"
            search
            value={formState.data.promotion?.pkPromotionType ?? ''}
          />
        </Form.Field>
        <Form.Field>
          <DateTimePicker
            data-testid="endTime"
            label="End Time"
            name="end"
            onChange={(date) =>
              setFormState({
                ...formState,
                data: {
                  ...formState.data,
                  end: date
                },
                error: null
              })
            }
            style={{ display: 'flex' }}
            value={formState.data.end}
          />
        </Form.Field>
        <Container style={{ textAlign: 'right', margin: 0 }}>
          <Button
            aria-label="cancelAddPromotion"
            color="black"
            onClick={onClose}
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="button"
          >
            Cancel
          </Button>
          <Button
            aria-label="addPromotion"
            content="Add"
            positive
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="submit"
          />
        </Container>
      </Form>
    </ContentModal>
  );
};

AddSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddSubscriptionModal;
