import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Group, Image, Select, Stack, Text } from '@mantine/core';
import { NumericFormat } from 'react-number-format';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { currencyFormat } from '../../../helpers/formatHelper';

const MerchPackageOrderDetailsModal = ({
  regCartMerchPackage,
  isOpen,
  onClose
}) => {
  const { state, updateMerchPackageDetails } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    inputAnswers: [],
    fkRegAssociation: null,
    editable: false,
    isLoading: false
  });
  const { merchPackage } = regCartMerchPackage || {};

  useEffect(() => {
    if (isOpen && regCartMerchPackage) {
      setFormState({
        inputAnswers: regCartMerchPackage.regCartMerchPackageInputAnswers,
        fkRegAssociation: regCartMerchPackage.regAssociation.pkRegAssociation.toString(),
        editable: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={600}
      title="Merch Package Details"
    >
      <FormSection
        cancelTitle={formState.editable ? 'Cancel' : 'Close'}
        isLoading={formState.isLoading}
        onCancel={() => {
          if (formState.editable) {
            setFormState({
              ...formState,
              editable: false
            });
          }
          else {
            onClose();
          }
        }}
        onSubmit={() => {
          if (!formState.editable) {
            setFormState({
              ...formState,
              editable: true
            });
          }
          else {
            const requiredInputs = regCartMerchPackage.merchPackage.merchPackageInputs.filter(
              (i) => i.isRequired
            );
            const allRequiredInputsFilled =
              requiredInputs.length === 0 ||
              requiredInputs.every((i) =>
                formState.inputAnswers.some(
                  (a) => a.fkMerchPackageInput === i.pkMerchPackageInput
                )
              );
            if (!allRequiredInputsFilled) {
              triggerNotification('Please fill in all required fields');
            }
            else {
              setFormState({
                ...formState,
                isLoading: true
              });
              updateMerchPackageDetails(
                regCartMerchPackage.pkRegCartMerchPackage,
                { inputAnswers: formState.inputAnswers },
                () => {
                  triggerNotification(
                    'Merch Package Updated!',
                    'Success',
                    'green'
                  );
                  onClose();
                },
                (error) => {
                  triggerNotification(error);
                  setFormState({
                    ...formState,
                    isLoading: false
                  });
                }
              );
            }
          }
        }}
        submitTitle={formState.editable ? 'Save Changes' : 'Edit Details'}
      >
        {merchPackage && (
          <Stack style={{ gap: 20 }}>
            <Stack style={{ border: 'solid 1px lightgrey' }}>
              <Stack style={{ flex: 1, overflow: 'hidden' }}>
                <Image
                  fit="contain"
                  height={300}
                  src={
                    merchPackage.merchPackageImages.sort(
                      (a, b) => a.sort - b.sort
                    )[0]?.image
                  }
                />
              </Stack>
            </Stack>

            <Stack style={{ gap: 0 }}>
              <Text style={{ fontSize: 18 }} weight={500}>
                {merchPackage.name}
              </Text>
              <Text
                style={{
                  color: 'grey',
                  fontSize: 14,
                  whiteSpace: 'pre-wrap'
                }}
              >
                {merchPackage.description}
              </Text>
              <Text size="lg" style={{ marginTop: 5 }} weight={500}>
                <NumericFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={merchPackage.price / 100}
                />
              </Text>
            </Stack>

            <Select
              data={state.regAssociations.value
                .sort((a, b) =>
                  a.association.name.localeCompare(b.association.name)
                )
                .map((a) => ({
                  label: a.association.name,
                  value: a.pkRegAssociation.toString()
                }))}
              disabled
              label="Association"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkRegAssociation: value
                })
              }
              placeholder="Select an option"
              value={formState.fkRegAssociation}
            />
            {merchPackage.merchPackageInputs.length > 0 && (
              <>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Stack>
                  {merchPackage.merchPackageInputs
                    .sort((a, b) => a.sort - b.sort)
                    .map((i) => {
                      const formValue = formState.inputAnswers.find(
                        (f) => f.fkMerchPackageInput === i.pkMerchPackageInput
                      );

                      return (
                        <Stack key={i.pkMerchPackageInput} style={{ gap: 0 }}>
                          <Stack style={{ flex: 1 }}>
                            <Group style={{ gap: 5 }}>
                              <Text weight={500}>{i.label}</Text>
                              {i.isRequired && (
                                <Text style={{ color: 'red', fontSize: 16 }}>
                                  *
                                </Text>
                              )}
                            </Group>
                          </Stack>
                          <Select
                            clearable
                            data={i.merchPackageInputOptions.map((p) => ({
                              label: p.adjustedCost
                                ? `${p.value} - ${currencyFormat(
                                    p.adjustedCost / 100
                                  )}`
                                : p.value,
                              value: p.value
                            }))}
                            disabled={
                              !formState.editable || formState.isLoading
                            }
                            onChange={(value) => {
                              setFormState({
                                ...formState,
                                inputAnswers: [
                                  ...formState.inputAnswers.filter(
                                    (f) =>
                                      f.fkMerchPackageInput !==
                                      i.pkMerchPackageInput
                                  ),
                                  {
                                    fkMerchPackageInput: i.pkMerchPackageInput,
                                    answer: value
                                  }
                                ]
                              });
                            }}
                            placeholder="Select an option"
                            required={i.isRequired}
                            searchable
                            value={formValue?.answer || ''}
                          />
                        </Stack>
                      );
                    })}
                </Stack>
              </>
            )}
          </Stack>
        )}
      </FormSection>
    </ResponsiveModal>
  );
};

MerchPackageOrderDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regCartMerchPackage: PropTypes.object
};

export default MerchPackageOrderDetailsModal;
