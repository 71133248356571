/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import { css } from '@emotion/react';
import { Button, Form, Image, Input, Message } from 'semantic-ui-react';
import { Redirect } from 'react-router';
import View from '../components/common/View';
import { palette } from '../config/theme';
import { Context as AuthContext } from '../providers/AuthProvider';
import logo from '../images/sh_logo_circle.png';
import ResponsiveInput from '../components/common/ResponsiveInput';

const SigninView = () => {
  const { state, signin } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  if (state.isSignedIn) {
    return <Redirect to="/" />;
  }

  return (
    <View
      css={{
        flex: 1,
        backgroundColor: palette.primary.dark,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <View
        css={{
          backgroundColor: 'white',
          width: 350,
          padding: 25,
          borderRadius: 16,
          alignItems: 'center'
        }}
      >
        <Image
          bordered
          circular
          css={{ marginBottom: 25 }}
          size="tiny"
          src={logo}
        />
        <Form
          css={{ width: '100%' }}
          error={state.error != null}
          loading={state.displayProgress}
        >
          <Form.Field>
            <label>Email</label>
            <ResponsiveInput
              focus
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email@email.com"
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <ResponsiveInput
              focus
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              type="password"
            />
          </Form.Field>
          <Message
            content={state.error ? state.error.message : ''}
            error
            header={state.error ? state.error.title : ''}
          />
          <Button
            css={css`
              margin-top: 25px !important;
            `}
            fluid
            onClick={() => signin({ email, password })}
            primary
          >
            Login
          </Button>
        </Form>
      </View>
    </View>
  );
};

SigninView.propTypes = {};

export default SigninView;
