import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import { Alert, Group, Stack, Text } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import { format } from 'date-fns';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import { mq } from '../../../config/theme';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import { downloadBlobFile } from '../../../helpers/fileHelper';

const GenerateCrmReportModal = ({ isOpen, onClose }) => {
  const { generateTournamentsCrmReportFileBlob } = useContext(
    LiveStreamContext
  );
  const [formState, setFormState] = useState({
    minDate: null,
    maxDate: null,
    loading: false
  });
  const dateNow = new Date();

  useEffect(() => {
    if (isOpen) {
      setFormState({
        minDate: new Date(new Date().setDate(dateNow.getDate() - 30)),
        maxDate: new Date(new Date().setDate(dateNow.getDate() + 14)),
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title="Generate CRM Report"
    >
      <FormSection
        isLoading={formState.loading}
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            loading: true,
            error: null
          });
          generateTournamentsCrmReportFileBlob(
            formState,
            (blob) => {
              downloadBlobFile(
                blob,
                `tournament_report_${format(
                  new Date(),
                  'dd_MM_yyyy_hh_mm_ss'
                )}.csv`
              );
              onClose();
            },
            (message) =>
              setFormState({
                ...formState,
                loading: false,
                error: message
              })
          );
        }}
        submitTitle="Generate"
        sx={{ gap: 30 }}
      >
        <Stack sx={{ gap: 0 }}>
          <Text weight={500}>Date range</Text>
          <Group>
            <DatePicker
              css={mq({
                width: ['100%', '100%', '100%', 'unset'],
                flex: ['unset', 1, 'unset', 1]
              })}
              disabled={formState.loading}
              format="MMMM do, yyyy"
              label="Minimum Date"
              onChange={(value) => {
                const dateValue = new Date(
                  value.getUTCFullYear(),
                  value.getUTCMonth(),
                  value.getUTCDate(),
                  0,
                  0,
                  0
                );
                setFormState({
                  ...formState,
                  minDate: dateValue,
                  maxDate:
                    formState.maxDate && dateValue > new Date(formState.maxDate)
                      ? dateValue
                      : formState.maxDate,
                  error: null
                });
              }}
              value={formState.minDate}
            />
            <DatePicker
              css={mq({
                width: ['100%', '100%', '100%', 'unset'],
                flex: ['unset', 1, 'unset', 1]
              })}
              disabled={formState.loading}
              format="MMMM do, yyyy"
              label="Maximum Date"
              onChange={(value) => {
                const dateValue = new Date(
                  value.getUTCFullYear(),
                  value.getUTCMonth(),
                  value.getUTCDate(),
                  0,
                  0,
                  0
                );
                setFormState({
                  ...formState,
                  maxDate: dateValue,
                  minDate:
                    formState.minDate &&
                    new Date(dateValue) < new Date(formState.minDate)
                      ? dateValue
                      : formState.minDate,
                  error: null
                });
              }}
              value={formState.maxDate}
            />
          </Group>
        </Stack>

        {formState.error && (
          <Alert color="red" icon={<AlertCircle />}>
            {formState.error}
          </Alert>
        )}
      </FormSection>
    </ResponsiveModal>
  );
};

GenerateCrmReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default GenerateCrmReportModal;
