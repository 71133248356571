import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Confirm,
  Container,
  Form,
  Radio,
  Select
} from 'semantic-ui-react';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import ContentModal from '../../common/ContentModal';
import ResponsiveInput from '../../common/ResponsiveInput';
import { PROVINCE_SELECT_OPTIONS } from '../../../config/constants';
import InputLabel from '../../common/InputLabel';
import { uploadFileToStorage } from '../../../helpers/awsHelper';

const initialFormState = {
  data: {
    name: '',
    street: '',
    city: '',
    province: '',
    provinceCode: '',
    postalCode: '',
    country: '',
    countryCode: '',
    phone: '',
    imageUrl: '',
    isEnabled: false,
    isComingSoon: false
  },
  error: ''
};

const CreateVenueModal = ({ isOpen, onClose }) => {
  const { createVenue } = useContext(LiveStreamContext);
  const imageInputRef = useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    if (isOpen) {
      setFormState(initialFormState);
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      data: {
        ...formState.data,
        [e.target.name]: e.target.value
      },
      error: ''
    });
  };

  return (
    <ContentModal
      actionContent="Create"
      error={formState.error}
      hideActions
      isOpen={isOpen}
      onClose={onClose}
      size="tiny"
      title="Create Venue"
    >
      <Confirm
        aria-label="confirm"
        content="Are you sure you want to create a new venue?"
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          createVenue(formState.data);
          onClose();
        }}
        open={showConfirm}
        role="dialog"
        size="small"
      />
      <Form
        aria-label="createVenue"
        onSubmit={() => {
          if (!formState.data.country) {
            setFormState({
              ...formState,
              error: 'Country is required.'
            });
          }
          else if (!formState.data.province) {
            setFormState({
              ...formState,
              error: 'Province is required.'
            });
          }
          else {
            setShowConfirm(true);
          }
        }}
        role="form"
      >
        <Form.Field>
          <ResponsiveInput
            label="Name"
            name="name"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.name}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Phone"
            name="phone"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.phone}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Street"
            name="street"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.street}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="City"
            name="city"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.city}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Postal Code"
            name="postalCode"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.postalCode}
          />
        </Form.Field>
        <Form.Field>
          <InputLabel>Country</InputLabel>
          <Select
            data-testid="selectCountry"
            onChange={(e, select) => {
              const { key } = select.options.find(
                (option) => option.value === select.value
              );
              setFormState({
                ...formState,
                data: {
                  ...formState.data,
                  country: select.value,
                  countryCode: key
                },
                error: ''
              });
            }}
            options={[{ key: 'CA', value: 'Canada', text: 'Canada' }]}
            placeholder="Select a Country"
            value={formState.country}
          />
        </Form.Field>
        <Form.Field>
          <InputLabel>Province</InputLabel>
          <Select
            data-testid="selectProvince"
            onChange={(e, select) => {
              const { key } = select.options.find(
                (option) => option.value === select.value
              );
              setFormState({
                ...formState,
                data: {
                  ...formState.data,
                  province: select.value,
                  provinceCode: key
                },
                error: ''
              });
            }}
            options={PROVINCE_SELECT_OPTIONS}
            placeholder="Select a Province"
            value={formState.province}
          />
        </Form.Field>
        <Form.Field>
          <InputLabel>Image</InputLabel>
          <ResponsiveInput
            action={(
              <Button
                onClick={() => imageInputRef.current.click()}
                type="button"
              >
                Upload
              </Button>
            )}
            name="imageUrl"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.data.imageUrl}
          />
          <input
            ref={imageInputRef}
            accept="image/png, image/jpeg"
            hidden
            onChange={(e) =>
              uploadFileToStorage('venue-', e.target.files[0], (imageUrl) => {
                setFormState({
                  ...formState,
                  data: {
                    ...formState.data,
                    imageUrl
                  },
                  error: ''
                });
              })
            }
            type="file"
          />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <Radio
              checked={formState.isEnabled}
              label="Enabled"
              onChange={() =>
                setFormState({
                  ...formState,
                  data: {
                    ...formState.data,
                    isEnabled: !formState.isEnabled
                  },
                  error: ''
                })
              }
              style={{ margin: 10 }}
              toggle
            />
          </Form.Field>
          <Form.Field>
            <Radio
              checked={formState.isComingSoon}
              label="Coming Soon"
              onChange={() =>
                setFormState({
                  ...formState,
                  data: {
                    ...formState.data,
                    isComingSoon: !formState.isComingSoon
                  },
                  error: ''
                })
              }
              style={{ margin: 10 }}
              toggle
            />
          </Form.Field>
        </Form.Group>
        <Container
          style={{ textAlign: 'right', marginTop: 20, marginRight: 0 }}
        >
          <Button
            color="black"
            onClick={onClose}
            style={{ marginLeft: '0.75em' }}
            type="button"
          >
            Cancel
          </Button>
          <Button
            aria-label="submitCreateVenue"
            content="Create"
            positive
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="submit"
          />
        </Container>
      </Form>
    </ContentModal>
  );
};

CreateVenueModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default CreateVenueModal;
