import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, List } from 'semantic-ui-react';
import userPlaceholder from '../../images/user_placeholder.png';

const ActionableListItem = ({
  header,
  description,
  avatar,
  icon,
  isLoading,
  showImage,
  actionColor,
  actionTitle,
  onAction,
  onClick,
  ...rest
}) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <List.Item
      onClick={() => onClick && onClick()}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...rest}
    >
      {isHovered && onAction && (
        <List.Content floated="right">
          <Button
            color={actionColor}
            compact
            content={actionTitle}
            loading={isLoading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onAction();
            }}
            type="button"
          />
        </List.Content>
      )}
      {showImage && (
        <Image
          avatar
          css={{ objectFit: 'cover' }}
          src={avatar || userPlaceholder}
        />
      )}
      {icon && <List.Icon>{icon}</List.Icon>}
      <List.Content>
        <List.Header>{header}</List.Header>
        <List.Description>{description}</List.Description>
      </List.Content>
    </List.Item>
  );
};

ActionableListItem.propTypes = {
  actionColor: PropTypes.string,
  actionTitle: PropTypes.node,
  avatar: PropTypes.string,
  description: PropTypes.string,
  header: PropTypes.string,
  icon: PropTypes.node,
  isLoading: PropTypes.bool,
  onAction: PropTypes.func,
  onClick: PropTypes.func,
  showImage: PropTypes.bool
};

export default ActionableListItem;
