const calculateCartTotals = (lineItems, includeFinancialAssistance) => {
  const payableLineItems = lineItems.filter(
    (i) => !i.financialAssistance || includeFinancialAssistance
  );
  return {
    subtotalInCents: payableLineItems.reduce(
      (r, c) => r + c.subtotalInCents,
      0
    ),
    discountInCents: payableLineItems.reduce(
      (r, c) => r + c.discountInCents,
      0
    ),
    taxInCents: payableLineItems.reduce((r, c) => r + c.taxInCents, 0),
    serviceFeeInCents: payableLineItems.reduce(
      (r, c) => (c.customerPaysServiceFees ? r + c.serviceFeeInCents : r),
      0
    ),
    totalInCents: payableLineItems.reduce((r, c) => r + c.totalInCents, 0)
  };
};

export { calculateCartTotals };
