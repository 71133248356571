import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Select, Stack, Text } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';

const ChangePaymentWindowStatusModal = ({
  isOpen,
  onClose,
  regAssociationPaymentWindows,
  currentStatus
}) => {
  const {
    changeRegAssociationPaymentWindows,
    fetchRegAssociationPaymentWindowsSummary
  } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    fkRegAssociationPaymentWindowStatus: '',
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        status:
          currentStatus === 'pending'
            ? 'invoiced'
            : currentStatus === 'invoiced'
            ? 'paid'
            : 'pending',
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title="Change Billing Status"
    >
      <FormSection
        isLoading={formState.isLoading}
        isSubmitDisabled={!formState.status}
        onCancel={onClose}
        onSubmit={(e) => {
          e.preventDefault();
          setFormState({
            ...formState,
            isLoading: true
          });
          changeRegAssociationPaymentWindows(
            {
              pkRegAssociationPaymentWindows: regAssociationPaymentWindows.map(
                (p) => p.pkRegAssociationPaymentWindow
              ),
              invoiced:
                formState.status === 'paid' || formState.status === 'invoiced',
              paid: formState.status === 'paid'
            },
            () => {
              fetchRegAssociationPaymentWindowsSummary();
              onClose();
            },
            () => {
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }}
        submitTitle="Change Status"
        sx={{ gap: 30 }}
      >
        <Stack sx={{ gap: 10 }}>
          <Alert icon={<InfoCircle height={30} />} variant="outline">
            <Text weight={500}>
              Select the status to change the offline payment windows
            </Text>
          </Alert>
          <Select
            data={[
              {
                label: 'Pending',
                value: 'pending'
              },
              {
                label: 'Invoiced',
                value: 'invoiced'
              },
              {
                label: 'Paid',
                value: 'paid'
              }
            ]}
            disabled={formState.isLoading}
            label="Status"
            onChange={(value) =>
              setFormState({
                ...formState,
                status: value
              })
            }
            placeholder="-- Select Status --"
            value={formState.status}
          />
        </Stack>
      </FormSection>
    </ResponsiveModal>
  );
};

ChangePaymentWindowStatusModal.propTypes = {
  currentStatus: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociationPaymentWindows: PropTypes.array
};

export default ChangePaymentWindowStatusModal;
