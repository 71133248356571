import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Confirm,
  Container,
  Form,
  Icon,
  Message,
  Select,
  TextArea
} from 'semantic-ui-react';
import { DateTimePicker } from '@material-ui/pickers';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ResponsiveInput from '../../common/ResponsiveInput';
import InputLabel from '../../common/InputLabel';
import View from '../../common/View';
import TournamentVenues from './TournamentVenues';

const ManagementTournamentEditor = ({ onClose, tournament }) => {
  const { updateManagementTournament, createManagementTournament } = useContext(
    LiveStreamContext
  );
  const { state: teamManagementState, fetchAssociations } = useContext(
    TeamManagementContext
  );
  const sortedAssociationsList = teamManagementState.associations.value.sort(
    (a, b) => a.name?.localeCompare(b.name)
  );
  const hasFormLoaded = useRef(false);
  const [showConfirmTournament, setShowConfirmTournament] = useState(false);
  const [formState, setFormState] = useState({
    data: {
      fkAssociation: null,
      mbswId: '',
      title: '',
      description: '',
      startDate: new Date(),
      endDate: null,
      isActive: true,
      venues: []
    },
    error: ''
  });

  useEffect(() => {
    if (tournament) {
      setFormState({
        data: { ...tournament },
        error: ''
      });
      hasFormLoaded.current = true;
    }
  }, [tournament]);

  useEffect(() => {
    if (
      !teamManagementState.associations.loading &&
      teamManagementState.associations.value.length === 0
    ) {
      fetchAssociations();
    }
  }, [teamManagementState.associations]);

  const handleInputChange = (e) => {
    setFormState({
      data: {
        ...formState.data,
        [e.target.name]: e.target.value
      },
      error: ''
    });
  };

  const hasUnsavedChanges = () => {
    for (const [key, value] of Object.entries(formState.data)) {
      if (value !== tournament[key]) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want to ${
          tournament ? 'save changes' : 'create a new management tournament'
        }?`}
        onCancel={() => setShowConfirmTournament(false)}
        onConfirm={() => {
          if (tournament) {
            updateManagementTournament(tournament.pkMgmtTournament, {
              ...formState.data,
              pkVenues: formState.data.venues.map((v) => v.id)
            });
            setShowConfirmTournament(false);
          }
          else {
            createManagementTournament({
              ...formState.data,
              pkVenues: formState.data.venues.map((v) => v.id)
            });
            onClose();
          }
        }}
        open={showConfirmTournament}
        role="dialog"
        size="tiny"
      />
      <Form
        aria-label="mgmtTournamentForm"
        onSubmit={(e) => {
          e.preventDefault();
          if (
            formState.data.endDate &&
            new Date(formState.data.startDate) >
              new Date(formState.data.endDate)
          ) {
            setFormState({
              ...formState,
              error: 'Invalid date range'
            });
          }
          else if (!formState.data.fkAssociation) {
            setFormState({
              ...formState,
              error: 'Association is required.'
            });
          }
          else {
            setShowConfirmTournament(true);
          }
        }}
        role="form"
      >
        <Form.Field>
          <InputLabel>Title</InputLabel>
          <ResponsiveInput
            name="title"
            onChange={handleInputChange}
            required
            value={formState.data.title ?? ''}
          />
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field>
            <InputLabel>Mbsw ID</InputLabel>
            <ResponsiveInput
              name="mbswId"
              onChange={handleInputChange}
              type="number"
              value={formState.data.mbswId ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <InputLabel>Association</InputLabel>
            <View css={{ flexDirection: 'row', flex: 1 }}>
              <Select
                css={{ flex: 1 }}
                data-testid="selectAssociation"
                loading={teamManagementState.associations.loading}
                name="association"
                onChange={(e, option) =>
                  handleInputChange({
                    target: { name: 'fkAssociation', value: option.value }
                  })
                }
                options={[
                  ...sortedAssociationsList.map((a) => ({
                    key: a.pkAssociation,
                    value: a.pkAssociation,
                    text: a.name
                  }))
                ]}
                placeholder="Select an association"
                search
                value={formState.data.fkAssociation}
              />
              <Icon
                css={{ alignSelf: 'center', cursor: 'pointer' }}
                disabled={teamManagementState.associations.loading}
                loading={teamManagementState.associations.loading}
                name="refresh"
                onClick={() => {
                  fetchAssociations();
                }}
                style={{ margin: 10 }}
              />
            </View>
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <InputLabel>Start Date</InputLabel>
            <DateTimePicker
              data-testid="startDate"
              onChange={(startTimeValue) =>
                handleInputChange({
                  target: { name: 'startDate', value: startTimeValue }
                })
              }
              style={{ display: 'flex' }}
              value={formState.data.startDate}
            />
          </Form.Field>
          <Form.Field>
            <InputLabel>End Date</InputLabel>
            <DateTimePicker
              data-testid="endDate"
              onChange={(endTimeValue) =>
                handleInputChange({
                  target: { name: 'endDate', value: endTimeValue }
                })
              }
              style={{ display: 'flex' }}
              value={formState.data.endDate}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <Form.Field>
            <InputLabel>Description</InputLabel>
            <TextArea
              name="description"
              onChange={handleInputChange}
              value={formState.data.description ?? ''}
            />
          </Form.Field>
        </Form.Field>

        {!tournament && (
          <View css={{ marginBottom: '20px', marginTop: '10px' }}>
            <TournamentVenues
              onAdd={(venue) =>
                setFormState({
                  data: {
                    ...formState.data,
                    venues: [...formState.data.venues, venue]
                  },
                  error: ''
                })
              }
              onRemove={(venue) =>
                setFormState({
                  data: {
                    ...formState.data,
                    venues: [
                      ...formState.data.venues.filter((f) => f.id !== venue.id)
                    ]
                  },
                  error: ''
                })
              }
              venues={formState.data.venues}
            />
          </View>
        )}

        {tournament && hasFormLoaded.current && hasUnsavedChanges() && (
          <View
            css={{
              flex: 1,
              justifyContent: 'flex-end',
              flexDirection: 'row',
              paddingTop: 10
            }}
          >
            <Button
              aria-label="cancelSaveTournament"
              content="Cancel"
              onClick={() =>
                tournament
                  ? setFormState({
                      data: { ...tournament },
                      error: ''
                    })
                  : onClose()
              }
              role="button"
              secondary
              style={{ marginLeft: 10 }}
              type="button"
            />
            <Button
              aria-label="saveTournamentSubmit"
              content={tournament ? 'Save Changes' : 'Create'}
              primary
              role="button"
              type="submit"
            />
          </View>
        )}
        {!tournament && (
          <Container style={{ textAlign: 'right', margin: 0 }}>
            <Button
              aria-label="cancelCreateTournament"
              color="black"
              onClick={onClose}
              role="button"
              style={{ marginLeft: '0.75em' }}
              type="button"
            >
              Cancel
            </Button>
            <Button
              aria-label="createTournamentSubmit"
              content="Create"
              positive
              role="button"
              style={{ marginLeft: '0.75em' }}
              type="submit"
            />
          </Container>
        )}
        {formState.error && (
          <Message color="red" data-testid="modalError" size="small">
            {formState.error}
          </Message>
        )}
      </Form>
    </>
  );
};

ManagementTournamentEditor.propTypes = {
  onClose: PropTypes.func,
  tournament: PropTypes.object
};

export default ManagementTournamentEditor;
