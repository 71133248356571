import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Container, Form } from 'semantic-ui-react';
import { uploadFileToStorage } from '../../../helpers/awsHelper';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ContentModal from '../../common/ContentModal';
import InputLabel from '../../common/InputLabel';
import ResponsiveInput from '../../common/ResponsiveInput';
import View from '../../common/View';

const CreateAssociationModal = () => {
  const { createAssociation } = useContext(TeamManagementContext);
  const [isOpen, setIsOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    domain: '',
    image: ''
  });
  const imageInputRef = useRef();

  useEffect(() => {
    if (isOpen) {
      setFormState({
        name: '',
        domain: '',
        image: ''
      });
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <View css={{ marginTop: 15 }}>
      <Button
        aria-label="newAssociation"
        color="green"
        compact
        content="New Association"
        css={{ width: 'fit-content', alignSelf: 'flex-end' }}
        icon={{ name: 'plus' }}
        labelPosition="right"
        onClick={() => setIsOpen(true)}
        role="button"
      />
      <ContentModal
        actionContent="Create"
        error=""
        hideActions
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="tiny"
        title="Create Association"
      >
        <Form
          aria-label="newAssociation"
          onSubmit={(e) => {
            e.preventDefault();
            createAssociation(formState);
            setIsOpen(false);
          }}
          role="form"
        >
          <Form.Field>
            <ResponsiveInput
              label="Name"
              name="name"
              onChange={handleInputChange}
              required
              style={{ fontSize: 15 }}
              value={formState.name}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              label="Domain"
              name="domain"
              onChange={handleInputChange}
              required
              style={{ fontSize: 15 }}
              value={formState.domain ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <InputLabel>Image</InputLabel>
            <ResponsiveInput
              action={(
                <Button
                  onClick={() => imageInputRef.current.click()}
                  type="button"
                >
                  Upload
                </Button>
              )}
              name="image"
              onChange={handleInputChange}
              style={{ fontSize: 15 }}
              value={formState.image}
            />
            <input
              ref={imageInputRef}
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) =>
                uploadFileToStorage('association-', e.target.files[0], (url) =>
                  setFormState({
                    ...formState,
                    image: url
                  })
                )
              }
              type="file"
            />
          </Form.Field>
          <Container
            style={{ textAlign: 'right', marginTop: 20, marginRight: 0 }}
          >
            <Button
              color="black"
              onClick={() => setIsOpen(false)}
              style={{ marginLeft: '0.75em' }}
              type="button"
            >
              Cancel
            </Button>
            <Button
              content="Create"
              positive
              style={{ marginLeft: '0.75em' }}
              type="submit"
            />
          </Container>
        </Form>
      </ContentModal>
    </View>
  );
};

export default CreateAssociationModal;
