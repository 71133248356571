import { PropTypes } from 'prop-types';
import React from 'react';
import { Modal, Title } from '@mantine/core';
import { mq } from '../../config/theme';

const ResponsiveModal = ({
  title,
  isOpen,
  onClose,
  children,
  styles,
  size,
  ...rest
}) => (
  <Modal
    centered
    onClose={onClose}
    opened={isOpen}
    padding="20px 40px 40px 40px"
    styles={{
      title: { flex: 1 },
      inner: { padding: 0 },
      body: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      },
      modal: mq({
        display: 'flex',
        flexDirection: 'column',
        width: ['100%', '100%', size ?? 600],
        minHeight: ['100%', '100%', 'unset']
      }),
      ...styles
    }}
    title={<Title order={2}>{title}</Title>}
    {...rest}
  >
    {children}
  </Modal>
);

ResponsiveModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  styles: PropTypes.object,
  title: PropTypes.string
};

export default ResponsiveModal;
