import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm, Form, Radio, Placeholder } from 'semantic-ui-react';
import { css } from '@emotion/react';
import { useParams } from 'react-router';
import View from '../../common/View';
import ResponsiveInput from '../../common/ResponsiveInput';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';

const initialEditState = {
  name: '',
  title: '',
  description: '',
  hls: '',
  hostName: '',
  stream: '',
  application: '',
  disabled: true
};

const StreamDetails = ({ venue }) => {
  const { updateStream, fetchVenues } = useContext(LiveStreamContext);
  const { pkCamera } = useParams();
  const [formState, setFormState] = useState(initialEditState);
  const hasCameraLoaded = useRef(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);

  const camera = venue?.cameras.find((c) => c.id.toString() === pkCamera);

  useEffect(() => {
    if (camera) {
      setFormState({
        ...(camera.stream ?? initialEditState),
        name: camera.name,
        hostName: camera.hostName
      });
      hasCameraLoaded.current = true;
    }
  }, [camera]);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const hasFormValueChanged = (key) =>
    formState[key] !== (camera.stream?.[key] ?? camera[key]);

  const hasUnsavedChanges = () =>
    hasFormValueChanged('name') ||
    hasFormValueChanged('title') ||
    hasFormValueChanged('description') ||
    hasFormValueChanged('hls') ||
    hasFormValueChanged('hostName') ||
    hasFormValueChanged('stream') ||
    hasFormValueChanged('application') ||
    formState.disabled !== (camera.stream?.disabled ?? true);

  return (
    <View>
      <Confirm
        aria-label="confirm"
        content="Are you sure you want to save changes?"
        onCancel={() => setShowConfirmEdit(false)}
        onConfirm={async () => {
          setShowConfirmEdit(false);
          await updateStream(camera.stream.pkStream, {
            ...formState,
            isActive: !formState.disabled,
            isBlackout: formState.blackoutMode
          });
          fetchVenues();
        }}
        open={showConfirmEdit}
        role="dialog"
        size="small"
      />
      {camera !== null && hasCameraLoaded.current ? (
        <Form aria-label="streamDetails" role="form">
          <Form.Group widths="equal">
            <Form.Field>
              <ResponsiveInput
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('name') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Name',
                  color: hasFormValueChanged('name') ? 'yellow' : undefined
                }}
                name="name"
                onChange={handleInputChange}
                value={formState.name}
              />
            </Form.Field>
            <Form.Field>
              <ResponsiveInput
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('title') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Title',
                  color: hasFormValueChanged('title') ? 'yellow' : undefined
                }}
                name="title"
                onChange={handleInputChange}
                value={formState.title ?? ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <ResponsiveInput
              icon={{
                name: 'pencil',
                color: hasFormValueChanged('description') ? 'yellow' : undefined
              }}
              label={{
                content: 'Description',
                color: hasFormValueChanged('description') ? 'yellow' : undefined
              }}
              name="description"
              onChange={handleInputChange}
              value={formState.description ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              icon={{
                name: 'pencil',
                color: hasFormValueChanged('hls') ? 'yellow' : undefined
              }}
              label={{
                content: 'HLS File',
                color: hasFormValueChanged('hls') ? 'yellow' : undefined
              }}
              name="hls"
              onChange={handleInputChange}
              value={formState.hls ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              icon={{
                name: 'pencil',
                color: hasFormValueChanged('hostName') ? 'yellow' : undefined
              }}
              label={{
                content: 'Host',
                color: hasFormValueChanged('hostName') ? 'yellow' : undefined
              }}
              name="hostName"
              onChange={handleInputChange}
              value={formState.hostName ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              icon={{
                name: 'pencil',
                color: hasFormValueChanged('stream') ? 'yellow' : undefined
              }}
              label={{
                content: 'Stream ID',
                color: hasFormValueChanged('stream') ? 'yellow' : undefined
              }}
              name="stream"
              onChange={handleInputChange}
              value={formState.stream ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              icon={{
                name: 'pencil',
                color: hasFormValueChanged('application') ? 'yellow' : undefined
              }}
              label={{
                content: 'Application ID',
                color: hasFormValueChanged('application') ? 'yellow' : undefined
              }}
              name="application"
              onChange={handleInputChange}
              value={formState.application ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              checked={!formState.disabled}
              label="Enabled"
              onChange={() =>
                setFormState({
                  ...formState,
                  disabled: !formState.disabled
                })
              }
              toggle
            />
          </Form.Field>
          {hasUnsavedChanges() && (
            <View
              css={{
                flex: 1,
                justifyContent: 'flex-end',
                flexDirection: 'row',
                paddingTop: 10
              }}
            >
              <Button
                aria-label="saveDetails"
                content="Save Changes"
                onClick={() => setShowConfirmEdit(true)}
                primary
                role="button"
              />
              <Button
                aria-label="cancelSaveDetails"
                content="Cancel"
                css={css`
                  margin-left: 10px !important;
                `}
                onClick={() =>
                  setFormState({
                    ...(camera.stream ?? initialEditState),
                    hostName: camera.hostName,
                    name: camera.name
                  })
                }
                role="button"
                secondary
              />
            </View>
          )}
        </Form>
      ) : (
        <Placeholder fluid>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
    </View>
  );
};

StreamDetails.propTypes = { venue: PropTypes.object };

export default StreamDetails;
