import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Group,
  Menu,
  Skeleton,
  Stack,
  Text
} from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import { mq } from '../../config/theme';

const CheckableListItem = ({
  isSelected,
  isDisabled,
  onSelect,
  label,
  subLabel,
  description,
  subDescription,
  onAction,
  menuActions
}) =>
  label || subLabel ? (
    <Group
      sx={mq({
        padding: 10,
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: ['column', 'row'],
        gap: [10, 15]
      })}
    >
      <Stack
        sx={mq({
          width: '100%',
          alignSelf: 'stretch',
          flex: 2,
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
          gap: [5, 15]
        })}
      >
        <Group
          noWrap
          onClick={() => {
            if (!isDisabled) {
              onSelect();
            }
          }}
          sx={{ gap: 15, flex: 1, cursor: onSelect ? 'pointer' : 'cursor' }}
        >
          {onSelect && (
            <Checkbox
              checked={isSelected}
              disabled={isDisabled}
              onChange={() => {}}
              sx={{ display: 'flex' }}
            />
          )}
          <Group
            sx={mq({
              width: '100%',
              flex: 1,
              flexWrap: 'nowrap',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              flexDirection: ['column', 'row'],
              alignItems: 'start',
              gap: [0, 15]
            })}
          >
            <Stack
              sx={{
                gap: 0,
                flex: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              {label && (
                <Text
                  sx={{
                    lineHeight: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  weight={500}
                >
                  {label}
                </Text>
              )}
              {subLabel && (
                <Text
                  sx={{
                    lineHeight: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'normal'
                  }}
                >
                  {subLabel}
                </Text>
              )}
            </Stack>
            {description && (
              <Group sx={{ alignSelf: 'stretch', flex: 1 }}>
                <Stack
                  sx={{
                    gap: 0,
                    flex: 1,
                    whiteSpace: 'nowrap',
                    textAlign: onAction ? 'start' : 'end'
                  }}
                >
                  <Text
                    sx={{
                      lineHeight: '20px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    weight={500}
                  >
                    {description}
                  </Text>
                  {subDescription && (
                    <Text
                      sx={{
                        lineHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'normal'
                      }}
                    >
                      {subDescription}
                    </Text>
                  )}
                </Stack>
              </Group>
            )}
          </Group>
        </Group>

        {onAction && (
          <Stack
            sx={mq({
              alignSelf: 'stretch',
              flex: [1, 'unset'],
              justifyContent: 'center'
            })}
          >
            {menuActions.length === 1 ? (
              <Button
                onClick={() => {
                  onAction(menuActions[0].value);
                }}
                radius="md"
                size="xs"
                sx={{ alignSelf: ['center', 'center', 'unset'] }}
                variant="outline"
              >
                <Text
                  color="dodgerblue"
                  radius="xl"
                  sx={{ fontSize: 14 }}
                  variant="subtle"
                  weight={500}
                >
                  {menuActions[0].label}
                </Text>
              </Button>
            ) : (
              <Menu
                control={
                  <Group
                    sx={{
                      outline: 'solid 1px dodgerblue',
                      borderRadius: 4,
                      height: 26,
                      gap: 5,
                      padding: '0px 8px',
                      alignSelf: ['center', 'center', 'unset'],
                      '&:hover': { background: 'rgba(30, 144, 255, 0.05)' }
                    }}
                  >
                    <Text
                      color="dodgerblue"
                      radius="xl"
                      sx={{ fontSize: 14 }}
                      variant="subtle"
                      weight={500}
                    >
                      Actions
                    </Text>
                    <ChevronDown color="dodgerblue" />
                  </Group>
                }
              >
                {menuActions.map((a) => (
                  <Menu.Item
                    key={a.value}
                    onClick={() => {
                      onAction(a.value);
                    }}
                  >
                    {a.label}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </Stack>
        )}
      </Stack>
    </Group>
  ) : (
    <Group sx={{ padding: 10, flex: 1 }}>
      <Skeleton height={20} width={20} />
      <Stack sx={{ gap: 0, flex: 1 }}>
        <Skeleton height={8} width="30%" />
      </Stack>
    </Group>
  );

CheckableListItem.propTypes = {
  description: PropTypes.any,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  menuActions: PropTypes.array,
  onAction: PropTypes.func,
  onSelect: PropTypes.func,
  subDescription: PropTypes.string,
  subLabel: PropTypes.string
};

export default CheckableListItem;
