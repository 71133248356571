import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Group,
  Image,
  ScrollArea,
  Skeleton,
  Stack,
  Text
} from '@mantine/core';
import { Link } from 'react-router-dom';

const RegAssociationList = ({ regAssociations, isLoading }) => (
  <Card shadow="lg" sx={{ flex: 1, padding: 0, border: 'solid 1px lightgrey' }}>
    <ScrollArea
      offsetScrollbars
      style={{ flex: 1, overflow: 'auto', minHeight: '100%', maxHeight: 450 }}
    >
      <Stack
        sx={{
          flex: 1,
          gap: 0
        }}
      >
        {isLoading || !regAssociations ? (
          <>
            {[...Array(6)].map((x, i) => (
              <Group
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                onClick={() => {}}
                sx={{
                  flex: 1,
                  padding: '10px 5px',
                  cursor: 'pointer',
                  flexWrap: 'nowrap'
                }}
              >
                <Skeleton height={40} width={40} />
                <Stack sx={{ flex: 1, gap: 5, overflowWrap: 'anywhere' }}>
                  <Skeleton height={10} width="100%" />
                  <Skeleton height={8} width="75%" />
                </Stack>
              </Group>
            ))}
          </>
        ) : regAssociations.length === 0 ? (
          <Text style={{ textAlign: 'center', marginTop: 10, fontWeight: 500 }}>
            No Associations Available
          </Text>
        ) : (
          regAssociations
            .sort((a, b) =>
              a.association.name.localeCompare(b.association.name)
            )
            .map((a) => (
              <Group
                key={a.pkRegAssociation}
                component={Link}
                onClick={() => {}}
                sx={{
                  flex: 1,
                  padding: '10px 5px',
                  cursor: 'pointer',
                  flexWrap: 'nowrap',
                  textDecoration: 'none',
                  color: '#000',
                  '&:hover': {
                    color: 'black',
                    backgroundColor: '#f4f4f4'
                  }
                }}
                to={`/registration/associations/${a.pkRegAssociation}`}
              >
                <Image
                  fit="contain"
                  height={40}
                  src={a.logoImageUrl ?? null}
                  width={40}
                />
                <Stack sx={{ gap: 5, overflowWrap: 'anywhere' }}>
                  <Text sx={{ fontSize: 16, lineHeight: '18px' }} weight={700}>
                    {a.association.name}
                  </Text>
                  <Text
                    sx={{
                      fontSize: 14,
                      lineHeight: '16px',
                      overflow: 'hidden'
                    }}
                  >
                    {a.association.domain}
                  </Text>
                </Stack>
              </Group>
            ))
        )}
      </Stack>
    </ScrollArea>
  </Card>
);

RegAssociationList.propTypes = {
  isLoading: PropTypes.bool,
  regAssociations: PropTypes.array
};

export default RegAssociationList;
