import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Divider } from 'semantic-ui-react';
import View from '../components/common/View';
import SearchBar from '../components/common/SearchBar';
import { Context as TeamManagementContext } from '../providers/TeamManagementProvider';
import AssociationView from '../components/content/AssociationsView/AssociationView';
import CreateAssociationModal from '../components/content/AssociationsView/AddAssociationModal';

const AssociationsView = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(null);
  const { state, appendState, fetchAssociations } = useContext(
    TeamManagementContext
  );

  useEffect(() => {
    if (!state.associations.loading && state.associations.value.length === 0) {
      fetchAssociations();
    }
  }, [state.associations]);

  const searchForAssociation = (term) => {
    setSearchResults(
      state.associations.value
        .filter(
          (a) =>
            a.name.toLowerCase().includes(term.toLowerCase()) ||
            a.domain.toLowerCase().includes(term.toLowerCase())
        )
        .filter((a, index) => index < 7)
        .map((a) => ({
          title: a.name,
          key: a.pkAssociation,
          description: a.domain,
          image: a.image,
          data: a
        }))
    );
  };

  return (
    <View>
      <View
        aria-label="associationsSearchBar"
        css={{ flexDirection: 'row', marginTop: 5 }}
        role="searchbox"
      >
        <SearchBar
          loading={state.teamSearchResults.loading}
          onClear={() => {
            appendState({ teamSearchResults: { value: [], loading: false } });
          }}
          onResultSelect={(term) => setSelectedResult(term)}
          onSearch={(term) => searchForAssociation(term)}
          placeholder="Search for association by name or domain..."
          results={searchResults}
        />
      </View>
      {selectedResult != null && (
        <Redirect
          to={`/team-management/associations/${selectedResult.pkAssociation}`}
        />
      )}
      <Switch>
        <Route path="/team-management/associations/:pkAssociation">
          <>
            <Divider style={{ marginTop: 25 }} />
            <AssociationView />
          </>
        </Route>
        <Route path="/team-management/associations">
          <CreateAssociationModal />
        </Route>
      </Switch>
    </View>
  );
};

AssociationsView.propTypes = {};

export default AssociationsView;
