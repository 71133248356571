import React from 'react';
import PropTypes from 'prop-types';

const Text = ({ children, className }) => (
  <p className={className} css={{ fontFamily: 'Roboto' }}>
    {children}
  </p>
);

Text.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default Text;
