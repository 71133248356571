import React, { useContext, useEffect } from 'react';
import { Stack, Tabs } from '@mantine/core';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';
import RegAssociationsView from '../components/content/RegistrationView/RegAssociationsView';
import RegMerchView from '../components/content/MerchView/RegMerchView';

const VIEW_TABS = [
  {
    label: 'Associations',
    value: 'associations',
    to: '/registration/associations',
    isSelected: (pathname) => pathname.startsWith('/registration/associations')
  },
  {
    label: 'Merchandise',
    value: 'merchandise',
    to: '/registration/merchandise',
    isSelected: (pathname) => pathname.startsWith('/registration/merchandise')
  }
];

const RegistrationsView = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { fetchRegAssociations, fetchMerchPackages } = useContext(
    RegistrationContext
  );
  const selectedTab = VIEW_TABS.find((t) => t.isSelected(pathname));

  useEffect(() => {
    fetchRegAssociations();
    fetchMerchPackages();
  }, []);

  return (
    <Stack sx={{ flex: 1, gap: 0 }}>
      <Stack sx={{ flex: 1, gap: 10 }}>
        <Tabs
          onTabChange={(v) =>
            history.push(VIEW_TABS.find((t) => t.value === v).to)
          }
          value={selectedTab?.value}
          variant="outline"
        >
          <Tabs.List>
            {VIEW_TABS.map((t) => (
              <Tabs.Tab key={t.value} value={t.value}>
                {t.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
        <Switch>
          <Route path="/registration/associations">
            <RegAssociationsView />
          </Route>
          <Route path="/registration/merchandise">
            <RegMerchView />
          </Route>
          <Route path="*">
            <Redirect to="/registration/associations" />
          </Route>
        </Switch>
      </Stack>
    </Stack>
  );
};

RegistrationsView.propTypes = {};

export default RegistrationsView;
