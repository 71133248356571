import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Stack, Text } from '@mantine/core';
import { useHistory } from 'react-router-dom';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import PaginationList from '../../common/PaginationList';
import ActionListItem from '../../common/ActionListItem';

const AssociationTeamsView = ({ association }) => {
  const hasFetched = useRef(false);
  const history = useHistory();
  const { state, fetchTeams } = useContext(TeamManagementContext);
  const activeTeams = state.teams.value.filter((t) => t.isActive);
  const inactiveTeams = state.teams.value.filter((t) => !t.isActive);
  const isLoading = !hasFetched.current || state.teams.loading;

  useEffect(() => {
    if (association) {
      fetchTeams(association.pkAssociation);
      hasFetched.current = true;
    }
  }, [association]);

  return (
    <Stack>
      <Stack sx={{ gap: 0 }}>
        <Text sx={{ fontSize: 16 }} weight={700}>
          Active Teams
        </Text>
        <PaginationList
          emptyMessage="No active teams"
          isLoading={isLoading}
          items={activeTeams.map((t) => (
            <ActionListItem
              key={t.pkTeam}
              avatar={t.image}
              description={t.domain}
              header={t.name}
              onClick={() => {
                history.push(`/team-management/teams/${t.pkTeam}`);
              }}
              showImage
            />
          ))}
          itemsPerPage={10}
          LoadingComponent={ActionListItem}
          showItemBorder
        />
      </Stack>

      {inactiveTeams.length > 0 && (
        <Stack sx={{ gap: 0 }}>
          <Text sx={{ fontSize: 16 }} weight={700}>
            Inactive Teams
          </Text>
          <PaginationList
            emptyMessage="No inactive teams"
            isLoading={isLoading}
            items={inactiveTeams.map((t) => (
              <ActionListItem
                key={t.pkTeam}
                avatar={t.image}
                description={t.domain}
                header={t.name}
                onClick={() => {
                  history.push(`/team-management/teams/${t.pkTeam}`);
                }}
                showImage
              />
            ))}
            itemsPerPage={10}
            LoadingComponent={ActionListItem}
            showItemBorder
          />
        </Stack>
      )}
    </Stack>
  );
};

AssociationTeamsView.propTypes = { association: PropTypes.object };

export default AssociationTeamsView;
