import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import SearchBar from '../../common/SearchBar';

const AddRegAssociationModal = ({ isOpen, onClose }) => {
  const { state, appendState, fetchAssociations } = useContext(
    TeamManagementContext
  );
  const { state: regState, createRegAssociation } = useContext(
    RegistrationContext
  );
  const [searchResults, setSearchResults] = useState([]);
  const [formState, setFormState] = useState({
    pkAssociation: '',
    error: '',
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        pkAssociation: '',
        error: '',
        loading: false
      });

      if (
        !state.associations.loading &&
        state.associations.value.length === 0
      ) {
        fetchAssociations();
      }
    }
  }, [isOpen]);

  const searchForAssociation = (term) => {
    setSearchResults(
      state.associations.value
        .filter(
          (a) =>
            a.name.toLowerCase().includes(term.toLowerCase()) ||
            a.domain.toLowerCase().includes(term.toLowerCase())
        )
        .filter((a, index) => index < 7)
        .map((a) => ({
          title: a.name,
          key: a.pkAssociation,
          description: a.domain,
          image: a.image,
          data: a
        }))
    );
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title="Create Registration Association"
    >
      <FormSection
        cancelTitle="Cancel"
        error={formState.error}
        isLoading={formState.loading}
        onCancel={onClose}
        onSubmit={() => {
          const existingRegAssociation = regState.regAssociations.value.find(
            (a) => a.association.pkAssociation === formState.pkAssociation
          );
          if (existingRegAssociation) {
            setFormState({
              ...formState,
              error:
                'Registration already created for the selected Association.'
            });
          }
          else if (!formState.pkAssociation) {
            setFormState({
              ...formState,
              error: 'Please select an Association.'
            });
          }
          else {
            setFormState({
              ...formState,
              error: '',
              loading: true
            });
            createRegAssociation(
              { fkAssociation: formState.pkAssociation },
              onClose,
              () => {
                setFormState({
                  ...formState,
                  loading: false,
                  error: 'Oops something went wrong'
                });
              }
            );
          }
        }}
        submitColor="blue"
        submitTitle="Create"
      >
        <Stack sx={{ marginTop: 20 }}>
          <SearchBar
            loading={state.teamSearchResults.loading}
            onClear={() => {
              appendState({ teamSearchResults: { value: [], loading: false } });
            }}
            onResultSelect={(association) =>
              setFormState({
                ...formState,
                error: '',
                pkAssociation: association?.pkAssociation ?? ''
              })
            }
            onSearch={(term) => searchForAssociation(term)}
            placeholder="Find the association by name/domain"
            results={searchResults}
          />
        </Stack>
      </FormSection>
    </ResponsiveModal>
  );
};

AddRegAssociationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddRegAssociationModal;
