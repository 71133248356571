import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Item, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import View from '../../common/View';
import Text from '../../common/Text';
import CreateStreamModal from './CreateStreamModal';

const VenueStreamList = ({ venue, action }) => {
  const [showCreateStreamModal, setShowCreateStreamModal] = useState(null);

  return (
    <View>
      <View
        css={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Header
          as="h4"
          css={css`
            margin-bottom: 0px !important;
          `}
        >
          Cameras
        </Header>
        <Button
          color="green"
          compact
          content="Create"
          icon={{ name: 'plus' }}
          labelPosition="right"
          onClick={() => setShowCreateStreamModal(true)}
        />
      </View>

      <Segment aria-label="venueStreamList" role="region">
        <Item.Group divided link>
          {venue.cameras.length > 0 ? (
            venue.cameras.map((c) => (
              <Item
                key={c.id}
                as={Link}
                data-id={c.stream?.pkStream}
                style={{ paddingBottom: 10, flexDirection: 'row' }}
                to={`/cameras/${venue.id}/${action}/details/${c.id}`}
              >
                <Item.Content>
                  <Item.Header style={{ fontSize: 16 }}>{c.name}</Item.Header>
                  <Item.Meta>
                    {c.stream?.stream ?? 'No stream available.'}
                  </Item.Meta>
                  <Item.Description>{c.stream?.description}</Item.Description>
                  <Item.Extra>
                    <Icon
                      css={css`
                        color: ${c.stream
                          ? c.stream.disabled
                            ? '#FBBD08'
                            : '#A7C957'
                          : '#FF220C'} !important;
                      `}
                      name="video camera"
                    />
                  </Item.Extra>
                </Item.Content>
                <Item.Content style={{ margin: 'auto' }}>
                  <Icon
                    name="chevron right"
                    size="large"
                    style={{ float: 'right' }}
                  />
                </Item.Content>
              </Item>
            ))
          ) : (
            <Text>No cameras available.</Text>
          )}
        </Item.Group>
      </Segment>
      <CreateStreamModal
        isOpen={showCreateStreamModal != null}
        onClose={() => setShowCreateStreamModal(null)}
        pkVenue={venue.id}
      />
    </View>
  );
};

VenueStreamList.propTypes = {
  action: PropTypes.string.isRequired,
  venue: PropTypes.object
};

export default VenueStreamList;
