import { Auth } from '@aws-amplify/auth';
import createDataProvider from './createDataProvider';
import sportsheadzApi from '../apis/sportsheadzApi';
import { debug, getCircularReplacer } from '../helpers/Logger';

const initialState = {
  userSearchResults: { value: [], loading: false },
  teamSearchResults: { value: [], loading: false },
  associations: { value: [], loading: false },
  associatedAccounts: { value: [], loading: false },
  user: { value: null, loading: false, error: null },
  userEvents: { value: [], loading: false },
  userHangouts: { value: [], loading: false },
  updatedUser: { value: null, loading: false, error: null },
  team: { value: null, loading: false, error: null },
  teams: { value: [], loading: false, error: null },
  teamEvents: { value: [], loading: false },
  teamHangouts: { value: [], loading: false },
  teamJoinRequests: { value: [], loading: false },
  promotions: { value: [], loading: false },
  userPromotions: { value: [], loading: false }
};

const teamReducer = (state, action) => {
  switch (action.type) {
    case 'APPEND_STATE':
      return { ...state, ...action.payload };
    case 'SEARCH_USER_START':
      return {
        ...state,
        userSearchResults: { ...state.userSearchResults, loading: true }
      };
    case 'SEARCH_USER_FINISH':
      return {
        ...state,
        userSearchResults: {
          value: action.payload
            ? action.payload
            : state.userSearchResults.value,
          loading: false
        }
      };
    case 'SEARCH_TEAM_START':
      return {
        ...state,
        teamSearchResults: { ...state.teamSearchResults, loading: true }
      };
    case 'SEARCH_TEAM_FINISH':
      return {
        ...state,
        teamSearchResults: {
          value: action.payload
            ? action.payload
            : state.teamSearchResults.value,
          loading: false
        }
      };
    case 'FETCH_USER_START':
      return {
        ...state,
        user: { value: null, loading: true, error: null },
        updatedUser: { value: null, loading: false, error: null }
      };
    case 'FETCH_USER_FINISH':
      return {
        ...state,
        user: {
          value: action.payload ? action.payload : state.user.value,
          loading: false,
          error: null
        }
      };
    case 'UPDATE_USER_START':
      return {
        ...state,
        updatedUser: {
          ...state.updateUser,
          loading: true,
          error: null
        }
      };
    case 'UPDATE_USER_FINISH':
      return {
        ...state,
        updatedUser: {
          value: action.payload ? action.payload : state.updateUser.value,
          loading: false,
          error: null
        }
      };
    case 'UPDATE_USER_ERROR':
      return {
        ...state,
        updatedUser: {
          value: state.updatedUser.value,
          loading: false,
          error: action.payload ?? 'Error updating user!'
        }
      };
    case 'FETCH_USER_EVENTS_START':
      return {
        ...state,
        userEvents: { ...state.userEvents, loading: true }
      };
    case 'FETCH_USER_EVENTS_FINISH':
      return {
        ...state,
        userEvents: {
          value: action.payload ?? state.userEvents.value,
          loading: false
        }
      };
    case 'FETCH_USER_HANGOUTS_START':
      return {
        ...state,
        userHangouts: { ...state.userHangouts, loading: true }
      };
    case 'FETCH_USER_HANGOUTS_FINISH':
      return {
        ...state,
        userHangouts: {
          value: action.payload ?? state.userHangouts.value,
          loading: false
        }
      };
    case 'FETCH_ASSOCIATES_START':
      return { ...state, associatedAccounts: { value: [], loading: true } };
    case 'FETCH_ASSOCIATES_FINISH':
      return {
        ...state,
        associatedAccounts: {
          value: action.payload
            ? action.payload
            : state.associatedAccounts.value,
          loading: false
        }
      };
    case 'CREATE_ASSOCIATES_START':
      return {
        ...state,
        associatedAccounts: {
          value: { ...state.associatedAccounts.value },
          loading: true
        }
      };
    case 'CREATE_ASSOCIATES_FINISH': {
      return { ...state };
    }
    case 'DELETE_ASSOCIATES_START':
      return {
        ...state,
        associatedAccounts: { ...state.associatedAccounts, loading: true }
      };
    case 'DELETE_ASSOCIATES_FINISH': {
      let associatedAccountsCopy = [...state.associatedAccounts.value];
      if (action.payload) {
        associatedAccountsCopy = associatedAccountsCopy.filter(
          (a) => !action.payload.includes(a.pkUser)
        );
      }
      return {
        ...state,
        associatedAccounts: {
          ...state.associatedAccounts,
          value: associatedAccountsCopy,
          loading: false
        }
      };
    }
    case 'FETCH_ASSOCIATIONS_START':
      return {
        ...state,
        associations: { ...state.associations, loading: true }
      };
    case 'FETCH_ASSOCIATIONS_FINISH':
      return {
        ...state,
        associations: {
          value: action.payload ? action.payload : state.associations.value,
          loading: false
        }
      };
    case 'CREATE_ASSOCIATION_START':
      return {
        ...state,
        associations: { ...state.associations, loading: true }
      };
    case 'CREATE_ASSOCIATION_FINISH':
      return {
        ...state,
        associations: {
          ...state.associations,
          value: action.payload
            ? [action.payload, ...state.associations.value]
            : [...state.associations.value],
          loading: false
        }
      };
    case 'UPDATE_ASSOCIATIONS_START':
      return {
        ...state,
        associations: { ...state.associations, loading: true }
      };
    case 'UPDATE_ASSOCIATIONS_FINISH': {
      const associationsCopy = [...state.associations.value].filter(
        (a) => a.pkAssociation !== action.payload.pkAssociation
      );
      return {
        ...state,
        associations: {
          ...state.associations,
          value: [action.payload, ...associationsCopy],
          loading: false
        }
      };
    }
    case 'FETCH_TEAM_START':
      return {
        ...state,
        team: { value: null, loading: true, error: null }
      };
    case 'FETCH_TEAM_FINISH':
      return {
        ...state,
        team: {
          value: action.payload ? action.payload : state.team.value,
          loading: false
        }
      };
    case 'FETCH_TEAMS_START':
      return {
        ...state,
        teams: { value: [], loading: true, error: null }
      };
    case 'FETCH_TEAMS_FINISH':
      return {
        ...state,
        teams: {
          value: action.payload ? action.payload : state.teams.value,
          loading: false
        }
      };
    case 'FETCH_TEAM_EVENTS_START':
      return {
        ...state,
        teamEvents: { value: [], loading: true }
      };
    case 'FETCH_TEAM_EVENTS_FINISH':
      return {
        ...state,
        teamEvents: {
          value: action.payload ? action.payload : state.teamEvents.value,
          loading: false
        }
      };
    case 'FETCH_TEAM_HANGOUTS_START':
      return {
        ...state,
        teamHangouts: { value: [], loading: true }
      };
    case 'FETCH_TEAM_HANGOUTS_FINISH':
      return {
        ...state,
        teamHangouts: {
          value: action.payload ? action.payload : state.teamHangouts.value,
          loading: false
        }
      };
    case 'CREATE_TEAM_START':
    case 'CREATE_TEAM_FINISH':
      return { ...state };
    case 'CREATE_TEAM_HANGOUTS_START':
    case 'UPDATE_TEAM_HANGOUT_START':
    case 'DELETE_TEAM_HANGOUTS_START':
      return {
        ...state,
        teamHangouts: { ...state.teamHangouts, loading: true }
      };
    case 'CREATE_TEAM_HANGOUTS_FINISH':
      return {
        ...state,
        teamHangouts: {
          value: action.payload
            ? [...state.teamHangouts.value, ...action.payload.events]
            : state.teamHangouts.value,
          loading: false
        }
      };
    case 'UPDATE_TEAM_HANGOUT_FINISH': {
      return {
        ...state,
        teamHangouts: {
          ...state.teamHangouts,
          value: action.payload
            ? [
                ...state.teamHangouts.value.filter(
                  (h) =>
                    h.pkPrivateCalendar !== action.payload.pkPrivateCalendar
                ),
                action.payload
              ]
            : state.teamHangouts.value,
          loading: false
        }
      };
    }
    case 'DELETE_TEAM_HANGOUTS_FINISH': {
      return {
        ...state,
        teamHangouts: {
          value: action.payload
            ? state.teamHangouts.value.filter(
                (h) =>
                  !action.payload
                    .map((p) => p.pkPrivateCalendar)
                    .includes(h.pkPrivateCalendar)
              )
            : state.teamHangouts.value,
          loading: false
        }
      };
    }
    case 'CREATE_TEAM_LEAGUE_EVENT_START':
    case 'UPDATE_TEAM_LEAGUE_EVENT_START':
    case 'DELETE_TEAM_LEAGUE_EVENTS_START':
      return {
        ...state,
        teamEvents: { ...state.teamEvents, loading: true }
      };
    case 'CREATE_TEAM_LEAGUE_EVENT_FINISH':
      return {
        ...state,
        teamEvents: {
          ...state.teamEvents,
          value: action.payload
            ? [...state.teamEvents.value, ...action.payload.events]
            : state.teamEvents.value,
          loading: false
        }
      };
    case 'UPDATE_TEAM_LEAGUE_EVENT_FINISH':
      return {
        ...state,
        teamEvents: {
          ...state.teamEvents,
          value: action.payload
            ? [
                ...state.teamEvents.value.filter(
                  (e) => e.pkCalendar !== action.payload.pkCalendar
                ),
                action.payload
              ]
            : state.teamEvents.value,
          loading: false
        }
      };
    case 'DELETE_TEAM_LEAGUE_EVENTS_FINISH':
      return {
        ...state,
        teamEvents: {
          ...state.teamEvents,
          value: action.payload
            ? [
                ...state.teamEvents.value.filter(
                  (e) =>
                    !action.payload.some((p) => p.pkCalendar === e.pkCalendar)
                )
              ]
            : state.teamEvents.value,
          loading: false
        }
      };
    case 'UPDATE_TEAM_START':
      return { ...state, team: { ...state.team, loading: true } };
    case 'UPDATE_TEAM_FINISH':
      return {
        ...state,
        team: {
          ...state.team,
          value: {
            ...state.team.value,
            team: action.payload ?? state.team.value.team
          },
          loading: false
        }
      };
    case 'UPDATE_TEAM_MEMBERS_START':
      return {
        ...state,
        team: {
          ...state.team,
          loading: true,
          error: null
        }
      };
    case 'UPDATE_TEAM_MEMBERS_FINISH': {
      const updatedUserPks = action.payload.map((p) => p.pkUser);
      let membersCopy = [...state.team.value.members];
      membersCopy = membersCopy.map((m) =>
        updatedUserPks.includes(m.pkUser) ? { ...m, isActive: !m.isActive } : m
      );
      return {
        ...state,
        team: {
          ...state.team,
          value: { ...state.team.value, members: membersCopy },
          loading: false
        }
      };
    }
    case 'UPDATE_USER_TEAMS_START':
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          error: null
        }
      };
    case 'UPDATE_USER_TEAMS_FINISH': {
      const userCopy = { ...state.user.value };
      userCopy.user.teams = userCopy.user.teams.map((t) =>
        t.team.pkTeam === action.payload ? { ...t, isActive: !t.isActive } : t
      );
      return {
        ...state,
        user: {
          ...state.user,
          value: userCopy,
          loading: false
        }
      };
    }
    case 'UPDATE_TEAM_MEMBERS_ERROR':
      return {
        ...state,
        team: {
          value: state.team.value,
          loading: false,
          error: null ?? 'Error updating team members!'
        }
      };
    case 'UPDATE_TEAM_MEMBER_ROLES_START':
      return { ...state, team: { ...state.team, loading: true } };
    case 'UPDATE_TEAM_MEMBER_ROLES_FINISH': {
      const teamCopy = { ...state.team.value };
      teamCopy.members = [
        action.payload,
        ...teamCopy.members.filter((m) => m.pkUser !== action.payload.pkUser)
      ];
      return {
        ...state,
        team: { ...state.team, value: teamCopy, loading: false }
      };
    }
    case 'CREATE_BASIC_USER_START':
    case 'CREATE_BASIC_USER_FINISH':
      return { ...state };
    case 'TEAM_INVITE_START':
      return {
        ...state,
        user: {
          ...state.user,
          loading: true
        },
        team: {
          ...state.team,
          loading: true
        }
      };
    case 'TEAM_INVITE_FINISH':
      return {
        ...state,
        user: {
          ...state.user,
          loading: false
        },
        team: {
          ...state.team,
          loading: false
        }
      };
    case 'FETCH_PROMOTIONS_START':
      return {
        ...state,
        promotions: { value: [], loading: true }
      };
    case 'FETCH_PROMOTIONS_FINISH':
      return {
        ...state,
        promotions: {
          value: action.payload ?? state.promotions.value,
          loading: false
        }
      };
    case 'FETCH_USER_PROMOTIONS_START':
      return {
        ...state,
        userPromotions: { value: [], loading: true }
      };
    case 'FETCH_USER_PROMOTIONS_FINISH':
      return {
        ...state,
        userPromotions: {
          value: action.payload ?? state.userPromotions.value,
          loading: false
        }
      };
    case 'ADD_USER_PROMOTION_START':
      return {
        ...state,
        userPromotions: {
          ...state.userPromotions,
          loading: true
        }
      };
    case 'ADD_USER_PROMOTION_FINISH':
      return {
        ...state,
        userPromotions: {
          value: action.payload ?? state.userPromotions.value,
          loading: false
        }
      };
    case 'CREATE_PROMOTION_START':
      return {
        ...state,
        promotions: { ...state.promotions, loading: true }
      };
    case 'CREATE_PROMOTION_FINISH':
      return {
        ...state,
        promotions: {
          ...state.promotions,
          value: [...state.promotions.value, action.payload],
          loading: false
        }
      };
    case 'DELETE_USER_PROMOTION_START':
      return {
        ...state,
        userPromotions: {
          ...state.userPromotions,
          loading: true
        }
      };
    case 'DELETE_USER_PROMOTION_FINISH': {
      return {
        ...state,
        userPromotions: {
          value: action.payload ?? state.userPromotions.value,
          loading: false
        }
      };
    }
    case 'FETCH_JOIN_REQUESTS_START':
    case 'CREATE_JOIN_REQUESTS_START':
    case 'RESPOND_JOIN_REQUESTS_START':
    case 'CANCEL_JOIN_REQUESTS_START':
      return {
        ...state,
        teamJoinRequests: { ...state.teamJoinRequests, loading: true }
      };
    case 'FETCH_JOIN_REQUESTS_FINISH':
      return {
        ...state,
        teamJoinRequests: {
          value: action.payload ?? state.teamJoinRequests.value,
          loading: false
        }
      };
    case 'CREATE_JOIN_REQUESTS_FINISH':
      return {
        ...state,
        teamJoinRequests: {
          value: action.payload
            ? [...state.teamJoinRequests.value, action.payload]
            : state.teamJoinRequests.value,
          loading: false
        }
      };
    case 'RESPOND_JOIN_REQUESTS_FINISH':
    case 'CANCEL_JOIN_REQUESTS_FINISH':
      return {
        ...state,
        teamJoinRequests: {
          value: action.payload
            ? [
                ...state.teamJoinRequests.value.filter(
                  (r) =>
                    r.pkTeamJoinRequest !== action.payload.pkTeamJoinRequest
                ),
                action.payload
              ]
            : state.teamJoinRequests.value,
          loading: false
        }
      };
    default:
      return state;
  }
};

const appendState = (dispatch) => (state) => {
  dispatch({ type: 'APPEND_STATE', payload: state });
};

// #region Users
const searchForUser = (dispatch) => async ({ searchTerm }) => {
  if (searchTerm) {
    try {
      dispatch({ type: 'SEARCH_USER_START' });
      const response = await sportsheadzApi.get('/api/user/search', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        params: { term: searchTerm }
      });
      debug(JSON.stringify(response, getCircularReplacer(), 2));

      dispatch({ type: 'SEARCH_USER_FINISH', payload: response.data });
    } catch (err) {
      debug(err);
      dispatch({ type: 'SEARCH_USER_FINISH', payload: null });
    }
  }
};

const fetchUser = (dispatch) => async (pkUser) => {
  if (pkUser) {
    try {
      dispatch({ type: 'FETCH_USER_START' });
      const response = await sportsheadzApi.get(`/api/staff/user/${pkUser}`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      });
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'FETCH_USER_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_USER_FINISH', payload: null });
    }
  }
};

const fetchUserEvents = (dispatch) => async (pkUser) => {
  if (pkUser) {
    try {
      dispatch({ type: 'FETCH_USER_EVENTS_START' });
      const response = await sportsheadzApi.get(
        `/api/staff/calendar/${pkUser}/events`,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'FETCH_USER_EVENTS_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_USER_EVENTS_FINISH', payload: null });
    }
  }
};

const fetchUserHangouts = (dispatch) => async (pkUser) => {
  if (pkUser) {
    try {
      dispatch({ type: 'FETCH_USER_HANGOUTS_START' });
      const response = await sportsheadzApi.get(
        `/api/staff/privateCalendar/${pkUser}/events`,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'FETCH_USER_HANGOUTS_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_USER_HANGOUTS_FINISH', payload: null });
    }
  }
};

const fetchRemoteUsers = (dispatch) => async (pkUser) => {
  if (pkUser) {
    try {
      dispatch({ type: 'FETCH_ASSOCIATES_START' });
      const response = await sportsheadzApi.get('/api/user/remote', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        params: { parent: pkUser }
      });
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'FETCH_ASSOCIATES_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_ASSOCIATES_FINISH', payload: null });
    }
  }
};

const createRemoteUsers = (dispatch) => async (remoteUsers = []) => {
  if (remoteUsers.length > 0) {
    try {
      dispatch({ type: 'CREATE_ASSOCIATES_START' });
      const response = await sportsheadzApi.post(
        '/api/user/remote',
        { remoteUsers },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'CREATE_ASSOCIATES_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'CREATE_ASSOCIATES_FINISH', payload: null });
    }
  }
};

const deleteRemoteUsers = (dispatch) => async (remoteUsers = []) => {
  if (remoteUsers.length > 0) {
    try {
      dispatch({ type: 'DELETE_ASSOCIATES_START' });
      const response = await sportsheadzApi.delete('/api/user/remote', {
        data: { remoteUsers },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      });
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'DELETE_ASSOCIATES_FINISH',
        payload: remoteUsers.map((u) => u.child)
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'DELETE_ASSOCIATES_FINISH', payload: null });
    }
  }
};

const updateUser = (dispatch) => async (pkUser, { name, phone, avatar }) => {
  if (pkUser) {
    const splitName = name?.split(/ (.*)/) ?? [];

    try {
      dispatch({ type: 'UPDATE_USER_START' });
      const response = await sportsheadzApi.put(
        `/api/staff/user/${pkUser}/profile`,
        { FirstName: splitName[0], LastName: splitName[1], phone, avatar },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'UPDATE_USER_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'UPDATE_USER_ERROR', payload: e.message });
    }
  }
};
// #endregion

// #region Associations
const fetchAssociations = (dispatch) => async () => {
  try {
    dispatch({ type: 'FETCH_ASSOCIATIONS_START' });
    const response = await sportsheadzApi.get('/api/association', {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'FETCH_ASSOCIATIONS_FINISH', payload: response.data });
  } catch (e) {
    debug(e);
    dispatch({ type: 'FETCH_ASSOCIATIONS_FINISH', payload: null });
  }
};

const createAssociation = (dispatch) => async ({ name, domain, image }) => {
  try {
    dispatch({ type: 'CREATE_ASSOCIATION_START' });
    const response = await sportsheadzApi.post(
      '/api/association',
      {
        name,
        domain,
        image,
        fkGoverningBody: 1,
        email: null
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({ type: 'CREATE_ASSOCIATION_FINISH', payload: response.data });
  } catch (e) {
    debug(e);
    dispatch({ type: 'CREATE_ASSOCIATION_FINISH', payload: null });
  }
};

const updateAssociation = (dispatch) => async (
  pkAssociation,
  { name, domain, image, email } = {}
) => {
  if (pkAssociation) {
    try {
      dispatch({ type: 'UPDATE_ASSOCIATIONS_START' });
      const response = await sportsheadzApi.put(
        `/api/association/${pkAssociation}`,
        {
          name,
          domain,
          image,
          email,
          fkGoverningBody: 1
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'UPDATE_ASSOCIATIONS_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'UPDATE_ASSOCIATIONS_FINISH', payload: null });
    }
  }
};
// #endregion

// #region Teams
const searchForTeam = (dispatch) => async (searchTerm) => {
  if (searchTerm) {
    try {
      dispatch({ type: 'SEARCH_TEAM_START' });
      const response = await sportsheadzApi.get('/api/staff/team/search', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        params: { name: searchTerm }
      });
      debug(JSON.stringify(response, getCircularReplacer(), 2));

      dispatch({ type: 'SEARCH_TEAM_FINISH', payload: response.data });
    } catch (err) {
      debug(err);
      dispatch({ type: 'SEARCH_TEAM_FINISH', payload: null });
    }
  }
};

const fetchTeam = (dispatch) => async (pkTeam) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'FETCH_TEAM_START' });
      const response = await sportsheadzApi.get(`/api/team/${pkTeam}/members`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      });
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'FETCH_TEAM_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_TEAM_FINISH', payload: null });
    }
  }
};

const fetchTeams = (dispatch) => async (pkAssociation) => {
  if (pkAssociation) {
    try {
      dispatch({ type: 'FETCH_TEAMS_START' });
      const response = await sportsheadzApi.get(
        `/api/staff/association/${pkAssociation}/teams`,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'FETCH_TEAMS_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_TEAMS_FINISH', payload: null });
    }
  }
};

const fetchTeamEvents = (dispatch) => async (
  pkTeam,
  { start, end, timezone, versionId } = {}
) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'FETCH_TEAM_EVENTS_START' });
      const response = await sportsheadzApi.get(
        `/api/calendar/team/${pkTeam}/events`,
        {
          params: { start, end, timezone, versionId },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'FETCH_TEAM_EVENTS_FINISH',
        payload: response.data?.events
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_TEAM_EVENTS_FINISH', payload: null });
    }
  }
};

const fetchTeamHangouts = (dispatch) => async (
  pkTeam,
  { start, end, versionId } = {}
) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'FETCH_TEAM_HANGOUTS_START' });
      const response = await sportsheadzApi.get(
        `/api/privatecalendar/team/${pkTeam}/events`,
        {
          params: { start, end, versionId },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'FETCH_TEAM_HANGOUTS_FINISH',
        payload: response.data?.events
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_TEAM_HANGOUTS_FINISH', payload: null });
    }
  }
};

const deleteTeamHangouts = (dispatch) => async (pkTeam, events = []) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'DELETE_TEAM_HANGOUTS_START' });
      const response = await sportsheadzApi.delete(
        `/api/privatecalendar/team/${pkTeam}/events`,
        {
          data: { events },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({ type: 'DELETE_TEAM_HANGOUTS_FINISH', payload: response.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'DELETE_TEAM_HANGOUTS_FINISH', payload: null });
    }
  }
};

const createTeam = (dispatch) => async ({
  name,
  season,
  associationDomain,
  image,
  mbswTeamId,
  fkGoverningBody = 1
} = {}) => {
  if (name && image) {
    try {
      dispatch({ type: 'CREATE_TEAM_START' });
      const response = await sportsheadzApi.post(
        `/api/team`,
        {
          name,
          season,
          mbswTeamId: mbswTeamId ?? 0,
          associationDomain,
          image,
          fkGoverningBody
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      dispatch({
        type: 'CREATE_TEAM_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'CREATE_TEAM_FINISH', payload: null });
    }
  }
};

const createTeamHangout = (dispatch) => async (
  pkTeam,
  { start, end, title, locationName, notes, dataType } = {}
) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'CREATE_TEAM_HANGOUTS_START' });
      const response = await sportsheadzApi.post(
        `/api/privatecalendar/team/${pkTeam}/events`,
        {
          events: [
            {
              start: start.toISOString(),
              end: end.toISOString(),
              title,
              locationName,
              notes,
              dataType
            }
          ]
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      dispatch({
        type: 'CREATE_TEAM_HANGOUTS_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'CREATE_TEAM_HANGOUTS_FINISH', payload: null });
    }
  }
};

const createTeamLeagueEvent = (dispatch) => async (
  pkTeam,
  {
    id,
    start,
    end,
    title,
    label,
    locationName,
    notes,
    dataType,
    locationMapinfo,
    gameType,
    arriveBefore,
    gameOpponent,
    gameScore,
    gameResult,
    data,
    notificationsEnabled,
    notifyUsers,
    isAllDay,
    isCancelled,
    gameHome
  } = {}
) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'CREATE_TEAM_LEAGUE_EVENT_START' });
      const response = await sportsheadzApi.post(
        `/api/calendar/team/${pkTeam}/events`,
        {
          events: [
            {
              id,
              start: start.toISOString(),
              end: end.toISOString(),
              title,
              label,
              locationName,
              notes,
              dataType,
              locationMapinfo,
              gameType,
              arriveBefore,
              gameOpponent,
              gameScore,
              gameResult,
              data,
              notificationsEnabled,
              notifyUsers,
              isAllDay,
              isCancelled,
              gameHome,
              isGame: dataType === 'Game'
            }
          ]
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'CREATE_TEAM_LEAGUE_EVENT_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'CREATE_TEAM_LEAGUE_EVENT_FINISH', payload: null });
    }
  }
};

const updateTeamLeagueEvent = (dispatch) => async (
  pkTeam,
  pkCalandar,
  {
    id,
    start,
    end,
    title,
    label,
    locationName,
    notes,
    dataType,
    locationMapinfo,
    gameType,
    arriveBefore,
    gameOpponent,
    gameScore,
    gameResult,
    data,
    notificationsEnabled,
    notifyUsers,
    isAllDay,
    isCancelled,
    gameHome
  } = {}
) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'UPDATE_TEAM_LEAGUE_EVENT_START' });
      const response = await sportsheadzApi.put(
        `/api/calendar/team/${pkTeam}/events/${pkCalandar}`,
        {
          id,
          start: start.toISOString(),
          end: end.toISOString(),
          title,
          label,
          locationName,
          notes,
          dataType,
          locationMapinfo,
          gameType,
          arriveBefore,
          gameOpponent,
          gameScore,
          gameResult,
          data,
          notificationsEnabled,
          notifyUsers,
          isAllDay,
          isCancelled,
          gameHome
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'UPDATE_TEAM_LEAGUE_EVENT_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'UPDATE_TEAM_LEAGUE_EVENT_FINISH', payload: null });
    }
  }
};

const deleteTeamLeagueEvents = (dispatch) => async (pkTeam, events = []) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'DELETE_TEAM_LEAGUE_EVENTS_START' });
      const response = await sportsheadzApi.delete(
        `/api/calendar/team/${pkTeam}/events`,
        {
          data: { events },
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'DELETE_TEAM_LEAGUE_EVENTS_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'DELETE_TEAM_LEAGUE_EVENTS_FINISH', payload: null });
    }
  }
};

const createBasicUser = (dispatch) => async (
  pkTeam,
  { name, phone, roles } = {}
) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'CREATE_BASIC_USER_START' });
      const response = await sportsheadzApi.post(
        `/api/team/${pkTeam}/basic_user`,
        {
          name,
          phone,
          roles
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'CREATE_BASIC_USER_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'CREATE_BASIC_USER_FINISH', payload: null });
    }
  }
};

const updateTeam = (dispatch) => async (
  pkTeam,
  {
    name,
    season,
    domain,
    mbswId,
    image,
    fkAssociation,
    isActive,
    fkGoverningBody = 1
  } = {}
) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'UPDATE_TEAM_START' });
      const response = await sportsheadzApi.put(
        `/api/team/${pkTeam}`,
        {
          name,
          season,
          domain,
          mbswId,
          image,
          fkAssociation,
          isActive,
          fkGoverningBody
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'UPDATE_TEAM_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'UPDATE_TEAM_FINISH', payload: null });
    }
  }
};

const updateTeamHangout = (dispatch) => async (
  pkTeam,
  pkPrivateCalendar,
  { start, end, title, locationName, notes, dataType } = {}
) => {
  if (pkTeam) {
    try {
      dispatch({ type: 'UPDATE_TEAM_HANGOUT_START' });
      const response = await sportsheadzApi.put(
        `/api/privatecalendar/team/${pkTeam}/events/${pkPrivateCalendar}`,
        {
          start,
          end,
          title,
          locationName,
          notes,
          dataType
        },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'UPDATE_TEAM_HANGOUT_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'UPDATE_TEAM_HANGOUT_FINISH', payload: null });
    }
  }
};

const updateTeamMembersStatus = (dispatch) => async (
  pkTeam,
  actions = [],
  updateUserTeams = false
) => {
  if (pkTeam && actions.length > 0) {
    dispatch({
      type: updateUserTeams
        ? 'UPDATE_USER_TEAMS_START'
        : 'UPDATE_TEAM_MEMBERS_START'
    });
    try {
      const response = await sportsheadzApi.put(
        `/api/team/${pkTeam}/members/activation`,
        { actions },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: updateUserTeams
          ? 'UPDATE_USER_TEAMS_FINISH'
          : 'UPDATE_TEAM_MEMBERS_FINISH',
        payload: updateUserTeams ? pkTeam : response.data
      });
    } catch (e) {
      debug(e);
      dispatch({
        type: updateUserTeams
          ? 'UPDATE_USER_TEAMS_FINISH'
          : 'UPDATE_TEAM_MEMBERS_ERROR',
        payload: e.message
      });
    }
  }
};

const updateTeamMemberRoles = (dispatch) => async (
  pkTeam,
  pkUser,
  roles = []
) => {
  if (pkTeam && pkUser && roles.length > 0) {
    dispatch({ type: 'UPDATE_TEAM_MEMBER_ROLES_START' });
    try {
      const response = await sportsheadzApi.put(
        `/api/team/${pkTeam}/members/${pkUser}/roles`,
        { roles },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'UPDATE_TEAM_MEMBER_ROLES_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'UPDATE_TEAM_MEMBER_ROLES_FINISH', payload: e.message });
    }
  }
};

const sendTeamInvite = (dispatch) => async (
  pkTeam,
  { email, pkUser, roles },
  forceAccept = false
) => {
  if (pkTeam) {
    dispatch({ type: 'TEAM_INVITE_START' });
    try {
      const inviteResponse = await sportsheadzApi.post(
        `/api/team/${pkTeam}/invite`,
        { email, pkUser, roles },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(inviteResponse, getCircularReplacer(), 2));
      if (forceAccept) {
        const acceptResponse = await sportsheadzApi.post(
          `/api/team/invitations/action`,
          { actions: [{ code: inviteResponse.data.code, accept: true }] },
          {
            headers: {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`
            }
          }
        );
        debug(JSON.stringify(acceptResponse, getCircularReplacer(), 2));
      }
      dispatch({ type: 'TEAM_INVITE_FINISH', payload: inviteResponse.data });
    } catch (e) {
      debug(e);
      dispatch({ type: 'TEAM_INVITE_FINISH' });
    }
  }
};

const fetchTeamJoinRequests = (dispatch) => async (
  pkValue,
  isUserFetch = false
) => {
  if (pkValue) {
    try {
      dispatch({ type: 'FETCH_JOIN_REQUESTS_START' });
      const response = await sportsheadzApi.get(
        isUserFetch
          ? `/api/staff/join-requests/${pkValue}`
          : `/api/team/join-requests/${pkValue}`,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'FETCH_JOIN_REQUESTS_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_JOIN_REQUESTS_FINISH', payload: null });
    }
  }
};

const createTeamJoinRequest = (dispatch) => async (
  pkTeam,
  { pkUser, pkRoles = [] }
) => {
  if (pkTeam && pkUser && pkRoles.length > 0) {
    try {
      dispatch({ type: 'CREATE_JOIN_REQUESTS_START' });
      const response = await sportsheadzApi.post(
        `/api/team/join-requests/${pkTeam}`,
        { pkUser, pkRoles },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'CREATE_JOIN_REQUESTS_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'CREATE_JOIN_REQUESTS_FINISH', payload: null });
    }
  }
};

const respondTeamJoinRequest = (dispatch) => async (
  pkTeamJoinRequest,
  isAccepted = false
) => {
  if (pkTeamJoinRequest) {
    try {
      dispatch({ type: 'RESPOND_JOIN_REQUESTS_START' });
      const response = await sportsheadzApi.put(
        `/api/team/join-requests/${pkTeamJoinRequest}`,
        { isAccepted },
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'RESPOND_JOIN_REQUESTS_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'RESPOND_JOIN_REQUESTS_FINISH', payload: null });
    }
  }
};

const cancelTeamJoinRequest = (dispatch) => async (pkTeamJoinRequest) => {
  if (pkTeamJoinRequest) {
    try {
      dispatch({ type: 'CANCEL_JOIN_REQUESTS_START' });
      const response = await sportsheadzApi.delete(
        `/api/me/join-requests/${pkTeamJoinRequest}`,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'CANCEL_JOIN_REQUESTS_FINISH',
        payload: response.data
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'CANCEL_JOIN_REQUESTS_FINISH', payload: null });
    }
  }
};

const acceptAllTeamJoinRequests = (dispatch) => async (
  { teamJoinRequests },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({ type: 'RESPOND_JOIN_REQUESTS_START' });
    const response = await sportsheadzApi.post(
      `/api/staff/join-requests/respond-all`,
      { teamJoinRequests },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({
      type: 'FETCH_JOIN_REQUESTS_FINISH',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    debug(e);
    dispatch({ type: 'RESPOND_JOIN_REQUESTS_FINISH', payload: null });
    if (onErrorCallback) {
      onErrorCallback();
    }
  }
};
// #endregion

// #region Promotions
const fetchPromotions = (dispatch) => async () => {
  try {
    dispatch({ type: 'FETCH_PROMOTIONS_START' });
    const response = await sportsheadzApi.get(`/api/Staff/promotions`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({
      type: 'FETCH_PROMOTIONS_FINISH',
      payload: response.data
    });
  } catch (e) {
    debug(e);
    dispatch({ type: 'FETCH_PROMOTIONS_FINISH', payload: null });
  }
};

const fetchUserPromotions = (dispatch) => async (pkUser) => {
  if (pkUser) {
    try {
      dispatch({ type: 'FETCH_USER_PROMOTIONS_START' });
      const response = await sportsheadzApi.get(
        `/api/Staff/promotions/${pkUser}`,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'FETCH_USER_PROMOTIONS_FINISH',
        payload: response.data?.promotions
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'FETCH_USER_PROMOTIONS_FINISH', payload: null });
    }
  }
};

const addUserPromotion = (dispatch) => async (
  pkUser,
  { pkPromotionType, end }
) => {
  if (pkUser) {
    try {
      dispatch({ type: 'ADD_USER_PROMOTION_START' });
      const response = await sportsheadzApi.post(
        `/api/Staff/promotions/${pkUser}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          },
          params: { pkPromotionType, end: Math.floor(new Date(end) / 1000) }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'ADD_USER_PROMOTION_FINISH',
        payload: response.data?.promotions
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'ADD_USER_PROMOTION_FINISH', payload: null });
    }
  }
};

const createPromotion = (dispatch) => async ({
  name,
  description,
  group,
  coupon,
  pkTournament
}) => {
  try {
    dispatch({ type: 'CREATE_PROMOTION_START' });
    const response = await sportsheadzApi.post(
      `/api/Staff/promotions/`,
      {
        name,
        description,
        group,
        coupon,
        pkTournament
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));
    dispatch({
      type: 'CREATE_PROMOTION_FINISH',
      payload: response.data
    });
  } catch (e) {
    debug(e);
    dispatch({ type: 'CREATE_PROMOTION_FINISH', payload: null });
  }
};

const deleteUserPromotion = (dispatch) => async (pkPromotion) => {
  if (pkPromotion) {
    try {
      dispatch({ type: 'DELETE_USER_PROMOTION_START' });
      const response = await sportsheadzApi.delete(
        `/api/Staff/promotions/${pkPromotion}`,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        }
      );
      debug(JSON.stringify(response, getCircularReplacer(), 2));
      dispatch({
        type: 'DELETE_USER_PROMOTION_FINISH',
        payload: response.data?.promotions
      });
    } catch (e) {
      debug(e);
      dispatch({ type: 'DELETE_USER_PROMOTION_FINISH', payload: null });
    }
  }
};

const fetchTournamentCrmReport = (dispatch) => async () => {
  try {
    dispatch({ type: 'SEARCH_TEAM_START' });
    const response = await sportsheadzApi.get(
      '/api/staff/tournament-crm-report',
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    debug(JSON.stringify(response, getCircularReplacer(), 2));

    dispatch({ type: 'SEARCH_TEAM_FINISH', payload: response.data });
  } catch (err) {
    debug(err);
    dispatch({ type: 'SEARCH_TEAM_FINISH', payload: null });
  }
};
// #endregion

export const { Provider, Context } = createDataProvider(
  teamReducer,
  {
    acceptAllTeamJoinRequests,
    appendState,
    searchForUser,
    searchForTeam,
    fetchAssociations,
    fetchUser,
    fetchUserEvents,
    fetchUserHangouts,
    fetchUserPromotions,
    fetchPromotions,
    fetchRemoteUsers,
    fetchTeam,
    fetchTeams,
    fetchTeamEvents,
    fetchTeamHangouts,
    fetchTeamJoinRequests,
    addUserPromotion,
    createRemoteUsers,
    createAssociation,
    createTeam,
    createTeamHangout,
    createTeamLeagueEvent,
    createTeamJoinRequest,
    createBasicUser,
    createPromotion,
    updateTeam,
    updateTeamMembersStatus,
    updateTeamMemberRoles,
    updateUser,
    updateTeamHangout,
    updateTeamLeagueEvent,
    updateAssociation,
    respondTeamJoinRequest,
    sendTeamInvite,
    deleteRemoteUsers,
    deleteTeamHangouts,
    deleteTeamLeagueEvents,
    deleteUserPromotion,
    cancelTeamJoinRequest,
    fetchTournamentCrmReport
  },
  initialState
);
