import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Radio,
  Checkbox,
  Container,
  Button,
  Confirm
} from 'semantic-ui-react';
import View from '../../common/View';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import SearchBar from '../../common/SearchBar';
import userPlaceholder from '../../../images/user_placeholder.png';
import { mq } from '../../../config/theme';
import { TEAM_MEMBER_ROLE_OPTIONS } from '../../../config/constants';
import InputLabel from '../../common/InputLabel';

const CreateTeamInvite = ({ team, user, onError, onClose, isJoinRequest }) => {
  const {
    state,
    searchForTeam,
    searchForUser,
    appendState,
    fetchUser,
    fetchTeam,
    sendTeamInvite,
    createTeamJoinRequest,
    fetchTeamJoinRequests
  } = useContext(TeamManagementContext);
  const [showInviteConfirm, setShowInviteConfirm] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [formState, setFormState] = useState({
    team: null,
    user: null,
    roles: [],
    inviteType: 'invite'
  });

  useEffect(() => {
    if (team || user) {
      setFormState({ ...formState, team, user });
    }
  }, [team, user]);

  useEffect(() => {
    if (team) {
      setSearchResults(
        state.userSearchResults.value
          .filter((s, index) => index < 7)
          .map((s) => ({
            title: s.name,
            key: s.pkUser,
            description: s.email,
            image: s.avatar ? s.avatar : userPlaceholder,
            data: s
          }))
      );
    }
    else {
      setSearchResults(
        state.teamSearchResults.value
          .filter((s, index) => index < 7)
          .map((s) => ({
            title: s.team.name,
            key: s.team.pkTeam,
            description: s.team.domain,
            image: s.team.image,
            data: s
          }))
      );
    }
  }, [team, state.teamSearchResults.value, state.userSearchResults.value]);

  const selectRole = (role) => {
    let rolesCopy = [...formState.roles];
    if (rolesCopy.includes(role)) {
      rolesCopy = rolesCopy.filter((r) => r !== role);
    }
    else {
      rolesCopy.push(role);
    }
    setFormState({
      ...formState,
      roles: rolesCopy
    });
    onError('');
  };

  return (
    <View>
      <View
        aria-label="inviteSearchBar"
        css={{ paddingBottom: '20px' }}
        role="searchbox"
      >
        <SearchBar
          loading={
            team
              ? state.userSearchResults.loading
              : state.teamSearchResults.loading
          }
          onClear={() => {
            team
              ? appendState({
                  userSearchResults: { value: [], loading: false }
                })
              : appendState({
                  teamSearchResults: { value: [], loading: false }
                });
          }}
          onResultSelect={(selectedOption) => {
            if (team) {
              const existingMember = formState.team.members.find(
                (m) => m.pkUser === selectedOption.pkUser && m.isActive
              );
              setFormState({
                ...formState,
                user: existingMember ? null : selectedOption
              });
              onError(
                existingMember
                  ? `${existingMember.name} already belongs to ${formState.team.team.name}`
                  : ''
              );
            }
            else {
              const existingTeam = formState.user.teams.find(
                (t) =>
                  t.team.pkTeam === selectedOption.team.pkTeam && t.isActive
              );
              setFormState({
                ...formState,
                team: existingTeam ? null : selectedOption
              });
              onError(
                existingTeam
                  ? `${formState.user.name} already belongs to ${existingTeam.team.name}`
                  : ''
              );
            }
          }}
          onSearch={(term) =>
            team ? searchForUser({ searchTerm: term }) : searchForTeam(term)
          }
          placeholder={`Search for ${team ? 'user' : 'team'} by name...`}
          results={searchResults}
        />
      </View>
      {formState.team && formState.user && (
        <View
          aria-label="inviteOptions"
          css={{ padding: '0px 5px' }}
          role="dialog"
        >
          <Form>
            <InputLabel>Role</InputLabel>
            <Form.Group inline style={{ marginTop: '5px' }}>
              {TEAM_MEMBER_ROLE_OPTIONS.map((r) => (
                <Form.Field key={r.value}>
                  <Checkbox
                    aria-label={r.value}
                    checked={formState.roles.includes(
                      isJoinRequest ? r.key : r.value
                    )}
                    label={r.text}
                    name={r.value}
                    onClick={(e, { value }) => selectRole(value)}
                    role="checkbox"
                    value={isJoinRequest ? r.key : r.value}
                  />
                </Form.Field>
              ))}
            </Form.Group>
            {!isJoinRequest && (
              <>
                <InputLabel>Invite Type</InputLabel>
                <Form.Group inline style={{ marginTop: '5px' }}>
                  <Form.Field>
                    <Radio
                      aria-label="invite"
                      checked={formState.inviteType === 'invite'}
                      label="Invite"
                      name="inviteType"
                      onChange={() =>
                        setFormState({
                          ...formState,
                          inviteType: 'invite'
                        })
                      }
                      role="radio"
                      value="invite"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      aria-label="force"
                      checked={formState.inviteType === 'force'}
                      label="Force Add"
                      name="inviteType"
                      onChange={() =>
                        setFormState({
                          ...formState,
                          inviteType: 'force'
                        })
                      }
                      role="radio"
                      value="force"
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}
          </Form>
          <Confirm
            aria-label="confirm"
            content={
              isJoinRequest
                ? `Are you sure you want to send a join request to ${formState.team.team.name} for ${formState.user.name}`
                : `Are you sure you want to ${
                    formState.inviteType === 'invite' ? 'invite' : 'force add'
                  } ${formState.user.name} to ${formState.team.team.name}
              `
            }
            css={mq({ minWidth: ['100%', 'unset'] })}
            onCancel={() => setShowInviteConfirm(false)}
            onConfirm={async () => {
              if (isJoinRequest) {
                onClose();
                await createTeamJoinRequest(formState.team.team.pkTeam, {
                  pkUser: formState.user.pkUser,
                  pkRoles: formState.roles
                });
                fetchTeamJoinRequests(formState.user.pkUser);
              }
              else {
                const isForceAdd = formState.inviteType === 'force';
                await sendTeamInvite(
                  formState.team.team.pkTeam,
                  {
                    email: formState.user.email,
                    pkUser: formState.user.pkUser,
                    roles: formState.roles
                  },
                  isForceAdd
                );
                if (isForceAdd) {
                  team
                    ? await fetchTeam(formState.team.team.pkTeam)
                    : await fetchUser(formState.user.pkUser);
                }
              }
            }}
            open={showInviteConfirm}
            role="dialog"
            size="tiny"
            style={{ margin: 0 }}
          />
        </View>
      )}
      <Container style={{ textAlign: 'right', margin: 0 }}>
        <Button
          aria-label="cancelAddUser"
          color="black"
          onClick={onClose}
          role="button"
          style={{ marginLeft: '0.75em' }}
        >
          Cancel
        </Button>
        <Button
          aria-label="submitAddUser"
          content={isJoinRequest ? 'Send' : 'Add'}
          onClick={() => {
            if (!formState.team) {
              onError('Please select a team.');
            }
            else if (!formState.user) {
              onError('Please select a user.');
            }
            else if (formState.roles.length === 0) {
              onError('Please select one or more roles.');
            }
            else {
              setShowInviteConfirm(true);
            }
          }}
          positive
          role="button"
          style={{ marginLeft: '0.75em' }}
        />
      </Container>
    </View>
  );
};

CreateTeamInvite.propTypes = {
  isJoinRequest: PropTypes.bool,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  team: PropTypes.object,
  user: PropTypes.object
};

export default CreateTeamInvite;
