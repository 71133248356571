import React, { useContext, useEffect, useState } from 'react';
import { Divider, Header, Icon, List, Segment } from 'semantic-ui-react';
import { css } from '@emotion/react';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import View from '../components/common/View';
import { Context as LiveStreamContext } from '../providers/LiveStreamProvider';
import CameraView from '../components/content/CamerasView/CameraView';
import { mq } from '../config/theme';
import CreateVenueModal from '../components/content/CamerasView/CreateVenueModal';
import Text from '../components/common/Text';

const CamerasView = () => {
  const { state, fetchVenues } = useContext(LiveStreamContext);
  const [showCreateVenueModal, setShowCreateVenueModal] = useState(false);

  useEffect(() => {
    fetchVenues();
  }, []);

  return (
    <>
      <CreateVenueModal
        isOpen={showCreateVenueModal}
        onClose={() => setShowCreateVenueModal(false)}
      />
      <Divider
        css={css`
          margin-top: 0px !important;
        `}
      />
      <View css={mq({ flexDirection: ['column', 'column', 'row'] })}>
        <View
          css={mq({
            maxWidth: ['100%', '100%', '33%'],
            minWidth: ['100%', '100%', '33%']
          })}
        >
          <View
            css={{
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Header
              as="h4"
              css={css`
                margin-bottom: 0px !important;
                margin-top: 0px !important;
              `}
            >
              Venues
            </Header>
            <View css={{ flexDirection: 'row' }}>
              <Icon
                css={{ alignSelf: 'center', cursor: 'pointer' }}
                name="refresh"
                onClick={() => {
                  if (!state.venues.loading) {
                    fetchVenues();
                  }
                }}
              />
              <Icon
                aria-label="createVenue"
                css={{
                  alignSelf: 'center',
                  cursor: 'pointer',
                  marginLeft: '5px !important'
                }}
                name="plus"
                onClick={() => {
                  setShowCreateVenueModal(true);
                }}
                role="button"
              />
            </View>
          </View>
          <Segment
            aria-label="cameraList"
            loading={state.venues.loading}
            role="region"
            style={{ flex: 1, minHeight: '50px' }}
          >
            {!state.venues.loading && (
              <View
                css={mq({
                  minHeight: [null, null, '100%'],
                  maxHeight: ['40vh', '40vh', 450],
                  overflowY: 'auto'
                })}
              >
                <List selection>
                  {state.venues.value.length > 0 ? (
                    state.venues.value.map((v) => (
                      <List.Item
                        key={v.id}
                        as={Link}
                        data-key={v.id}
                        to={`/cameras/${v.id}`}
                      >
                        <List.Icon
                          color={
                            v.comingsoon
                              ? 'yellow'
                              : v.enabled
                              ? 'green'
                              : 'red'
                          }
                          name="building"
                        />
                        <List.Content>
                          <List.Header>{v.name}</List.Header>
                          <List.List>
                            {v.cameras.map((c) => (
                              <List.Item
                                key={c.id}
                                as={Link}
                                onClick={(e) => {
                                  console.log(c.name);
                                  e.stopPropagation();
                                }}
                                to={`/cameras/${v.id}/streams/details/${c.id}`}
                              >
                                <List.Icon
                                  css={css`
                                    color: ${c.stream
                                      ? c.stream.disabled
                                        ? '#FBBD08'
                                        : '#A7C957'
                                      : '#FF220C'} !important;
                                  `}
                                  name="video camera"
                                />
                                <List.Content>
                                  <List.Header>{c.name}</List.Header>
                                </List.Content>
                              </List.Item>
                            ))}
                          </List.List>
                        </List.Content>
                      </List.Item>
                    ))
                  ) : (
                    <Text>No venues available</Text>
                  )}
                </List>
              </View>
            )}
          </Segment>
        </View>
        <Switch>
          <Route path="/cameras/:pkVenue">
            <CameraView />
          </Route>
        </Switch>
      </View>
    </>
  );
};

CamerasView.propTypes = {};

export default CamerasView;
