import { useEffect, useRef, useState } from 'react';
import { mqBreakpoints } from '../config/theme';

export const useWindowSize = () => {
  const resizeListener = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    isMobile: false
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth <= mqBreakpoints[0]
    });
  };

  useEffect(() => {
    handleResize();
    if (!resizeListener.current) {
      resizeListener.current = window.addEventListener('resize', handleResize);
    }
    return () => {
      if (resizeListener.current) {
        window.removeEventListener(resizeListener.current);
      }
    };
  }, []);

  return dimensions;
};
