import React, { useContext, useState } from 'react';
import { Badge, Card, Group, Image, Stack, Tabs, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import RegAssociationAdminList from './RegAssociationAdminList';
import RegAssociationOfflinePayments from './RegAssociationOfflinePayments';
import RegAssociationSettingsView from './RegAssociationSettingsView';

const ASSOCIATION_TABS = [
  {
    value: 'ADMINS',
    label: 'Admins'
  },
  {
    value: 'OFFLINE-PAYMENTS',
    label: 'Offline Payments'
  },
  {
    value: 'SETTINGS',
    label: 'Settings'
  }
];

const RegAssociationDetails = () => {
  const { pkRegAssociation } = useParams();
  const { state } = useContext(RegistrationContext);
  const [activeTab, setActiveTab] = useState('ADMINS');
  const regAssociation = state.regAssociations.value.find(
    (a) => a.pkRegAssociation.toString() === pkRegAssociation
  );

  return regAssociation ? (
    <Card
      shadow="lg"
      sx={{
        flex: 1,
        border: 'solid 1px lightgrey',
        padding: 15,
        gap: 5,
        alignSelf: 'stretch'
      }}
    >
      <Group style={{ justifyContent: 'space-between' }}>
        <Group sx={{ flexWrap: 'nowrap' }}>
          <Image
            fit="contain"
            height={50}
            src={regAssociation.logoImageUrl ?? null}
            width={50}
          />
          <Text sx={{ fontSize: 18 }} weight={700}>
            {regAssociation.association.name}
          </Text>
        </Group>
        {regAssociation.deleted ? (
          <Badge color="red" variant="filled">
            Deleted
          </Badge>
        ) : regAssociation.sandboxEnabled ? (
          <Badge color="yellow" variant="filled">
            Sandbox
          </Badge>
        ) : (
          <Badge color="green" variant="filled">
            Live
          </Badge>
        )}
      </Group>

      <Stack sx={{ gap: 10 }}>
        <Tabs onTabChange={setActiveTab} value={activeTab}>
          <Stack sx={{ gap: 20 }}>
            <Tabs.List>
              {ASSOCIATION_TABS.map((a) => (
                <Tabs.Tab key={a.value} value={a.value}>
                  {a.label}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Stack>
        </Tabs>
        {activeTab === 'ADMINS' ? (
          <RegAssociationAdminList regAssociation={regAssociation} />
        ) : activeTab === 'SETTINGS' ? (
          <RegAssociationSettingsView regAssociation={regAssociation} />
        ) : (
          <RegAssociationOfflinePayments regAssociation={regAssociation} />
        )}
      </Stack>
    </Card>
  ) : (
    <></>
  );
};

RegAssociationDetails.propTypes = {};

export default RegAssociationDetails;
