import React, { useState } from 'react';
import { Alert, Button, Card, Stack, Text } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import GenerateStripePaymentsModal from './GenerateStripePaymentsModal';

const StripePaymentsView = () => {
  const [isGenerateCsvModalOpen, setIsGenerateCsvModalOpen] = useState(false);

  return (
    <>
      <Card
        shadow="lg"
        sx={{
          flex: 1,
          border: 'solid 1px lightgrey',
          padding: 15,
          gap: 5,
          alignSelf: 'stretch'
        }}
      >
        <Stack sx={{ alignItems: 'center', gap: 20 }}>
          <Alert
            color="blue"
            icon={<AlertCircle />}
            sx={{ alignSelf: 'stretch' }}
            variant="outline"
          >
            <Text>
              Generate Stripe payment reports from the modal options below.
            </Text>
          </Alert>

          <Button onClick={() => setIsGenerateCsvModalOpen(true)}>
            Generate Reports
          </Button>
        </Stack>
      </Card>
      <GenerateStripePaymentsModal
        isOpen={isGenerateCsvModalOpen}
        onClose={() => setIsGenerateCsvModalOpen(false)}
      />
    </>
  );
};

StripePaymentsView.propTypes = {};

export default StripePaymentsView;
