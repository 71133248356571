import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { Input } from 'semantic-ui-react';
import { mqBreakpoints } from '../../config/theme';
import InputLabel from './InputLabel';
import View from './View';

const ResponsiveInput = (props) => {
  const isMobile = !useMediaQuery(`(min-width:${mqBreakpoints[2]}px)`);

  return (
    <View {...{ ...props }.containerProps}>
      {isMobile && (
        <InputLabel>
          {{ ...props }.label?.content ?? { ...props }.label}
        </InputLabel>
      )}
      <Input {...props} label={!isMobile ? { ...props }.label : undefined} />
    </View>
  );
};

export default ResponsiveInput;
