import React from 'react';
import { Card, Divider, Group, Skeleton, Stack, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { NumericFormat } from 'react-number-format';
import CheckoutItem from './CheckoutItem';
import { mq } from '../../../config/theme';
import { PAYMENT_PROVIDER_LINE_ITEM_TYPES } from '../../../config/constants';
import CheckoutMerchItem from './CheckoutMerchItem';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';
import { calculateCartTotals } from '../../../helpers/cartHelper';

const RegCartOrderDetails = ({ isLoading, regCart, withBorder, hideTitle }) => {
  const isPendingPayment = regCart?.regCartProducts.some(
    (p) => p.regFormSubmission.status === 'PENDING_PAYMENT'
  );
  const hasBeenRefunded = regCart?.cart.lineItems.some(
    (i) => i.amountRefundedInCents > 0
  );
  const cartTotals = regCart
    ? calculateCartTotals(regCart.cart.lineItems, true)
    : {};

  return !isLoading && regCart ? (
    <>
      <Card style={{ border: withBorder ? 'solid 1px lightgrey' : 'none' }}>
        <Group
          sx={mq({
            padding: '0px 15px',
            alignItems: 'start',
            flexDirection: ['column-reverse', 'column-reverse', 'row']
          })}
        >
          <Stack
            style={{
              flex: 1,
              gap: 0,
              alignSelf: 'stretch'
            }}
          >
            {!hideTitle && (
              <Text size="lg" weight={700}>
                Order Summary
              </Text>
            )}

            <Stack style={{ gap: 0 }}>
              <Group
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <Text size="lg">Subtotal</Text>
                <Group style={{ gap: 10 }}>
                  <Text size="lg">
                    <NumericFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={cartTotals.subtotalInCents / 100}
                    />
                  </Text>
                  <Text size="lg">CAD</Text>
                </Group>
              </Group>
              {cartTotals.discountInCents > 0 && (
                <Group
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <Text size="lg">Discount</Text>
                  <Group style={{ gap: 10 }}>
                    <Text size="lg">
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={(cartTotals.discountInCents / 100) * -1}
                      />
                    </Text>
                    <Text size="lg">CAD</Text>
                  </Group>
                </Group>
              )}
              <Group
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <Text size="lg">Taxes</Text>
                <Group style={{ gap: 10 }}>
                  <Text size="lg">
                    <NumericFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={cartTotals.taxInCents / 100}
                    />
                  </Text>
                  <Text size="lg">CAD</Text>
                </Group>
              </Group>
              {cartTotals.serviceFeeInCents > 0 && (
                <Group
                  style={{
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap'
                  }}
                >
                  <Text size="lg">Service Fee</Text>
                  <Group style={{ gap: 10 }}>
                    <Text size="lg">
                      <NumericFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={cartTotals.serviceFeeInCents / 100}
                      />
                    </Text>
                    <Text size="lg">CAD</Text>
                  </Group>
                </Group>
              )}
              <Divider style={{ margin: '10px 0px' }} />
              <Group
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <Text size="lg" weight={700}>
                  Total
                </Text>
                <Group style={{ gap: 10 }}>
                  <Text size="lg" weight={700}>
                    <NumericFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={cartTotals.totalInCents / 100}
                    />
                  </Text>
                  <Text size="lg" weight={700}>
                    CAD
                  </Text>
                </Group>
              </Group>
            </Stack>
          </Stack>
          <Stack
            sx={mq({
              flex: [1, 1, 2],
              gap: 0,
              alignItems: ['start', 'start', 'end'],
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              textAlign: ['start', 'start', 'end']
            })}
          >
            <Stack
              sx={mq({
                gap: 0,
                alignSelf: 'stretch',
                flexDirection: ['row', 'row', 'column'],
                justifyContent: 'space-between'
              })}
            >
              <Text style={{ fontSize: 18, whiteSpace: 'nowrap' }} weight={700}>
                Order # {regCart.pkRegCart}
              </Text>
              <Text style={{ fontSize: 18 }}>
                {isPendingPayment
                  ? 'PENDING'
                  : hasBeenRefunded
                  ? 'REFUNDED'
                  : 'COMPLETED'}
              </Text>
            </Stack>

            {regCart?.cart.purchasedAt && (
              <Text style={{ fontSize: 18, textAlign: 'end' }}>
                Ordered on{' '}
                {format(
                  new Date(
                    `${stripUtcDateFormatting(regCart?.cart.purchasedAt)}Z`
                  ),
                  'M/dd/yyyy'
                )}
              </Text>
            )}
          </Stack>
        </Group>
      </Card>

      <Stack
        sx={mq({
          flex: 2,
          gap: [10, 10, 20],
          marginBottom: [10, 10, 0]
        })}
      >
        {regCart.cart.lineItems.map((i) => {
          const regCartProduct = regCart.regCartProducts.find(
            (p) =>
              p.regFormSubmission.pkRegFormSubmission === i.entityId &&
              p.regProduct.pkRegProduct ===
                p.regFormSubmission.regAssociationDivisionForm.fkRegProduct &&
              i.lineItemType ===
                PAYMENT_PROVIDER_LINE_ITEM_TYPES.registration.value
          );

          let regCartMerchPackage = null;
          if (!regCartProduct) {
            regCartMerchPackage = regCart.regCartMerchPackages.find(
              (p) =>
                p.pkRegCartMerchPackage === i.entityId &&
                i.lineItemType === PAYMENT_PROVIDER_LINE_ITEM_TYPES.merch.value
            );
          }

          return regCartMerchPackage ? (
            <CheckoutMerchItem
              key={regCartMerchPackage.pkRegCartMerchPackage}
              isDisabled
              lineItem={i}
              regCartMerchPackage={regCartMerchPackage}
              withBorder={withBorder}
            />
          ) : (
            <CheckoutItem
              key={i.entityId}
              cartProduct={regCartProduct}
              isDisabled
              lineItem={i}
              withBorder={withBorder}
            />
          );
        })}
      </Stack>
    </>
  ) : (
    <>
      <Card style={{ border: withBorder ? 'solid 1px lightgrey' : 'none' }}>
        <Group
          sx={mq({
            padding: '0px 15px',
            alignItems: 'start',
            flexDirection: ['column-reverse', 'column-reverse', 'row']
          })}
        >
          <Stack
            style={{
              flex: 1,
              gap: 10,
              alignSelf: 'stretch'
            }}
          >
            <Skeleton height={14} width={150} />
            <Stack style={{ gap: 10 }}>
              <Group
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                  gap: 40
                }}
              >
                <Skeleton height={14} width={100} />
                <Skeleton height={14} width={100} />
              </Group>
              <Group
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <Skeleton height={14} width={100} />
                <Skeleton height={14} width={100} />
              </Group>
              <Divider style={{ margin: '10px 0px' }} />
              <Group
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap'
                }}
              >
                <Skeleton height={14} width={100} />
                <Skeleton height={14} width={100} />
              </Group>
            </Stack>
          </Stack>
          <Stack
            sx={mq({
              flex: [1, 1, 2],
              gap: 0,
              alignItems: 'end',
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              flexDirection: ['row', 'row', 'column']
            })}
          >
            <Skeleton height={14} width={100} />
            <Skeleton height={14} width={250} />
          </Stack>
        </Group>
      </Card>

      <Stack
        sx={mq({
          flex: 2,
          gap: [10, 10, 20],
          width: ['100%', '100%', 'unset'],
          marginBottom: [10, 10, 0]
        })}
      >
        <CheckoutItem isDisabled />
        <CheckoutItem isDisabled />
      </Stack>
    </>
  );
};

RegCartOrderDetails.propTypes = {
  hideTitle: PropTypes.bool,
  isLoading: PropTypes.bool,
  regCart: PropTypes.object,
  withBorder: PropTypes.bool
};

export default RegCartOrderDetails;
