import React from 'react';
import PropTypes from 'prop-types';

const InputLabel = ({ children, style, ...rest }) => (
  <label
    style={{ fontWeight: 'bold', fontSize: '1rem', margin: 0, ...style }}
    {...rest}
  >
    {children}
  </label>
);

InputLabel.propTypes = { children: PropTypes.node, style: PropTypes.object };

export default InputLabel;
