import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Confirm,
  Container,
  Form,
  Icon,
  Radio,
  Select,
  TextArea
} from 'semantic-ui-react';
import { DateTimePicker } from '@material-ui/pickers';
import ContentModal from '../../common/ContentModal';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ResponsiveInput from '../../common/ResponsiveInput';
import InputLabel from '../../common/InputLabel';
import View from '../../common/View';

const initalFormState = {
  data: {
    id: null,
    start: new Date(),
    end: new Date(),
    title: '',
    label: '',
    locationName: '',
    notes: '',
    dataType: '',
    locationMapinfo: '',
    gameType: 0,
    arriveBefore: 0,
    gameOpponent: '',
    gameScore: '',
    gameResult: '',
    data: '',
    notificationsEnabled: true,
    notifyUsers: true,
    isAllDay: false,
    isCancelled: false,
    gameHome: true
  },
  error: ''
};

const AddTeamEventModal = ({
  isOpen,
  onClose,
  selectedEvent,
  isLeagueEvent
}) => {
  const {
    state,
    createTeamHangout,
    createTeamLeagueEvent,
    updateTeamHangout,
    updateTeamLeagueEvent
  } = useContext(TeamManagementContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [formState, setFormState] = useState(initalFormState);
  const [showCustomEventInput, setShowCustomEventInput] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormState(
        selectedEvent
          ? {
              ...initalFormState,
              data: {
                ...initalFormState.data,
                ...selectedEvent,
                start: new Date(
                  `${stripUtcDateFormatting(selectedEvent.start)}Z`
                ),
                end: new Date(`${stripUtcDateFormatting(selectedEvent.end)}Z`),
                dataType: isLeagueEvent ? selectedEvent.dataType : 'Hangouts'
              }
            }
          : {
              ...initalFormState,
              data: {
                ...initalFormState.data,
                dataType: isLeagueEvent ? '' : 'Hangouts'
              }
            }
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setShowConfirm(false);
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      data: {
        ...formState.data,
        [e.target.name]: e.target.value
      },
      error: ''
    });
  };

  const handleTeamEventAction = () => {
    if (isLeagueEvent) {
      if (selectedEvent) {
        updateTeamLeagueEvent(
          state.team.value.team.pkTeam,
          selectedEvent.pkCalendar,
          formState.data
        );
      }
      else {
        createTeamLeagueEvent(state.team.value.team.pkTeam, formState.data);
      }
    }
    else if (selectedEvent) {
      updateTeamHangout(
        state.team.value.team.pkTeam,
        selectedEvent.pkPrivateCalendar,
        formState.data
      );
    }
    else {
      createTeamHangout(state.team.value.team.pkTeam, formState.data);
    }
    onClose();
  };

  return (
    <ContentModal
      actionContent="Create"
      error={formState.error}
      hideActions
      isOpen={isOpen}
      onClose={onClose}
      size="tiny"
      title={
        selectedEvent
          ? `Edit ${isLeagueEvent ? 'League' : 'Hangout'} Event`
          : `Create ${isLeagueEvent ? 'League' : 'Hangout'} Event`
      }
    >
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want to ${
          selectedEvent
            ? 'save changes?'
            : `create a new ${isLeagueEvent ? 'league' : 'hangout'} event?`
        }`}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          handleTeamEventAction();
        }}
        open={showConfirm}
        role="dialog"
        size="tiny"
      />
      <Form
        aria-label="addEvent"
        onSubmit={(e) => {
          e.preventDefault();
          if (formState.data.end <= formState.data.start) {
            setFormState({
              ...formState,
              error: 'Invalid date range'
            });
          }
          else if (!formState.data.dataType) {
            setFormState({
              ...formState,
              error: 'Event type required'
            });
          }
          else {
            setShowConfirm(true);
          }
        }}
        role="form"
      >
        <Form.Group widths="equal">
          <Form.Field>
            <DateTimePicker
              label="Start Time"
              name="start"
              onChange={(date) => {
                const end =
                  date > formState.data.end ? date : formState.data.end;
                setFormState({
                  ...formState,
                  data: {
                    ...formState.data,
                    start: date,
                    end
                  },
                  error: ''
                });
              }}
              style={{ display: 'flex' }}
              value={formState.data.start}
            />
          </Form.Field>
          <Form.Field>
            <DateTimePicker
              data-testid="endTime"
              label="End Time"
              name="end"
              onChange={(date) =>
                setFormState({
                  ...formState,
                  data: {
                    ...formState.data,
                    end: date
                  },
                  error: ''
                })
              }
              style={{ display: 'flex' }}
              value={formState.data.end}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <InputLabel>Title</InputLabel>
          <ResponsiveInput
            name="title"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.data.title}
          />
        </Form.Field>
        {isLeagueEvent && (
          <>
            <Form.Field>
              <InputLabel>Label</InputLabel>
              <ResponsiveInput
                name="label"
                onChange={handleInputChange}
                style={{ fontSize: 15 }}
                value={formState.data.label}
              />
            </Form.Field>
          </>
        )}
        <Form.Field>
          <InputLabel>Location Name</InputLabel>
          <ResponsiveInput
            name="locationName"
            onChange={handleInputChange}
            style={{ fontSize: 15 }}
            value={formState.data.locationName}
          />
        </Form.Field>
        {isLeagueEvent && (
          <>
            <Form.Field>
              <InputLabel>Map Info</InputLabel>
              <ResponsiveInput
                name="locationMapinfo"
                onChange={handleInputChange}
                style={{ fontSize: 15 }}
                value={formState.data.locationMapinfo}
              />
            </Form.Field>

            <Form.Group widths="equal">
              <Form.Field>
                <InputLabel>Event Type</InputLabel>
                {showCustomEventInput ? (
                  <View style={{ flexDirection: 'row', flex: 1 }}>
                    <ResponsiveInput
                      containerProps={{ style: { flex: 1 } }}
                      name="dataType"
                      onChange={handleInputChange}
                      required
                      style={{ fontSize: 15, flex: 1 }}
                      value={formState.data.dataType}
                    />
                    <Icon
                      css={{ alignSelf: 'center', cursor: 'pointer' }}
                      name="x"
                      onClick={() => setShowCustomEventInput(false)}
                      style={{ margin: 10 }}
                    />
                  </View>
                ) : (
                  <Select
                    data-testid="selectDataType"
                    onChange={(e, option) => {
                      if (option.value === 'Custom') {
                        setShowCustomEventInput(true);
                      }
                      else {
                        handleInputChange({
                          target: { name: 'dataType', value: option.value }
                        });
                      }
                    }}
                    options={['Game', 'Practice', 'Tournament', 'Custom'].map(
                      (t) => ({
                        key: t,
                        value: t,
                        text: t
                      })
                    )}
                    placeholder="Select Type"
                    value={formState.data.dataType}
                  />
                )}
              </Form.Field>
              <Form.Field>
                <InputLabel>Arrive before</InputLabel>
                <ResponsiveInput
                  name="arriveBefore"
                  onChange={handleInputChange}
                  required
                  style={{ fontSize: 15 }}
                  type="number"
                  value={formState.data.arriveBefore}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <InputLabel>Game Type</InputLabel>
                <ResponsiveInput
                  name="gameType"
                  onChange={handleInputChange}
                  required
                  style={{ fontSize: 15 }}
                  type="number"
                  value={formState.data.gameType}
                />
              </Form.Field>
              <Form.Field>
                <InputLabel>Game Opponent</InputLabel>
                <ResponsiveInput
                  name="gameOpponent"
                  onChange={handleInputChange}
                  style={{ fontSize: 15 }}
                  value={formState.data.gameOpponent}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <InputLabel>Game Result</InputLabel>
                <ResponsiveInput
                  name="gameResult"
                  onChange={handleInputChange}
                  style={{ fontSize: 15 }}
                  value={formState.data.gameResult}
                />
              </Form.Field>
              <Form.Field>
                <InputLabel>Game Score</InputLabel>
                <ResponsiveInput
                  name="gameScore"
                  onChange={handleInputChange}
                  style={{ fontSize: 15 }}
                  value={formState.data.gameScore}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <InputLabel>External Id</InputLabel>
              <ResponsiveInput
                name="id"
                onChange={handleInputChange}
                required
                style={{ fontSize: 15 }}
                type="number"
                value={formState.data.id ?? ''}
              />
            </Form.Field>

            <Form.Group widths="equal">
              <Form.Field>
                <Radio
                  checked={formState.data.notificationsEnabled}
                  label="Notications"
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: 'notificationsEnabled',
                        value: !formState.data.notificationsEnabled
                      }
                    })
                  }
                  toggle
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  checked={formState.data.notifyUsers}
                  label="Notify Users"
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: 'notifyUsers',
                        value: !formState.data.notifyUsers
                      }
                    })
                  }
                  toggle
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <Radio
                  checked={formState.data.isAllDay}
                  label="All Day"
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: 'isAllDay',
                        value: !formState.data.isAllDay
                      }
                    })
                  }
                  toggle
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  checked={formState.data.isCancelled}
                  label="Cancelled"
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: 'isCancelled',
                        value: !formState.data.isCancelled
                      }
                    })
                  }
                  toggle
                />
              </Form.Field>
            </Form.Group>
            {formState.data.dataType === 'Game' && (
              <Form.Field>
                <Radio
                  checked={formState.data.gameHome}
                  label="Home Game"
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: 'gameHome',
                        value: !formState.data.gameHome
                      }
                    })
                  }
                  style={{ marginTop: 25, marginBottom: 25 }}
                  toggle
                />
              </Form.Field>
            )}
          </>
        )}
        <Form.Field>
          <InputLabel>Description</InputLabel>
          <TextArea
            name="notes"
            onChange={handleInputChange}
            value={formState.data.notes}
          />
        </Form.Field>
        <Container style={{ textAlign: 'right', margin: 0 }}>
          <Button
            aria-label="cancelAddEvent"
            color="black"
            onClick={onClose}
            role="button"
            style={{ marginLeft: '0.75em' }}
          >
            Cancel
          </Button>
          <Button
            aria-label="addEvent"
            content={selectedEvent ? 'Save Changes' : 'Create'}
            positive
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="submit"
          />
        </Container>
      </Form>
    </ContentModal>
  );
};

AddTeamEventModal.propTypes = {
  isLeagueEvent: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedEvent: PropTypes.object
};

export default AddTeamEventModal;
