import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Button,
  Checkbox,
  Divider,
  Group,
  Radio,
  Select,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import { Pencil, Plus, X } from 'tabler-icons-react';
import { NumericFormat } from 'react-number-format';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import PaginationList from '../../common/PaginationList';
import { REG_FORM_CONTROL_TYPE_ENUM } from '../../../config/constants';

const RegMerchPackageInputModal = ({
  onAdd,
  isOpen,
  onClose,
  merchPackageInput
}) => {
  const MAX_PAGE_LENGTH = 10;
  const [formState, setFormState] = useState({
    label: '',
    isRequired: false,
    options: [],
    pageIndex: 1
  });
  const isDropdownInput =
    formState.fkRegFormControlType === REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN;

  useEffect(() => {
    if (isOpen) {
      if (merchPackageInput) {
        setFormState({
          label: merchPackageInput.label,
          isRequired: merchPackageInput.isRequired,
          fkRegFormControlType: merchPackageInput.fkRegFormControlType.toString(),
          options: merchPackageInput.options,
          pageIndex: 1
        });
      }
      else {
        setFormState({
          label: '',
          isRequired: false,
          fkRegFormControlType: REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN,
          options: [],
          pageIndex: 1
        });
      }
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      closeOnClickOutside={false}
      isOpen={isOpen}
      onClose={onClose}
      title={merchPackageInput ? 'Update Input' : 'Add Input'}
    >
      <Stack>
        <Stack style={{ gap: 5 }}>
          <Text weight={500}>Preview</Text>
          <Group style={{ flex: 1, justifyContent: 'center' }}>
            {isDropdownInput ? (
              <Select
                data={formState.options.map((a) => ({
                  label: a.value,
                  value: a.value
                }))}
                label={formState.label}
                required={formState.isRequired}
                style={{ width: '100%', maxWidth: 250 }}
              />
            ) : (
              <TextInput
                label={formState.label}
                required={formState.isRequired}
                style={{ width: '100%', maxWidth: 250 }}
              />
            )}
          </Group>
        </Stack>

        <Divider />
        <FormSection
          isSubmitDisabled={!formState.options.length === 0}
          onCancel={onClose}
          onSubmit={() => onAdd(formState)}
          submitTitle={merchPackageInput ? 'Update Input' : 'Add Input'}
        >
          <Stack style={{ gap: 15 }}>
            <TextInput
              label="Label"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  label: e.currentTarget.value
                })
              }
              required
              value={formState.label || ''}
            />

            <Radio.Group
              label="Input Type"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkRegFormControlType: value
                })
              }
              value={formState.fkRegFormControlType}
              withAsterisk
            >
              <Radio
                label="Dropdown"
                value={REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN}
              />
              <Radio
                label="Text Input"
                value={REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT}
              />
            </Radio.Group>

            <Checkbox
              checked={formState.isRequired}
              label="Input is required"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  isRequired: e.currentTarget.checked
                })
              }
              style={{ fontWeight: 500 }}
            />

            {isDropdownInput && (
              <Stack style={{ gap: 5 }}>
                <Group style={{ justifyContent: 'space-between' }}>
                  <Text style={{ fontWeight: 500 }}>Input Options</Text>
                  <Button
                    compact
                    disabled={formState.isLoading}
                    leftIcon={<Plus />}
                    onClick={() => {
                      const maxPageLength = Math.ceil(
                        formState.options.length / MAX_PAGE_LENGTH
                      );
                      const newMaxPageLength = Math.ceil(
                        (formState.options.length + 1) / MAX_PAGE_LENGTH
                      );

                      setFormState({
                        ...formState,
                        pageIndex:
                          maxPageLength === formState.pageIndex &&
                          newMaxPageLength > maxPageLength
                            ? newMaxPageLength
                            : formState.pageIndex,
                        options: [
                          ...formState.options,
                          { key: new Date().getTime(), value: '' }
                        ]
                      });
                    }}
                  >
                    Add Input
                  </Button>
                </Group>
                {formState.options.length === 0 ? (
                  <Stack
                    style={{
                      padding: '30px 0px',
                      border: 'solid 1px lightgrey'
                    }}
                  >
                    <Text style={{ textAlign: 'center' }}>
                      No options available
                    </Text>
                  </Stack>
                ) : (
                  <PaginationList
                    emptyMessage="No options available"
                    items={formState.options
                      .sort((a, b) => a.key - b.key)
                      .map((a) => (
                        <Group
                          key={a.key}
                          style={{
                            flex: 1,
                            padding: 5,
                            gap: 5
                          }}
                        >
                          <TextInput
                            icon={<Pencil />}
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                options: [
                                  ...formState.options.filter(
                                    (b) => b.key !== a.key
                                  ),
                                  { ...a, value: e.currentTarget.value }
                                ]
                              })
                            }
                            placeholder="Option Label"
                            style={{ flex: 1 }}
                            value={a.value}
                          />
                          <NumericFormat
                            customInput={TextInput}
                            decimalScale={2}
                            disabled={formState.isLoading}
                            fixedDecimalScale
                            onValueChange={(values) => {
                              setFormState({
                                ...formState,
                                options: [
                                  ...formState.options.filter(
                                    (b) => b.key !== a.key
                                  ),
                                  {
                                    ...a,
                                    adjustedCost: values.floatValue * 100
                                  }
                                ]
                              });
                            }}
                            placeholder="Adjusted Cost"
                            prefix="$"
                            thousandSeparator
                            value={a.adjustedCost ? a.adjustedCost / 100 : ''}
                          />
                          <ActionIcon
                            color="red"
                            onClick={() => {
                              const maxPageLength = Math.ceil(
                                (formState.options.length - 1) / MAX_PAGE_LENGTH
                              );
                              setFormState({
                                ...formState,
                                options: formState.options.filter(
                                  (b) => b.key !== a.key
                                ),
                                pageIndex:
                                  formState.pageIndex > maxPageLength
                                    ? maxPageLength
                                    : formState.pageIndex
                              });
                            }}
                          >
                            <X />
                          </ActionIcon>
                        </Group>
                      ))}
                    itemsPerPage={MAX_PAGE_LENGTH}
                    onPageChange={(pageIndex) =>
                      setFormState({
                        ...formState,
                        pageIndex
                      })
                    }
                    pageIndex={formState.pageIndex}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </FormSection>
      </Stack>
    </ResponsiveModal>
  );
};

RegMerchPackageInputModal.propTypes = {
  isOpen: PropTypes.bool,
  merchPackageInput: PropTypes.object,
  onAdd: PropTypes.func,
  onClose: PropTypes.func
};

export default RegMerchPackageInputModal;
