import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import createDataProvider from './createDataProvider';
import sportsheadzApi from '../apis/sportsheadzApi';
import {
  COGNITO_SETTING_DEV,
  COGNITO_SETTING_PROD,
  PRODUCTION
} from '../config/constants';
import { debug } from '../helpers/Logger';

Auth.configure(PRODUCTION ? COGNITO_SETTING_PROD : COGNITO_SETTING_DEV);
Storage.configure(PRODUCTION ? COGNITO_SETTING_PROD : COGNITO_SETTING_DEV);

const initialState = {
  error: null,
  isSignedIn: false,
  subscriptionData: {},
  displayProgress: false,
  tokenAttempted: false
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return {
        ...state,
        error: action.payload,
        displayProgress: false,
        tokenAttempted: true
      };
    case 'signin':
      return {
        ...initialState,
        isSignedIn: true,
        subscriptionData: action.payload,
        tokenAttempted: true
      };
    case 'token_attempt':
      return { ...state, tokenAttempted: true };
    case 'clear_error_message':
      return { ...state, error: null };
    case 'logout':
      return { ...initialState, displayProgress: false, tokenAttempted: true };
    case 'show_progress':
      return { ...state, displayProgress: action.payload };
    default:
      debug('Unknown action');
      return state;
  }
};

const tokenSignin = (dispatch) => async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      if (
        !user.signInUserSession.accessToken.payload['cognito:groups'].includes(
          'Admin'
        )
      ) {
        await Auth.signOut();
        dispatch({
          type: 'error',
          payload: {
            title: 'Not Authorized',
            message: 'You do not have permission to access this resource.'
          }
        });
        dispatch({ type: 'logout' });
        return;
      }

      debug(JSON.stringify(user, null, 2));

      const response = await sportsheadzApi.get('/api/user/auth', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      });
      debug(`Response: \n${JSON.stringify(response, null, 2)}`);
      dispatch({ type: 'signin', payload: response.data });
      dispatch({ type: 'show_progress', payload: false });
    }
    else {
      dispatch({ type: 'logout' });
    }
  } catch {
    dispatch({ type: 'logout' });
    // navigate("Signin");
    // reset(0, [{ name: "Signin" }]);
  }
};

const signin = (dispatch) => async ({ email, password }) => {
  if (email && password) {
    try {
      dispatch({ type: 'show_progress', payload: true });
      const cognitoResponse = await Auth.signIn(email, password);

      if (
        !cognitoResponse.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].includes('Admin')
      ) {
        await Auth.signOut();
        dispatch({
          type: 'error',
          payload: {
            title: 'Not Authorized',
            message: 'You do not have permission to access this resource.'
          }
        });
        dispatch({ type: 'logout' });
        return;
      }

      debug(JSON.stringify(cognitoResponse, null, 2));

      const response = await sportsheadzApi.get('/api/user/auth', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      });
      // debug(`Response: \n${JSON.stringify(response, null, 2)}`);
      dispatch({ type: 'signin', payload: response.data });
      dispatch({ type: 'show_progress', payload: false });
    } catch (err) {
      switch (err.code) {
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
          dispatch({
            type: 'error',
            payload: {
              title: 'Invalid Credentials',
              message: 'Incorrect username or password.'
            }
          });
          break;
        default:
          dispatch({
            type: 'error',
            payload: { title: 'Error', message: 'An unknown error occured.' }
          });
          debug(err);
      }
    }
  }
  else {
    dispatch({
      type: 'error',
      payload: {
        title: 'Invalid Credentials',
        message: 'Both a username & password are required.'
      }
    });
  }
};

const clearErrorMessage = (dispatch) => () => {
  dispatch({ type: 'clear_error_message' });
};

const setErrorMessage = (dispatch) => ({ message }) => {
  dispatch({ type: 'error', payload: message });
};

const logout = (dispatch) => async () => {
  dispatch({ type: 'show_progress', payload: true });
  await Auth.signOut();
  dispatch({ type: 'logout' });
};

const showProgress = (dispatch) => ({ show }) => {
  dispatch({ type: 'show_progress', payload: show });
};

export const { Provider, Context } = createDataProvider(
  authReducer,
  {
    signin,
    logout,
    clearErrorMessage,
    tokenSignin,
    setErrorMessage,
    showProgress
  },
  initialState
);
