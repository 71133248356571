import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { Button, Card, Group, Select, Stack } from '@mantine/core';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useHistory, useLocation } from 'react-router';
import { NumericFormat } from 'react-number-format';
import PaginationList from '../../common/PaginationList';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import CheckableListItem from '../../common/CheckableListItem';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';
import ChangePaymentWindowStatusModal from './ChangePaymentWindowStatusModal';
import ViewOfflinePaymentsModal from './ViewOfflinePaymentsModal';
import { mq } from '../../../config/theme';

const initialPaymentWindowsState = {
  selectedPaymentWindows: [],
  isLoading: false,
  paymentWindowModal: {
    regAssociationPaymentWindow: null,
    action: '',
    isOpen: false
  },
  pageIndex: 1
};

const paymentWindowsReducer = (state, action) => {
  switch (action.type) {
    case 'select-payment-windows': {
      return {
        ...state,
        selectedPaymentWindows: [
          ...state.selectedPaymentWindows.filter(
            (w) =>
              !action.payload.some(
                (p) =>
                  p.pkRegAssociationPaymentWindow ===
                  w.pkRegAssociationPaymentWindow
              )
          ),
          ...action.payload
        ]
      };
    }
    case 'unselect-payment-windows': {
      return {
        ...state,
        selectedPaymentWindows: [
          ...state.selectedPaymentWindows.filter(
            (w) =>
              !action.payload.some(
                (p) =>
                  p.pkRegAssociationPaymentWindow ===
                  w.pkRegAssociationPaymentWindow
              )
          )
        ]
      };
    }
    case 'open-modal': {
      return {
        ...state,
        paymentWindowModal: {
          action: action.payload.action,
          regAssociationPaymentWindow:
            action.payload.regAssociationPaymentWindow,
          isOpen: true
        }
      };
    }
    case 'close-modal': {
      return {
        ...state,
        paymentWindowModal: {
          ...state.paymentWindowModal,
          isOpen: false
        }
      };
    }
    case 'change-page': {
      return {
        ...state,
        pageIndex: action.payload
      };
    }
    case 'reset': {
      return initialPaymentWindowsState;
    }
    default:
      return state;
  }
};

const OfflinePaymentsView = ({ status }) => {
  const associationSelectRef = useRef(null);
  const hasFetched = useRef(false);
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const pkRegAssociation = query.get('pkRegAssociation');
  const { state, fetchRegAssociationPaymentWindows } = useContext(
    RegistrationContext
  );
  const [paymentWindowsState, paymentWindowsDispatch] = useReducer(
    paymentWindowsReducer,
    initialPaymentWindowsState
  );
  const isLoading =
    !hasFetched.current ||
    state.regAssociationPaymentWindows.loading ||
    state.regAssociations.loading;

  useEffect(() => {
    associationSelectRef.current.blur();
    fetchRegAssociationPaymentWindows({
      pkRegAssociation,
      status
    });
    hasFetched.current = true;

    paymentWindowsDispatch({ type: 'reset' });
  }, [pkRegAssociation, status]);

  return (
    <>
      <Card
        shadow="lg"
        sx={{
          flex: 1,
          border: 'solid 1px lightgrey',
          padding: 15,
          gap: 5,
          alignSelf: 'stretch',
          minHeight: 400
        }}
      >
        <Stack sx={{ flex: 1, gap: 10 }}>
          <Group
            sx={mq({
              flex: 1,
              alignSelf: 'stretch',
              justifyContent: 'space-between',
              alignItems: 'end',
              flexDirection: ['column', 'row']
            })}
          >
            <Select
              ref={associationSelectRef}
              clearable
              data={state.regAssociations.value
                .filter(
                  (a) =>
                    !a.deleted ||
                    a.pkRegAssociation.toString() === pkRegAssociation
                )
                .sort((a, b) =>
                  a.association.name.localeCompare(b.association.name)
                )
                .map((a) => ({
                  label: a.association.name,
                  value: a.pkRegAssociation.toString()
                }))}
              disabled={isLoading || paymentWindowsState.isLoading}
              label="Association"
              onChange={(value) => {
                value
                  ? history.push(`/billing/${status}?pkRegAssociation=${value}`)
                  : history.push(`/billing/${status}`);
              }}
              placeholder="Search by association..."
              searchable
              sx={mq({ flex: [1, 'unset'], alignSelf: ['stretch', 'start'] })}
              value={pkRegAssociation}
            />
            <Button
              disabled={paymentWindowsState.selectedPaymentWindows.length === 0}
              loading={paymentWindowsState.isLoading}
              onClick={() => {
                paymentWindowsDispatch({
                  type: 'open-modal',
                  payload: { action: 'change-status' }
                });
              }}
              sx={mq({ width: ['100%', 'unset'] })}
              variant="outline"
            >
              Change Status
            </Button>
          </Group>

          <PaginationList
            emptyMessage="No payment windows exists"
            isLoading={isLoading}
            items={state.regAssociationPaymentWindows.value
              .filter(
                (w) =>
                  w.regFormSubmissionPayments.length > 0 &&
                  (status === 'invoiced'
                    ? w.invoiced && !w.paid
                    : status === 'paid'
                    ? w.paid
                    : !w.paid && !w.invoiced)
              )
              .sort((a, b) =>
                a.regAssociation.association.name.localeCompare(
                  b.regAssociation.association.name
                )
              )
              .map((window) => {
                const isSelected = paymentWindowsState.selectedPaymentWindows.some(
                  (w) =>
                    w.pkRegAssociationPaymentWindow ===
                    window.pkRegAssociationPaymentWindow
                );
                return (
                  <Stack key={window.pkRegAssociationPaymentWindow}>
                    <CheckableListItem
                      description={
                        <NumericFormat
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={window.regFormSubmissionPayments.length * 3}
                        />
                      }
                      isDisabled={paymentWindowsState.isLoading}
                      isSelected={isSelected}
                      label={window.regAssociation.association.name}
                      menuActions={[
                        {
                          label: 'View',
                          value: 'view-payments'
                        }
                      ]}
                      onAction={() =>
                        paymentWindowsDispatch({
                          type: 'open-modal',
                          payload: {
                            action: 'view-payments',
                            regAssociationPaymentWindow: window
                          }
                        })
                      }
                      onSelect={() => {
                        if (isSelected) {
                          paymentWindowsDispatch({
                            type: 'unselect-payment-windows',
                            payload: [window]
                          });
                        }
                        else {
                          paymentWindowsDispatch({
                            type: 'select-payment-windows',
                            payload: [window]
                          });
                        }
                      }}
                      subDescription={`${window.regFormSubmissionPayments.length} Payments`}
                      subLabel={`${format(
                        new Date(
                          `${stripUtcDateFormatting(window.startDate)}Z`
                        ),
                        'M/dd/yyyy'
                      )}${
                        window.endDate
                          ? `- ${format(
                              new Date(
                                `${stripUtcDateFormatting(window.endDate)}Z`
                              ),
                              'M/dd/yyyy'
                            )}`
                          : ''
                      }`}
                    />
                  </Stack>
                );
              })}
            itemsPerPage={10}
            LoadingComponent={CheckableListItem}
            onPageChange={(index) =>
              paymentWindowsDispatch({ type: 'change-page', payload: index })
            }
            pageIndex={paymentWindowsState.pageIndex}
            showItemBorder
          />
        </Stack>
      </Card>
      <ChangePaymentWindowStatusModal
        currentStatus={status}
        isOpen={
          paymentWindowsState.paymentWindowModal.isOpen &&
          paymentWindowsState.paymentWindowModal.action === 'change-status'
        }
        onClose={() => paymentWindowsDispatch({ type: 'close-modal' })}
        regAssociationPaymentWindows={
          paymentWindowsState.selectedPaymentWindows
        }
      />
      <ViewOfflinePaymentsModal
        isOpen={
          paymentWindowsState.paymentWindowModal.isOpen &&
          paymentWindowsState.paymentWindowModal.action === 'view-payments'
        }
        onClose={() => paymentWindowsDispatch({ type: 'close-modal' })}
        regAssociationPaymentWindow={
          paymentWindowsState.paymentWindowModal.regAssociationPaymentWindow
        }
      />
    </>
  );
};

OfflinePaymentsView.propTypes = { status: PropTypes.string };

export default OfflinePaymentsView;
