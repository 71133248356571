import { Auth } from '@aws-amplify/auth';
import createDataProvider from './createDataProvider';
import sportsheadzApi from '../apis/sportsheadzApi';

const initialState = {
  stripePayments: { value: [], loading: false, error: null }
};

const registrationReducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: null,
          loading: true
        }
      };
    case 'success':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          ...action.payload,
          loading: false
        }
      };
    case 'append':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [...state[action.stateName].value, action.payload],
          loading: false
        }
      };
    case 'replace':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value.filter(action.payload.filter),
            action.payload.value
          ],
          loading: false
        }
      };
    case 'remove':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: state[action.stateName].value.filter(action.payload),
          loading: false
        }
      };
    case 'error':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: action.payload,
          loading: false
        }
      };
    default:
      return state;
  }
};

const getErrorMessage = (error) => {
  let errorMessage = 'Oops something went wrong!';
  if (error?.response && error.response.status === 409) {
    errorMessage = error.response.data?.error ?? errorMessage;
  }

  return errorMessage;
};

const fetchStripePayments = (dispatch) => async (
  { minDate, maxDate, categories },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'stripePayments'
    });

    const categoriesParam = categories.reduce(
      // eslint-disable-next-line no-return-assign
      (result, current, index) =>
        // eslint-disable-next-line no-param-reassign
        (result += `categories[${index}]=${current}&`),
      ''
    );
    const response = await sportsheadzApi.get(
      `/api/staff/payments?${categoriesParam}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        params: {
          minDate,
          maxDate
        }
      }
    );
    dispatch({
      type: 'append',
      stateName: 'stripePayments',
      payload: response.data
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    const message = getErrorMessage(e);
    dispatch({
      type: 'error',
      stateName: 'stripePayments',
      payload: message
    });
    if (onErrorCallback) {
      onErrorCallback(message);
    }
  }
};

export const { Provider, Context } = createDataProvider(
  registrationReducer,
  { fetchStripePayments },
  initialState
);
