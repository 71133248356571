import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Item, ItemImage } from 'semantic-ui-react';
import { format } from 'date-fns';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ContentModal from '../../common/ContentModal';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';

const UserEventInfoModal = ({ event, onClose }) => {
  const { state } = useContext(TeamManagementContext);
  const team = event
    ? state.user.value.user.teams.find((t) => t.team.pkTeam === event.fkTeam)
        ?.team
    : null;

  return (
    <>
      {event ? (
        <ContentModal
          hideActions
          isOpen
          onClose={onClose}
          size="tiny"
          style={{ padding: 0 }}
        >
          <Item.Group>
            <Item style={{ marginBottom: 0 }}>
              <ItemImage size="tiny" src={team?.image} />

              <Item.Content>
                <Item.Header>
                  {event.title}
                  {event.dataType ? ` - ${event.dataType}` : ''}
                </Item.Header>
                <Item.Meta>
                  {`${format(
                    new Date(`${stripUtcDateFormatting(event.start)}Z`),
                    'p'
                  )} - ${format(
                    new Date(`${stripUtcDateFormatting(event.end)}Z`),
                    'p'
                  )} `}
                  {event.arriveBefore
                    ? `*arrive ${event.arriveBefore} minutes before*`
                    : ''}
                </Item.Meta>

                <Item.Description style={{ marginTop: 10 }}>
                  <p>
                    {team.name}{' '}
                    {event.gameOpponent ? `VS ${event.gameOpponent}` : ''}
                  </p>
                  <p>{event.locationName}</p>
                  <p> {event.locationMapinfo}</p>
                  {event.notes && (
                    <>
                      <p>Notes:</p>
                      <p>{event.notes}</p>
                    </>
                  )}
                </Item.Description>
                <Item.Extra>
                  <Icon
                    color={event.notifications ? 'green' : 'red'}
                    name={event.notifications ? 'check' : 'x'}
                  />
                  Notifications
                  {event.isGame && (
                    <>
                      <Icon
                        color={event.gameHome ? 'green' : 'red'}
                        name={event.gameHome ? 'check' : 'x'}
                        style={{ marginLeft: 20 }}
                      />
                      Home game
                    </>
                  )}
                  {event.isAllDay && (
                    <>
                      <Icon
                        color={event.notifications ? 'green' : 'red'}
                        name={event.notifications ? 'check' : 'x'}
                        style={{ marginLeft: 20 }}
                      />
                      All Day
                    </>
                  )}
                </Item.Extra>
                <Item.Extra>
                  <Button
                    as="a"
                    floated="right"
                    href={`/team-management/teams/${team.pkTeam}`}
                    onClick={onClose}
                    positive
                    type="button"
                  >
                    View Team
                  </Button>
                  <Button
                    color="black"
                    floated="right"
                    onClick={onClose}
                    type="button"
                  >
                    Close
                  </Button>
                </Item.Extra>
              </Item.Content>
            </Item>
          </Item.Group>
        </ContentModal>
      ) : null}
    </>
  );
};

UserEventInfoModal.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func
};

export default UserEventInfoModal;
