import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm } from 'semantic-ui-react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ContentModal from '../../common/ContentModal';
import View from '../../common/View';
import Text from '../../common/Text';

const RespondToJoinRequestModal = ({ isOpen, onClose, teamJoinRequest }) => {
  const { respondTeamJoinRequest, fetchTeam } = useContext(
    TeamManagementContext
  );
  const [showConfirmAction, setShowConfirmAction] = useState(null);

  return (
    <ContentModal
      ariaLabel="respondJoinRequest"
      error=""
      hideActions
      isOpen={isOpen}
      onClose={onClose}
      role="dialog"
      size="tiny"
      title="Respond To Join Request"
    >
      <Confirm
        aria-label="confirm"
        content={(
          <View css={{ margin: 20 }}>
            <Text>
              Are you sure you want to <b>{showConfirmAction}</b>{' '}
              {`${teamJoinRequest?.user.name}'s`} join request to{' '}
              {teamJoinRequest?.team.name}
            </Text>
          </View>
        )}
        onCancel={() => setShowConfirmAction(null)}
        onConfirm={async () => {
          onClose();
          if (showConfirmAction === 'accept') {
            await respondTeamJoinRequest(
              teamJoinRequest.pkTeamJoinRequest,
              true
            );
            fetchTeam(teamJoinRequest.team.pkTeam);
          }
          else {
            respondTeamJoinRequest(teamJoinRequest.pkTeamJoinRequest, false);
          }
        }}
        open={showConfirmAction != null}
        role="dialog"
        size="tiny"
      />
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <Button
          color="red"
          content="Deny"
          onClick={() => setShowConfirmAction('deny')}
          style={{ marginRight: '0.35em', flex: 1 }}
          type="button"
        />
        <Button
          content="Accept"
          onClick={() => setShowConfirmAction('accept')}
          positive
          style={{ marginLeft: '0.35em', flex: 1 }}
          type="button"
        />
      </View>
    </ContentModal>
  );
};

RespondToJoinRequestModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  teamJoinRequest: PropTypes.object
};

export default RespondToJoinRequestModal;
