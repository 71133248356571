import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Divider } from 'semantic-ui-react';
import View from '../components/common/View';
import SearchBar from '../components/common/SearchBar';
import { Context as TeamManagementContext } from '../providers/TeamManagementProvider';
import TeamView from '../components/content/TeamsView.js/TeamView';
import CreateTeamModal from '../components/content/TeamsView.js/CreateTeamModal';

const TeamsView = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(null);
  const { state, searchForTeam, appendState } = useContext(
    TeamManagementContext
  );

  useEffect(() => {
    setSearchResults(
      state.teamSearchResults.value
        .filter((s, index) => index < 7)
        .map((s) => ({
          title: s.team.name,
          key: s.team.pkTeam,
          description: s.team.domain,
          image: s.team.image,
          data: s
        }))
    );
  }, [state.teamSearchResults.value]);

  return (
    <View>
      <View
        aria-label="teamsSearch"
        css={{ flexDirection: 'row', marginTop: 5 }}
        role="searchbox"
      >
        <SearchBar
          loading={state.teamSearchResults.loading}
          onClear={() => {
            appendState({ teamSearchResults: { value: [], loading: false } });
          }}
          onResultSelect={(term) => setSelectedResult(term)}
          onSearch={(term) => searchForTeam(term)}
          placeholder="Search for team by name..."
          results={searchResults}
        />
      </View>
      {selectedResult != null && (
        <Redirect to={`/team-management/teams/${selectedResult.team.pkTeam}`} />
      )}
      <Switch>
        <Route path="/team-management/teams/:pkTeam">
          <>
            <Divider style={{ marginTop: 25 }} />
            <TeamView />
          </>
        </Route>
        <Route path="/team-management/teams">
          <CreateTeamModal />
        </Route>
      </Switch>
    </View>
  );
};

TeamsView.propTypes = {};

export default TeamsView;
