import React, { useContext, useState } from 'react';
import { Button, Group, Select, Stack, Text } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import PaginationList from '../../common/PaginationList';
import RegMerchPackageModal from './RegMerchPackageModal';
import ActionListItem from '../../common/ActionListItem';
import { mq } from '../../../config/theme';
import { currencyFormat } from '../../../helpers/formatHelper';

const RegMerchPackages = () => {
  const { state } = useContext(RegistrationContext);
  const [modalState, setModalState] = useState({
    isOpen: false,
    item: null,
    showDelete: false,
    copyPackage: false
  });
  const [filterState, setFilterState] = useState({ fkRegAssociation: null });

  const filterOptions = state.merchPackages.value.reduce(
    (r, c) => {
      const regAssociation = c.regAssociationMerchPackages[0]?.regAssociation;
      if (
        regAssociation &&
        !r.regAssociations.find(
          (f) => f.value === regAssociation.pkRegAssociation.toString()
        )
      ) {
        r.regAssociations.push({
          label: regAssociation.association.name,
          value: regAssociation.pkRegAssociation.toString()
        });
      }
      return r;
    },
    { regAssociations: [] }
  );

  return (
    <Stack style={{ flex: 1 }}>
      <Stack style={{ flex: 1, gap: 0 }}>
        <Group style={{ flex: 1, justifyContent: 'space-between' }}>
          <Text weight={500}>Available Packages</Text>
          <Button
            compact
            leftIcon={<Plus />}
            onClick={() => setModalState({ isOpen: true })}
          >
            Add Package
          </Button>
        </Group>
        <Stack style={{ flex: 1, gap: 5 }}>
          <Group>
            <Select
              clearable
              data={filterOptions.regAssociations}
              label=""
              onChange={(value) =>
                setFilterState({
                  ...filterState,
                  fkRegAssociation: value
                })
              }
              placeholder="Filter by Association..."
              searchable
              sx={mq({ maxWidth: ['unset', 250], flex: 1 })}
              withinPortal
            />
          </Group>

          <PaginationList
            emptyMessage="No packages available"
            isLoading={state.merchPackages.loading}
            items={state.merchPackages.value
              .filter(
                (f) =>
                  !filterState.fkRegAssociation ||
                  f.regAssociationMerchPackages.some(
                    (s) =>
                      s.regAssociation.pkRegAssociation.toString() ===
                      filterState.fkRegAssociation
                  )
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((a) => (
                <ActionListItem
                  key={a.pkMerchPackage}
                  actions={[
                    {
                      label: 'Edit',
                      value: 'EDIT'
                    },
                    {
                      label: 'Copy',
                      value: 'COPY'
                    },
                    {
                      label: 'Delete',
                      value: 'DELETE'
                    }
                  ]}
                  alwaysShowActions
                  description={a.description}
                  extraDescriptions={[
                    `${a.merchPackageImages.length} ${
                      a.merchPackageImages.length === 1 ? 'Image' : 'Images'
                    }, ${a.merchPackageInputs.length} ${
                      a.merchPackageInputs.length === 1 ? 'Input' : 'Inputs'
                    }`,
                    a.isGlobal
                      ? 'Global'
                      : a.regAssociationMerchPackages[0]?.regAssociation
                          .association.name
                  ]}
                  header={`${a.name} - ${currencyFormat(a.price / 100)}`}
                  onAction={(action) =>
                    setModalState({
                      isOpen: true,
                      item: a,
                      copyPackage: action === 'COPY',
                      showDelete: action === 'DELETE'
                    })
                  }
                />
              ))}
            LoadingComponent={ActionListItem}
          />
        </Stack>
      </Stack>

      <RegMerchPackageModal
        copyPackage={modalState.copyPackage}
        isOpen={modalState.isOpen}
        merchPackage={modalState.item}
        onClose={() => setModalState({ ...modalState, isOpen: false })}
        showDelete={modalState.showDelete}
      />
    </Stack>
  );
};

export default RegMerchPackages;
