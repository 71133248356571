import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Confirm,
  Header,
  List,
  Modal,
  Pagination,
  Segment
} from 'semantic-ui-react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router';
import ActionableListItem from '../../common/ActionableListItem';
import View from '../../common/View';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { mq } from '../../../config/theme';
import AddTeamUserModal from '../UsersView/AddTeamUserModal';
import AssignGuardiansModal from './AssignGuardiansModal';
import EditTeamMemberRolesModal from './EditTeamMemberRolesModal';
import Text from '../../common/Text';
import RespondToJoinRequestModal from './RespondToJoinRequestModal';
import AcceptAllJoinRequestModal from './AcceptAllJoinRequestModal';

const TeamMembers = ({ className }) => {
  const { state, updateTeamMembersStatus, fetchTeamJoinRequests } = useContext(
    TeamManagementContext
  );
  const history = useHistory();
  const [activePage, setActivePage] = useState(1);
  const [inactivePage, setInactivePage] = useState(1);
  const [activeJoinRequestsPage, setActiveJoinRequestsPage] = useState(1);
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [showAcceptAllJoinRequests, setShowAcceptAllJoinRequests] = useState(
    false
  );
  const [respondJoinRequest, setRespondJoinRequest] = useState(null);
  const [selectedMemberInfo, setSelectedMemberInfo] = useState({
    user: null,
    action: ''
  });

  const { members, team } = state.team.value;
  const activeMembers = members
    .filter((u) => u.isActive)
    .sort((a, b) => a.name.localeCompare(b.name));
  const inactiveMembers = members
    .filter((u) => !u.isActive)
    .sort((a, b) => a.name.localeCompare(b.name));
  const activeJoinRequests = state.teamJoinRequests.value.filter(
    (t) => t.isActive
  );

  useEffect(() => {
    if (state.team.value) {
      fetchTeamJoinRequests(state.team.value.team.pkTeam);
    }
  }, [state.team.value]);

  return (
    <View
      aria-label="teamMembers"
      className={className}
      css={{ flexDirection: 'column' }}
      role="region"
    >
      {selectedMemberInfo.action === 'DELETE' && selectedMemberInfo.user && (
        <Confirm
          content={`Are you sure you want to ${
            selectedMemberInfo.user.isActive ? 'remove' : 'restore'
          } ${selectedMemberInfo.user.name}?`}
          onCancel={() =>
            setSelectedMemberInfo({
              user: null,
              action: ''
            })
          }
          onConfirm={() => {
            updateTeamMembersStatus(team.pkTeam, [
              {
                pkUser: selectedMemberInfo.user.pkUser,
                isActive: !selectedMemberInfo.user.isActive
              }
            ]);
          }}
          open
          size="small"
        />
      )}
      <View>
        <View
          css={mq({
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            marginBottom: 8,
            alignItems: 'center'
          })}
        >
          <Header
            as="h4"
            css={css`
              margin-bottom: 0px !important;
            `}
          >
            Active Members
          </Header>
          <Button
            color="green"
            compact
            content="Add User"
            icon={{ name: 'plus' }}
            labelPosition="right"
            onClick={() => setShowAddTeam(true)}
          />
        </View>
        <Segment
          aria-label={activeMembers.length.toString()}
          css={css`
            margin-top: 0px !important;
          `}
          data-testid="activeMembers"
        >
          <List aria-label="activeMembersList" role="region" selection>
            {activeMembers
              .slice(-5 + activePage * 5, activePage * 5)
              .map((u) => (
                <ActionableListItem
                  key={u.pkUser}
                  actionColor="red"
                  actionTitle="Remove"
                  avatar={u.avatar}
                  description={`${u.roles.join(', ')}${
                    u.status === 'Basic' ? ' (Basic User)' : ''
                  }`}
                  header={u.name}
                  onAction={() => {
                    setSelectedMemberInfo({
                      user: u,
                      action: 'DELETE'
                    });
                  }}
                  onClick={() =>
                    setSelectedMemberInfo({
                      user: u,
                      action: 'VIEW'
                    })
                  }
                  showImage
                />
              ))}
          </List>
          {Math.ceil(activeMembers.length / 5) > 1 && (
            <Pagination
              activePage={activePage}
              fluid
              nextItem={null}
              onPageChange={(e, { activePage: page }) => {
                setActivePage(page);
              }}
              prevItem={null}
              totalPages={Math.ceil(activeMembers.length / 5)}
            />
          )}
        </Segment>
        {inactiveMembers.length > 0 && (
          <>
            <Header
              as="h4"
              css={css`
                margin-bottom: 10px !important;
                margin-top: 5px !important;
              `}
            >
              Inactive Members
            </Header>
            <Segment
              aria-label={inactiveMembers.length.toString()}
              css={css`
                margin-top: 0px !important;
              `}
              data-testid="inactiveMembers"
            >
              <List aria-label="inactiveMembersList" role="region" selection>
                {inactiveMembers
                  .slice(-5 + inactivePage * 5, inactivePage * 5)
                  .map((u) => (
                    <ActionableListItem
                      key={u.pkUser}
                      actionColor="green"
                      actionTitle="Restore"
                      avatar={u.avatar}
                      description={`${u.roles.join(', ')}${
                        u.status === 'Basic' ? ' (Basic User)' : ''
                      }`}
                      header={u.name}
                      onAction={() => {
                        setSelectedMemberInfo({
                          user: u,
                          action: 'DELETE'
                        });
                      }}
                      onClick={() =>
                        u.status === 'Basic'
                          ? setSelectedMemberInfo({
                              user: u,
                              action: 'VIEW'
                            })
                          : history.push(`/users/${u.pkUser}`)
                      }
                      role="listitem"
                    />
                  ))}
              </List>
              {Math.ceil(inactiveMembers.length / 5) > 1 && (
                <Pagination
                  activePage={inactivePage}
                  fluid
                  nextItem={null}
                  onPageChange={(e, { activePage: page }) => {
                    setInactivePage(page);
                  }}
                  prevItem={null}
                  totalPages={Math.ceil(inactiveMembers.length / 5)}
                />
              )}
            </Segment>
          </>
        )}
        <View
          css={mq({
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            marginBottom: 8,
            alignItems: 'center'
          })}
        >
          <Header
            as="h4"
            css={css`
              margin-bottom: 0px !important;
            `}
          >
            Join Requests
          </Header>
          {activeJoinRequests.length > 0 && (
            <Button
              color="green"
              compact
              content="Approve All"
              icon={{ name: 'plus' }}
              labelPosition="right"
              onClick={() => setShowAcceptAllJoinRequests(true)}
            />
          )}
        </View>

        <Segment
          aria-label={activeJoinRequests.length.toString()}
          data-testid="joinRequests"
          loading={state.teamJoinRequests.loading}
          style={{ marginTop: '0px', minHeight: '50px' }}
        >
          {!state.teamJoinRequests.loading && (
            <>
              {activeJoinRequests.length > 0 ? (
                <>
                  <List aria-label="joinRequests" role="list" selection>
                    {activeJoinRequests
                      .slice(
                        -5 + activeJoinRequestsPage * 5,
                        activeJoinRequestsPage * 5
                      )
                      .map((u) => (
                        <ActionableListItem
                          key={u.pkTeamJoinRequest}
                          actionColor="green"
                          actionTitle="Respond"
                          avatar={u.user.avatar}
                          description={u.roles.join(', ')}
                          header={`${u.user.name} - ${u.status}`}
                          onAction={() => setRespondJoinRequest(u)}
                          onClick={() =>
                            history.push(`/users/${u.user.pkUser}`)
                          }
                          showImage
                        />
                      ))}
                  </List>
                  {Math.ceil(activeJoinRequests.length / 5) > 1 && (
                    <Pagination
                      activePage={activeJoinRequestsPage}
                      fluid
                      nextItem={null}
                      onPageChange={(e, { activePage: page }) => {
                        setActiveJoinRequestsPage(page);
                      }}
                      prevItem={null}
                      totalPages={Math.ceil(activeJoinRequests.length / 5)}
                    />
                  )}
                </>
              ) : (
                <Text>No active join requests</Text>
              )}
            </>
          )}
        </Segment>
      </View>
      <Modal
        aria-label="memberMenuOptions"
        closeIcon
        css={mq({ minWidth: ['100%', 'unset'] })}
        onClose={() =>
          setSelectedMemberInfo({
            user: null,
            action: ''
          })
        }
        open={selectedMemberInfo.action === 'VIEW'}
        role="dialog"
        size="tiny"
        style={{ margin: 0 }}
      >
        <Modal.Content>
          <View>
            <Button
              content="View User"
              onClick={() =>
                history.push(`/users/${selectedMemberInfo.user.pkUser}`)
              }
              positive
              style={{ margin: 5 }}
            />
            <Button
              content="Edit Roles"
              onClick={() =>
                setSelectedMemberInfo({
                  user: selectedMemberInfo.user,
                  action: 'EDIT'
                })
              }
              positive
              style={{ margin: 5 }}
            />
            {selectedMemberInfo.user?.status === 'Basic' && (
              <Button
                content="Assign Guardians"
                onClick={() =>
                  setSelectedMemberInfo({
                    user: selectedMemberInfo.user,
                    action: 'ASSIGN'
                  })
                }
                positive
                style={{ margin: 5 }}
              />
            )}
          </View>
        </Modal.Content>
      </Modal>

      <AddTeamUserModal
        isOpen={showAddTeam}
        onClose={() => setShowAddTeam(false)}
        team={state.team.value}
      />
      <EditTeamMemberRolesModal
        onClose={() =>
          setSelectedMemberInfo({
            user: null,
            action: ''
          })
        }
        pkTeam={team.pkTeam}
        selectedMember={
          selectedMemberInfo.action === 'EDIT' ? selectedMemberInfo.user : null
        }
      />
      <AssignGuardiansModal
        onClose={() =>
          setSelectedMemberInfo({
            user: null,
            action: ''
          })
        }
        selectedMember={
          selectedMemberInfo.action === 'ASSIGN'
            ? selectedMemberInfo.user
            : null
        }
      />
      <RespondToJoinRequestModal
        isOpen={respondJoinRequest != null}
        onClose={() => setRespondJoinRequest(null)}
        teamJoinRequest={respondJoinRequest}
      />

      <AcceptAllJoinRequestModal
        isOpen={showAcceptAllJoinRequests}
        onClose={() => setShowAcceptAllJoinRequests(false)}
        teamJoinRequests={activeJoinRequests}
      />
    </View>
  );
};

TeamMembers.propTypes = { className: PropTypes.string };

export default TeamMembers;
