import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Group, Stack, Text } from '@mantine/core';
import { useHistory } from 'react-router-dom';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import PaginationList from '../../common/PaginationList';
import ActionListItem from '../../common/ActionListItem';
import RegAssociationAdminModal from './RegAssociationAdminModal';

const RegAssociationAdminList = ({ regAssociation }) => {
  const history = useHistory();
  const { state, fetchRegAssociationAdmins } = useContext(RegistrationContext);
  const [pageState, setPageState] = useState({
    activePageIndex: 1,
    inactivePageIndex: 1
  });
  const [adminModalState, setAdminModalState] = useState({
    isOpen: false,
    action: '',
    regAssociationAdmin: null
  });
  const regAssociationAdmins = state.regAssociationAdmins.value
    .filter(
      (a) =>
        a.user &&
        a.regAssociation.pkRegAssociation === regAssociation.pkRegAssociation
    )
    .sort((a, b) => a.user.name.localeCompare(b.user.name));
  const deletedAdmins = regAssociationAdmins.filter((a) => a.deleted);

  useEffect(() => {
    if (regAssociation) {
      fetchRegAssociationAdmins(regAssociation.pkRegAssociation);
    }
  }, [regAssociation]);

  return (
    <Stack sx={{ gap: 20, marginTop: 20 }}>
      <Stack sx={{ gap: 5 }}>
        <Group sx={{ flex: 1, justifyContent: 'space-between' }}>
          <Text weight={500}>Active Admins</Text>
          <Button
            color="green"
            compact
            onClick={() => {
              setAdminModalState({
                isOpen: true,
                action: 'ADD',
                regAssociationAdmin: null
              });
            }}
          >
            Add Admin
          </Button>
        </Group>
        <PaginationList
          emptyMessage="No admins assigned"
          isLoading={state.regAssociationAdmins.loading}
          items={regAssociationAdmins
            .filter((a) => !a.deleted)
            .map((a) => (
              <ActionListItem
                key={a.pkRegAssociationAdmin}
                actions={[
                  {
                    label: 'Remove',
                    color: 'red',
                    value: 'REMOVE'
                  }
                ]}
                avatar={a.user.avatar}
                description={a.user.email}
                header={a.user.name}
                onAction={(action) =>
                  setAdminModalState({
                    isOpen: true,
                    action,
                    regAssociationAdmin: a
                  })
                }
                onClick={() => {
                  history.push(`/users/${a.user.pkUser}`);
                }}
                showImage
              />
            ))}
          itemsPerPage={10}
          LoadingComponent={ActionListItem}
          onPageChange={(index) =>
            setPageState({
              ...pageState,
              activePageIndex: index
            })
          }
          pageIndex={pageState.activePageIndex}
          showItemBorder
        />
      </Stack>

      {deletedAdmins.length === 0 ? (
        <></>
      ) : (
        <Stack sx={{ gap: 5 }}>
          <Group>
            <Text weight={500}>Disabled Admins</Text>
          </Group>
          <PaginationList
            isLoading={state.regAssociationAdmins.loading}
            items={deletedAdmins.map((a) => (
              <ActionListItem
                key={a.pkRegAssociationAdmin}
                actions={[
                  {
                    label: 'Restore',
                    color: 'green',
                    value: 'RESTORE'
                  }
                ]}
                avatar={a.user.avatar}
                description={a.user.email}
                header={a.user.name}
                onAction={(action) =>
                  setAdminModalState({
                    isOpen: true,
                    action,
                    regAssociationAdmin: a
                  })
                }
                onClick={() => {
                  history.push(`/users/${a.user.pkUser}`);
                }}
                showImage
              />
            ))}
            itemsPerPage={10}
            LoadingComponent={ActionListItem}
            onPageChange={(index) =>
              setPageState({
                ...pageState,
                inactivePageIndex: index
              })
            }
            pageIndex={pageState.inactivePageIndex}
          />
        </Stack>
      )}
      <RegAssociationAdminModal
        action={adminModalState.action}
        isOpen={adminModalState.isOpen}
        onClose={() =>
          setAdminModalState({
            ...adminModalState,
            isOpen: false
          })
        }
        onSuccess={() => {
          setAdminModalState({
            ...adminModalState,
            isOpen: false
          });
          fetchRegAssociationAdmins(regAssociation.pkRegAssociation);
        }}
        regAssociation={regAssociation}
        regAssociationAdmin={adminModalState.regAssociationAdmin}
      />
    </Stack>
  );
};

RegAssociationAdminList.propTypes = { regAssociation: PropTypes.object };

export default RegAssociationAdminList;
