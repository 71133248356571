import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Center, Group, Pagination, Stack, Text } from '@mantine/core';
import { mq } from '../../config/theme';

const PaginationList = ({
  isLoading,
  items,
  LoadingComponent,
  itemsPerPage,
  emptyMessage,
  pageIndex,
  onPageChange
}) => {
  const MAX_PAGE_LENGTH = itemsPerPage ?? 10;
  const [pageIndexState, setPageIndexState] = useState(1);
  const index = pageIndex ?? pageIndexState;

  useEffect(() => {
    setPageIndexState(1);
  }, [items]);

  return (
    <Stack sx={mq({ flex: 1, gap: [20, 10], alignSelf: 'stretch' })}>
      <Stack
        sx={{
          gap: 0,
          border: 'solid 1px #ced4da',
          borderBottom: 'none',
          flex: 1
        }}
      >
        {isLoading ? (
          <>
            <Stack sx={{ borderBottom: 'solid 1px #ced4da' }}>
              <LoadingComponent />
            </Stack>
            <Stack sx={{ borderBottom: 'solid 1px #ced4da' }}>
              <LoadingComponent />
            </Stack>
            <Stack sx={{ borderBottom: 'solid 1px #ced4da' }}>
              <LoadingComponent />
            </Stack>
            <Stack sx={{ borderBottom: 'solid 1px #ced4da' }}>
              <LoadingComponent />
            </Stack>
          </>
        ) : items.length === 0 ? (
          <Group
            sx={{
              gap: 20,
              flex: 1,
              padding: '50px 10px',
              borderBottom: 'solid 1px #ced4da'
            }}
          >
            <Text sx={{ margin: 'auto' }}>
              {emptyMessage ?? 'No Items available'}
            </Text>
          </Group>
        ) : (
          items
            .slice(
              -MAX_PAGE_LENGTH + index * MAX_PAGE_LENGTH,
              index * MAX_PAGE_LENGTH
            )
            .map((r) => (
              <Group key={r.key} style={{ borderBottom: 'solid 1px #ced4da' }}>
                {r}
              </Group>
            ))
        )}
      </Stack>
      {!isLoading && items.length > MAX_PAGE_LENGTH && (
        <Center>
          <Pagination
            color="blue"
            onChange={(page) => {
              if (onPageChange) {
                onPageChange(page);
              }
              else {
                setPageIndexState(page);
              }
            }}
            page={index}
            total={Math.ceil(items.length / MAX_PAGE_LENGTH)}
          />
        </Center>
      )}
    </Stack>
  );
};

PaginationList.propTypes = {
  LoadingComponent: PropTypes.any,
  emptyMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  itemsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  pageIndex: PropTypes.number
};

export default PaginationList;
