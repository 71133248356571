import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Image, List } from 'semantic-ui-react';
import userPlaceholder from '../../images/user_placeholder.png';

const SelectableListItem = ({
  header,
  description,
  avatar,
  isChecked,
  showImage,
  onClick
}) => (
  <List.Item onClick={() => (onClick ? onClick() : {})}>
    {(onClick || isChecked) && (
      <List.Content floated="right">
        <Icon
          color={isChecked ? 'green' : null}
          name={`${isChecked ? 'check ' : ''}circle outline`}
          size="big"
        />
      </List.Content>
    )}
    {showImage && (
      <Image
        avatar
        css={{ objectFit: 'cover' }}
        src={avatar || userPlaceholder}
      />
    )}
    <List.Content>
      <List.Header>{header}</List.Header>
      <List.Description>{description}</List.Description>
    </List.Content>
  </List.Item>
);

SelectableListItem.propTypes = {
  avatar: PropTypes.string,
  description: PropTypes.string,
  header: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  showImage: PropTypes.bool
};

export default SelectableListItem;
