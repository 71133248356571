import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Confirm, List } from 'semantic-ui-react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ContentModal from '../../common/ContentModal';
import View from '../../common/View';
import Text from '../../common/Text';
import { mq } from '../../../config/theme';
import SelectableListItem from '../../common/SelectableListItem';

const AssignGuardiansModal = ({ selectedMember, onClose }) => {
  const { state, createRemoteUsers, fetchTeam } = useContext(
    TeamManagementContext
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const assignedGuardians = state.team.value.members.filter((m) =>
    selectedMember?.remoteUserHandlers.map((r) => r.pkUser).includes(m.pkUser)
  );
  const selectableMemebers = state.team.value.members.filter(
    (m) => m.pkUser !== selectedMember?.pkUser && m.roles.includes('Guardian')
  );

  useEffect(() => {
    if (selectedMember) {
      setSelectedUsersList([]);
      setShowConfirm(false);
    }
  }, [selectedMember]);

  return (
    <View>
      <ContentModal
        actionContent="Assign"
        ariaLabel="assignGuardiansModal"
        error=""
        isOpen={selectedMember !== null}
        onAction={
          selectedUsersList.length > 0 ? () => setShowConfirm(true) : null
        }
        onClose={onClose}
        size="tiny"
        title="Assign Guardians"
      >
        <View
          css={{
            maxHeight: '150px',
            overflowY: 'auto'
          }}
        >
          <List
            aria-label="guardiansList"
            role="region"
            selection
            style={{ margin: 0 }}
          >
            {selectableMemebers.length > 0 ? (
              selectableMemebers.map((u) => (
                <SelectableListItem
                  key={u.pkUser}
                  avatar={u.avatar}
                  description={`${u.roles.join(', ')}${
                    u.status === 'Basic' ? ' (Basic User)' : ''
                  }`}
                  header={u.name}
                  isChecked={
                    selectedUsersList.findIndex((s) => s.pkUser === u.pkUser) >
                      -1 ||
                    assignedGuardians.findIndex((s) => s.pkUser === u.pkUser) >
                      -1
                  }
                  onClick={() => {
                    const existingGuardian =
                      assignedGuardians.findIndex(
                        (s) => s.pkUser === u.pkUser
                      ) > -1;
                    if (!existingGuardian) {
                      const listCopy = [...selectedUsersList];
                      const index = selectedUsersList.findIndex(
                        (m) => m.pkUser === u.pkUser
                      );
                      index > -1 ? listCopy.splice(index, 1) : listCopy.push(u);
                      setSelectedUsersList(listCopy);
                    }
                  }}
                />
              ))
            ) : (
              <View>
                <Text>No guardians available</Text>
              </View>
            )}
          </List>
        </View>
      </ContentModal>
      <Confirm
        content={(
          <View css={{ margin: 20 }}>
            <Text>
              Are you sure you want to assign the following guardians to{' '}
              <b>{selectedMember?.name}</b>
            </Text>
            <List style={{ marginTop: 5 }}>
              {selectedUsersList.map((u) => (
                <SelectableListItem
                  key={u.pkUser}
                  avatar={u.avatar}
                  description={`${u.roles.join(', ')}${
                    u.status === 'Basic' ? ' (Basic User)' : ''
                  }`}
                  header={u.name}
                  showImage
                />
              ))}
            </List>
          </View>
        )}
        css={mq({
          top: [0, 'unset'],
          left: [0, 'unset'],
          minWidth: ['100%', 'unset'],
          height: ['100vh', 'unset']
        })}
        onCancel={() => setShowConfirm(false)}
        onConfirm={async () => {
          await createRemoteUsers(
            selectedUsersList.map((u) => ({
              parent: u.pkUser,
              child: selectedMember.pkUser
            }))
          );
          onClose();
          fetchTeam(state.team.value.team.pkTeam);
        }}
        open={selectedMember && showConfirm}
        size="tiny"
        style={{ margin: 0 }}
      />
    </View>
  );
};

AssignGuardiansModal.propTypes = {
  onClose: PropTypes.func,
  selectedMember: PropTypes.object
};

export default AssignGuardiansModal;
