import { PRODUCTION, TESTING } from '../config/constants';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return null;
      }
      seen.add(value);
    }
    return value;
  };
};

const debug = (message) => {
  if (PRODUCTION && !TESTING) {
    // eslint-disable-next-line no-console
    console.log(message);
  }
  else {
    // @TODO: Hookup to CloudWatch as needed
  }
};

export { debug, getCircularReplacer };
