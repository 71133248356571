import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Container, Form, Icon, Select } from 'semantic-ui-react';
import { uploadFileToStorage } from '../../../helpers/awsHelper';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ContentModal from '../../common/ContentModal';
import InputLabel from '../../common/InputLabel';
import ResponsiveInput from '../../common/ResponsiveInput';
import View from '../../common/View';

const CreateTeamModal = () => {
  const { state, fetchAssociations, createTeam } = useContext(
    TeamManagementContext
  );
  const [isOpen, setIsOpen] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    season: null,
    mbswTeamId: null,
    association: null,
    image: ''
  });
  const imageInputRef = useRef();

  const sortedAssociationsList = state.associations.value.sort((a, b) =>
    a.name?.localeCompare(b.name)
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        name: '',
        season: null,
        mbswTeamId: null,
        association: null,
        image: ''
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (state.associations.value.length === 0) {
      fetchAssociations();
    }
  }, [state.associations.value]);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <View css={{ marginTop: 15 }}>
      <Button
        color="green"
        compact
        content="New Team"
        css={{ width: 'fit-content', alignSelf: 'flex-end' }}
        icon={{ name: 'plus' }}
        labelPosition="right"
        onClick={() => setIsOpen(true)}
      />
      <ContentModal
        actionContent="Create"
        ariaLabel="createTeamModal"
        error=""
        hideActions
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        role="dialog"
        size="tiny"
        title="Create Team"
      >
        <Form
          aria-label="createTeam"
          onSubmit={(e) => {
            e.preventDefault();
            createTeam({
              ...formState,
              associationDomain: formState.association?.domain
            });
            setIsOpen(false);
          }}
          role="form"
        >
          <Form.Field>
            <ResponsiveInput
              label="Name"
              name="name"
              onChange={handleInputChange}
              required
              style={{ fontSize: 15 }}
              value={formState.name}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              label="Season"
              name="season"
              onChange={handleInputChange}
              required
              style={{ fontSize: 15 }}
              type="number"
              value={formState.season ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              label="MBSW ID"
              name="mbswTeamId"
              onChange={handleInputChange}
              style={{ fontSize: 15 }}
              type="number"
              value={formState.mbswTeamId ?? ''}
            />
          </Form.Field>
          <Form.Field>
            <View css={{ flexDirection: 'column' }}>
              <InputLabel>Association</InputLabel>
              <View css={{ flexDirection: 'row', flex: 1 }}>
                <Select
                  css={{ flex: 1 }}
                  loading={state.associations.loading}
                  name="association"
                  onChange={(e, option) =>
                    setFormState({
                      ...formState,
                      association: sortedAssociationsList.find(
                        (a) => a.pkAssociation === option.value
                      )
                    })
                  }
                  options={sortedAssociationsList.map((a) => ({
                    key: a.pkAssociation,
                    value: a.pkAssociation,
                    text: a.name
                  }))}
                  placeholder="Select an association"
                  search
                  value={formState.association?.pkAssociation}
                />
                <Icon
                  css={{ alignSelf: 'center', cursor: 'pointer' }}
                  disabled={state.associations.loading}
                  loading={state.associations.loading}
                  name="refresh"
                  onClick={() => {
                    fetchAssociations();
                  }}
                  style={{ margin: 10 }}
                />
              </View>
            </View>
          </Form.Field>
          <Form.Field>
            <InputLabel>Image</InputLabel>
            <ResponsiveInput
              action={(
                <Button
                  onClick={() => imageInputRef.current.click()}
                  type="button"
                >
                  Upload
                </Button>
              )}
              name="image"
              onChange={handleInputChange}
              required
              style={{ fontSize: 15 }}
              value={formState.image}
            />
            <input
              ref={imageInputRef}
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) =>
                uploadFileToStorage('team-', e.target.files[0], (url) =>
                  setFormState({
                    ...formState,
                    image: url
                  })
                )
              }
              type="file"
            />
          </Form.Field>
          <Container
            style={{ textAlign: 'right', marginTop: 20, marginRight: 0 }}
          >
            <Button
              color="black"
              onClick={() => setIsOpen(false)}
              style={{ marginLeft: '0.75em' }}
              type="button"
            >
              Cancel
            </Button>
            <Button
              content="Create"
              positive
              style={{ marginLeft: '0.75em' }}
              type="submit"
            />
          </Container>
        </Form>
      </ContentModal>
    </View>
  );
};

export default CreateTeamModal;
