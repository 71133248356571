import facepaint from 'facepaint';

const palette = {
  primary: {
    main: '#696969',
    light: '#C7CEDB',
    dark: '#3E3E3E',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#F3C20A',
    light: '#fff04d',
    dark: '#EFA00B',
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#FF220C',
    light: '#ff4e3c',
    dark: '#b21708'
  },
  warning: {
    main: '#EFA00B',
    light: '#f2b33b',
    dark: '#a77007'
  },
  success: {
    main: '#A7C957',
    light: '#b8d378',
    dark: '#748c3c'
  },
  typography: {
    fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
    fontSize: 16
  }
};

const mqBreakpoints = [800, 1000, 1340, 1920];

const mq = facepaint([
  `@media(min-width: ${mqBreakpoints[0]}px)`,
  `@media(min-width: ${mqBreakpoints[1]}px)`,
  `@media(min-width: ${mqBreakpoints[2]}px)`
]);

export { palette, mq, mqBreakpoints };
