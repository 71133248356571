import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Confirm } from 'semantic-ui-react';
import { css } from '@emotion/react';
import View from '../../common/View';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import Text from '../../common/Text';
import ResponsiveInput from '../../common/ResponsiveInput';

const AssociationDetails = ({ association, className }) => {
  const hasSetState = useRef(false);
  const { state, updateAssociation } = useContext(TeamManagementContext);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    domain: ''
  });

  useEffect(() => {
    if (association) {
      setFormState({
        name: association.name,
        domain: association.domain
      });
      hasSetState.current = true;
    }
  }, [association]);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <View className={className}>
      <Confirm
        aria-label="confirm"
        content="Are you sure you want to save changes?"
        onCancel={() => setShowEditConfirmation(false)}
        onConfirm={() => {
          updateAssociation(association.pkAssociation, {
            ...association,
            ...formState
          });
          setShowEditConfirmation(false);
        }}
        open={showEditConfirmation}
        role="dialog"
        size="small"
      />
      <Form
        aria-label="associationDetails"
        onSubmit={(e) => {
          e.preventDefault();
          setShowEditConfirmation(true);
        }}
        role="form"
      >
        <Form.Group widths="equal">
          <Form.Field>
            <ResponsiveInput
              focus
              icon={{
                name: 'pencil',
                color:
                  formState.name !== association.name ? 'yellow' : undefined
              }}
              label={{
                content: 'Name',
                color:
                  formState.name !== association.name ? 'yellow' : undefined
              }}
              name="name"
              onChange={handleInputChange}
              required
              value={formState.name}
            />
          </Form.Field>
          <Form.Field>
            <ResponsiveInput
              focus
              icon={{
                name: 'pencil',
                color:
                  formState.domain !== association.domain ? 'yellow' : undefined
              }}
              label={{
                content: 'Domain',
                color:
                  formState.domain !== association.domain ? 'yellow' : undefined
              }}
              name="domain"
              onChange={handleInputChange}
              required
              value={formState.domain}
            />
          </Form.Field>
        </Form.Group>
        {hasSetState.current &&
          (formState.domain !== association.domain ||
            formState.name !== association.name) && (
            <View
              css={{
                flex: 1,
                justifyContent: 'flex-end',
                flexDirection: 'row'
              }}
            >
              <Button
                aria-label="saveDetails"
                content="Save Changes"
                loading={state.updatedUser.loading}
                primary
                role="button"
                type="submit"
              />
              <Button
                aria-label="cancelSaveDetails"
                content="Cancel"
                css={css`
                  margin-left: 10px !important;
                `}
                onClick={() =>
                  setFormState({
                    name: association.name,
                    domain: association.domain
                  })
                }
                role="button"
                secondary
                type="button"
              />
            </View>
          )}
        <View css={{ flex: 1, textAlign: 'right', color: 'red', padding: 10 }}>
          <Text>{state.updatedUser.error}</Text>
        </View>
      </Form>
    </View>
  );
};

AssociationDetails.propTypes = {
  association: PropTypes.object,
  className: PropTypes.string
};

export default AssociationDetails;
