import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider, Group, Skeleton, Text } from '@mantine/core';
import { NumericFormat } from 'react-number-format';

const PaymentPriceItem = ({
  label,
  weight,
  value,
  valueLabel,
  color,
  isLoading,
  isDividerHidden
}) => (
  <>
    <Group style={{ flexWrap: 'nowrap', gap: 10, marginTop: 15 }}>
      <Text style={{ fontSize: 16 }} weight={weight ?? 'normal'}>
        {label}
      </Text>
      <Divider style={{ flex: 1 }} variant="dotted" />
      {isLoading ? (
        <Skeleton height={20} width={90} />
      ) : (
        <Text style={{ fontSize: 16, color }} weight={weight}>
          <NumericFormat
            decimalScale={2}
            displayType="text"
            fixedDecimalScale
            prefix="$"
            thousandSeparator
            value={value}
          />
          {valueLabel ? ` ${valueLabel}` : ''}
        </Text>
      )}
    </Group>
    {!isDividerHidden && <Divider />}
  </>
);

PaymentPriceItem.propTypes = {
  color: PropTypes.string,
  isDividerHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
  valueLabel: PropTypes.string,
  weight: PropTypes.number
};

export default PaymentPriceItem;
