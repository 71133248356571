import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Divider, Group, Progress, Skeleton, Stack, Text } from '@mantine/core';
import { format } from 'date-fns';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import PaymentPriceItem from '../../common/PaymentPriceItem';
import FinancialRegistrationTransactionList from '../../common/PaymentTransactionList';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import {
  currencyFormat,
  stripUtcDateFormatting
} from '../../../helpers/formatHelper';
import { PAYMENT_TYPES } from '../../../config/constants';

const MerchPackageOrderPaymentsModal = ({
  isOpen,
  onClose,
  regCartMerchPackage
}) => {
  const fetchedPkRegCartMerchPackage = useRef(null);
  const { state, fetchRegCartMerchPackageBalance } = useContext(
    RegistrationContext
  );
  const isLoading =
    !fetchedPkRegCartMerchPackage.current ||
    state.regCartMerchPackageBalance.loading;

  const { balance, merchPackage, regCartMerchPackagePayments } =
    state.regCartMerchPackageBalance.value || {};

  const isPaid = balance?.balanceInCents <= 0;
  const isRefunded = balance?.refundedAmountInCents > 0;

  useEffect(() => {
    if (
      isOpen &&
      fetchedPkRegCartMerchPackage.current !==
        regCartMerchPackage.pkRegCartMerchPackage
    ) {
      fetchRegCartMerchPackageBalance(
        regCartMerchPackage.pkRegCartMerchPackage,
        null,
        triggerNotification
      );
      fetchedPkRegCartMerchPackage.current =
        regCartMerchPackage.pkRegCartMerchPackage;
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={1100}
      title="Merch Order Payments"
    >
      <FormSection
        cancelTitle="Close"
        isSubmitHidden
        onCancel={onClose}
        style={{ gap: 20 }}
      >
        <Stack style={{ gap: 20 }}>
          <Divider />
          <Group
            style={{
              gap: 40,
              alignItems: 'start',
              flexDirection: 'row'
            }}
          >
            <Stack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
              <Text size="lg" style={{ color: 'dodgerblue' }} weight={700}>
                Payment Status
              </Text>
              <Divider size={2} />

              {isLoading ? (
                <Stack style={{ gap: 5 }}>
                  <Skeleton height={12} width="40%" />
                  <Skeleton height={8} width="100%" />
                </Stack>
              ) : isPaid ? (
                <Stack style={{ gap: 0 }}>
                  <Text style={{ fontSize: 16 }} weight={500}>
                    Paid in full (payments)
                  </Text>
                  <Progress value={100} />
                </Stack>
              ) : isRefunded ? (
                <Stack style={{ gap: 0 }}>
                  <Text style={{ fontSize: 16 }} weight={500}>
                    Refunded
                  </Text>
                  <Progress value={0} />
                </Stack>
              ) : (
                <Stack style={{ gap: 0 }}>
                  <Text style={{ fontSize: 16 }} weight={500}>
                    Waiting for payment
                  </Text>
                  <Progress
                    value={
                      (balance.amountPaidInCents / balance.totalInCents) * 100
                    }
                  />
                </Stack>
              )}
            </Stack>
            <Stack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
              <Text style={{ color: 'dodgerblue', fontSize: 18 }} weight={700}>
                Overview
              </Text>
              <Divider size={2} />
              {isLoading ? (
                <>
                  <PaymentPriceItem isLoading label="Subtotal" />
                  <PaymentPriceItem isLoading label="Transaction Total" />
                  <PaymentPriceItem isLoading label="Total Payments" />
                  <PaymentPriceItem isLoading label="Balance" />
                </>
              ) : (
                <>
                  <PaymentPriceItem
                    label="Wholesale Price"
                    value={merchPackage.wholesalePrice / 100}
                  />

                  <PaymentPriceItem
                    label="Association Split"
                    value={
                      merchPackage.regAssociationMerchPackages[0]
                        .associationSplit / 100
                    }
                  />

                  <PaymentPriceItem
                    label="SportsHeadz Split"
                    value={
                      (merchPackage.price -
                        merchPackage.wholesalePrice -
                        merchPackage.regAssociationMerchPackages[0]
                          .associationSplit) /
                      100
                    }
                  />

                  <PaymentPriceItem
                    label="Subtotal"
                    value={balance.subtotalInCents / 100}
                  />

                  <PaymentPriceItem
                    label="Transaction Total"
                    value={balance.totalInCents / 100}
                    weight={700}
                  />

                  <PaymentPriceItem
                    color="#37b24d"
                    label="Total Payments"
                    value={balance.amountPaidInCents / 100}
                  />

                  {balance.refundedAmountInCents > 0 && (
                    <PaymentPriceItem
                      color="red"
                      label="Refunded Amount"
                      value={balance.refundedAmountInCents / 100}
                    />
                  )}

                  <PaymentPriceItem
                    isDividerHidden
                    label="Balance"
                    value={
                      (balance.totalInCents -
                        balance.amountPaidInCents +
                        balance.refundedAmountInCents) /
                      100
                    }
                    weight={700}
                  />
                </>
              )}
            </Stack>
          </Group>
          <FinancialRegistrationTransactionList
            isLoading={isLoading}
            payments={
              regCartMerchPackagePayments?.map((p) => {
                const statusInfo =
                  p.fkRegFormSubmissionPaymentType === 5
                    ? {
                        label: 'Refunded',
                        color: 'red'
                      }
                    : {
                        label: 'Paid',
                        color: 'green'
                      };

                return {
                  key: p.pkRegCartMerchPackagePayment,
                  rows: [
                    {
                      key: 1,
                      label: statusInfo.label,
                      color: statusInfo.color,
                      weight: 500
                    },
                    {
                      key: 2,
                      label: format(
                        new Date(`${stripUtcDateFormatting(p.createdAt)}Z`),
                        'PP p'
                      )
                    },
                    { key: 3, label: 'Card' },
                    {
                      key: 4,
                      label: PAYMENT_TYPES.find(
                        (t) =>
                          t.value ===
                          p.fkRegFormSubmissionPaymentType.toString()
                      )?.label
                    },
                    {
                      key: 5,
                      label: currencyFormat(p.amount / 100),
                      textAlign: 'end',
                      color:
                        p.fkRegFormSubmissionPaymentType === 5 ? 'red' : 'green'
                    }
                  ]
                };
              }) ?? []
            }
          />
        </Stack>
      </FormSection>
    </ResponsiveModal>
  );
};

MerchPackageOrderPaymentsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regCartMerchPackage: PropTypes.object
};

export default MerchPackageOrderPaymentsModal;
