import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay, Stack } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import CustomSelect from '../../common/CustomSelect';
import { REG_PERMISSION_ENUM } from '../../../config/constants';

const AddUserRegAssociationAdmin = ({ isOpen, onClose, pkUser, onSuccess }) => {
  const hasFetched = useRef(false);
  const { state, fetchRegAssociations, addRegAssociationAdmin } = useContext(
    RegistrationContext
  );
  const [formState, setFormState] = useState({
    pkRegAssociation: '',
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        pkRegAssociation: '',
        loading: false
      });
      if (!hasFetched.current) {
        fetchRegAssociations();
        hasFetched.current = true;
      }
    }
  }, [isOpen]);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} title="Add Association">
      <Stack style={{ position: 'relative' }}>
        <LoadingOverlay
          overlayBlur={2}
          visible={!hasFetched.current || state.regAssociations.loading}
        />
        <FormSection
          cancelTitle="Cancel"
          isLoading={formState.loading}
          isSubmitDisabled={!formState.pkRegAssociation}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              error: '',
              loading: true
            });
            addRegAssociationAdmin(
              {
                fkUser: pkUser,
                fkRegAssociation: formState.pkRegAssociation,
                permissions: [
                  {
                    fkRegPermission: REG_PERMISSION_ENUM.SUPER_ADMIN,
                    regAssociationDivisions: []
                  }
                ]
              },
              onSuccess,
              () => {
                setFormState({
                  ...formState,
                  loading: false
                });
                triggerNotification();
              }
            );
          }}
          style={{ gap: 20 }}
          submitColor="blue"
          submitTitle="Add Association"
        >
          <CustomSelect
            clearable
            data={state.regAssociations.value
              .sort((a, b) =>
                a.association.name.localeCompare(b.association.name)
              )
              .map((a) => ({
                label: `${a.pkRegAssociation} - ${a.association.name}`,
                value: a.pkRegAssociation.toString(),
                image: a.logoImageUrl || a.association.image
              }))}
            disabled={formState.loading}
            label="Association"
            onChange={(value) =>
              setFormState({ ...formState, pkRegAssociation: value })
            }
            placeholder="Select an Association"
            required
            searchable
            sx={{ flex: 1 }}
            value={formState.pkRegAssociation}
          />
        </FormSection>
      </Stack>
    </ResponsiveModal>
  );
};

AddUserRegAssociationAdmin.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  pkUser: PropTypes.number
};

export default AddUserRegAssociationAdmin;
