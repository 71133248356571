import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Radio,
  Placeholder,
  Button,
  Confirm,
  Select
} from 'semantic-ui-react';
import format from 'date-fns/format';
import View from '../../common/View';
import ResponsiveInput from '../../common/ResponsiveInput';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';
import { PROVINCE_SELECT_OPTIONS } from '../../../config/constants';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import { mq } from '../../../config/theme';

const VenueDetails = ({ venue }) => {
  const { updateVenue } = useContext(LiveStreamContext);
  const hasVenueFormLoaded = useRef(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [editVenueFormState, setEditVenueFormState] = useState({
    creation_date: null,
    name: '',
    street: '',
    city: '',
    province: '',
    province_code: '',
    postal: '',
    country: '',
    country_code: '',
    phone: '',
    email: '',
    enabled: false,
    comingsoon: false
  });

  useEffect(() => {
    if (venue) {
      setEditVenueFormState({ ...venue });
      hasVenueFormLoaded.current = true;
    }
  }, [venue]);

  const hasFormValueChanged = (key) => editVenueFormState[key] !== venue[key];

  const hasUnsavedChanges = () => {
    for (const [key, value] of Object.entries(editVenueFormState)) {
      if (value !== venue[key]) {
        return true;
      }
    }
    return false;
  };

  const handleInputChange = (e) => {
    setEditVenueFormState({
      ...editVenueFormState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <View>
      <Confirm
        aria-label="confirm"
        content="Are you sure you want to save changes?"
        onCancel={() => setShowConfirmEdit(false)}
        onConfirm={() => {
          setShowConfirmEdit(false);
          updateVenue(venue.id, {
            ...editVenueFormState,
            countryCode: editVenueFormState.country_code,
            provinceCode: editVenueFormState.province_code,
            postalCode: editVenueFormState.postal,
            isEnabled: editVenueFormState.enabled,
            isComingSoon: editVenueFormState.comingsoon,
            imageUrl: venue.image_url
          });
        }}
        open={showConfirmEdit}
        role="dialog"
        size="small"
      />
      {venue && hasVenueFormLoaded.current ? (
        <Form
          aria-label="cameraDetails"
          onSubmit={(e) => {
            e.preventDefault();
            setShowConfirmEdit(true);
          }}
          role="form"
        >
          <Form.Group widths="equal">
            <Form.Field>
              <ResponsiveInput
                focus
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('name') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Name',
                  color: hasFormValueChanged('name') ? 'yellow' : undefined
                }}
                name="name"
                onChange={handleInputChange}
                required
                value={editVenueFormState.name}
              />
            </Form.Field>
            <Form.Field>
              <ResponsiveInput
                label={{ content: 'Created' }}
                value={format(
                  new Date(
                    `${stripUtcDateFormatting(
                      editVenueFormState.creation_date
                    )}Z`
                  ),
                  'p PP'
                )}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <ResponsiveInput
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('email') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Email',
                  color: hasFormValueChanged('email') ? 'yellow' : undefined
                }}
                name="email"
                onChange={handleInputChange}
                type="email"
                value={editVenueFormState.email ?? ''}
              />
            </Form.Field>
            <Form.Field>
              <ResponsiveInput
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('phone') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Phone',
                  color: hasFormValueChanged('phone') ? 'yellow' : undefined
                }}
                name="phone"
                onChange={handleInputChange}
                value={editVenueFormState.phone ?? ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <ResponsiveInput
              icon={{
                name: 'pencil',
                color: hasFormValueChanged('street') ? 'yellow' : undefined
              }}
              label={{
                content: 'Street',
                color: hasFormValueChanged('street') ? 'yellow' : undefined
              }}
              name="street"
              onChange={handleInputChange}
              value={editVenueFormState.street ?? ''}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <ResponsiveInput
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('postal') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Postal',
                  color: hasFormValueChanged('postal') ? 'yellow' : undefined
                }}
                name="postal"
                onChange={handleInputChange}
                value={editVenueFormState.postal ?? ''}
              />
            </Form.Field>
            <Form.Field>
              <ResponsiveInput
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('city') ? 'yellow' : undefined
                }}
                label={{
                  content: 'City',
                  color: hasFormValueChanged('city') ? 'yellow' : undefined
                }}
                name="city"
                onChange={handleInputChange}
                value={editVenueFormState.city ?? ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <ResponsiveInput
                label={{
                  content: 'Province',
                  color: hasFormValueChanged('province_code')
                    ? 'yellow'
                    : undefined
                }}
                name="provinceCode"
                value={editVenueFormState.province_code ?? ''}
              />
            </Form.Field>
            <Form.Field>
              <Select
                css={mq({ marginTop: [0, 20, 20, 0] })}
                onChange={(e, select) => {
                  const { key } = select.options.find(
                    (option) => option.value === select.value
                  );
                  setEditVenueFormState({
                    ...editVenueFormState,
                    province: select.value,
                    province_code: key
                  });
                }}
                options={PROVINCE_SELECT_OPTIONS}
                placeholder="Select a Province"
                value={editVenueFormState.province ?? ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <ResponsiveInput
                label={{
                  content: 'Country',
                  color: hasFormValueChanged('country_code')
                    ? 'yellow'
                    : undefined
                }}
                name="countryCode"
                value={editVenueFormState.country_code ?? ''}
              />
            </Form.Field>
            <Form.Field>
              <Select
                css={mq({ marginTop: [0, 20, 20, 0] })}
                onChange={(e, select) => {
                  const { key } = select.options.find(
                    (option) => option.value === select.value
                  );
                  setEditVenueFormState({
                    ...editVenueFormState,
                    country: select.value,
                    country_code: key
                  });
                }}
                options={[{ key: 'CA', value: 'Canada', text: 'Canada' }]}
                placeholder="Select a Country"
                value={editVenueFormState.country ?? ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Radio
                checked={editVenueFormState.enabled}
                label="Enabled"
                onChange={() =>
                  setEditVenueFormState({
                    ...editVenueFormState,
                    enabled: !editVenueFormState.enabled
                  })
                }
                style={{ margin: 10 }}
                toggle
              />
            </Form.Field>
            <Form.Field>
              <Radio
                checked={editVenueFormState.comingsoon}
                disabled={venue.comingsoon}
                label="Coming Soon"
                onChange={() =>
                  setEditVenueFormState({
                    ...editVenueFormState,
                    comingsoon: !editVenueFormState.comingsoon
                  })
                }
                style={{ margin: 10 }}
                toggle
              />
            </Form.Field>
          </Form.Group>
          {hasUnsavedChanges() && (
            <View
              css={{
                flex: 1,
                justifyContent: 'flex-end',
                flexDirection: 'row',
                paddingTop: 10
              }}
            >
              <Button
                aria-label="saveDetails"
                content="Save Changes"
                primary
                role="button"
                type="submit"
              />
              <Button
                aria-label="cancelSaveDetails"
                content="Cancel"
                onClick={() => setEditVenueFormState({ ...venue })}
                role="button"
                secondary
                style={{ marginLeft: 10 }}
                type="button"
              />
            </View>
          )}
        </Form>
      ) : (
        <Placeholder fluid>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
    </View>
  );
};

VenueDetails.propTypes = { venue: PropTypes.object };

export default VenueDetails;
