import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon, Image, Menu, Segment } from 'semantic-ui-react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import View from '../../common/View';
import { mq } from '../../../config/theme';
import VenueDetails from './VenueDetails';
import VenueStreamList from './VenueStreamList';
import StreamDetails from './StreamDetails';
import StreamEventSchedule from './StreamEventSchedule';
import { uploadFileToStorage } from '../../../helpers/awsHelper';

const CameraView = ({ className }) => {
  const { state, updateVenue } = useContext(LiveStreamContext);
  const { pkVenue } = useParams();
  const location = useLocation();
  const [isLoadingVenue, setLoadingVenue] = useState(true);
  const venue = state.venues.value.find((v) => v.id.toString() === pkVenue);
  const imageInputRef = useRef();

  useEffect(() => {
    if (!isLoadingVenue || state.venues.loading) {
      setLoadingVenue(true);
    }
  }, [pkVenue, state.venues.loading]);

  useEffect(() => {
    if (isLoadingVenue) {
      const venueFound =
        !state.venues.loading &&
        state.venues.value.some((v) => v.id.toString() === pkVenue);
      if (venueFound) {
        setLoadingVenue(false);
      }
    }
  }, [isLoadingVenue, state.venues.value]);

  return (
    <Segment
      aria-label="cameraView"
      className={className}
      css={mq({
        width: '100%',
        marginLeft: [null, null, '20px !important'],
        marginTop: [null, null, '34px !important'],
        minHeight: '50px'
      })}
      loading={isLoadingVenue || !venue || state.venues.loading}
      role="region"
    >
      {!isLoadingVenue && venue && !state.venues.loading ? (
        <View
          css={mq({
            flexDirection: ['column', 'row'],
            alignItems: ['center', 'flex-start']
          })}
        >
          <View
            css={mq({
              minWidth: '100px',
              maxWidth: '100px',
              maxHeight: '100px',
              marginRight: [null, 15]
            })}
          >
            <View css={{ position: 'relative' }}>
              <Image size="small" src={venue.image_url} />
              <Icon
                circular
                color="yellow"
                css={mq({
                  position: 'absolute',
                  bottom: [-10, -40],
                  right: [-40, -5],
                  cursor: 'pointer'
                })}
                inverted
                name="pencil"
                onClick={() => imageInputRef.current.click()}
                size="big"
              />
              <input
                ref={imageInputRef}
                accept="image/png, image/jpeg"
                data-testid="fileInput"
                hidden
                onChange={(e) =>
                  uploadFileToStorage(
                    venue.name.replace(/\s/g, ''),
                    e.target.files[0],
                    (url) => {
                      updateVenue(venue.id, {
                        ...venue,
                        countryCode: venue.country_code,
                        provinceCode: venue.province_code,
                        postalCode: venue.postal,
                        isEnabled: venue.enabled,
                        isComingSoon: venue.comingsoon,
                        imageUrl: url
                      });
                    }
                  )
                }
                type="file"
              />
            </View>
          </View>
          <View css={{ flexDirection: 'column', flex: 1, width: '100%' }}>
            <Menu
              aria-label="cameraNav"
              pointing
              role="tablist"
              secondary
              stackable
            >
              <Menu.Item
                active={location.pathname.startsWith(
                  `/cameras/${pkVenue}/venue`
                )}
                as={Link}
                css={{ justifyContent: 'center' }}
                name="Venue"
                to={`/cameras/${pkVenue}/venue`}
              />
              <Menu.Item
                active={location.pathname.startsWith(
                  `/cameras/${pkVenue}/streams`
                )}
                as={Link}
                css={{ justifyContent: 'center' }}
                name="Streams"
                to={`/cameras/${pkVenue}/streams`}
              />
              <Menu.Item
                active={location.pathname.startsWith(
                  `/cameras/${pkVenue}/schedule`
                )}
                as={Link}
                css={{ justifyContent: 'center' }}
                name="Schedules"
                to={
                  venue.cameras.length === 1
                    ? `/cameras/${pkVenue}/schedule/details/${venue.cameras[0].id}`
                    : `/cameras/${pkVenue}/schedule`
                }
              />
            </Menu>
            <Switch>
              <Route path={`/cameras/${pkVenue}/venue`}>
                <VenueDetails venue={venue} />
              </Route>
              <Route path={`/cameras/${pkVenue}/streams`}>
                <Switch>
                  <Route path={`/cameras/${pkVenue}/streams/list`}>
                    <VenueStreamList action="streams" venue={venue} />
                  </Route>
                  <Route path={`/cameras/${pkVenue}/streams/details/:pkCamera`}>
                    <StreamDetails venue={venue} />
                  </Route>
                  <Route path={`/cameras/${pkVenue}/streams/details/`}>
                    <Redirect to={`/cameras/${pkVenue}/streams/list`} />
                  </Route>
                  <Route path={`/cameras/${pkVenue}/streams`}>
                    <Redirect to={`/cameras/${pkVenue}/streams/list`} />
                  </Route>
                </Switch>
              </Route>
              <Route path={`/cameras/${pkVenue}/schedule`}>
                <Switch>
                  <Route
                    path={`/cameras/${pkVenue}/schedule/details/:pkCamera`}
                  >
                    <StreamEventSchedule venue={venue} />
                  </Route>
                  <Route path={`/cameras/${pkVenue}/schedule/list`}>
                    <VenueStreamList action="schedule" venue={venue} />
                  </Route>
                  <Route path={`/cameras/${pkVenue}/schedule/details`}>
                    <Redirect to={`/cameras/${pkVenue}/schedule/list`} />
                  </Route>
                  <Route path={`/cameras/${pkVenue}/schedule`}>
                    <Redirect to={`/cameras/${pkVenue}/schedule/list`} />
                  </Route>
                </Switch>
              </Route>
              <Route path={`/cameras/${pkVenue}`}>
                <Redirect to={`/cameras/${pkVenue}/venue`} />
              </Route>
            </Switch>
          </View>
        </View>
      ) : null}
    </Segment>
  );
};

CameraView.propTypes = { className: PropTypes.string };

export default CameraView;
