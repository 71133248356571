import React from 'react';
import { PropTypes } from 'prop-types';

import { Select, Image } from '@mantine/core';
import CustomSelectItem from './CustomSelectItem';

const CustomSelect = ({ data, value, ...rest }) => {
  const selectedItem = data.find((d) => d.value === value);

  return (
    <Select
      data={data}
      icon={
        selectedItem?.image && (
          <Image
            fit="contain"
            src={selectedItem.image}
            sx={{ margin: '0px 5px' }}
          />
        )
      }
      iconWidth={40}
      itemComponent={CustomSelectItem}
      value={value}
      {...rest}
    />
  );
};

CustomSelect.propTypes = {
  data: PropTypes.array,
  value: PropTypes.string
};

export default CustomSelect;
