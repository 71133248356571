import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Select } from 'semantic-ui-react';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import InputLabel from '../../common/InputLabel';
import ContentModal from '../../common/ContentModal';
import View from '../../common/View';

const AddVenueModal = ({ isOpen, onClose, tournamentPkVenues, onAdd }) => {
  const { state, fetchVenues } = useContext(LiveStreamContext);
  const [formState, setFormState] = useState({
    venue: null,
    error: ''
  });

  useEffect(() => {
    if (isOpen) {
      if (!state.venues.loading && state.venues.value.length === 0) {
        fetchVenues();
      }
      setFormState({
        venue: null,
        error: ''
      });
    }
  }, [isOpen]);

  return (
    <ContentModal
      actionContent="Add"
      error={formState.error}
      isOpen={isOpen}
      onAction={() => {
        if (!formState.venue) {
          setFormState({
            ...formState,
            error: 'Venue is required.'
          });
        }
        else {
          onAdd(formState.venue);
          onClose();
        }
      }}
      onClose={onClose}
      size="tiny"
      title="Add Venue"
    >
      <Form aria-label="addVenue" role="form">
        <Form.Field>
          <InputLabel>Venue</InputLabel>
          <View css={{ flexDirection: 'row', flex: 1, marginBottom: '10px' }}>
            <Select
              css={{ flex: 1 }}
              data-testid="selectVenue"
              loading={state.venues.loading}
              name="venue"
              onChange={(e, option) =>
                setFormState({
                  ...formState,
                  venue: state.venues.value.find((v) => v.id === option.value)
                })
              }
              options={[
                ...state.venues.value
                  .filter((v) => !tournamentPkVenues.includes(v.id))
                  .map((a) => ({
                    key: a.id,
                    value: a.id,
                    text: a.name
                  }))
              ]}
              placeholder="Select a venue"
              search
              value={formState.venue?.id ?? ''}
            />
            <Icon
              css={{ alignSelf: 'center', cursor: 'pointer' }}
              disabled={state.venues.loading}
              loading={state.venues.loading}
              name="refresh"
              onClick={() => {
                fetchVenues();
              }}
              style={{ margin: 10 }}
            />
          </View>
        </Form.Field>
      </Form>
    </ContentModal>
  );
};

AddVenueModal.propTypes = {
  isOpen: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  tournamentPkVenues: PropTypes.array
};

export default AddVenueModal;
