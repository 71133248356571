import React, { useContext, useEffect, useState } from 'react';
import { Divider } from 'semantic-ui-react';
import { Redirect, Route, Switch } from 'react-router';
import View from '../components/common/View';
import { Context as TeamManagementContext } from '../providers/TeamManagementProvider';
import UserView from '../components/content/UsersView/UserView';
import SearchBar from '../components/common/SearchBar';
import userPlaceholder from '../images/user_placeholder.png';

const UsersView = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(null);
  const { state, searchForUser, appendState } = useContext(
    TeamManagementContext
  );

  useEffect(() => {
    setSearchResults(
      state.userSearchResults.value
        .filter((s, index) => index < 7)
        .map((s) => ({
          title: s.name,
          key: s.pkUser,
          description: s.email,
          image: s.avatar ?? userPlaceholder,
          data: s
        }))
    );
  }, [state.userSearchResults.value]);

  return (
    <View>
      <View
        aria-label="usersSearch"
        css={{ flexDirection: 'row', marginTop: 5 }}
        role="searchbox"
      >
        <SearchBar
          loading={state.userSearchResults.loading}
          onClear={() => {
            appendState({ userSearchResults: { value: [], loading: false } });
          }}
          onResultSelect={(term) => setSelectedResult(term)}
          onSearch={(term) => searchForUser({ searchTerm: term })}
          placeholder="Search for user by Name or Email..."
          results={searchResults}
        />
      </View>
      {selectedResult != null && (
        <Redirect to={`/users/${selectedResult.pkUser}`} />
      )}
      <Switch>
        <Route path="/users/:id">
          <>
            <Divider style={{ marginTop: 25 }} />
            <UserView user={selectedResult} />
          </>
        </Route>
        <Route path="/">
          <Redirect to="/users" />
        </Route>
      </Switch>
    </View>
  );
};

UsersView.propTypes = {};

export default UsersView;
