import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { Card, Divider, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { CreditCard } from 'tabler-icons-react';
import { mq } from '../config/theme';
import StripePaymentsView from '../components/content/PaymentView/StripePaymentsView';

const PAYMENT_NAV = [
  {
    icon: <CreditCard color="black" height={20} width={20} />,
    label: 'Stripe Payments',
    href: '/payment/stripe',
    isSelected: (url) => url.startsWith('/payment/stripe')
  }
];

const PaymentView = () => {
  const location = useLocation();

  return (
    <Stack sx={{ flex: 1, gap: 0 }}>
      <Stack sx={{ flex: 1, gap: 10 }}>
        <Divider />
        <Group
          sx={mq({
            flex: 1,
            alignItems: 'start',
            flexDirection: ['column', 'column', 'row']
          })}
        >
          <Stack css={{ flex: 1, gap: 10, alignSelf: 'stretch' }}>
            <Card
              shadow="lg"
              sx={{ flex: 1, padding: 0, border: 'solid 1px lightgrey' }}
            >
              <ScrollArea
                offsetScrollbars
                style={{
                  flex: 1,
                  overflow: 'auto',
                  minHeight: '100%',
                  maxHeight: 450
                }}
                styles={{ viewport: { paddingBottom: 0, paddingRight: 0 } }}
              >
                <Stack
                  sx={{
                    flex: 1,
                    gap: 0
                  }}
                >
                  {PAYMENT_NAV.map((p) => {
                    const isSelected = p.isSelected(location.pathname);

                    return (
                      <Group
                        key={p.href}
                        component={Link}
                        onClick={() => {}}
                        sx={{
                          flex: 1,
                          padding: 10,
                          cursor: 'pointer',
                          flexWrap: 'nowrap',
                          textDecoration: 'none',
                          color: '#000',
                          gap: 10,
                          backgroundColor: isSelected ? '#f4f4f4' : 'none',
                          '&:hover': {
                            color: 'black',
                            backgroundColor: '#f4f4f4'
                          }
                        }}
                        to={p.href}
                      >
                        {p.icon}
                        <Stack sx={{ gap: 5, overflowWrap: 'anywhere' }}>
                          <Text
                            sx={{ fontSize: 16, lineHeight: '18px' }}
                            weight={isSelected ? 700 : 500}
                          >
                            {p.label}
                          </Text>
                        </Stack>
                      </Group>
                    );
                  })}
                </Stack>
              </ScrollArea>
            </Card>
          </Stack>
          <Stack
            sx={{
              flex: 2,
              alignSelf: 'stretch'
            }}
          >
            <Switch>
              <Route path="/payment/stripe">
                <StripePaymentsView />
              </Route>
              <Route path="/">
                <Redirect to="/payment/stripe" />
              </Route>
            </Switch>
          </Stack>
        </Group>
      </Stack>
    </Stack>
  );
};

PaymentView.propTypes = {};

export default PaymentView;
