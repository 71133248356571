import { css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Button, Header, Icon, List, Segment } from 'semantic-ui-react';
import ActionableListItem from '../../common/ActionableListItem';
import View from '../../common/View';
import Text from '../../common/Text';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';

const StreamEventList = ({
  title,
  events,
  isLoading,
  onRemoveEvent,
  onAddEvent
}) => (
  <View css={{ marginBottom: 15 }}>
    <View
      css={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Header
        as="h4"
        css={css`
          margin-bottom: 0px !important;
          margin-top: 0px !important;
        `}
      >
        {title} Events
      </Header>
      {onAddEvent && (
        <Button
          color="green"
          compact
          content="Create"
          icon={{ name: 'plus' }}
          labelPosition="right"
          onClick={onAddEvent}
        />
      )}
    </View>
    <Segment
      aria-label="eventList"
      css={{ minHeight: '50px' }}
      loading={isLoading}
      role="region"
    >
      {!isLoading && (
        <View
          css={{
            maxHeight: '325px',
            overflowY: 'auto'
          }}
        >
          {events && events.length > 0 ? (
            <List selection>
              {events.map((event, index, eventsArray) => {
                const eventDate = format(
                  new Date(`${stripUtcDateFormatting(event.title.startDate)}Z`),
                  'PP'
                );
                if (
                  index === 0 ||
                  (eventsArray[index - 1] &&
                    format(
                      new Date(
                        `${stripUtcDateFormatting(
                          eventsArray[index - 1].title.startDate
                        )}Z`
                      ),
                      'PP'
                    ) !== eventDate)
                ) {
                  return [
                    <Header
                      key={event.title.startDate}
                      as="h4"
                      css={css`
                        margin-bottom: 0px !important;
                        margin-top: 5px !important;
                      `}
                    >
                      {eventDate}
                    </Header>,
                    <ActionableListItem
                      key={event.title.pkScheduledEventTitle}
                      actionColor="red"
                      actionTitle="Remove"
                      description={`${format(
                        new Date(
                          `${stripUtcDateFormatting(event.title.startDate)}Z`
                        ),
                        'p'
                      )} - ${format(
                        new Date(
                          `${stripUtcDateFormatting(event.title.endDate)}Z`
                        ),
                        'p'
                      )} (${format(
                        new Date(
                          `${stripUtcDateFormatting(event.title.startDate)}`
                        ),
                        'p'
                      )} - ${format(
                        new Date(
                          `${stripUtcDateFormatting(event.title.endDate)}`
                        ),
                        'p'
                      )} UTC)`}
                      header={`${event.title.title} - ${event.title.eventType}`}
                      icon={(
                        <Icon circular fitted>
                          {event.title.fkTournament ? 'T' : 'S'}
                        </Icon>
                      )}
                      onAction={
                        onRemoveEvent ? () => onRemoveEvent(event) : null
                      }
                      onClick={() => {}}
                    />
                  ];
                }
                return (
                  <ActionableListItem
                    key={event.title.pkScheduledEventTitle}
                    actionColor="red"
                    actionTitle="Remove"
                    description={`${format(
                      new Date(
                        `${stripUtcDateFormatting(event.title.startDate)}Z`
                      ),
                      'p'
                    )} - ${format(
                      new Date(
                        `${stripUtcDateFormatting(event.title.endDate)}Z`
                      ),
                      'p'
                    )} (${format(
                      new Date(
                        `${stripUtcDateFormatting(event.title.startDate)}`
                      ),
                      'p'
                    )} - ${format(
                      new Date(
                        `${stripUtcDateFormatting(event.title.endDate)}`
                      ),
                      'p'
                    )} UTC)`}
                    header={`${event.title.title} - ${event.title.eventType}`}
                    icon={(
                      <Icon circular fitted>
                        {event.title.fkTournament ? 'T' : 'S'}
                      </Icon>
                    )}
                    onAction={onRemoveEvent ? () => onRemoveEvent(event) : null}
                    onClick={() => {}}
                  />
                );
              })}
            </List>
          ) : (
            <List selection>
              <View css={{ marginLeft: '5px' }}>
                <Text>No {title.toLowerCase()} events</Text>
              </View>
            </List>
          )}
        </View>
      )}
    </Segment>
  </View>
);

StreamEventList.propTypes = {
  events: PropTypes.array,
  isLoading: PropTypes.bool,
  onAddEvent: PropTypes.func,
  onRemoveEvent: PropTypes.func,
  title: PropTypes.string
};

export default StreamEventList;
