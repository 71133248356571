import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Group,
  Loader,
  Select,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import { format } from 'date-fns';
import { DatePicker } from '@mantine/dates';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import PaginationList from '../../common/PaginationList';
import ActionListItem from '../../common/ActionListItem';
import ListFilter from '../../common/ListFilter';
import MerchPackageOrderRefundModal from './MerchPackageOrderRefundModal';
import MerchPackageOrderDetailsModal from './MerchPackageOrderDetailsModal';
import MerchPackageOrderPaymentsModal from './MerchPackageOrderPaymentsModal';
import ViewRegCartOrderDetailsModal from './ViewRegCartOrderDetailsModal';
import {
  singularPluralFormat,
  stripUtcDateFormatting
} from '../../../helpers/formatHelper';
import { ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_LIST } from '../../../config/constants';
import MerchReportModal from './MerchReportModal';

const MerchPackageOrders = () => {
  const {
    state,
    fetchMerchPackageOrders,
    fetchRegCartMerchPackageFilters
  } = useContext(RegistrationContext);
  const [filterState, setFilterState] = useState({
    merchPackageUuid: null,
    fkRegAssociation: null,
    purchasedBefore: null,
    purchasedAfter: null,
    search: null,
    appliedFilters: [],
    pageIndex: 1
  });
  const [modalState, setModalState] = useState({
    isOpen: false,
    item: null,
    action: ''
  });
  const isLoading = state.regCartMerchPackages.loading;

  const merchPackageFilterOptions = (
    state.regCartMerchPackageFilters.value?.merchPackages || []
  ).reduce(
    (r, c) => {
      if (
        !r.merchPackages.find(
          (f) =>
            f.label.toLowerCase() === c.name.toLowerCase() && f.value === c.uuid
        )
      ) {
        r.merchPackages.push({ label: c.name, value: c.uuid });
      }

      const regAssociation = c.regAssociationMerchPackages[0]?.regAssociation;
      if (
        regAssociation &&
        !r.regAssociations.find(
          (f) => f.value === regAssociation.pkRegAssociation.toString()
        )
      ) {
        r.regAssociations.push({
          label: regAssociation.association.name,
          value: regAssociation.pkRegAssociation.toString()
        });
      }
      return r;
    },
    {
      regAssociations: [],
      merchPackages: []
    }
  );

  useEffect(() => {
    fetchRegCartMerchPackageFilters();
  }, []);

  useEffect(() => {
    const filters = filterState.appliedFilters.reduce(
      (r, c) => ({
        ...r,
        [c.variable]: c.value
      }),
      {}
    );
    fetchMerchPackageOrders(filters);
  }, [filterState.appliedFilters]);

  return (
    <Stack style={{ flex: 1 }}>
      <Stack style={{ flex: 1, gap: 5 }}>
        <Group
          style={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'end'
          }}
        >
          <Text style={{ fontSize: 18, lineHeight: '18px' }} weight={500}>
            Merch Orders
          </Text>
          {isLoading ? (
            <Loader color="dark" size={20} />
          ) : (
            <Text style={{ fontSize: 14, color: 'grey', fontWeight: 500 }}>
              {singularPluralFormat(
                state.regCartMerchPackages.value.length,
                'record',
                'records'
              )}
            </Text>
          )}
        </Group>

        <Group style={{ flex: 1, justifyContent: 'space-between' }}>
          <ListFilter
            appliedFilters={filterState.appliedFilters}
            onApply={() => {
              const regAssociationOption = merchPackageFilterOptions.regAssociations.find(
                (a) => a.value === filterState.fkRegAssociation
              );
              const merchPackageOption = merchPackageFilterOptions.merchPackages.find(
                (a) => a.value === filterState.merchPackageUuid
              );
              const analyticsCtaOption = ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_LIST.find(
                (a) => a.value === filterState.analyticsCta
              );
              setFilterState({
                ...filterState,
                pageIndex: 1,
                appliedFilters: [
                  {
                    variable: 'merchPackageUuid',
                    label: merchPackageOption?.label,
                    value: filterState.merchPackageUuid
                  },
                  {
                    variable: 'fkRegAssociation',
                    label: regAssociationOption?.label,
                    value: filterState.fkRegAssociation
                  },
                  {
                    variable: 'purchasedBefore',
                    label: filterState.purchasedBefore
                      ? `Purchased Before - ${format(
                          filterState.purchasedBefore,
                          'M/dd/yyyy'
                        )}`
                      : null,
                    value: filterState.purchasedBefore
                  },
                  {
                    variable: 'analyticsCta',
                    label: analyticsCtaOption?.label,
                    value: filterState.analyticsCta
                  },
                  {
                    variable: 'purchasedAfter',
                    label: filterState.purchasedAfter
                      ? `Purchased After - ${format(
                          filterState.purchasedAfter,
                          'M/dd/yyyy'
                        )}`
                      : null,
                    value: filterState.purchasedAfter
                  },
                  {
                    variable: 'search',
                    label: filterState.search,
                    value: filterState.search
                  }
                ].filter((f) => f.value)
              });
            }}
            onClear={() =>
              setFilterState({
                merchPackageUuid: null,
                fkRegAssociation: null,
                purchasedBefore: null,
                purchasedAfter: null,
                search: null,
                appliedFilters: [],
                pageIndex: 1
              })
            }
            onRemoveFilter={(filter) =>
              setFilterState({
                ...filterState,
                [filter.variable]: '',
                appliedFilters: [
                  ...filterState.appliedFilters.filter(
                    (f) => f.variable !== filter.variable
                  )
                ],
                pageIndex: 1
              })
            }
          >
            <Select
              clearable
              data={merchPackageFilterOptions.regAssociations}
              label="Association"
              onChange={(value) =>
                setFilterState({
                  ...filterState,
                  fkRegAssociation: value
                })
              }
              searchable
              value={filterState.fkRegAssociation ?? ''}
            />
            <Select
              clearable
              data={merchPackageFilterOptions.merchPackages}
              label="Merch Package"
              onChange={(value) =>
                setFilterState({
                  ...filterState,
                  merchPackageUuid: value
                })
              }
              searchable
              value={filterState.merchPackageUuid ?? ''}
            />
            <DatePicker
              label="Purchased After"
              onChange={(value) => {
                const dateValue = value
                  ? new Date(
                      value.getUTCFullYear(),
                      value.getUTCMonth(),
                      value.getUTCDate(),
                      0,
                      0,
                      0
                    )
                  : null;
                setFilterState({
                  ...filterState,
                  purchasedAfter: dateValue
                });
              }}
              value={filterState.purchasedAfter}
            />
            <DatePicker
              label="Purchased Before"
              onChange={(value) => {
                const dateValue = value
                  ? new Date(
                      value.getUTCFullYear(),
                      value.getUTCMonth(),
                      value.getUTCDate(),
                      0,
                      0,
                      0
                    )
                  : null;
                setFilterState({
                  ...filterState,
                  purchasedBefore: dateValue
                });
              }}
              value={filterState.purchasedBefore}
            />
            <Select
              clearable
              data={ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_LIST}
              label="Call to Action"
              onChange={(value) =>
                setFilterState({
                  ...filterState,
                  analyticsCta: value
                })
              }
              searchable
              value={filterState.analyticsCta ?? ''}
            />
            <TextInput
              label="User Search"
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  search: e.target.value
                })
              }
              value={filterState.search ?? ''}
            />
          </ListFilter>

          <Button
            compact
            onClick={() =>
              setModalState({
                ...modalState,
                isOpen: true,
                item: null,
                action: 'GENERATE-REPORT'
              })
            }
            size="xs"
          >
            Generate Report
          </Button>
        </Group>

        <PaginationList
          emptyMessage="No orders"
          isLoading={isLoading}
          items={state.regCartMerchPackages.value
            .sort((a, b) =>
              a.merchPackage.name.localeCompare(b.merchPackage.name)
            )
            .map((a) => {
              const isFullyRefunded =
                a.regCartMerchPackagePayments.reduce(
                  (r, c) =>
                    c.fkRegFormSubmissionPaymentType === 5
                      ? r - (c.amount > 0 ? c.amount : c.amount * -1)
                      : r + c.amount,
                  0
                ) === 0;
              const isPartiallyRefunded = a.regCartMerchPackagePayments.some(
                (p) => p.fkRegFormSubmissionPaymentType === 5
              );

              const statusInfo =
                a.regCartMerchPackagePayments.length === 0
                  ? {
                      label: 'Unpaid',
                      color: 'blue'
                    }
                  : !isPartiallyRefunded
                  ? {
                      label: 'Paid',
                      color: 'green'
                    }
                  : {
                      label: isFullyRefunded
                        ? 'Refunded'
                        : 'Partially Refunded',
                      color: 'red'
                    };

              return (
                <ActionListItem
                  key={a.pkRegCartMerchPackage}
                  actions={[
                    {
                      label: 'View Details',
                      value: 'VIEW'
                    },
                    {
                      label: 'View Cart',
                      value: 'VIEW-CART'
                    },
                    {
                      label: 'View Payments',
                      value: 'PAYMENTS'
                    },
                    ...(isFullyRefunded
                      ? []
                      : [
                          {
                            label: 'Refund',
                            value: 'REFUND'
                          }
                        ])
                  ]}
                  alwaysShowActions
                  description={`${a.regCart.user.name}`}
                  extraDescriptions={[
                    ...(a.regCartMerchPackagePayments[0]
                      ? [
                          {
                            label: `Purchased ${format(
                              new Date(
                                `${stripUtcDateFormatting(
                                  a.regCartMerchPackagePayments[0].createdAt
                                )}Z`
                              ),
                              'M/dd/yyyy'
                            )}`
                          }
                        ]
                      : []),
                    {
                      label: statusInfo.label,
                      color: statusInfo.color
                    }
                  ]}
                  header={a.merchPackage.name}
                  onAction={(action) =>
                    setModalState({
                      ...modalState,
                      isOpen: true,
                      item: a,
                      action
                    })
                  }
                />
              );
            })}
          LoadingComponent={ActionListItem}
          onPageChange={(pageIndex) =>
            setFilterState({
              ...filterState,
              pageIndex
            })
          }
          pageIndex={filterState.pageIndex}
        />
      </Stack>

      <MerchPackageOrderRefundModal
        isOpen={modalState.isOpen && modalState.action === 'REFUND'}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
        onRefundComplete={() => {
          setModalState({
            ...modalState,
            isOpen: false
          });
          fetchMerchPackageOrders();
        }}
        regCartMerchPackage={modalState.item}
      />

      <MerchPackageOrderDetailsModal
        isOpen={modalState.isOpen && modalState.action === 'VIEW'}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
        regCartMerchPackage={modalState.item}
      />

      <ViewRegCartOrderDetailsModal
        isOpen={modalState.isOpen && modalState.action === 'VIEW-CART'}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
        pkRegCart={modalState.item?.regCart.pkRegCart}
      />

      <MerchPackageOrderPaymentsModal
        isOpen={modalState.isOpen && modalState.action === 'PAYMENTS'}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
        regCartMerchPackage={modalState.item}
      />

      <MerchReportModal
        filterState={filterState}
        isOpen={modalState.isOpen && modalState.action === 'GENERATE-REPORT'}
        merchPackageFilterOptions={merchPackageFilterOptions}
        onClose={() =>
          setModalState({
            ...modalState,
            isOpen: false
          })
        }
      />
    </Stack>
  );
};

export default MerchPackageOrders;
