import Storage from '@aws-amplify/storage';
import { S3_PUBLIC_URL } from '../config/constants';
import { debug } from './Logger';

const uploadFileToStorage = async (
  identifier,
  file,
  onSuccess,
  config = {}
) => {
  try {
    const extension = file.name.split('.').pop();
    const fileName = `${identifier}${Math.round(
      new Date().getTime() / 1000
    )}.${extension}`;
    const uploadData = await Storage.put(fileName, file, config);
    debug(uploadData);
    if (uploadData) {
      onSuccess(`${S3_PUBLIC_URL}${uploadData.key}`);
    }
  } catch (err) {
    console.error('Error uploading file:', err);
  }
};

const fileToBase64String = (file, callback) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    callback(event.target.result);
  };

  reader.onerror = (error) => {
    console.log('Error: ', error);
  };

  reader.readAsDataURL(file);
};

export { uploadFileToStorage, fileToBase64String };
