import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Checkbox, Stack, Text } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';

const SendOrderReceiptModal = ({
  isOpen,
  onClose,
  pkRegCarts,
  pkRegFormSubmissions
}) => {
  const { sendAdminRegFormSubmissionOrderReceipt } = useContext(
    RegistrationContext
  );
  const [formState, setFormState] = useState({
    sendCopy: false,
    sendToRegistrant: false,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        sendCopy: false,
        sendToRegistrant: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} title="Email Receipt">
      <FormSection
        isLoading={formState.isLoading}
        isSubmitDisabled={!formState.sendCopy && !formState.sendToRegistrant}
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            isLoading: true
          });
          sendAdminRegFormSubmissionOrderReceipt(
            [
              ...(pkRegCarts
                ? pkRegCarts.map((entityId) => ({
                    entityId,
                    entityType: 'regCart',
                    sendCopy: formState.sendCopy,
                    sendToRegistrant: formState.sendToRegistrant
                  }))
                : []),
              ...(pkRegFormSubmissions
                ? pkRegFormSubmissions.map((entityId) => ({
                    entityId,
                    entityType: 'regFormSubmission',
                    sendCopy: formState.sendCopy,
                    sendToRegistrant: formState.sendToRegistrant
                  }))
                : [])
            ],
            () => {
              triggerNotification('Receipt Sent!', 'Success', 'green');
              onClose();
            },
            (e) => {
              triggerNotification(e);
              setFormState({
                ...formState,
                isLoading: false
              });
            }
          );
        }}
        submitTitle="Send Receipt"
      >
        <Stack style={{ gap: 10 }}>
          <Text weight={500}>Select who to send the receipt to;</Text>
          <Checkbox
            disabled={formState.isLoading}
            label="Registrants mailbox"
            onChange={(e) =>
              setFormState({
                ...formState,
                sendToRegistrant: e.target.checked
              })
            }
            value={formState.sendToRegistrant}
          />
          <Checkbox
            disabled={formState.isLoading}
            label="Your own mailbox"
            onChange={(e) =>
              setFormState({
                ...formState,
                sendCopy: e.target.checked
              })
            }
            value={formState.sendCopy}
          />
        </Stack>
      </FormSection>
    </ResponsiveModal>
  );
};

SendOrderReceiptModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegCarts: PropTypes.array,
  pkRegFormSubmissions: PropTypes.array
};
export default SendOrderReceiptModal;
