import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Text } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import SearchBar from '../../common/SearchBar';
import userPlaceholder from '../../../images/user_placeholder.png';
import { REG_PERMISSION_ENUM } from '../../../config/constants';

const RegAssociationAdminModal = ({
  isOpen,
  onClose,
  regAssociation,
  regAssociationAdmin,
  action,
  onSuccess
}) => {
  const { state, searchForUser, appendState } = useContext(
    TeamManagementContext
  );
  const { addRegAssociationAdmin, deleteRegAssociationAdmin } = useContext(
    RegistrationContext
  );
  const [searchResults, setSearchResults] = useState([]);
  const [formState, setFormState] = useState({
    pkUser: '',
    error: '',
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        selectedUser: null,
        pkUser: regAssociationAdmin?.user.pkUser ?? '',
        error: '',
        loading: false
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setSearchResults(
      state.userSearchResults.value
        .filter((s, index) => index < 7)
        .map((s) => ({
          title: s.name,
          key: s.pkUser,
          description: s.email,
          image: s.avatar ?? userPlaceholder,
          data: s
        }))
    );
  }, [state.userSearchResults.value]);

  const onError = (errorMessage) => {
    setFormState({
      ...formState,
      error: errorMessage,
      loading: false
    });
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        action === 'RESTORE'
          ? 'Restore Admin'
          : action === 'REMOVE'
          ? 'Remove Admin'
          : 'Add Admin'
      }
    >
      <FormSection
        cancelTitle="Cancel"
        error={formState.error}
        isLoading={formState.loading}
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            error: '',
            loading: true
          });
          if (!regAssociationAdmin || action === 'RESTORE') {
            if (!formState.pkUser) {
              setFormState({
                ...formState,
                error: 'Please select a user.',
                loading: false
              });
            }
            else {
              addRegAssociationAdmin(
                {
                  fkUser: formState.pkUser,
                  fkRegAssociation: regAssociation.pkRegAssociation,
                  permissions: [
                    {
                      fkRegPermission: REG_PERMISSION_ENUM.SUPER_ADMIN,
                      regAssociationDivisions: []
                    }
                  ]
                },
                onSuccess,
                onError
              );
            }
          }
          else {
            deleteRegAssociationAdmin(
              regAssociationAdmin.pkRegAssociationAdmin,
              onSuccess,
              onError
            );
          }
        }}
        submitColor={action === 'REMOVE' ? 'red' : 'blue'}
        submitTitle={
          action === 'RESTORE'
            ? 'Restore'
            : action === 'REMOVE'
            ? 'Remove'
            : 'Add'
        }
      >
        <Stack sx={{ marginTop: 20, gap: 40 }}>
          {!regAssociationAdmin ? (
            <>
              <SearchBar
                loading={state.userSearchResults.loading}
                onClear={() =>
                  appendState({
                    userSearchResults: { value: [], loading: false }
                  })
                }
                onResultSelect={(user) =>
                  setFormState({
                    ...formState,
                    selectedUser: user,
                    pkUser: user.pkUser,
                    error: ''
                  })
                }
                onSearch={(term) => searchForUser({ searchTerm: term })}
                placeholder="Find the user by name/email"
                results={searchResults}
              />
              {formState.selectedUser && (
                <Text sx={{ textAlign: 'center', fontSize: 16 }}>
                  Are you sure you want to add{' '}
                  <b>{formState.selectedUser.name}</b> to{' '}
                  <b>{regAssociation.association.name}</b>?
                </Text>
              )}
            </>
          ) : action === 'RESTORE' ? (
            <Text sx={{ textAlign: 'center', fontSize: 16 }}>
              Are you sure you want to restore{' '}
              <b>{regAssociationAdmin.user.name}</b> to{' '}
              <b>{regAssociation.association.name}</b>?
            </Text>
          ) : (
            <Text sx={{ textAlign: 'center', fontSize: 16 }}>
              Are you sure you want to remove{' '}
              <b>{regAssociationAdmin.user.name}</b> from{' '}
              <b>{regAssociation.association.name}</b>?
            </Text>
          )}
        </Stack>
      </FormSection>
    </ResponsiveModal>
  );
};

RegAssociationAdminModal.propTypes = {
  action: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationAdmin: PropTypes.object
};

export default RegAssociationAdminModal;
