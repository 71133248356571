import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';

import { Group, Stack, Text, Avatar } from '@mantine/core';

const CustomSelectItem = forwardRef(
  ({ image, color, label, description, ...others }, ref) => (
    <Stack ref={ref} {...others}>
      <Group noWrap>
        {image && <Avatar src={image} />}

        <Group noWrap sx={{ gap: 10 }}>
          {color && (
            <Stack
              sx={{
                width: 15,
                height: 15,
                borderRadius: 15,
                backgroundColor: color,
                border: color === 'white' ? 'solid 1px black' : 'none'
              }}
            />
          )}
          <Stack sx={{ gap: 0 }}>
            <Text size="sm">{label}</Text>
            {description && <Text size="xs">{description}</Text>}
          </Stack>
        </Group>
      </Group>
    </Stack>
  )
);

CustomSelectItem.propTypes = {
  color: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  label: PropTypes.string
};

export default CustomSelectItem;
