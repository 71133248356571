import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Image, Menu, Placeholder, Segment } from 'semantic-ui-react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import View from '../../common/View';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { mq } from '../../../config/theme';
import TeamDetails from './TeamDetails';
import TeamMembers from './TeamMembers';
import TeamEvents from './TeamEvents';
import { uploadFileToStorage } from '../../../helpers/awsHelper';

const TeamView = ({ className }) => {
  const hasFetched = useRef(false);
  const { pkTeam } = useParams();
  const location = useLocation();
  const { state, fetchTeam, fetchAssociations, updateTeam } = useContext(
    TeamManagementContext
  );
  const imageInputRef = useRef();
  const isLoading =
    !hasFetched.current || !state.team.value || state.team.loading;

  useEffect(async () => {
    await fetchAssociations();
    fetchTeam(pkTeam);
    hasFetched.current = true;
  }, [pkTeam]);

  return (
    <Segment
      aria-label="teamView"
      css={css`
        margin-top: 10px !important;
      `}
      loading={isLoading}
      role="region"
    >
      {!isLoading ? (
        <View
          className={className}
          css={mq({
            flexDirection: ['column', 'row'],
            alignItems: ['center', 'flex-start']
          })}
        >
          <View css={{ position: 'relative' }}>
            <Image
              avatar
              bordered
              circular
              css={{ fontSize: 80, minWidth: '2em', objectFit: 'contain' }}
              rounded
              src={state.team.value.team.image}
            />
            <Icon
              circular
              color="yellow"
              css={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                cursor: 'pointer'
              }}
              inverted
              name="pencil"
              onClick={() => imageInputRef.current.click()}
              size="big"
            />
            <input
              ref={imageInputRef}
              accept="image/png, image/jpeg"
              data-testid="fileInput"
              hidden
              onChange={(e) =>
                uploadFileToStorage(
                  `team-${state.team.value.team.pkTeam}`,
                  e.target.files[0],
                  (url) =>
                    updateTeam(state.team.value.team.pkTeam, {
                      ...state.team.value.team,
                      fkAssociation:
                        state.team.value.team.association?.pkAssociation,
                      mbswId: state.team.value.team.mbswTeamId,
                      image: url
                    })
                )
              }
              type="file"
            />
          </View>
          <View
            css={mq({ flexDirection: 'column', flex: 1, marginLeft: [0, 10] })}
          >
            <Menu
              aria-label="teamNav"
              pointing
              role="tablist"
              secondary
              stackable
            >
              <Menu.Item
                active={location.pathname.startsWith(
                  `/team-management/teams/${pkTeam}/details`
                )}
                as={Link}
                name="Details"
                to={`/team-management/teams/${pkTeam}/details`}
              />
              <Menu.Item
                active={location.pathname.startsWith(
                  `/team-management/teams/${pkTeam}/members`
                )}
                as={Link}
                name="members"
                to={`/team-management/teams/${pkTeam}/members`}
              />
              <Menu.Item
                active={location.pathname.startsWith(
                  `/team-management/teams/${pkTeam}/events`
                )}
                as={Link}
                name="Events"
                to={`/team-management/teams/${pkTeam}/events`}
              />
            </Menu>
            <Switch>
              <Route path={`/team-management/teams/${pkTeam}/details`}>
                <TeamDetails />
              </Route>
              <Route path={`/team-management/teams/${pkTeam}/members`}>
                <TeamMembers />
              </Route>
              <Route path={`/team-management/teams/${pkTeam}/events`}>
                <TeamEvents />
              </Route>
              <Route path={`/team-management/teams/${pkTeam}`}>
                <Redirect to={`/team-management/teams/${pkTeam}/details`} />
              </Route>
            </Switch>
          </View>
        </View>
      ) : (
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
    </Segment>
  );
};

TeamView.propTypes = { className: PropTypes.string };

export default TeamView;
