import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Group,
  Loader,
  Stack,
  Text,
  Switch
} from '@mantine/core';
import { Image } from 'semantic-ui-react';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import CustomSelect from '../../common/CustomSelect';
import { mq } from '../../../config/theme';
import DeleteItemView from '../../common/DeleteItemView';
import ResponsiveModal from '../../common/ResponsiveModal';

const RegAssociationSettingsView = ({ regAssociation }) => {
  const hasFetched = useRef(false);
  const {
    rebaseRegAssociation,
    deleteRegAssociation,
    fetchRegAssociations
  } = useContext(RegistrationContext);
  const { state: teamManagementState, fetchAssociations } = useContext(
    TeamManagementContext
  );
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: '',
    item: null
  });
  const [formState, setFormState] = useState({
    fkAssociation: null,
    merchEnabled: false,
    associationSearchTerm: '',
    hasUnsavedChanges: false,
    isLoading: false,
    error: ''
  });
  const loadingAssociations =
    !hasFetched || teamManagementState.associations.loading;
  const associationOptions =
    !loadingAssociations && !!formState.associationSearchTerm
      ? teamManagementState.associations.value
          .filter(
            (a) =>
              !formState.associationSearchTerm ||
              a.name
                .toLowerCase()
                .includes(formState.associationSearchTerm.toLowerCase()) ||
              a.domain
                .toLowerCase()
                .includes(formState.associationSearchTerm.toLowerCase())
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((a) => ({
            label: a.name,
            value: a.pkAssociation.toString(),
            image: a.image
          }))
      : [
          regAssociation?.association && {
            label: regAssociation.association.name,
            value: regAssociation.association.pkAssociation.toString(),
            image: regAssociation.association.image
          }
        ];
  const selectedAssociation = !loadingAssociations
    ? teamManagementState.associations.value.find(
        (a) => a.pkAssociation.toString() === formState.fkAssociation
      )
    : regAssociation?.association;

  useEffect(() => {
    fetchAssociations();
    hasFetched.current = true;
  }, []);

  useEffect(() => {
    if (regAssociation) {
      setFormState({
        fkAssociation: regAssociation.association?.pkAssociation.toString(),
        merchEnabled: regAssociation.merchEnabled,
        associationSearchTerm: '',
        hasUnsavedChanges: false,
        isLoading: false,
        error: ''
      });
    }
  }, [regAssociation]);

  return (
    <Stack sx={{ gap: 20 }}>
      <Stack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();

          setFormState({
            ...formState,
            isLoading: true
          });
          rebaseRegAssociation(
            regAssociation.pkRegAssociation,
            formState,
            () => {
              setFormState({
                ...formState,
                isLoading: false,
                hasUnsavedChanges: false
              });
            },
            (error) => {
              setFormState({
                ...formState,
                isLoading: false,
                error
              });
            }
          );
        }}
        sx={{ gap: 10 }}
      >
        <Stack sx={{ gap: 10 }}>
          <Group sx={mq({ flex: 1 })}>
            <CustomSelect
              clearable
              data={associationOptions}
              disabled={formState.isLoading}
              icon={
                loadingAssociations ? (
                  <Loader color="dark" size="xs" />
                ) : selectedAssociation ? (
                  <Image
                    fit="contain"
                    src={selectedAssociation.image}
                    sx={{ margin: '0px 5px' }}
                  />
                ) : null
              }
              label="Association"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  fkAssociation: value,
                  hasUnsavedChanges: true
                })
              }
              onSearchChange={(value) =>
                setFormState({
                  ...formState,
                  associationSearchTerm: value
                })
              }
              placeholder="Search for association"
              required
              searchable
              sx={{ flex: 1 }}
              value={formState.fkAssociation}
            />
            <Stack sx={{ flex: 1 }}>
              <Switch
                checked={formState.merchEnabled}
                label={
                  formState.merchEnabled ? 'Merch Enabled' : 'Merch Disabled'
                }
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    merchEnabled: e.currentTarget.checked,
                    hasUnsavedChanges: true
                  })
                }
                style={{ fontWeight: 500 }}
              />
            </Stack>
          </Group>
        </Stack>

        <Group style={{ justifyContent: 'space-between' }}>
          <Group>
            <Button
              color={regAssociation.deleted ? 'Reinstate' : 'red'}
              disabled={formState.isLoading}
              onClick={() =>
                setModalState({
                  ...modalState,
                  isOpen: true,
                  action: regAssociation.deleted ? 'reinstate' : 'delete',
                  item: regAssociation
                })
              }
              variant={regAssociation.deleted ? 'outline' : 'filled'}
            >
              {regAssociation.deleted
                ? 'Reinstate Association'
                : 'Delete Association'}
            </Button>
          </Group>
          {formState.hasUnsavedChanges && (
            <Group sx={{ justifyContent: 'end' }}>
              <Button
                color="dark"
                compact
                disabled={formState.isLoading}
                onClick={() =>
                  setFormState({
                    fkAssociation: regAssociation.association?.pkAssociation.toString(),
                    merchEnabled: regAssociation.merchEnabled,
                    associationSearchTerm: '',
                    hasUnsavedChanges: false,
                    isLoading: false,
                    error: ''
                  })
                }
                type="button"
              >
                Cancel
              </Button>
              <Button compact loading={formState.isLoading} type="submit">
                Save Changes
              </Button>
            </Group>
          )}
        </Group>

        {formState.error && (
          <Alert color="red" variant="outline">
            <Text weight={500}>{formState.error}</Text>
          </Alert>
        )}
      </Stack>

      <ResponsiveModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ ...modalState, isOpen: false })}
        title={
          modalState.action === 'reinstate'
            ? 'Reinstate Association'
            : 'Delete Association'
        }
      >
        <DeleteItemView
          isLoading={formState.isLoading}
          itemLabel="Association"
          onCancel={() => setModalState({ ...modalState, isOpen: false })}
          onDelete={() => {
            setFormState({
              ...formState,
              isLoading: true
            });

            if (modalState.action === 'reinstate') {
              rebaseRegAssociation(
                regAssociation.pkRegAssociation,
                { deleted: false },
                () => {
                  fetchRegAssociations();
                  setModalState({ ...modalState, isOpen: false });
                },
                (error) => {
                  setFormState({
                    ...formState,
                    isLoading: false,
                    error
                  });
                  setModalState({ ...modalState, isOpen: false });
                }
              );
            }
            else {
              deleteRegAssociation(
                regAssociation.pkRegAssociation,
                () => {
                  fetchRegAssociations();
                  setModalState({ ...modalState, isOpen: false });
                },
                (error) => {
                  setFormState({
                    ...formState,
                    isLoading: false,
                    error
                  });
                  setModalState({ ...modalState, isOpen: false });
                }
              );
            }
          }}
          reinstate={modalState.action === 'reinstate'}
          warnings={[
            'The ability to publicly view the association on the reigstration site.'
          ]}
        />
      </ResponsiveModal>
    </Stack>
  );
};

RegAssociationSettingsView.propTypes = { regAssociation: PropTypes.object };

export default RegAssociationSettingsView;
