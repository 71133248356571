import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  Group,
  Loader,
  RingProgress,
  Stack,
  Text
} from '@mantine/core';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Check, Send, X } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import PaginationList from '../../common/PaginationList';
import ActionListItem from '../../common/ActionListItem';
import {
  currencyFormat,
  singularPluralFormat,
  stripUtcDateFormatting
} from '../../../helpers/formatHelper';

const FixRegCartCheckoutSessionsModal = ({
  isOpen,
  onClose,
  pkRegAssociation
}) => {
  const {
    state,
    fetchRegCartsWithMissingTransfers,
    fetchRegCartsWithMissingTransfersSummary,
    completeRegCartCheckoutSessions
  } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    selectedPkRegCarts: [],
    confirmedSelectedPkRegCarts: [],
    pageIndex: 1,
    submitState: {
      loading: false,
      cancel: false,
      index: 0,
      completedItems: []
    }
  });

  const filteredRegCartsMissingTransfers = state.regCartsMissingTransfers.value
    .filter(
      (c) =>
        c.regAssociation.pkRegAssociation.toString() ===
        pkRegAssociation?.toString()
    )
    .sort(
      (a, b) =>
        new Date(a.regCartHistory.cart.lineItems[0].purchaseDate) -
        new Date(b.regCartHistory.cart.lineItems[0].purchaseDate)
    );

  const allCartsSelected = filteredRegCartsMissingTransfers.every((c) =>
    formState.selectedPkRegCarts.includes(c.regCartHistory.pkRegCart)
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        selectedPkRegCarts: [],
        confirmedSelectedPkRegCarts: [],
        pageIndex: 1,
        submitState: {
          loading: false,
          cancel: false,
          index: 0,
          completedItems: []
        }
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (formState.submitState.loading) {
      if (formState.submitState.cancel) {
        setFormState((c) => ({
          ...c,
          submitState: {
            ...c.submitState,
            loading: false
          }
        }));
        triggerNotification('Request Cancelled', 'Success', 'green');
      }
      else {
        // eslint-disable-next-line no-use-before-define
        fixRegCartsRequest();
      }
    }
  }, [formState.submitState.loading, formState.submitState.index]);

  const fixRegCartsRequest = () => {
    const remainingRegCarts = formState.confirmedSelectedPkRegCarts.filter(
      (pkRegCart) =>
        !formState.submitState.completedItems.some(
          (i) => i.pkRegCart === pkRegCart
        )
    );

    if (remainingRegCarts.length === 0) {
      setFormState((c) => ({
        ...c,
        submitState: {
          ...c.submitState,
          loading: false
        }
      }));
      fetchRegCartsWithMissingTransfersSummary();
      fetchRegCartsWithMissingTransfers();
      triggerNotification('Request complete!', 'Success', 'green');
    }
    else {
      completeRegCartCheckoutSessions(
        remainingRegCarts.slice(0, 5),
        (responseData) => {
          setFormState((c) => ({
            ...c,
            submitState: {
              ...c.submitState,
              index: c.submitState.index + 1,
              completedItems: [...c.submitState.completedItems, ...responseData]
            }
          }));
        },
        (message) => {
          setFormState({
            ...formState,
            loading: false
          });
          triggerNotification(message);
        }
      );
    }
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} title="Fix Transfers">
      {formState.submitState.loading ||
      formState.submitState.completedItems.length > 0 ? (
        <FormSection cancelTitle="Close" isSubmitHidden onCancel={onClose}>
          <Stack style={{ gap: 20 }}>
            <Stack style={{ alignItems: 'center' }}>
              <RingProgress
                label={(
                  <Stack
                    style={{
                      gap: 5,
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: formState.submitState.loading
                        ? 'dodgerblue'
                        : formState.submitState.cancel
                        ? 'red'
                        : 'green',
                      fontWeight: 700
                    }}
                  >
                    <Text style={{ fontSize: 24 }}>
                      {`${Math.round(
                        (formState.submitState.completedItems.length /
                          formState.confirmedSelectedPkRegCarts.length) *
                          100
                      )}%`}
                    </Text>
                    <Group style={{ gap: 5, alignItems: 'center' }}>
                      {formState.submitState.loading ? (
                        <>
                          <Send />
                          <Text style={{ fontSize: 22 }}>
                            {formState.submitState.cancel
                              ? 'Cancelling...'
                              : 'Fixing...'}
                          </Text>
                        </>
                      ) : formState.submitState.cancel ? (
                        <Text style={{ fontSize: 22 }}>Cancelled</Text>
                      ) : (
                        <Text style={{ fontSize: 22 }}>Sent</Text>
                      )}
                    </Group>
                  </Stack>
                )}
                sections={[
                  {
                    value: Math.round(
                      (formState.submitState.completedItems.length /
                        formState.confirmedSelectedPkRegCarts.length) *
                        100
                    ),
                    color: 'blue'
                  }
                ]}
                size={250}
              />
            </Stack>
            <PaginationList
              emptyMessage="No carts available"
              items={filteredRegCartsMissingTransfers.map((c) => {
                const completedResult = formState.submitState.completedItems.find(
                  (i) => i.pkRegCart === c.regCartHistory.pkRegCart
                );

                return (
                  <Group
                    key={c.regCartHistory.pkRegCart}
                    style={{
                      flex: 1,
                      gap: 10,
                      padding: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Group style={{ flex: 1, alignItem: 'center', gap: 10 }}>
                      {!completedResult && formState.submitState.loading ? (
                        <Loader color="dark" size={24} />
                      ) : completedResult && !completedResult.error ? (
                        <Check color="green" />
                      ) : (
                        <X color="red" />
                      )}
                      <Stack style={{ gap: 0 }}>
                        <Text style={{ fontWeight: 500, fontSize: 14 }}>
                          {format(
                            new Date(
                              `${stripUtcDateFormatting(
                                c.regCartHistory.cart.lineItems[0].purchaseDate
                              )}Z`
                            ),
                            'M/dd/yyyy'
                          )}
                        </Text>
                        <Text style={{ fontSize: 14, color: '#666' }}>
                          {c.regCartHistory.user.email}
                        </Text>
                        {completedResult?.error && (
                          <Text style={{ color: 'red', fontSize: 14 }}>
                            {completedResult.error}
                          </Text>
                        )}
                      </Stack>
                    </Group>
                    <Stack style={{ gap: 0 }}>
                      <Text style={{ fontWeight: 500, fontSize: 14 }}>
                        {currencyFormat(
                          c.regCartHistory.cart.lineItems.reduce(
                            (r1, c1) => r1 + c1.amountPaidInCents,
                            0
                          ) / 100
                        )}
                      </Text>
                      <Text style={{ fontSize: 14, color: '#666' }}>
                        {singularPluralFormat(
                          c.regCartHistory.cart.lineItems.length,
                          'cart item',
                          'cart items'
                        )}
                      </Text>
                    </Stack>
                  </Group>
                );
              })}
              itemsPerPage={10}
              LoadingComponent={ActionListItem}
              onPageChange={(pageIndex) =>
                setFormState({
                  ...formState,
                  pageIndex
                })
              }
              pageIndex={formState.pageIndex}
              showItemBorder
            />
          </Stack>
        </FormSection>
      ) : (
        <FormSection
          isLoading={formState.loading}
          isSubmitDisabled={formState.selectedPkRegCarts.length === 0}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              confirmedSelectedPkRegCarts: formState.selectedPkRegCarts,
              pageIndex: 1,
              submitState: {
                loading: true,
                cancel: false,
                index: 0,
                completedItems: []
              }
            });
          }}
          submitTitle={
            formState.selectedPkRegCarts.length === 0
              ? 'Fix Transfers'
              : `Fix Transfers (${formState.selectedPkRegCarts.length})`
          }
        >
          <Stack>
            <Alert color="blue" variant="outline">
              <Text style={{ fontSize: 14 }}>
                Select the carts you would like to fix the transfers for. This
                will trigger the 'checkoutComplete' function for each cart. This
                will not affect carts that have already been completed in any
                way.
              </Text>
            </Alert>

            <Stack style={{ gap: 5 }}>
              <Group
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Text style={{ fontSize: 16, fontWeight: 500 }}>Carts</Text>
                <Checkbox
                  checked={allCartsSelected}
                  label={allCartsSelected ? 'Unselect all' : 'Select all'}
                  onChange={() => {
                    setFormState({
                      ...formState,
                      selectedPkRegCarts: allCartsSelected
                        ? []
                        : filteredRegCartsMissingTransfers.map(
                            (c) => c.regCartHistory.pkRegCart
                          )
                    });
                  }}
                />
              </Group>
              <PaginationList
                emptyMessage="No carts available"
                isLoading={formState.submitState.loading}
                items={filteredRegCartsMissingTransfers.map((c) => (
                  <Group
                    key={c.regCartHistory.pkRegCart}
                    onClick={() => {
                      setFormState({
                        ...formState,
                        selectedPkRegCarts: formState.selectedPkRegCarts.includes(
                          c.regCartHistory.pkRegCart
                        )
                          ? formState.selectedPkRegCarts.filter(
                              (i) => i !== c.regCartHistory.pkRegCart
                            )
                          : [
                              ...formState.selectedPkRegCarts,
                              c.regCartHistory.pkRegCart
                            ]
                      });
                    }}
                    style={{
                      flex: 1,
                      gap: 10,
                      padding: 10,
                      alignItems: 'center',
                      cursor: 'pointer',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Group style={{ flex: 1, alignItem: 'center', gap: 10 }}>
                      <Checkbox
                        checked={formState.selectedPkRegCarts.includes(
                          c.regCartHistory.pkRegCart
                        )}
                        onChange={() => {}}
                      />
                      <Stack style={{ gap: 0 }}>
                        <Text style={{ fontWeight: 500, fontSize: 14 }}>
                          {format(
                            new Date(
                              `${stripUtcDateFormatting(
                                c.regCartHistory.cart.lineItems[0].purchaseDate
                              )}Z`
                            ),
                            'M/dd/yyyy'
                          )}
                        </Text>
                        <Text style={{ fontSize: 14, color: '#666' }}>
                          {c.regCartHistory.user.email}
                        </Text>
                      </Stack>
                    </Group>
                    <Stack style={{ gap: 0 }}>
                      <Text style={{ fontWeight: 500, fontSize: 14 }}>
                        {currencyFormat(
                          c.regCartHistory.cart.lineItems.reduce(
                            (r1, c1) => r1 + c1.amountPaidInCents,
                            0
                          ) / 100
                        )}
                      </Text>
                      <Text style={{ fontSize: 14, color: '#666' }}>
                        {singularPluralFormat(
                          c.regCartHistory.cart.lineItems.length,
                          'cart item',
                          'cart items'
                        )}
                      </Text>
                    </Stack>
                  </Group>
                ))}
                itemsPerPage={10}
                LoadingComponent={ActionListItem}
                onPageChange={(pageIndex) =>
                  setFormState({
                    ...formState,
                    pageIndex
                  })
                }
                pageIndex={formState.pageIndex}
                showItemBorder
              />
            </Stack>
          </Stack>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

FixRegCartCheckoutSessionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FixRegCartCheckoutSessionsModal;
