import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import View from './View';

const VideoPlayer = ({ playerId, playerProps }) => {
  useEffect(() => {
    window.jwplayer(playerId).setup(playerProps);
  }, [playerId]);

  return <View id={playerId} />;
};

VideoPlayer.propTypes = {
  playerId: PropTypes.string,
  playerProps: PropTypes.object
};

export default VideoPlayer;
