import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import {
  Alert,
  Button,
  Divider,
  Group,
  MultiSelect,
  Stack,
  Text
} from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import FormSection from '../../common/FormSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import { mq } from '../../../config/theme';
import { Context as PaymentContext } from '../../../providers/PaymentProvider';

const PAYMENT_CATEGORIES = [
  {
    label: 'Streaming Subscriptions Report',
    value: 'STREAMING_SUBSCRIPTION'
  },
  {
    label: 'Streaming Tournaments Report',
    value: 'STREAMING_TOURNAMENTS'
  },
  {
    label: 'Registration Revenue Report',
    value: 'REGISTRATION_REVENUE'
  }
];

const GenerateStripePaymentsModal = ({ isOpen, onClose }) => {
  const { fetchStripePayments } = useContext(PaymentContext);
  const multiSelectRef = useRef();
  const [formState, setFormState] = useState({
    loading: false,
    minDate: null,
    maxDate: null,
    categories: [],
    hasRequestedCsv: false
  });
  const dateNow = new Date();

  useEffect(() => {
    if (isOpen) {
      setFormState({
        loading: false,
        minDate: new Date(dateNow.setDate(dateNow.getDate() - 30)),
        maxDate: new Date(),
        categories: [],
        hasRequestedCsv: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title="Generate Payment Reports"
    >
      {formState.hasRequestedCsv ? (
        <Stack sx={{ gap: 30 }}>
          <Divider />
          <Alert variant="outline">
            <Stack sx={{ gap: 0 }}>
              <Text>
                Payment reports requested and will be sent to you by email.
              </Text>
              <Text>This can take up to 5 minutes.</Text>
            </Stack>
          </Alert>
          <Divider />
          <Button
            color="dark"
            onClick={onClose}
            sx={{ alignSelf: 'center', width: '100%', maxWidth: 250 }}
          >
            Close
          </Button>
        </Stack>
      ) : (
        <FormSection
          isLoading={formState.loading}
          isSubmitDisabled={formState.categories.length === 0}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              loading: true,
              error: null
            });
            fetchStripePayments(
              formState,
              () => {
                setFormState({
                  ...formState,
                  loading: false,
                  hasRequestedCsv: true
                });
              },
              (message) =>
                setFormState({
                  ...formState,
                  loading: false,
                  error: message
                })
            );
          }}
          submitTitle="Generate"
          sx={{ gap: 30 }}
        >
          <Stack sx={{ gap: 0 }}>
            <Text weight={500}>Date range</Text>
            <Group>
              <DatePicker
                css={mq({
                  width: ['100%', '100%', '100%', 'unset'],
                  flex: ['unset', 1, 'unset', 1]
                })}
                disabled={formState.loading}
                label="Minimum Date"
                maxDate={new Date()}
                onChange={(value) => {
                  const dateValue = new Date(
                    value.getUTCFullYear(),
                    value.getUTCMonth(),
                    value.getUTCDate(),
                    0,
                    0,
                    0
                  );
                  setFormState({
                    ...formState,
                    minDate: dateValue,
                    maxDate:
                      formState.maxDate &&
                      dateValue > new Date(formState.maxDate)
                        ? dateValue
                        : formState.maxDate,
                    error: null
                  });
                }}
                value={formState.minDate}
              />
              <DatePicker
                css={mq({
                  width: ['100%', '100%', '100%', 'unset'],
                  flex: ['unset', 1, 'unset', 1]
                })}
                disabled={formState.loading}
                label="Maximum Date"
                maxDate={new Date()}
                onChange={(value) => {
                  const dateValue = new Date(
                    value.getUTCFullYear(),
                    value.getUTCMonth(),
                    value.getUTCDate(),
                    0,
                    0,
                    0
                  );
                  setFormState({
                    ...formState,
                    maxDate: dateValue,
                    minDate:
                      formState.minDate &&
                      new Date(dateValue) < new Date(formState.minDate)
                        ? dateValue
                        : formState.minDate,
                    error: null
                  });
                }}
                value={formState.maxDate}
              />
            </Group>
          </Stack>

          <MultiSelect
            ref={multiSelectRef}
            clearable
            data={PAYMENT_CATEGORIES}
            description="Select the payment reports to run"
            disabled={formState.loading}
            label="Reports"
            onChange={(values) => {
              setFormState({
                ...formState,
                categories: values,
                error: null
              });
              multiSelectRef.current.blur();
            }}
            required
            searchable
            value={formState.categories}
          />

          {formState.error && (
            <Alert color="red" icon={<AlertCircle />}>
              {formState.error}
            </Alert>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

GenerateStripePaymentsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default GenerateStripePaymentsModal;
