import React from 'react';
import PropTypes from 'prop-types';
import { Button, Group, Stack, Text } from '@mantine/core';
import { NumericFormat } from 'react-number-format';
import { format } from 'date-fns';
import ResponsiveModal from '../../common/ResponsiveModal';
import PaginationList from '../../common/PaginationList';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';

const ViewOfflinePaymentsModal = ({
  isOpen,
  onClose,
  regAssociationPaymentWindow
}) => (
  <ResponsiveModal isOpen={isOpen} onClose={onClose} title="View Payments">
    {regAssociationPaymentWindow && (
      <Stack sx={{ gap: 20 }}>
        <Stack sx={{ gap: 0, alignItems: 'center' }}>
          <Text sx={{ fontSize: 24 }} weight={500}>
            {regAssociationPaymentWindow.regAssociation.association.name}
          </Text>
          <Text sx={{ fontSize: 18 }}>
            {format(
              new Date(
                `${stripUtcDateFormatting(
                  regAssociationPaymentWindow.startDate
                )}Z`
              ),
              'M/dd/yyyy'
            )}{' '}
            -
            {format(
              new Date(
                `${stripUtcDateFormatting(
                  regAssociationPaymentWindow.endDate
                )}Z`
              ),
              'M/dd/yyyy'
            )}
          </Text>
        </Stack>
        <PaginationList
          emptyMessage="No payments exists"
          items={regAssociationPaymentWindow.regFormSubmissionPayments
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((p) => (
              <Group key={p.pkRegFormSubmissionPayment} sx={{ padding: 10 }}>
                <Stack style={{ gap: 0, flex: 1 }}>
                  <Text weight={500}>
                    {
                      p.regFormSubmission.regAssociationDivisionForm.regForm
                        .name
                    }
                  </Text>
                  <Text>
                    {
                      p.regFormSubmission.regAssociationDivisionForm
                        .regAssociationDivision.name
                    }
                  </Text>
                  <Text>
                    {p.regFormSubmission.regPerson.firstName}{' '}
                    {p.regFormSubmission.regPerson.lastName}
                  </Text>
                </Stack>
                <Stack style={{ gap: 0, flex: 1 }}>
                  <Text weight={500}>
                    <NumericFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={p.amount}
                    />
                  </Text>
                  <Text>
                    {format(
                      new Date(`${stripUtcDateFormatting(p.createdAt)}Z`),
                      'M/dd/yyyy p'
                    )}
                  </Text>
                </Stack>
              </Group>
            ))}
          itemsPerPage={10}
          showItemBorder
        />
        <Stack sx={{ alignItems: 'center', marginTop: 20 }}>
          <Button
            color="dark"
            onClick={onClose}
            sx={{ width: '100%', maxWidth: 250 }}
          >
            Close
          </Button>
        </Stack>
      </Stack>
    )}
  </ResponsiveModal>
);

ViewOfflinePaymentsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociationPaymentWindow: PropTypes.object
};

export default ViewOfflinePaymentsModal;
