import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import PaginationList from '../../common/PaginationList';
import ActionListItem from '../../common/ActionListItem';
import RegAssociationAdminModal from '../RegistrationView/RegAssociationAdminModal';
import AddUserRegAssociationAdmin from '../RegistrationView/AddUserRegAssociationAdmin';

const UserAssociations = ({}) => {
  const { state } = useContext(TeamManagementContext);
  const { state: regState, fetchUserAdminAssociations } = useContext(
    RegistrationContext
  );
  const history = useHistory();
  const [pageState, setPageState] = useState({
    search: '',
    activePageIndex: 1,
    inactivePageIndex: 1
  });
  const [adminModalState, setAdminModalState] = useState({
    isOpen: false,
    action: '',
    regAssociationAdmin: null
  });
  const adminAssociations = regState.userAssociationAdmins.value
    .filter(
      (a) =>
        !pageState.search ||
        a.regAssociation.association.name
          .replace(' ', '')
          .toLowerCase()
          .includes(pageState.search.replace(' ', '').toLowerCase()) ||
        a.regAssociation.association.domain
          .replace(' ', '')
          .toLowerCase()
          .includes(pageState.search.replace(' ', '').toLowerCase())
    )
    .sort((a, b) =>
      a.regAssociation.association.name.localeCompare(
        b.regAssociation.association.name
      )
    );
  const inactiveCount = regState.userAssociationAdmins.value.filter(
    (a) => a.deleted
  ).length;
  const inactiveAssociations = adminAssociations.filter((a) => a.deleted);

  useEffect(() => {
    if (state.user.value) {
      fetchUserAdminAssociations(state.user.value.user.pkUser);
    }
  }, [state.user.value]);

  return (
    <Stack style={{ gap: 20 }}>
      <Stack style={{ gap: 5 }}>
        <Stack style={{ gap: 0 }}>
          <Text style={{ fontSize: 16 }} weight={500}>
            Active Association Admins
          </Text>
          <Group style={{ justifyContent: 'space-between', alignItems: 'end' }}>
            <TextInput
              onChange={(e) =>
                setPageState({
                  ...pageState,
                  search: e.target.value,
                  activePageIndex: 1,
                  inactivePageIndex: 1
                })
              }
              placeholder="Search for association...."
              style={{ flex: 1, maxWidth: 200 }}
              value={pageState.search}
            />
            <Button
              compact
              leftIcon={<Plus />}
              onClick={() => {
                setAdminModalState({
                  isOpen: true,
                  action: 'add-association',
                  regAssociationAdmin: null
                });
              }}
            >
              Add Association
            </Button>
          </Group>
        </Stack>
        <PaginationList
          emptyMessage="No associations assigned"
          isLoading={regState.userAssociationAdmins.loading}
          items={adminAssociations
            .filter((a) => !a.deleted)
            .map((a) => (
              <ActionListItem
                key={a.pkRegAssociationAdmin}
                actions={[
                  {
                    label: 'Remove',
                    color: 'red',
                    value: 'REMOVE'
                  }
                ]}
                avatar={a.regAssociation.logoImageUrl}
                description={a.regAssociation.association.domain}
                header={a.regAssociation.association.name}
                onAction={(action) =>
                  setAdminModalState({
                    isOpen: true,
                    action,
                    regAssociationAdmin: a
                  })
                }
                onClick={() =>
                  history.push(
                    `/registration/associations/${a.regAssociation.pkRegAssociation}`
                  )
                }
                showImage
              />
            ))}
          itemsPerPage={10}
          LoadingComponent={ActionListItem}
          onPageChange={(index) =>
            setPageState({
              ...pageState,
              activePageIndex: index
            })
          }
          pageIndex={pageState.activePageIndex}
          showItemBorder
        />
      </Stack>
      {inactiveCount > 0 && (
        <Stack style={{ gap: 5 }}>
          <Stack style={{ gap: 0 }}>
            <Text style={{ fontSize: 16 }} weight={500}>
              Inactive Association Admins
            </Text>
            <Group
              style={{ justifyContent: 'space-between', alignItems: 'end' }}
            >
              <TextInput
                onChange={(e) =>
                  setPageState({
                    ...pageState,
                    search: e.target.value,
                    activePageIndex: 1,
                    inactivePageIndex: 1
                  })
                }
                placeholder="Search for association...."
                style={{ flex: 1, maxWidth: 200 }}
                value={pageState.search}
              />
            </Group>
          </Stack>
          <PaginationList
            emptyMessage="No associations assigned"
            isLoading={regState.userAssociationAdmins.loading}
            items={inactiveAssociations.map((a) => (
              <ActionListItem
                key={a.pkRegAssociationAdmin}
                actions={[
                  {
                    label: 'Restore',
                    color: 'green',
                    value: 'RESTORE'
                  }
                ]}
                avatar={a.regAssociation.logoImageUrl}
                description={a.regAssociation.association.domain}
                header={a.regAssociation.association.name}
                onAction={(action) =>
                  setAdminModalState({
                    isOpen: true,
                    action,
                    regAssociationAdmin: a
                  })
                }
                onClick={() =>
                  history.push(
                    `/registration/associations/${a.regAssociation.pkRegAssociation}`
                  )
                }
                showImage
              />
            ))}
            itemsPerPage={10}
            LoadingComponent={ActionListItem}
            onPageChange={(index) =>
              setPageState({
                ...pageState,
                inactivePageIndex: index
              })
            }
            pageIndex={pageState.inactivePageIndex}
            showItemBorder
          />
        </Stack>
      )}

      <RegAssociationAdminModal
        action={adminModalState.action}
        isOpen={
          adminModalState.isOpen && adminModalState.action !== 'add-association'
        }
        onClose={() =>
          setAdminModalState({
            ...adminModalState,
            isOpen: false
          })
        }
        onSuccess={() => {
          setAdminModalState({
            ...adminModalState,
            isOpen: false
          });
          fetchUserAdminAssociations(state.user.value.user.pkUser);
        }}
        regAssociation={adminModalState.regAssociationAdmin?.regAssociation}
        regAssociationAdmin={adminModalState.regAssociationAdmin}
      />
      <AddUserRegAssociationAdmin
        isOpen={
          adminModalState.isOpen && adminModalState.action === 'add-association'
        }
        onClose={() =>
          setAdminModalState({
            ...adminModalState,
            isOpen: false
          })
        }
        onSuccess={() => {
          setAdminModalState({
            ...adminModalState,
            isOpen: false
          });
          fetchUserAdminAssociations(state.user.value.user.pkUser);
        }}
        pkUser={state.user.value?.user.pkUser}
      />
    </Stack>
  );
};

UserAssociations.propTypes = {};

export default UserAssociations;
