import React from 'react';
import { Card } from '@mantine/core';
import { Switch, Redirect, Route } from 'react-router-dom';
import MerchPackageOrders from './MerchPackageOrders';

const MerchPackageOrderView = () => (
  <Card
    shadow="lg"
    sx={{
      flex: 1,
      border: 'solid 1px lightgrey',
      padding: 15,
      gap: 5,
      alignSelf: 'stretch'
    }}
  >
    <Switch>
      <Route path="/registration/merchandise/orders">
        <MerchPackageOrders />
      </Route>
      <Route path="/registration/merchandise/orders/:pkRegCart">
        <>order details</>
      </Route>
      <Route path="*">
        <Redirect to="/registration/merchandise/orders" />
      </Route>
    </Switch>
  </Card>
);

export default MerchPackageOrderView;
