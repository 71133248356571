import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Divider,
  Loader,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import { NumericFormat } from 'react-number-format';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import PaymentPriceItem from '../../common/PaymentPriceItem';
import ActionListItem from '../../common/ActionListItem';

const MerchPackageOrderRefundModal = ({
  isOpen,
  onClose,
  onRefundComplete,
  regCartMerchPackage
}) => {
  const fetchedPkRegCartMerchPackage = useRef(null);
  const {
    state,
    fetchRegCartMerchPackageBalance,
    refundRegCartMerchPackage
  } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    isPartialRefund: false,
    partialRefundAmount: 0,
    showConfirmRefund: false,
    isLoading: false
  });
  const isFetching =
    !fetchedPkRegCartMerchPackage.current ||
    state.regCartMerchPackageBalance.loading;

  const { balance, merchPackage, regCart } =
    state.regCartMerchPackageBalance.value || {};

  const availableRefundAmount = balance
    ? balance.totalInCents -
      balance.serviceFeeInCents -
      balance.refundedAmountInCents
    : 0;

  useEffect(() => {
    if (isOpen) {
      setFormState({
        isPartialRefund: false,
        partialRefundAmount: 0,
        showConfirmRefund: false,
        isLoading: false
      });
      if (
        fetchedPkRegCartMerchPackage.current !==
        regCartMerchPackage.pkRegCartMerchPackage
      ) {
        fetchRegCartMerchPackageBalance(
          regCartMerchPackage.pkRegCartMerchPackage,
          null,
          triggerNotification
        );
        fetchedPkRegCartMerchPackage.current =
          regCartMerchPackage.pkRegCartMerchPackage;
      }
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      size={600}
      title="Refund Merch Package Order"
    >
      {isFetching ? (
        <Stack
          style={{
            padding: 40,
            paddingTop: 60,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loader color="dark" size={60} />
        </Stack>
      ) : formState.showConfirmRefund ? (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={() =>
            setFormState({
              ...formState,
              showConfirmRefund: false
            })
          }
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            refundRegCartMerchPackage(
              regCartMerchPackage.pkRegCartMerchPackage,
              {
                partialRefundAmount: formState.isPartialRefund
                  ? formState.partialRefundAmount
                  : null
              },
              () => {
                triggerNotification(
                  'Merch Package Order Refunded',
                  'Success',
                  'green'
                );
                onRefundComplete();
              },
              (error) => {
                triggerNotification(error);
                setFormState({
                  ...formState,
                  isLoading: false
                });
              }
            );
          }}
          submitColor="red"
          submitTitle="Refund"
        >
          <Stack style={{ marginTop: 20 }}>
            <Text style={{ textAlign: 'center' }}>
              Are you sure you want to refund this merch package order?
            </Text>
          </Stack>
        </FormSection>
      ) : (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              showConfirmRefund: true
            });
          }}
          submitColor="red"
          submitTitle="Refund"
        >
          <Stack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
            <Text style={{ color: 'dodgerblue', fontSize: 18 }} weight={700}>
              Overview
            </Text>
            <Divider size={2} />

            <PaymentPriceItem
              label="Wholesale Price"
              value={merchPackage.wholesalePrice / 100}
            />

            <PaymentPriceItem
              label="Association Split"
              value={
                merchPackage.regAssociationMerchPackages[0].associationSplit /
                100
              }
            />

            <PaymentPriceItem
              label="SportsHeadz Split"
              value={
                (merchPackage.price -
                  merchPackage.wholesalePrice -
                  merchPackage.regAssociationMerchPackages[0]
                    .associationSplit) /
                100
              }
            />

            <PaymentPriceItem
              label="Merch Cost"
              value={merchPackage.price / 100}
            />

            <PaymentPriceItem
              label="Transaction Total"
              value={balance.totalInCents / 100}
            />

            {balance.serviceFeeInCents > 0 && (
              <PaymentPriceItem
                color="#FA5252"
                label="Service Fee"
                value={balance.serviceFeeInCents / 100}
              />
            )}

            <PaymentPriceItem
              color="#FA5252"
              label="Amount Refunded"
              value={balance.refundedAmountInCents / 100}
            />

            <PaymentPriceItem
              isDividerHidden
              label="Refundable Balance"
              value={availableRefundAmount / 100}
            />
          </Stack>

          <Stack style={{ border: 'solid 1px lightgrey' }}>
            <ActionListItem
              description={regCart.user.name}
              header={merchPackage.name}
            />
          </Stack>

          <Stack style={{ gap: 10 }}>
            <Checkbox
              checked={formState.isPartialRefund}
              label="Partial Refund"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  isPartialRefund: e.currentTarget.checked,
                  partialRefundAmount:
                    formState.partialRefundAmount === 0
                      ? availableRefundAmount
                      : formState.partialRefundAmount
                })
              }
              style={{ fontWeight: 500 }}
            />
            {formState.isPartialRefund && (
              <NumericFormat
                allowNegative={false}
                customInput={TextInput}
                decimalScale={2}
                disabled={formState.isLoading}
                fixedDecimalScale
                label="Partial Refund Amount"
                onValueChange={(values) =>
                  setFormState({
                    ...formState,
                    partialRefundAmount:
                      values.floatValue * 100 > availableRefundAmount
                        ? availableRefundAmount
                        : values.floatValue * 100
                  })
                }
                prefix="$"
                required
                thousandSeparator
                value={
                  formState.partialRefundAmount
                    ? formState.partialRefundAmount / 100
                    : 0
                }
              />
            )}
          </Stack>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

MerchPackageOrderRefundModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRefundComplete: PropTypes.func,
  regCartMerchPackage: PropTypes.object
};

export default MerchPackageOrderRefundModal;
