import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm, Container, Form, Radio } from 'semantic-ui-react';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import ContentModal from '../../common/ContentModal';
import ResponsiveInput from '../../common/ResponsiveInput';

const initailFormState = {
  name: '',
  hostName: '',
  title: '',
  description: '',
  hls: '',
  stream: '',
  application: '',
  isActive: false,
  isBlackout: false
};

const CreateStreamModal = ({ isOpen, onClose, pkVenue }) => {
  const { createStream, fetchVenues } = useContext(LiveStreamContext);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [formState, setFormState] = useState(initailFormState);

  useEffect(() => {
    if (isOpen) {
      setFormState(initailFormState);
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <ContentModal
      actionContent="Create"
      error=""
      hideActions
      isOpen={isOpen}
      onClose={onClose}
      size="tiny"
      title="Create Stream"
    >
      <Confirm
        aria-label="confirm"
        content="Are you sure you want to create a new stream?"
        onCancel={() => setShowConfirmDialog(false)}
        onConfirm={async () => {
          onClose();
          await createStream(pkVenue, formState);
          fetchVenues();
        }}
        open={showConfirmDialog}
        role="dialog"
        size="tiny"
      />
      <Form
        aria-label="createStream"
        onSubmit={(e) => {
          e.preventDefault();
          setShowConfirmDialog(true);
        }}
        role="form"
      >
        <Form.Field>
          <ResponsiveInput
            label="Name"
            name="name"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.name}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Title"
            name="title"
            onChange={handleInputChange}
            style={{ fontSize: 15 }}
            value={formState.title}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Description"
            name="description"
            onChange={handleInputChange}
            style={{ fontSize: 15 }}
            value={formState.description}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="HLS File"
            name="hls"
            onChange={handleInputChange}
            style={{ fontSize: 15 }}
            value={formState.hls}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Host"
            name="hostName"
            onChange={handleInputChange}
            style={{ fontSize: 15 }}
            value={formState.hostName}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Stream ID"
            name="stream"
            onChange={handleInputChange}
            style={{ fontSize: 15 }}
            value={formState.stream}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            label="Application ID"
            name="application"
            onChange={handleInputChange}
            style={{ fontSize: 15 }}
            value={formState.application}
          />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <Radio
              checked={formState.isBlackout}
              label="Blackout"
              onChange={() =>
                setFormState({
                  ...formState,
                  isBlackout: !formState.isBlackout
                })
              }
              toggle
            />
          </Form.Field>
          <Form.Field>
            <Radio
              checked={formState.isActive}
              label="Enabled"
              onChange={() =>
                setFormState({
                  ...formState,
                  isActive: !formState.isActive
                })
              }
              toggle
            />
          </Form.Field>
        </Form.Group>
        <Container
          style={{ textAlign: 'right', marginTop: 20, marginRight: 0 }}
        >
          <Button
            aria-label="cancelCreateStream"
            color="black"
            onClick={onClose}
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="button"
          >
            Cancel
          </Button>
          <Button
            aria-label="submitCreateStream"
            content="Create"
            positive
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="submit"
          />
        </Container>
      </Form>
    </ContentModal>
  );
};

CreateStreamModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkVenue: PropTypes.number
};

export default CreateStreamModal;
