import React, { useContext, useEffect, useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import View from '../../common/View';
import AddTeamEventModal from './AddTeamEventModal';
import TeamEventList from './TeamEventsList';

const TeamEvents = () => {
  const {
    state,
    fetchTeamEvents,
    fetchTeamHangouts,
    deleteTeamHangouts,
    deleteTeamLeagueEvents
  } = useContext(TeamManagementContext);
  const teamEvents = state.teamEvents.value.sort(
    (a, b) => new Date(a.start) - new Date(b.start)
  );
  const teamHangouts = state.teamHangouts.value.sort(
    (a, b) => new Date(a.start) - new Date(b.start)
  );
  const [selectedEventDelete, setSelectedEventDelete] = useState({
    event: null,
    isLeagueEvent: false
  });
  const [eventEditorInfo, setEventEditorInfo] = useState({
    selectedEvent: null,
    isLeagueEvent: false,
    isOpen: false
  });

  useEffect(() => {
    fetchTeamEvents(state.team.value.team.pkTeam);
    fetchTeamHangouts(state.team.value.team.pkTeam);
  }, [state.team.value]);

  return (
    <View>
      <AddTeamEventModal
        isLeagueEvent={eventEditorInfo.isLeagueEvent}
        isOpen={eventEditorInfo.isOpen}
        onClose={() =>
          setEventEditorInfo({
            selectedEvent: null,
            isLeagueEvent: false,
            isOpen: false
          })
        }
        selectedEvent={eventEditorInfo.selectedEvent}
      />
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want remove ${selectedEventDelete.event?.title}`}
        onCancel={() =>
          setSelectedEventDelete({
            event: null,
            isLeagueEvent: false
          })
        }
        onConfirm={() => {
          if (selectedEventDelete.isLeagueEvent) {
            deleteTeamLeagueEvents(state.team.value.team.pkTeam, [
              {
                pkCalendar: selectedEventDelete.event.pkCalendar,
                notifyUsers: true
              }
            ]);
          }
          else {
            deleteTeamHangouts(state.team.value.team.pkTeam, [
              selectedEventDelete.event.pkPrivateCalendar
            ]);
          }

          setSelectedEventDelete({
            event: null,
            isLeagueEvent: false
          });
        }}
        open={selectedEventDelete.event != null}
        role="dialog"
        size="tiny"
      />
      <View aria-label="leagueEvents" role="region">
        <TeamEventList
          events={teamEvents}
          isLoading={state.teamEvents.loading}
          onAddEvent={
            !state.team.value.team.mbswTeamId ||
            state.team.value.team.mbswTeamId === 0
              ? () =>
                  setEventEditorInfo({
                    selectedEvent: null,
                    isLeagueEvent: true,
                    isOpen: true
                  })
              : null
          }
          onClick={
            !state.team.value.team.mbswTeamId ||
            state.team.value.team.mbswTeamId === 0
              ? (event) =>
                  setEventEditorInfo({
                    selectedEvent: event,
                    isLeagueEvent: true,
                    isOpen: true
                  })
              : null
          }
          onRemoveEvent={
            !state.team.value.team.mbswTeamId ||
            state.team.value.team.mbswTeamId === 0
              ? (event) =>
                  setSelectedEventDelete({
                    event,
                    isLeagueEvent: true
                  })
              : null
          }
          title="League Events"
        />
      </View>
      <View
        aria-label="hangoutEvents"
        css={{ marginTop: '15px' }}
        role="region"
      >
        <TeamEventList
          events={teamHangouts}
          isLoading={state.teamHangouts.loading}
          onAddEvent={() =>
            setEventEditorInfo({
              selectedEvent: null,
              isLeagueEvent: false,
              isOpen: true
            })
          }
          onClick={(event) =>
            setEventEditorInfo({
              selectedEvent: event,
              isLeagueEvent: false,
              isOpen: true
            })
          }
          onRemoveEvent={(event) =>
            setSelectedEventDelete({
              event,
              isLeagueEvent: false
            })
          }
          title="Hangout Events"
        />
      </View>
    </View>
  );
};

export default TeamEvents;
