import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, Stack, Text, TextInput } from '@mantine/core';
import FormSection from './FormSection';

const DeleteItemView = ({
  isLoading,
  itemLabel,
  onCancel,
  onDelete,
  warnings,
  confirmationValue,
  reinstate,
  ...rest
}) => {
  const [formState, setFormState] = useState({ confirmation: '' });
  const confirmationRequired = warnings && warnings.length > 0;
  const confirmationText =
    confirmationValue || (reinstate ? 'reinstate' : 'delete');

  useEffect(() => {
    setFormState({ confirmation: '' });
  }, []);

  return (
    <FormSection
      isLoading={isLoading}
      isSubmitDisabled={
        confirmationRequired && formState.confirmation !== confirmationText
      }
      onCancel={onCancel}
      onSubmit={onDelete}
      submitColor={reinstate ? 'blue' : 'red'}
      submitTitle={reinstate ? `Reinstate ${itemLabel}` : `Delete ${itemLabel}`}
      {...rest}
    >
      {confirmationRequired ? (
        <Stack>
          <Stack style={{ gap: 5 }}>
            <Text color="grey">
              This will {reinstate ? 'reinstate' : 'delete'}:
            </Text>
            <List withPadding>
              <List.Item>
                <Text color="grey">The {itemLabel.toLowerCase()}.</Text>
              </List.Item>
              {warnings.map((w) => (
                <List.Item key={w}>
                  <Text color="grey">{w}</Text>
                </List.Item>
              ))}
            </List>
          </Stack>

          <Stack style={{ gap: 5 }}>
            <Text color="grey">Type '{confirmationText}' to confirm.</Text>
            <TextInput
              disabled={isLoading}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  confirmation: e.target.value
                })
              }
              placeholder={confirmationText}
              value={formState.confirmation}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack style={{ marginTop: 20 }}>
          <Text style={{ textAlign: 'center' }}>
            Are you sure you want to {reinstate ? 'reinstate' : 'delete'} this{' '}
            {itemLabel.toLowerCase()}?
          </Text>
        </Stack>
      )}
    </FormSection>
  );
};

DeleteItemView.propTypes = {
  confirmationValue: PropTypes.string,
  isLoading: PropTypes.bool,
  reinstate: PropTypes.bool,
  itemLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  warnings: PropTypes.array
};

export default DeleteItemView;
