import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Form,
  Icon,
  Input,
  Radio,
  Select,
  Button,
  Confirm
} from 'semantic-ui-react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import View from '../../common/View';
import { mq } from '../../../config/theme';
import InputLabel from '../../common/InputLabel';

const TeamDetails = () => {
  const { state, fetchAssociations, updateTeam } = useContext(
    TeamManagementContext
  );
  const [formState, setFormState] = useState({
    name: '',
    mbswTeamId: null,
    domain: '',
    season: '',
    association: null,
    isActive: false
  });
  const sortedAssociationsList = state.associations.value.sort((a, b) =>
    a.name?.localeCompare(b.name)
  );
  const hasFormStateLoaded = useRef(false);
  const [isConfirmEditOpen, setIsConfirmEditOpen] = useState(false);

  useEffect(() => {
    if (state.team.value) {
      setFormState(state.team.value.team);
      hasFormStateLoaded.current = true;
    }
  }, [state.team.value]);

  const hasFormValueChanged = (key) =>
    formState[key]?.toString() !== state.team.value.team[key]?.toString();

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <View>
      <Confirm
        aria-label="confirm"
        content="Are you sure you want to save changes?"
        onCancel={() => setIsConfirmEditOpen(false)}
        onConfirm={() => {
          setIsConfirmEditOpen(false);
          updateTeam(state.team.value.team.pkTeam, {
            ...formState,
            fkAssociation: formState.association?.pkAssociation,
            mbswId: formState.mbswTeamId
          });
        }}
        open={isConfirmEditOpen}
        role="dialog"
        size="tiny"
      />
      {state.team.value && hasFormStateLoaded.current && (
        <Form
          aria-label="teamDetails"
          onSubmit={(e) => {
            e.preventDefault();
            setIsConfirmEditOpen(true);
          }}
          role="form"
        >
          <Form.Group widths="equal">
            <Form.Field>
              <Input
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('name') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Name',
                  color: hasFormValueChanged('name') ? 'yellow' : undefined
                }}
                name="name"
                onChange={handleInputChange}
                required
                value={formState.name}
              />
            </Form.Field>
            <Form.Field>
              <Input
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('domain') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Domain',
                  color: hasFormValueChanged('domain') ? 'yellow' : undefined
                }}
                name="domain"
                onChange={handleInputChange}
                value={formState.domain ?? ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Input
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('season') ? 'yellow' : undefined
                }}
                label={{
                  content: 'Season',
                  color: hasFormValueChanged('season') ? 'yellow' : undefined
                }}
                name="season"
                onChange={handleInputChange}
                type="number"
                value={formState.season}
              />
            </Form.Field>
            <Form.Field>
              <Input
                icon={{
                  name: 'pencil',
                  color: hasFormValueChanged('mbswTeamId')
                    ? 'yellow'
                    : undefined
                }}
                label={{
                  content: 'MBSW ID',
                  color: hasFormValueChanged('mbswTeamId')
                    ? 'yellow'
                    : undefined
                }}
                name="mbswTeamId"
                onChange={handleInputChange}
                type="number"
                value={formState.mbswTeamId ?? ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <View css={{ flexDirection: 'column' }}>
                <InputLabel>Association</InputLabel>
                <View css={{ flexDirection: 'row', flex: 1 }}>
                  <Select
                    css={{ flex: 1 }}
                    loading={state.associations.loading}
                    name="association"
                    onChange={(e, option) =>
                      setFormState({
                        ...formState,
                        association: sortedAssociationsList.find(
                          (a) => a.pkAssociation === option.value
                        )
                      })
                    }
                    options={sortedAssociationsList.map((a) => ({
                      key: a.pkAssociation,
                      value: a.pkAssociation,
                      text: a.name
                    }))}
                    placeholder="Select an association"
                    search
                    value={formState.association?.pkAssociation ?? ''}
                  />
                  <Icon
                    css={{ alignSelf: 'center', cursor: 'pointer' }}
                    disabled={state.associations.loading}
                    loading={state.associations.loading}
                    name="refresh"
                    onClick={() => {
                      fetchAssociations();
                    }}
                    style={{ margin: 10 }}
                  />
                </View>
              </View>
            </Form.Field>
            <Form.Field
              css={mq({ paddingTop: [null, 15] })}
              style={{ margin: 'auto' }}
            >
              <Radio
                checked={formState.isActive}
                label={formState.isActive ? 'Active' : 'Inactive'}
                onChange={() =>
                  setFormState({
                    ...formState,
                    isActive: !formState.isActive
                  })
                }
                style={{ margin: 0 }}
                toggle
              />
            </Form.Field>
          </Form.Group>
          {(Object.entries(formState).find(
            ([key, value]) =>
              key !== 'pkAssociation' &&
              value?.toString() !== state.team.value.team[key]?.toString()
          ) ||
            formState.association?.pkAssociation !==
              state.team.value.team.association?.pkAssociation) && (
            <View
              css={mq({
                flex: 1,
                justifyContent: 'flex-end',
                flexDirection: 'row',
                marginTop: [20, 0]
              })}
            >
              <Button
                aria-label="saveDetails"
                content="Save Changes"
                primary
                role="button"
                type="submit"
              />
              <Button
                aria-label="cancelSaveDetails"
                content="Cancel"
                onClick={() => setFormState(state.team.value.team)}
                role="button"
                secondary
                style={{ marginLeft: 10 }}
                type="button"
              />
            </View>
          )}
        </Form>
      )}
    </View>
  );
};

export default TeamDetails;
