import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Confirm, Container, Form } from 'semantic-ui-react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ResponsiveInput from '../../common/ResponsiveInput';
import { mq } from '../../../config/theme';
import View from '../../common/View';
import { TEAM_MEMBER_ROLE_OPTIONS } from '../../../config/constants';
import InputLabel from '../../common/InputLabel';

const CreateBasicUser = ({ onClose, onError }) => {
  const { state, createBasicUser, fetchTeam } = useContext(
    TeamManagementContext
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    phone: '',
    roles: []
  });

  const selectRole = (role) => {
    let rolesCopy = [...formState.roles];
    if (rolesCopy.includes(role)) {
      rolesCopy = rolesCopy.filter((r) => r !== role);
    }
    else {
      rolesCopy.push(role);
    }
    setFormState({
      ...formState,
      roles: rolesCopy
    });
    onError('');
  };

  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
    onError('');
  };

  return (
    <View>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (formState.roles.length === 0) {
            onError('Please select one or more roles.');
          }
          else {
            setShowConfirm(true);
          }
        }}
      >
        <Form.Field>
          <ResponsiveInput
            data-testid="basicName"
            label="Name"
            name="name"
            onChange={handleInputChange}
            required
            style={{ fontSize: 15 }}
            value={formState.name}
          />
        </Form.Field>
        <Form.Field>
          <ResponsiveInput
            data-testid="basicPhone"
            label="Phone"
            name="phone"
            onChange={handleInputChange}
            style={{ fontSize: 15 }}
            value={formState.phone}
          />
        </Form.Field>
        <InputLabel>Role</InputLabel>
        <Form.Group inline style={{ marginTop: '5px' }}>
          {TEAM_MEMBER_ROLE_OPTIONS.map((r) => (
            <Form.Field key={r.key}>
              <Checkbox
                aria-label={r.key}
                checked={formState.roles.includes(r.value)}
                label={r.text}
                name={r.key}
                onClick={(e, { value }) => selectRole(value)}
                role="checkbox"
                value={r.value}
              />
            </Form.Field>
          ))}
        </Form.Group>
        <Container style={{ textAlign: 'right', margin: 0 }}>
          <Button
            aria-label="cancelAddUser"
            color="black"
            onClick={onClose}
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="button"
          >
            Cancel
          </Button>
          <Button
            aria-label="submitAddUser"
            content="Add"
            onClick={() => {}}
            positive
            role="button"
            style={{ marginLeft: '0.75em' }}
            type="submit"
          />
        </Container>
      </Form>
      <Confirm
        aria-label="confirm"
        content={`Are you sure you want to add ${formState.name} to ${state.team.value.team.name}`}
        css={mq({ minWidth: ['100%', 'unset'] })}
        onCancel={() => setShowConfirm(false)}
        onConfirm={async () => {
          await createBasicUser(state.team.value.team.pkTeam, formState);
          fetchTeam(state.team.value.team.pkTeam);
        }}
        open={showConfirm}
        role="dialog"
        size="tiny"
        style={{ margin: 0 }}
      />
    </View>
  );
};

CreateBasicUser.propTypes = {
  onClose: PropTypes.func,
  onError: PropTypes.func
};

export default CreateBasicUser;
