import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Group, Image, Stack, TextInput } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import FileUploader from '../../common/FileUploader';
import { fileToBase64String } from '../../../helpers/awsHelper';

const RegMerchPackageImageModal = ({ onAdd, isOpen, onClose }) => {
  const [formState, setFormState] = useState({
    file: null,
    url: '',
    preview: ''
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({ file: null, url: '', preview: '' });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      closeOnClickOutside={false}
      isOpen={isOpen}
      onClose={onClose}
      title="Add Image"
    >
      <FormSection
        isSubmitDisabled={!formState.file && !formState.url}
        onCancel={onClose}
        onSubmit={() => {
          onAdd({
            file: formState.file,
            url: formState.url,
            preview: formState.preview
          });
        }}
        submitTitle="Add Image"
      >
        {formState.preview && (
          <Group style={{ flex: 1, width: '100%', maxHeight: 250 }}>
            <Image
              fit="contain"
              height={250}
              src={formState.preview}
              width="100%"
            />
          </Group>
        )}

        <Stack style={{ gap: 10 }}>
          <FileUploader
            height={150}
            onError={() => {}}
            onUpload={(file) => {
              setFormState({
                file,
                url: null
              });
              fileToBase64String(file, (base64String) => {
                setFormState({ ...formState, file, preview: base64String });
              });
            }}
          />

          <TextInput
            label="Image Src"
            onChange={(e) =>
              setFormState({
                ...formState,
                url: e.currentTarget.value,
                file: null,
                preview: e.currentTarget.value
              })
            }
            value={formState.url || ''}
          />
        </Stack>
      </FormSection>
    </ResponsiveModal>
  );
};

RegMerchPackageImageModal.propTypes = {
  isOpen: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func
};

export default RegMerchPackageImageModal;
