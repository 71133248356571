import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Message, Modal } from 'semantic-ui-react';
import { Text } from '@mantine/core';
import View from '../../common/View';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { mq } from '../../../config/theme';

const AcceptAllJoinRequestModal = ({ isOpen, onClose, teamJoinRequests }) => {
  const { acceptAllTeamJoinRequests } = useContext(TeamManagementContext);
  const [formState, setFormState] = useState({
    isLoading: false,
    error: null
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        isLoading: false,
        error: null
      });
    }
  }, [isOpen]);

  return (
    <Modal
      closeIcon
      css={mq({ minWidth: ['100%', 'unset'] })}
      onClose={onClose}
      open={isOpen}
      size="tiny"
      style={{ margin: 0 }}
    >
      <Modal.Content>
        <View>
          <Text sx={{ textAlign: 'center', margin: '20px 0px' }}>
            Are you sure you want to approve all join requests?
          </Text>
          {formState.error && (
            <Modal.Actions>
              <Message color="red" data-testid="modalError" size="small">
                {formState.error}
              </Message>
            </Modal.Actions>
          )}
          <Divider />
          <View css={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button
              content="Cancel"
              disabled={formState.isLoading}
              onClick={onClose}
              style={{ margin: 5, flex: 1 }}
            />
            <Button
              content="Approve All"
              loading={formState.isLoading}
              onClick={() => {
                setFormState({
                  isLoading: true,
                  error: null
                });
                acceptAllTeamJoinRequests(
                  {
                    teamJoinRequests: teamJoinRequests.map((j) => ({
                      pkTeamJoinRequest: j.pkTeamJoinRequest,
                      isAccepted: true
                    }))
                  },
                  onClose,
                  () => {
                    setFormState({
                      isLoading: false,
                      error: 'Oops something went wrong.'
                    });
                  }
                );
              }}
              positive
              style={{ margin: 5, flex: 1 }}
            />
          </View>
        </View>
      </Modal.Content>
    </Modal>
  );
};

AcceptAllJoinRequestModal.propTypes = {
  isOpen: PropTypes.bool,
  teamJoinRequests: PropTypes.array,
  onClose: PropTypes.func
};

export default AcceptAllJoinRequestModal;
