import { Group, Pagination, Skeleton, Stack, Table, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const FinancialRegistrationTransactionList = ({ payments, isLoading }) => {
  const MAX_PAGE_LENGTH = 5;
  const [pageIndex, setPageIndex] = useState(1);

  return (
    <Stack style={{ gap: 10 }}>
      <Group style={{ flex: 1, justifyContent: 'space-between' }}>
        <Text weight={500}>Transaction Log</Text>
      </Group>

      <Stack>
        <Stack style={{ overflow: 'auto', flex: 1, gap: 0 }}>
          <Table
            horizontalSpacing="md"
            style={{ border: 'solid 1px #ced4da' }}
            verticalSpacing="md"
          >
            <thead>
              <tr>
                <th>
                  <Text style={{ whiteSpace: 'nowrap' }}>Status</Text>
                </th>
                <th>
                  <Text style={{ whiteSpace: 'nowrap' }}>Date</Text>
                </th>
                <th>
                  <Text style={{ whiteSpace: 'nowrap' }}>Type</Text>
                </th>
                <th>
                  <Text style={{ whiteSpace: 'nowrap' }}>Description</Text>
                </th>
                <th style={{ borderRight: 'solid 1px #ced4da' }}>
                  <Text style={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                    Gross Amount
                  </Text>
                </th>
              </tr>
            </thead>

            <tbody>
              {isLoading ? (
                <>
                  {[...Array(2)].map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={i}>
                      <td>
                        <Skeleton height={10} width={60} />
                      </td>
                      <td>
                        <Skeleton height={10} width={125} />
                      </td>
                      <td>
                        <Skeleton height={10} width={80} />
                      </td>
                      <td>
                        <Skeleton height={10} width={80} />
                      </td>
                      <td style={{ borderRight: 'solid 1px #ced4da' }}>
                        <Skeleton
                          height={10}
                          style={{ alignSelf: 'end' }}
                          width={80}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : payments.length === 0 ? (
                <tr>
                  <td colSpan="6" style={{ padding: 50, textAlign: 'center' }}>
                    <Text weight={500}>- No Payments Received -</Text>
                  </td>
                </tr>
              ) : (
                payments
                  ?.sort(
                    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                  )
                  .slice(
                    -MAX_PAGE_LENGTH + pageIndex * MAX_PAGE_LENGTH,
                    pageIndex * MAX_PAGE_LENGTH
                  )
                  .map((p) => (
                    <tr key={p.key}>
                      {p.rows.map((r) => (
                        <td key={r.key}>
                          <Text
                            style={{
                              color: r.color,
                              textAlign: r.textAlign || 'start'
                            }}
                            weight={r.weight}
                          >
                            {r.label}
                          </Text>
                        </td>
                      ))}
                    </tr>
                  ))
              )}
            </tbody>
          </Table>
        </Stack>
        <Group style={{ justifyContent: 'center' }}>
          <Stack>
            {!isLoading && payments.length > MAX_PAGE_LENGTH && (
              <Pagination
                color="blue"
                onChange={setPageIndex}
                total={Math.ceil(payments.length / MAX_PAGE_LENGTH)}
                value={pageIndex}
              />
            )}
          </Stack>
        </Group>
      </Stack>
    </Stack>
  );
};

FinancialRegistrationTransactionList.propTypes = {
  isLoading: PropTypes.bool,
  payments: PropTypes.array
};

export default FinancialRegistrationTransactionList;
