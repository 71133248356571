const getInitialsFromName = (name) => {
  let initials = '';
  if (name?.length > 0) {
    const splitName = name.replace(/\s+/g, ' ').trim().split(' ');
    initials += splitName[0][0];
    if (splitName.length > 1) {
      initials += splitName[splitName.length - 1][0];
    }
  }
  return initials;
};

const stripUtcDateFormatting = (date) =>
  `${date.charAt(date.length - 1) === 'Z' ? date.slice(0, -1) : date}`;

const currencyFormat = (num) => {
  if (!Number.isNaN(parseFloat(num))) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return formatter.format(parseFloat(num), 2);
  }
  return '';
};

const singularPluralFormat = (value, singular, plural) =>
  `${value} ${value === 1 ? singular : plural}`;

export {
  getInitialsFromName,
  stripUtcDateFormatting,
  currencyFormat,
  singularPluralFormat
};
