import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Confirm } from 'semantic-ui-react';
import { useParams } from 'react-router';
import { Context as LiveStreamContext } from '../../../providers/LiveStreamProvider';
import View from '../../common/View';
import StreamEventList from './StreamEventList';
import AddScheduledEventModal from './AddScheduledEventModal';
import StreamViewStats from './StreamViewStats';

const StreamEventSchedule = ({ venue }) => {
  const {
    state,
    fetchTournaments,
    removeScheduledEvent,
    fetchUpcoming,
    fetchRecorded
  } = useContext(LiveStreamContext);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const { pkCamera } = useParams();
  const camera = venue?.cameras.find((c) => c.id.toString() === pkCamera);
  const upcoming = state.upcoming.value
    ?.filter((u) => u.title.fkStream === camera.stream.pkStream)
    .sort((a, b) => new Date(a.title.startDate) - new Date(b.title.startDate));
  const recorded = state.recorded.value
    ?.filter((u) => u.title.fkStream === camera.stream.pkStream)
    .sort((a, b) => new Date(a.title.startDate) - new Date(b.title.startDate));

  useEffect(() => {
    if (camera) {
      fetchTournaments();
      fetchUpcoming();
      fetchRecorded();
    }
  }, [camera]);

  return (
    <View>
      <Confirm
        aria-label="confirm"
        content="Are you sure you want remove the scheduled event?"
        onCancel={() => setSelectedEvent(null)}
        onConfirm={() => {
          removeScheduledEvent(selectedEvent.title.pkScheduledEventTitle);
          setSelectedEvent(null);
        }}
        open={selectedEvent != null}
        role="dialog"
        size="small"
      />
      <AddScheduledEventModal
        isOpen={isAddEventModalOpen}
        isRecurring
        isTournament
        onClose={() => setIsAddEventModalOpen(false)}
        pkStream={camera.stream.pkStream}
      />
      <View aria-label="streamStats" role="region">
        <StreamViewStats pkStream={camera.stream.pkStream} />
      </View>
      <View aria-label="upcomingEvents" role="region">
        <StreamEventList
          events={upcoming}
          isLoading={state.upcoming.loading}
          onAddEvent={() => setIsAddEventModalOpen(true)}
          onRemoveEvent={setSelectedEvent}
          title="Upcoming"
        />
      </View>
      <View aria-label="recordedEvents" role="region">
        <StreamEventList
          events={recorded}
          isLoading={state.recorded.loading}
          onRemoveEvent={setSelectedEvent}
          title="Recorded"
        />
      </View>
    </View>
  );
};

StreamEventSchedule.propTypes = { venue: PropTypes.object };

export default StreamEventSchedule;
