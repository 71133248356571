import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Header, List, Segment } from 'semantic-ui-react';
import { format } from 'date-fns';
import ActionableListItem from '../../common/ActionableListItem';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import View from '../../common/View';
import Text from '../../common/Text';
import { stripUtcDateFormatting } from '../../../helpers/formatHelper';
import UserEventInfoModal from './UserEventInfoModal';

const UserEventList = ({ title, events, isLoading }) => {
  const { state } = useContext(TeamManagementContext);
  const [showEventInfo, setShowEventInfo] = useState(null);

  return (
    <View>
      <UserEventInfoModal
        event={showEventInfo}
        onClose={() => setShowEventInfo(null)}
      />
      <View
        css={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Header as="h4" css={{ margin: '0px !important' }}>
          {title}
        </Header>
      </View>
      <Segment
        aria-label="eventList"
        css={{ minHeight: '50px' }}
        loading={isLoading}
        role="region"
      >
        <View
          css={{
            maxHeight: '325px',
            overflowY: 'auto'
          }}
        >
          <List selection>
            {!isLoading &&
              (events && events.length > 0 ? (
                events.map((event, index, eventsArray) => {
                  const eventDate = format(
                    new Date(`${stripUtcDateFormatting(event.start)}Z`),
                    'PP'
                  );
                  if (
                    index === 0 ||
                    (eventsArray[index - 1] &&
                      format(
                        new Date(
                          `${stripUtcDateFormatting(
                            eventsArray[index - 1].start
                          )}Z`
                        ),
                        'PP'
                      ) !== eventDate)
                  ) {
                    return [
                      <Header
                        key={event.start}
                        as="h4"
                        css={{ margin: '0px !important' }}
                      >
                        {eventDate}
                      </Header>,
                      <ActionableListItem
                        key={event.pkCalendar ?? event.pkPrivateCalendar}
                        actionColor="red"
                        actionTitle="Remove"
                        avatar={
                          state.user.value.user.teams.find(
                            (t) => t.team.pkTeam === event.fkTeam
                          )?.team.image
                        }
                        description={
                          event.locationName ?? event.locationMapinfo
                        }
                        header={`${event.title}${
                          !event.dataType || event.dataType === 'Hangout'
                            ? ''
                            : ` - ${event.dataType}`
                        }`}
                        onClick={() => {
                          setShowEventInfo(event);
                        }}
                        showImage
                      />
                    ];
                  }
                  return (
                    <ActionableListItem
                      key={event.pkCalendar ?? event.pkPrivateCalendar}
                      actionColor="red"
                      actionTitle="Remove"
                      avatar={
                        state.user.value.user.teams.find(
                          (t) => t.team.pkTeam === event.fkTeam
                        )?.team.image
                      }
                      description={event.locationName ?? event.locationMapinfo}
                      header={`${event.title}${
                        !event.dataType || event.dataType === 'Hangout'
                          ? ''
                          : ` - ${event.dataType}`
                      }`}
                      onClick={() => {
                        setShowEventInfo(event);
                      }}
                      showImage
                    />
                  );
                })
              ) : (
                <View css={{ marginLeft: '5px' }}>
                  <Text>No scheduled events</Text>
                </View>
              ))}
          </List>
        </View>
      </Segment>
    </View>
  );
};

UserEventList.propTypes = {
  events: PropTypes.array,
  isLoading: PropTypes.bool,
  title: PropTypes.string
};

export default UserEventList;
