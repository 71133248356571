import React, { useContext, useEffect } from 'react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import View from '../../common/View';
import UserEventList from './UserEventList';

const UserEvents = () => {
  const { state, fetchUserEvents, fetchUserHangouts } = useContext(
    TeamManagementContext
  );

  useEffect(() => {
    if (state.user.value) {
      fetchUserEvents(state.user.value.user.pkUser);
      fetchUserHangouts(state.user.value.user.pkUser);
    }
  }, [state.user.value]);

  return (
    <View>
      <View aria-label="leaugeEvents" role="region">
        <UserEventList
          events={state.userEvents.value}
          isLoading={state.userEvents.loading}
          title="League Events"
        />
      </View>
      <View
        aria-label="hangoutEvents"
        css={{ marginTop: '15px' }}
        role="region"
      >
        <UserEventList
          events={state.userHangouts.value}
          isLoading={state.userHangouts.loading}
          title="Hangout Events"
        />
      </View>
    </View>
  );
};

UserEvents.propTypes = {};

export default UserEvents;
