import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router';
import {
  Card,
  Divider,
  Group,
  Indicator,
  ScrollArea,
  Stack,
  Text
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { Cash, FileInvoice, Hourglass } from 'tabler-icons-react';
import { mq } from '../config/theme';
import { Context as RegistrationContext } from '../providers/RegistrationProvider';
import OfflinePaymentsView from '../components/content/BillingView/OfflinePaymentsView';
import MissingTransfersView from '../components/content/BillingView/MissingTransfersView';

const BILLING_NAV = [
  {
    icon: <Hourglass color="black" height={25} width={25} />,
    label: 'Pending Invoice',
    to: 'pending',
    color: 'red',
    filter: (w) => !w.paid && !w.invoiced,
    isSelected: (url) => url.startsWith('/billing/pending')
  },
  {
    icon: <FileInvoice color="black" height={25} width={25} />,
    label: 'Invoiced',
    to: 'invoiced',
    color: 'yellow',
    filter: (w) => w.invoiced && !w.paid,
    isSelected: (url) => url.includes('/billing/invoiced')
  },
  {
    icon: <Cash color="black" height={25} width={25} />,
    label: 'Paid Up',
    to: 'paid',
    color: 'green',
    filter: (w) => w.paid && w.invoiced,
    isSelected: (url) => url.includes('/billing/paid')
  },
  {
    icon: <Cash color="black" height={25} width={25} />,
    label: 'Missing Transfers',
    to: 'missing-transfers',
    color: 'red',
    filter: (w) => w.paid && w.invoiced,
    isSelected: (url) => url.includes('/billing/missing-transfers')
  }
];

const BillingView = () => {
  const { fkRegAssociation } = useParams();
  const { state, fetchRegAssociations } = useContext(RegistrationContext);
  const location = useLocation();
  const missingTransferCount =
    state.regCartsMissingTransfersSummary.value?.regCartMissingTransferCount ??
    0;

  useEffect(() => {
    fetchRegAssociations();
  }, []);

  return (
    <Stack sx={{ flex: 1, gap: 0 }}>
      <Stack sx={{ flex: 1, gap: 10 }}>
        <Divider />
        <Group
          sx={mq({
            flex: 1,
            alignItems: 'start',
            flexDirection: ['column', 'column', 'row']
          })}
        >
          <Stack css={{ flex: 1, gap: 10, alignSelf: 'stretch' }}>
            <Card
              shadow="lg"
              sx={{ flex: 1, padding: 0, border: 'solid 1px lightgrey' }}
            >
              <ScrollArea
                offsetScrollbars
                style={{
                  flex: 1,
                  overflow: 'auto',
                  minHeight: '100%',
                  maxHeight: 450
                }}
                styles={{ viewport: { paddingBottom: 0, paddingRight: 0 } }}
              >
                <Stack
                  sx={{
                    flex: 1,
                    gap: 0
                  }}
                >
                  {BILLING_NAV.map((p) => {
                    const isSelected = p.isSelected(location.pathname);
                    const isTransferView = p.to === 'missing-transfers';

                    return (
                      <Group
                        key={p.to}
                        component={Link}
                        onClick={() => {}}
                        sx={{
                          flex: 1,
                          padding: 10,
                          cursor: 'pointer',
                          flexWrap: 'nowrap',
                          textDecoration: 'none',
                          color: '#000',
                          gap: 10,
                          backgroundColor: isSelected ? '#f4f4f4' : 'none',
                          '&:hover': {
                            color: 'black',
                            backgroundColor: '#f4f4f4'
                          }
                        }}
                        to={
                          fkRegAssociation
                            ? `/billing/${fkRegAssociation}/${p.to}`
                            : `/billing/${p.to}`
                        }
                      >
                        <Indicator
                          color={p.color}
                          label={
                            isTransferView
                              ? missingTransferCount
                              : state.regAssociationPaymentWindowsSummary.value.filter(
                                  p.filter
                                ).length
                          }
                          position="top-start"
                          size={20}
                          sx={{ marginLeft: 10 }}
                        >
                          {p.icon}
                        </Indicator>
                        <Stack sx={{ gap: 5, overflowWrap: 'anywhere' }}>
                          <Text
                            sx={{ fontSize: 16, lineHeight: '18px' }}
                            weight={isSelected ? 700 : 500}
                          >
                            {p.label}
                          </Text>
                        </Stack>
                      </Group>
                    );
                  })}
                </Stack>
              </ScrollArea>
            </Card>
          </Stack>
          <Stack
            sx={{
              flex: 3,
              alignSelf: 'stretch'
            }}
          >
            <Switch>
              <Route path="/billing/pending">
                <OfflinePaymentsView status="pending" />
              </Route>
              <Route path="/billing/invoiced">
                <OfflinePaymentsView status="invoiced" />
              </Route>
              <Route path="/billing/paid">
                <OfflinePaymentsView status="paid" />
              </Route>
              <Route path="/billing/missing-transfers">
                <MissingTransfersView status="paid" />
              </Route>
              <Route path="/">
                <Redirect to="/billing/pending" />
              </Route>
            </Switch>
          </Stack>
        </Group>
      </Stack>
    </Stack>
  );
};

BillingView.propTypes = {};

export default BillingView;
