import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Confirm, Form } from 'semantic-ui-react';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import ContentModal from '../../common/ContentModal';
import View from '../../common/View';
import Text from '../../common/Text';
import { mq } from '../../../config/theme';
import { TEAM_MEMBER_ROLE_OPTIONS } from '../../../config/constants';
import InputLabel from '../../common/InputLabel';

const EditTeamMemberRolesModal = ({ pkTeam, selectedMember, onClose }) => {
  const { updateTeamMemberRoles } = useContext(TeamManagementContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    if (selectedMember) {
      setSelectedRoles(selectedMember.roles);
    }
  }, [selectedMember]);

  return (
    <View>
      <ContentModal
        actionContent="Save Changes"
        ariaLabel="editRolesModal"
        error=""
        isOpen={selectedMember !== null}
        onAction={selectedRoles.length > 0 ? () => setShowConfirm(true) : null}
        onClose={onClose}
        size="tiny"
        title="Edit Roles"
      >
        <Form>
          <InputLabel>Roles</InputLabel>
          <Form.Group inline style={{ marginTop: '5px' }}>
            {TEAM_MEMBER_ROLE_OPTIONS.map((r) => (
              <Form.Field key={r.key}>
                <Checkbox
                  aria-label={r.value}
                  checked={selectedRoles.includes(r.value)}
                  label={r.text}
                  name={r.key}
                  onClick={(e, { value }) => {
                    let rolesCopy = [...selectedRoles];
                    if (rolesCopy.includes(value)) {
                      rolesCopy = rolesCopy.filter((s) => s !== value);
                    }
                    else {
                      rolesCopy.push(value);
                    }
                    setSelectedRoles(rolesCopy);
                  }}
                  role="checkbox"
                  value={r.value}
                />
              </Form.Field>
            ))}
          </Form.Group>
        </Form>
      </ContentModal>
      <Confirm
        aria-label="confirm"
        content={(
          <View css={{ margin: 20 }}>
            <Text>
              Are you sure you want to change <b>{selectedMember?.name}</b>{' '}
              roles to{' '}
              {selectedRoles.map((r, index) => (index > 0 ? `, ${r}` : r))}
            </Text>
          </View>
        )}
        css={mq({})}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() =>
          updateTeamMemberRoles(pkTeam, selectedMember.pkUser, selectedRoles)
        }
        open={selectedMember && showConfirm}
        role="dialog"
        size="tiny"
        style={{ margin: 0 }}
      />
    </View>
  );
};

EditTeamMemberRolesModal.propTypes = {
  onClose: PropTypes.func,
  pkTeam: PropTypes.number,
  selectedMember: PropTypes.object
};

export default EditTeamMemberRolesModal;
